import React, { useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Skeleton,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { SiteUser } from 'clipsal-cortex-types/src/api';
import { AlertDialogModal } from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import { ArcButton } from '../../../../common/components/ArcButton';
import { SlidingAnimationPageBase } from '../../../../common/components/SlidingAnimationPageBase';
import { useCreateInvitationMutation, useGetInvitationsQuery } from '../../../site/invitationsApi';
import { selectSite } from '../../../site/siteSlice';
import { useDeleteSiteUserMutation, useGetSiteUsersQuery } from '../../../site/siteUsersApi';
import { selectUser } from '../../../user/userSlice';

export function EditFamilyMembers() {
  const { data: invitations, isLoading } = useGetInvitationsQuery();
  const { data: users, isLoading: isSiteUsersLoading } = useGetSiteUsersQuery();
  const [isEditing, setEditing] = useState<boolean>(false);
  const isLoaded = !isLoading && !isSiteUsersLoading;
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase title={t('Account.edit family members')} backURL={`../view?direction=back`}>
      <Box m={4}>
        <Text fontWeight={600}>Members</Text>
        <Skeleton
          isLoaded={isLoaded}
          borderBottomLeftRadius={'5px'}
          borderBottomRightRadius={'25px'}
          borderTopRightRadius={'5px'}
          borderTopLeftRadius={'25px'}
        >
          {users?.map((user, i) => (
            <UserListItem key={user.user_id} data={user} index={i} />
          ))}

          {invitations?.map((invitation, i) => (
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              key={`pending-invitation-${invitation.user_email}-${i}`}
            >
              <Input
                my={2}
                value={invitation.user_email}
                size="md"
                data-testid={`pending-invitation-${i}`}
                readOnly
                borderRadius={0}
                _dark={{ background: 'customGrey.900' }}
                position="relative"
                paddingRight="80px"
              />
              <Badge position="absolute" mr={2} data-testid={`pending-badge-${i}`}>
                {t('Common.pending')}
              </Badge>
            </Flex>
          ))}
          {isEditing ? <AddNewFamilyMemberForm onFinishEdit={() => setEditing(false)} /> : <></>}
        </Skeleton>
        <Button
          mt={1}
          variant="ghost"
          colorScheme="schneiderSkyBlue"
          fontWeight={500}
          onClick={() => setEditing(true)}
          hidden={isEditing}
          data-testid="add-new-member-button"
          pl={0}
        >
          {t('Account.add family member')}
        </Button>
      </Box>
    </SlidingAnimationPageBase>
  );
}

function UserListItem({ data, index }: { data: SiteUser; index: number }) {
  const { user_email: email, user_id: userId } = data;
  const { t } = useTranslation();
  const { site_id: siteId } = useSelector(selectSite);
  const { userID, role } = useSelector(selectUser);
  const [deleteUser, { isLoading: isDeleteLoadig }] = useDeleteSiteUserMutation();
  const { refetch, isLoading: isUsersLoading } = useGetSiteUsersQuery();
  const toast = useToast();
  const { isOpen: isAlertDialogOpen, onOpen: onOpenAlertDialog, onClose: onCloseAlertDialog } = useDisclosure();

  const isLoading = isDeleteLoadig || isUsersLoading;

  async function handleDelete() {
    try {
      await deleteUser({ siteId, userId }).unwrap();
      await refetch().unwrap(); // even if mutation is invalidating user fetch query, we need to wait for it to be updated.
      onCloseAlertDialog();
    } catch {
      toast({
        status: 'error',
        title: t('Common.something went wrong'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
      });
    }
  }

  return (
    <Flex alignItems="center" justifyContent="flex-end">
      <Input
        my={2}
        value={email}
        size="md"
        readOnly
        borderRadius={0}
        _dark={{ background: 'customGrey.900' }}
        data-testid={`family-member-${index}`}
        position="relative"
        paddingRight="80px"
      />
      <Button
        hidden={userID === userId || role === 'ADMIN'}
        size="xs"
        variant="solid"
        colorScheme="red"
        fontWeight={500}
        onClick={onOpenAlertDialog}
        data-testid={`delete-button-${index}`}
        position={'absolute'}
        mr={3}
        zIndex={1}
      >
        {t('Common.delete')}
      </Button>
      <AlertDialogModal
        header={t('Account.remove family member')}
        subHeader=" "
        isOpen={isAlertDialogOpen}
        onClose={onCloseAlertDialog}
        confirmButtonTextColor={'customRed.500'}
        isConfirming={isLoading}
        onConfirm={handleDelete}
      />
    </Flex>
  );
}

type AddFamilyMemberFormData = {
  email: string;
};

type EditPersonalDetailsProps = {
  onFinishEdit: () => void;
};

function AddNewFamilyMemberForm({ onFinishEdit }: EditPersonalDetailsProps) {
  const { site_id: siteId } = useSelector(selectSite);
  const toast = useToast({ isClosable: true });
  const [createInvitation, { isLoading }] = useCreateInvitationMutation();
  const { t } = useTranslation();
  const schema = yup.object({
    email: yup.string().max(100).email().trim().required().label(t('Common.email')),
  });
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<AddFamilyMemberFormData>({
    resolver: yupResolver(schema),
  });

  async function handleSubmit(values: AddFamilyMemberFormData) {
    try {
      await createInvitation({ siteId, ...values }).unwrap();
    } catch {
      toast({
        status: 'error',
        title: t('Account.error inviting family member'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
      });
    }

    onFinishEdit();
  }

  return (
    <Box data-testid="add-new-family-member-form" mb={16}>
      <FormControl isInvalid={!!errors?.email} my={2} mb={6}>
        <Input
          borderRadius={0}
          _dark={{ background: 'customGrey.900' }}
          size="md"
          data-testid="email-input"
          placeholder={`${t('Common.email')}...`}
          {...register(`email`)}
        />
        <FormErrorMessage data-testid="email-error">{errors?.email?.message}</FormErrorMessage>
      </FormControl>
      <Center>
        <ArcButton
          arcColor="#3DCD57"
          onClick={handleFormSubmit(handleSubmit)}
          width="90%"
          isLoading={isLoading}
          data-testid="save-button"
        >
          {t('Common.save')}
        </ArcButton>
      </Center>
    </Box>
  );
}
