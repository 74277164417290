import { ComponentType } from 'react';
import { ComponentWithAs, IconProps } from '@chakra-ui/react';

import { Device, DeviceType } from 'clipsal-cortex-types/src/api/api-site';

import { MANUFACTURER_ID_SATURN } from '../../devices/devices-helper';
import { HOMEPAGE_WIDGETS } from './constants';
import { HomePageWidgetTileProps } from './HomePageWidgetTile';

// TODO: Add Generator to DeviceType when available
const WIDGET_TYPE_TO_DEVICE_TYPE: Record<Exclude<WidgetType, 'LIVE_ENERGY'>, DeviceType> = {
  SOLAR: 'INVERTER',
  ENERGY_MODE: 'INVERTER',
  SAVINGS: 'INVERTER',
  ECO_SCORE: 'INVERTER',
  BATTERY: 'BATTERY_PACK',
  EV_CHARGER: 'EV_CHARGER',
  GENERATOR: 'GENERATOR' as DeviceType,
};

export type WidgetType =
  | 'LIVE_ENERGY'
  | 'SOLAR'
  | 'ENERGY_MODE'
  | 'SAVINGS'
  | 'ECO_SCORE'
  | 'BATTERY'
  | 'EV_CHARGER'
  | 'GENERATOR';

export type HomepageWidget = {
  id: number;
  title: string;
  type: WidgetType;
  Icon: ComponentWithAs<'svg', IconProps>;
  link: string;
  status: string;
  Component: ComponentType<HomePageWidgetTileProps>;
};

/**
 * Parses a site's devices and returns Widget IDs for them
 *
 * @param devices - An array of devices
 */
export function getWidgetIDsForDevices(devices: Device[]) {
  return getWidgetsForDevices(devices).map((widget) => widget.id);
}

/**
 * Parses a site's devices and returns Widgets for them
 *
 * @param devices - An array of devices
 */
function getWidgetsForDevices(devices: Device[]) {
  const devicesType = devices.map((d) => d.device_type);

  return HOMEPAGE_WIDGETS.filter((widget) => {
    switch (widget.type) {
      case 'LIVE_ENERGY':
        // Requires Saturn inverter or Sense meter.
        return devicesType.includes('INVERTER') || devicesType.includes('METER');
      case 'ENERGY_MODE':
        // Requires Saturn Inverter
        return devices.some(
          ({ device_type: deviceType, manufacturer_id: manufacturerId }) =>
            deviceType === 'INVERTER' && [MANUFACTURER_ID_SATURN].includes(manufacturerId)
        );
      default:
        return devicesType.includes(WIDGET_TYPE_TO_DEVICE_TYPE[widget.type]);
    }
  });
}

/**
 * Returns Widgets from an array of Widget IDs in their order.
 *
 * @param widgetIDs - Array of Widget IDs
 */
function getWidgetsByIDs(widgetIDs: number[]) {
  const widgets: HomepageWidget[] = [];
  widgetIDs.forEach((cardId: number) => {
    const widget = HOMEPAGE_WIDGETS.find((card: HomepageWidget) => card.id === cardId);
    widget && widgets.push(widget);
  });
  return widgets;
}

/**
 * Returns Widget IDs from localStorage
 */
function getWidgetsFromStore(siteId: number) {
  const userCardSelection = localStorage.getItem(`homeGridItems_${siteId}`);
  let userCardSelectionArray: number[] = [];
  if (userCardSelection) {
    userCardSelectionArray = JSON.parse(userCardSelection);
  }
  return userCardSelectionArray;
}

/**
 * Returns Widgets from the store if they are valid (ie. the site has the device for a Widget)
 */
export function getWidgets({ devices, siteId }: { devices: Device[]; siteId: number }) {
  const widgetIDsFromStore = getWidgetsFromStore(siteId);

  // Either nothing in the store, or they turned them all off -- display them all in both cases
  if (!widgetIDsFromStore.length) {
    return getWidgetsForDevices(devices);
  } else {
    return getWidgetsByIDs(widgetIDsFromStore);
  }
}

/**
 * Returns Widget IDs from the store if they are valid (ie. the site has the device for a Widget)
 */
export function getWidgetIDs({ devices, siteId }: { devices: Device[]; siteId: number }) {
  return getWidgets({ devices, siteId }).map((w) => w.id);
}
