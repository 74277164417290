import React, { useEffect, useState } from 'react';
import { Browser } from '@capacitor/browser';
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useReduxDispatch } from '../../app/store';
import { LanguageSelector } from '../../common/components/LanguageSelector';
import { SEHomeCard } from '../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../common/components/SlidingAnimationPageBase';
import { IS_DEMO_LOGIN } from '../../common/constants';
import { SUPPORTED_LOCALES } from '../../i18n';
import { getFreshEimTokens, getIdpEditProfileUrl } from '../auth/auth-helpers';
import { selectUser, updateUserDetails } from '../user/userSlice';

type State = {
  isEditing: boolean;
  isSubmitting: boolean;
};

const FORM_ID = 'edit-personal-details-form';

export const PersonalDetails = () => {
  const user = useSelector(selectUser);
  const [state, setState] = useState<State>({ isEditing: false, isSubmitting: false });
  const { isEditing, isSubmitting } = state;
  const navigate = useNavigate();
  const { t } = useTranslation();
  useAuthenticateOnBrowserClose();

  return (
    <SlidingAnimationPageBase
      includeTopNav
      px={3}
      onClickBack={() => {
        if (isEditing) {
          setState((prevState) => ({ ...prevState, isEditing: false }));
        } else {
          navigate('../dashboard?direction=back');
        }
      }}
      title={isEditing ? t('Account.edit profile') : t('Account.profile')}
      customTopNavButton={
        <Button
          mr={2}
          px={1}
          isDisabled={IS_DEMO_LOGIN}
          data-testid={isEditing ? 'submit-personal-details-form' : 'edit-personal-details-button'}
          variant={'ghost'}
          form={isEditing ? FORM_ID : undefined}
          fontSize="0.8em"
          fontWeight={400}
          colorScheme={'schneiderSkyBlue'}
          type={isEditing ? 'submit' : 'button'}
          onClick={(e) => {
            if (!isEditing) {
              e.preventDefault();
              e.stopPropagation();
              setState((prevState) => ({ ...prevState, isEditing: true }));
            }
          }}
          isLoading={isSubmitting}
        >
          {isEditing ? t('Common.save') : t('Common.edit')}
        </Button>
      }
    >
      <Center>
        <Avatar size={'xl'} name={`${user.firstName} ${user.lastName}`} />
      </Center>
      {isEditing ? (
        <EditPersonalDetails
          onSetSubmitting={(isSubmitting) => setState((prevState) => ({ ...prevState, isSubmitting }))}
          onFinishEdit={() => setState((prevState) => ({ ...prevState, isEditing: false, isSubmitting: false }))}
        />
      ) : (
        <ViewPersonalDetails />
      )}
    </SlidingAnimationPageBase>
  );
};

function ViewPersonalDetails() {
  const user = useSelector(selectUser);
  const bgColor = useColorModeValue('customGrey.300', 'customGrey.500');
  const { t, i18n } = useTranslation();

  return (
    <SEHomeCard py={0} borderTopLeftRadius={'5px'} mt={3}>
      <Text data-testid="full-name-label" py={5}>
        {user.firstName} {user.lastName}
      </Text>
      <Divider borderColor="rgba(151, 151, 151, 0.3)" />
      <Flex data-testid="email-label" justify={'space-between'} alignItems="center" py={5}>
        <Box w={'70%'}>{user.email}</Box>
        <Button
          data-testid="update-email-btn"
          onClick={async () => {
            await Browser.open({ url: await getIdpEditProfileUrl(), windowName: '_self' });
          }}
          bg={bgColor}
          isDisabled={IS_DEMO_LOGIN}
          fontSize={'0.75em'}
          whiteSpace="pre-wrap"
          ml={1}
        >
          {t('Common.update').toUpperCase()}
        </Button>
      </Flex>
      <Divider borderColor="rgba(151, 151, 151, 0.3)" />

      {IS_DEMO_LOGIN ? (
        <LanguageSelector py={6} />
      ) : (
        <Text py={6}>
          {t('Common.language')}: {SUPPORTED_LOCALES[i18n.language]}
        </Text>
      )}
    </SEHomeCard>
  );
}

type UserProfileFormData = {
  firstName: string;
  lastName: string;
};

type EditPersonalDetailsProps = {
  onSetSubmitting: (isSubmitting: boolean) => void;
  onFinishEdit: () => void;
};

function EditPersonalDetails({ onFinishEdit, onSetSubmitting }: EditPersonalDetailsProps) {
  const user = useSelector(selectUser);
  const toast = useToast({ isClosable: true });
  const dispatch = useReduxDispatch();
  const bgColor = useColorModeValue('white', 'customGrey.900');
  const { t } = useTranslation();
  const schema = yup.object().shape({
    firstName: yup.string().max(40).required().label(t('Account.first name')),
    lastName: yup.string().max(40).required().label(t('Account.last name')),
  });
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<UserProfileFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
  });

  async function handleSubmit(values: UserProfileFormData) {
    onSetSubmitting(true);

    try {
      await dispatch(updateUserDetails(values));
    } catch (e) {
      console.error(e);
      toast({
        status: 'error',
        title: t('Account.error updating profile'),
        description: `${t('Common.please try again')}. ${t('Common.if this persists contact support')}.`,
      });
    }
    onFinishEdit();
  }

  return (
    <SEHomeCard borderTopLeftRadius={'5px'} mt={3}>
      <Box as={'form'} id={FORM_ID} onSubmit={handleFormSubmit(handleSubmit)}>
        <Box pt={3} pb={5}>
          <FormControl isInvalid={!!errors?.firstName}>
            <Input
              height="50px"
              borderRadius={2}
              border="1px solid #9FA0A4"
              backgroundColor={bgColor}
              size="md"
              data-testid="edit-first-name-input"
              placeholder={`${t('Account.first name')}...`}
              {...register(`firstName`)}
            />
            <FormErrorMessage data-testid="first-name-error">{errors?.firstName?.message}</FormErrorMessage>
          </FormControl>
        </Box>
        <Divider borderColor="rgba(151, 151, 151, 0.3)" />
        <Box py={5}>
          <FormControl isInvalid={!!errors?.lastName}>
            <Input
              height="50px"
              borderRadius={2}
              border="1px solid #9FA0A4"
              backgroundColor={bgColor}
              size="md"
              data-testid="edit-last-name-input"
              placeholder={`${t('Account.last name')}...`}
              {...register(`lastName`)}
            />
            <FormErrorMessage data-testid="last-name-error">{errors?.lastName?.message}</FormErrorMessage>
          </FormControl>
        </Box>
        <Divider borderColor="rgba(151, 151, 151, 0.3)" />
        <Box py={5}>
          <LanguageSelector />
        </Box>
      </Box>
    </SEHomeCard>
  );
}

/**
 * Covers the case when the user logs out of EIM in the in-app browser (e.g. when they change their email or password).
 * It refreshes the EIM session.
 */
function useAuthenticateOnBrowserClose() {
  const navigate = useNavigate();

  useEffect(() => {
    Browser.addListener('browserFinished', async () => {
      try {
        await getFreshEimTokens();
      } catch (e) {
        console.error('Failed to refresh the EIM session after they changed their details.');
        navigate('/login');
      }
    });
  }, []);
}
