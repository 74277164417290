import { useSelector } from 'react-redux';

import { Device } from 'clipsal-cortex-types/src/api';

import { GENERIC_BATTERIES_MANUFACTURER_IDS } from '../../battery/constants';
import { selectSite } from '../../site/siteSlice';
import { useSource } from './use-source';

export function useInitialSelectedPowerSources(devices: Device[]) {
  const source = useSource();
  const { is_consumption_site: isConsumptionSite } = useSelector(selectSite);

  if (source) {
    return {
      solar: source === 'solar',
      battery: source === 'battery',
      // generator: source === 'generator',
      grid: source === 'grid',
    };
  }

  const hasBattery = devices.some(
    (d) => d.device_type === 'BATTERY_PACK' && !GENERIC_BATTERIES_MANUFACTURER_IDS.includes(d.manufacturer_id)
  );
  const hasInverter = devices.some((d) => d.device_type === 'INVERTER');
  const hasMeter = devices.some((d) => d.device_type === 'METER');

  return {
    solar: hasInverter || (hasMeter && !isConsumptionSite),
    battery: hasBattery,
    generator: false,
    grid: (hasBattery && hasInverter) || hasMeter,
  };
}
