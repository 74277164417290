import React, { useMemo } from 'react';
import { Alert, AlertIcon, Skeleton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DateRangeType } from '../../../../common/components/DateRangeTypePicker';
import { GridIcon, SEHomeBatteryIcon, SEHomeSolarIcon } from '../../../../styles/custom-icons';
import { useGetSavingsQuery } from '../../../savings/savingsApi';
import { useGetCostsQuery } from '../../../site/costApi';
import { useLiveData } from '../../../site/live-data/liveDataApi';
import { selectCurrentDayForSite } from '../../../site/siteSlice';
import { SenseAccordionItem } from '../common/SenseAccordionItem';
import { SensePowerCard } from '../common/SensePowerCard';
import { SOURCES_COLOURS } from '../constants';

export function PowerSources() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentDay = useSelector(selectCurrentDayForSite);
  const {
    data: costsData,
    isLoading: isCostsLoading,
    isError: isCostsError,
  } = useGetCostsQuery(currentDay, DateRangeType.Day);
  const {
    data: savingsData,
    isLoading: isSavingsLoading,
    isError: isSavingsError,
  } = useGetSavingsQuery(currentDay, DateRangeType.Day);
  const { data: liveData, isLoading: isLiveLoading, isMeterError: isLiveError } = useLiveData();
  const isLoaded = !isLiveLoading && !isCostsLoading && !isSavingsLoading;
  const isError = isCostsError || isSavingsError || isLiveError;

  const sources = useMemo(
    () => [
      {
        uri: '../solar?backURL=../home',
        borderColor: SOURCES_COLOURS.solar,
        name: t('Solar.solar'),
        power: liveData.solar,
        cost: savingsData.reduce((total, { avoided_cost: avoidedCost }) => total + avoidedCost, 0),
        Icon: SEHomeSolarIcon,
        dataTestId: 'solar-power-summary-card',
        costLabel: `${t('Solar.savings')}: `,
      },
      {
        uri: '../activity?backURL=../home',
        borderColor: SOURCES_COLOURS.grid,
        name: t('Grid.grid'),
        power: liveData.grid,
        cost: costsData.costData[0]?.components?.find(({ type }) => type === 'import')?.amount || 0,
        Icon: GridIcon,
        dataTestId: 'grid-power-summary-card',
      },
      {
        uri: '../battery?backURL=../home',
        borderColor: SOURCES_COLOURS.battery,
        name: t('Battery.battery'),
        power: liveData.battery,
        cost: undefined,
        soc: liveData.battery_soc_fraction,
        Icon: SEHomeBatteryIcon,
        dataTestId: 'battery-power-summary-card',
      },
    ],
    [liveData.solar, liveData.grid, liveData.battery, costsData.costData, savingsData]
  );

  return (
    <SenseAccordionItem title={t('Home Sense.power sources')} dataTestId="power-sources-list">
      <Skeleton
        isLoaded={isLoaded}
        borderRadius={5}
        borderBottomRightRadius="25px"
        height={isLoaded ? 'unset' : 75}
        my={4}
      >
        {isError ? (
          <Alert status="error" variant="left-accent" data-testid="error-alert">
            <AlertIcon />
            {t('Common.error fetching data')}
          </Alert>
        ) : (
          sources
            .filter(({ power }) => power !== undefined)
            .map(({ uri, power, ...props }) => (
              <SensePowerCard key={uri} onClick={() => navigate(uri)} power={power as number} {...props} />
            ))
        )}
      </Skeleton>
    </SenseAccordionItem>
  );
}
