import React from 'react';
import { BleClient } from '@capacitor-community/bluetooth-le';
import { Box, Center, Heading, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ErrorStatusIcon } from '../../../../styles/custom-icons';

/* istanbul ignore next -- @preserve */
export function PermissionNotGranted() {
  const { t } = useTranslation();

  return (
    <Box mx={3}>
      <Center w="100%" flexDirection="column">
        <ErrorStatusIcon height={200} width={200} />
        <Heading size="md" my={3}>
          {t('Set Up Hardware.try again ensure wifi permission')}
        </Heading>
        <Link
          fontSize={18}
          color="schneiderSkyBlue.600"
          onClick={async () => {
            await BleClient.openAppSettings();
          }}
        >
          {t('Common.go to settings')}
        </Link>
      </Center>
    </Box>
  );
}
