import React from 'react';
import { Center, Heading, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import semCommissioningSuccessful from '../../../../assets/images/sem_commissioning_successful.svg';
import { ArcButton } from '../../../../common/components/ArcButton';
import { SlidingAnimationPageBase } from '../../../../common/components/SlidingAnimationPageBase';
import { useOnboardingWizardProgress } from '../../../home/useOnboardingWizardProgress';
import { selectSite } from '../../../site/siteSlice';

export function MeterSetupComplete() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { needsToVisitTariffs } = useOnboardingWizardProgress();
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase title={t('Set Up Hardware.setup complete')}>
      <Center flexDirection="column" px={3} mt={6} textAlign="center">
        <Image w={'70%'} src={semCommissioningSuccessful} alt="SEM configuration complete" />

        <Heading mt={2}>{t('Set Up Hardware.setup successful')}</Heading>
        <Text mt={1}>
          {t('Set Up Hardware.we setup your device', {
            device: t('Set Up Hardware.schneider energy monitor'),
          })}
        </Text>
        <ArcButton
          data-testid={'meter-finish'}
          onClick={() => {
            const deviceId = search.get('deviceId') || '';
            // If we have a deviceId, we are coming from the meter details page
            if (deviceId) {
              navigate(`../../../${deviceId}/view`);
            } else if (needsToVisitTariffs) {
              const redirectBackURL = `/sites/${site.site_id}/account/hardware/configure/meter/finish`;
              navigate(`../../../../tariff/new/home?backURL=${redirectBackURL}`);
            } else {
              navigate(`../../../../../home`);
            }
          }}
          mt={3}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {t('Common.continue')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
