import React from 'react';
import { Box, Flex, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type EditDeviceFormData = {
  displayName: string;
};

type Props = {
  onSubmit: (formValues: EditDeviceFormData) => void;
  displayName: string;
  formId: string;
};

export function EditMatterDeviceForm({ displayName, onSubmit, formId }: Props) {
  const { t } = useTranslation();
  const editDeviceSchema = yup.object().shape({
    displayName: yup.string().max(45).trim().label(t('Common.name')).required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditDeviceFormData>({
    resolver: yupResolver(editDeviceSchema),
    defaultValues: {
      displayName,
    },
  });

  return (
    <Box as="form" id={formId} onSubmit={handleSubmit(onSubmit)} data-testid="edit-matter-device-form">
      <Flex flexDirection="column">
        <FormControl isInvalid={!!errors?.displayName}>
          <Input
            data-testid="edit-device-name-input"
            placeholder={`${t('Devices.name your device')}...`}
            {...register(`displayName`)}
            borderRadius={2}
            background="#F1F1F1"
            _dark={{ background: 'customGrey.800' }}
            height="50px"
            border="1px solid #9FA0A4"
          />
          <FormErrorMessage>{errors?.displayName?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
    </Box>
  );
}
