import {
  Notification,
  NotificationSeverity,
  NotificationToUpdate,
} from 'clipsal-cortex-types/src/api/api-notifications';
import { PaginatedResponseV2 } from 'clipsal-cortex-types/src/common/types';
import { useAppVisibility } from 'clipsal-cortex-utils/src/hooks/use-app-visibility';

import { baseApi } from '../../app/services/baseApi';
import { NOTIFICATION_POLLING_TIME_MS } from './constants';

export type NotificationGrouping = 'UNREAD' | 'PRIORITY' | 'CHRONOLOGICAL';

export type CommonNotificationQueryParams = {
  limit: number;
  offset: number;
  archived: boolean;
  severity?: NotificationSeverity;
  read?: boolean;
};

export const notificationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<PaginatedResponseV2<Notification>, CommonNotificationQueryParams>({
      query: ({ limit, offset, archived, severity, read }) => {
        let uri = `/v1/notifications?app_name=SE_HOME&limit=${limit}&offset=${offset}&archived=${archived}`;
        if (severity) uri += `&severity=${severity}`;
        if (read !== undefined) uri += `&read=${read.toString()}`;
        return uri;
      },
      providesTags: ['Notifications'],
    }),
    getNotificationById: builder.query<Notification, string>({
      query: (notificationId) => `/v1/notifications/${notificationId}?app_name=SE_HOME`,
      providesTags: ['Notification'],
    }),
    updateNotificationById: builder.mutation<Notification, { notificationId: string; body: NotificationToUpdate }>({
      query: ({ notificationId, body }) => ({
        url: `/v1/notifications/${notificationId}?app_name=SE_HOME`,
        method: 'PATCH',
        body,
      }),
      // With the current caching architecture, we need to invalidate all notification list endpoints
      invalidatesTags: ['Notifications', 'Notification'],
    }),
    deleteNotificationById: builder.mutation<void, string>({
      query: (notificationId) => ({
        url: `/v1/notifications/${notificationId}?app_name=SE_HOME`,
        method: 'DELETE',
      }),
      // With the current caching architecture, we need to invalidate all notification list endpoints
      invalidatesTags: ['Notifications', 'Notification'],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useUpdateNotificationByIdMutation,
  useGetNotificationByIdQuery,
  useDeleteNotificationByIdMutation,
} = notificationsApi;

export function useGetNotifications(
  groupingType: NotificationGrouping,
  params: CommonNotificationQueryParams = { limit: 10, offset: 0, archived: false }
) {
  const isAppVisible = useAppVisibility();
  const pollingInterval = isAppVisible ? NOTIFICATION_POLLING_TIME_MS : 0;
  const queries = {
    chronological: useGetNotificationsQuery(params, { skip: groupingType !== 'CHRONOLOGICAL', pollingInterval }),
    read: useGetNotificationsQuery({ ...params, read: true }, { skip: groupingType !== 'UNREAD', pollingInterval }),
    unread: useGetNotificationsQuery({ ...params, read: false }, { skip: groupingType !== 'UNREAD', pollingInterval }),
    alarm: useGetNotificationsQuery(
      { ...params, severity: 'ALARM' },
      { skip: groupingType !== 'PRIORITY', pollingInterval }
    ),
    info: useGetNotificationsQuery(
      { ...params, severity: 'INFO' },
      { skip: groupingType !== 'PRIORITY', pollingInterval }
    ),
    warning: useGetNotificationsQuery(
      { ...params, severity: 'WARNING' },
      { skip: groupingType !== 'PRIORITY', pollingInterval }
    ),
  };

  return {
    data: queries,
    isLoading: Object.values(queries).some((q) => q.isLoading),
    isFetching: Object.values(queries).some((q) => q.isFetching),
    isError: Object.values(queries).some((q) => q.isError),
  };
}
