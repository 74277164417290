import React, { useEffect, useMemo } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useReduxDispatch } from '../../../../app/store';
import { SEHomeCard } from '../../../../common/components/SEHomeCard';
import { useGetSwitchSchedules } from './schedulerApi';
import { selectSchedules, setSchedulerState } from './schedulerSlice';
import { SwitchSchedule } from './SwitchSchedule';
import { mapAPISchedulesToUI } from './utils';

type Props = {
  switchId: number;
};

export function SwitchScheduleList({ switchId }: Props) {
  const { schedules: apiSchedules, isLoading, isError, isFetching } = useGetSwitchSchedules(switchId);
  const dispatch = useReduxDispatch();
  const { schedules, isLoaded: isUiLoaded } = useSelector(selectSchedules);
  const isEditing = useMemo(() => schedules.some((s) => s.isEditing), [schedules]);
  const { t } = useTranslation();
  const isLoaded = isUiLoaded && !isLoading;

  // Populate state from API if applicable
  useEffect(() => {
    if (!isLoading && !isError) {
      if (apiSchedules.length) {
        const schedules = mapAPISchedulesToUI(apiSchedules);
        dispatch(
          setSchedulerState({
            schedules,
            isLoaded: true,
            // Save a copy of the last saved schedules to compare for unsaved changes
            previouslySavedSchedules: schedules,
          })
        );
      } else {
        dispatch(
          setSchedulerState({
            // Filter out any schedules which might've been removed from the API
            schedules: schedules.filter((s) => !s.isEditing && !s.startScheduleId && !s.endScheduleId),
            isLoaded: true,
          })
        );
      }
    }
  }, [apiSchedules, isError, isLoading, isFetching]);

  function handleAddSchedule() {
    dispatch(
      setSchedulerState({
        schedules: [
          ...schedules,
          {
            isEditing: true,
            startScheduleId: null,
            endScheduleId: null,
            daysOfWeek: [],
            startTime: null,
            endTime: null,
            name: `${t('Devices.schedule')} ${schedules.length + 1}`,
            startSubType: '',
            endSubType: '',
            startOffset: 0,
            endOffset: 0,
            deviceLevel: null,
          },
        ],
        previouslySavedSchedules: schedules,
      })
    );
  }

  if (isError) {
    return (
      <Box mt={3}>
        <Alert status="error" variant="left-accent" data-testid="switch-error-alert">
          <AlertIcon />
          <Box>
            <AlertTitle>{t('Common.notice')}</AlertTitle>
            <AlertDescription>
              {t('Devices.error fetching schedules')}.{' '}
              {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}.
            </AlertDescription>
          </Box>
        </Alert>
      </Box>
    );
  }

  return (
    <Box mt={3}>
      <Flex mb={3} borderBottom="1px solid" borderColor="#C6C6C6" align="Center" justify="space-between">
        <Box>{t('Devices.schedules').toUpperCase()}</Box>

        <Button
          data-testid="add-switch-schedule"
          onClick={handleAddSchedule}
          size="sm"
          variant="ghost"
          colorScheme="schneiderSkyBlue"
          fontWeight={400}
          isDisabled={isEditing || !isLoaded}
        >
          {t('Common.add')}
        </Button>
      </Flex>

      <Skeleton isLoaded={isLoaded}>
        {schedules.length ? (
          schedules.map((schedule, i) => (
            <SwitchSchedule
              key={`start-${schedule.startScheduleId}_end-${schedule.endScheduleId}`}
              scheduleIndex={i}
              switchId={switchId}
            />
          ))
        ) : (
          <SEHomeCard data-testid="empty-schedules-card">
            <Text color="#8A8A8D" my={2}>
              {t('Devices.empty schedules text')}
            </Text>
          </SEHomeCard>
        )}
      </Skeleton>
    </Box>
  );
}
