import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DateRangeType } from '../../../../common/components/DateRangeTypePicker';
import { useGetCostsQuery } from '../../../site/costApi';
import { useLiveData } from '../../../site/live-data/liveDataApi';
import { selectCurrentDayForSite } from '../../../site/siteSlice';

export interface LiveDeviceWithCost {
  switchId?: number | null;
  applianceId?: number | null;
  assignment: string;
  name: string;
  power: number;
  cost: number;
}

export function useLiveDevicesWithCost() {
  const currentDay = useSelector(selectCurrentDayForSite);
  const { data: liveData, isLoading: isLiveDataLoading, isMeterError } = useLiveData();
  const {
    data: costsData,
    isLoading: isCostsLoading,
    isError: isCostsError,
  } = useGetCostsQuery(currentDay, DateRangeType.Day);
  const isLoaded = !isLiveDataLoading && !isCostsLoading;
  const isError = isMeterError || isCostsError;

  const costLookup = useMemo(() => {
    if (!costsData?.devices) return {};
    return costsData.devices.reduce(
      (acc: Record<number, number>, { appliance_id: applianceId, switch_id: switchId, cost }) => {
        acc[applianceId ?? switchId] = cost;
        return acc;
      },
      {}
    );
  }, [costsData]);

  const devicesWithCost = useMemo<LiveDeviceWithCost[]>(() => {
    if (!liveData || !costsData) return [];

    const appliances = liveData.appliances.map(
      ({ appliance_id: applianceId, display_name: displayName, power, assignment }) => ({
        applianceId,
        assignment,
        name: displayName,
        power,
        cost: applianceId ? costLookup[applianceId] || 0 : 0,
      })
    );

    const switches = liveData.switches.map(
      ({ switch_id: switchId, name, site_switch_label: siteSwitchLabel, power, assignment }) => ({
        switchId,
        assignment: assignment ?? 'load_other',
        name: siteSwitchLabel ?? name ?? '',
        power,
        cost: switchId ? costLookup[switchId] || 0 : 0,
      })
    );

    return [...appliances, ...switches];
  }, [costsData, liveData]);

  return {
    devicesWithCost,
    liveData,
    costsData,
    isLoaded,
    isError,
  };
}
