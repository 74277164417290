import React from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CHARGE_PERIODS } from 'clipsal-cortex-types/src/api/api-tariffs-v2';

import { useTranslatedCommonInputProps } from './tariff-constants';
import { useTariffFormContext } from './useTariffFormContext';

export const MinimumDeliveryCharge = () => {
  const { errors, register } = useTariffFormContext();
  const { t } = useTranslation();
  const COMMON_INPUT_PROPS = useTranslatedCommonInputProps();
  const optionStyles = useColorModeValue(
    { background: 'white', color: 'black' },
    { background: '#27282A', color: 'white' }
  );

  return (
    <Box>
      <Text fontWeight={'bold'} lineHeight={'19px'} mb={3}>
        {t('Energy Rates.minimum delivery charge')}
      </Text>
      <FormControl
        isInvalid={!!errors?.tariff?.deliveryCharge?.rateBands?.[0]?.rate?.message}
        data-testid="delivery-charge-form"
      >
        <InputGroup>
          <InputLeftElement pointerEvents="none" color="gray.300" h={12}>
            $
          </InputLeftElement>
          <Input
            {...COMMON_INPUT_PROPS}
            pr={108}
            {...register('tariff.deliveryCharge.rateBands.0.rate', { valueAsNumber: true })}
          />
          <InputRightElement w={108}>
            <Select
              data-testid="delivery-rate-charge-period"
              size="lg"
              {...{
                borderRadius: 0,
                h: 12,
                mt: 2,
                fontSize: '15px',
                border: 'none',
                background: 'transparent',
              }}
              {...register('tariff.deliveryCharge.chargePeriod')}
            >
              {CHARGE_PERIODS.map((option) => (
                <option key={option} value={option} style={{ ...optionStyles, fontSize: '14px' }}>
                  / {t(`Energy Rates.units.${option}`)}
                </option>
              ))}
            </Select>
          </InputRightElement>
        </InputGroup>

        <FormErrorMessage>{errors?.tariff?.deliveryCharge?.rateBands?.[0]?.rate?.message}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};
