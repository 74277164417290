import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Skeleton,
  SkeletonCircle,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsThreeDots } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';

import { SEHomeCard } from '../../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { DeviceActivity } from '../DeviceActivity';
import { DEVICE_ICON_RESPONSIVE_SIZE } from '../devices-helper';
import { DeviceStatusIcon } from '../DeviceStatusIcon';
import { SwitchScheduleList } from '../switches/schedules/SwitchScheduleList';
import { DimmerSlider } from './DimmerSlider';
import { getMatterDeviceType, useAylaSwitchData, useTranslatedUIConfig } from './matter-helpers';
import { MatterSwitchToggle } from './MatterSwitchToggle';

export function ViewMatterDevice() {
  const navigate = useNavigate();
  const params = useParams<{ switchId: string }>();
  const switchId = Number(params.switchId);
  const { t } = useTranslation();
  const { data: aylaSwitch, isLiveDataLoading, isApiDataLoading, isApiSwitchError } = useAylaSwitchData(switchId);
  const MATTER_DEVICES_UI_CONFIG = useTranslatedUIConfig();
  const deviceType = getMatterDeviceType(aylaSwitch?.display_name);

  return (
    <SlidingAnimationPageBase
      backURL={`../../../devices/list?direction=back`}
      includeBottomNav
      title={t('Devices.smart device')}
      customTopNavButton={
        <Button
          data-testid="edit-button"
          onClick={() => navigate(`../edit?direction=forward`)}
          variant={'ghost'}
          size={'sm'}
          hidden={isApiSwitchError}
          fontWeight={400}
          colorScheme="schneiderSkyBlue"
        >
          {t('Common.edit')}
        </Button>
      }
    >
      <Box data-testid="matter-device-detail-contents" px={3}>
        {isApiSwitchError ? (
          <Alert status="error" variant="left-accent" data-testid="error-alert">
            <AlertIcon />
            <Box>
              <AlertTitle>{t('Common.notice')}</AlertTitle>
              <AlertDescription>
                {t('Devices.error fetching device')}.{' '}
                {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}
              </AlertDescription>
            </Box>
          </Alert>
        ) : (
          <>
            <Flex position="absolute" right={0}>
              <IconButton
                aria-label="Device specs"
                variant="unstyled"
                height="auto"
                icon={<BsThreeDots size={26} />}
                data-testid="device-specs-button"
                onClick={() => navigate(`../specs`)}
              />
            </Flex>
            <SkeletonCircle
              isLoaded={!isLiveDataLoading}
              w={DEVICE_ICON_RESPONSIVE_SIZE}
              h={DEVICE_ICON_RESPONSIVE_SIZE}
              m="auto"
            >
              {aylaSwitch && (
                <DeviceStatusIcon
                  isOn={aylaSwitch.state === 'closed'}
                  icon={MATTER_DEVICES_UI_CONFIG[deviceType].icon}
                />
              )}
            </SkeletonCircle>
            <Skeleton isLoaded={!isApiDataLoading} borderBottomRightRadius="25px" mt={2} mb={2}>
              <SEHomeCard borderTopLeftRadius={5}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize={20} minH="30px" data-testid="switch-name" overflowWrap="anywhere">
                    {aylaSwitch.site_switch_label}
                  </Text>
                  <MatterSwitchToggle switchId={switchId} />
                </Flex>
                {deviceType === 'AYLA_DIMMER' && (
                  <>
                    <Divider my={4} />
                    <DimmerSlider switchId={switchId} />
                  </>
                )}
              </SEHomeCard>
            </Skeleton>
            <Skeleton isLoaded={!isApiDataLoading} borderBottomRightRadius="25px" mt={4} mb={4}>
              {aylaSwitch.appliance_id && (
                <DeviceActivity
                  applianceId={aylaSwitch.appliance_id}
                  power={aylaSwitch.power ?? 0}
                  shouldShowCurrentPowerInKw={false}
                />
              )}
            </Skeleton>
            <SwitchScheduleList switchId={switchId} />
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
