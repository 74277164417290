import React from 'react';
import { IconButton, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SchneiderHamIcon } from 'clipsal-cortex-icons/src/custom-icons';

import { selectSite } from '../../features/site/siteSlice';
import { useViewportType } from '../hooks/use-viewport-type';

export function SchneiderHamButton() {
  const navigate = useNavigate();
  const { isDesktopViewport } = useViewportType();
  const { site_id: siteId } = useSelector(selectSite);
  const logoColor = useColorModeValue('#FFFFFF', '#060805');
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');

  if (isDesktopViewport) return null;

  return (
    <IconButton
      data-testid="schneider-ham-icon"
      zIndex={999}
      bg={iconColor}
      _hover={{
        bg: iconColor,
      }}
      aria-label="ham-icon"
      icon={<SchneiderHamIcon w={'18px'} h={'18px'} color={logoColor} />}
      minW={'27px'}
      h={'50px'}
      borderTopLeftRadius={0}
      borderBottomLeftRadius={0}
      rounded={10}
      onClick={() => navigate(`/sites/${siteId}/account/support?direction=back&backURL=/sites/${siteId}/home`)}
    />
  );
}
