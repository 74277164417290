import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Skeleton,
  SkeletonCircle,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { SEHomeCard } from '../../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { useParamBackUrl } from '../../../common/hooks/use-param-back-url';
import { DeviceActivity } from '../DeviceActivity';
import { DEVICE_ICON_RESPONSIVE_SIZE } from '../devices-helper';
import { useGetDeviceQuery } from '../devicesApi';
import { DeviceStatusIcon } from '../DeviceStatusIcon';
import { SwitchScheduleList } from './schedules/SwitchScheduleList';
import { useGetLiveSwitchQuery } from './switchApi';
import { SwitchToggle } from './SwitchToggle';

export function ViewSwitch() {
  const navigate = useNavigate();
  const { switchId: id } = useParams<{ switchId: string }>();
  const switchId = Number(id);
  const { data: liveSwitch, isLoading: isSwitchLoading, isSwitchError } = useGetLiveSwitchQuery(switchId);
  const backURL = useParamBackUrl('../../../devices/list?direction=back');

  const { t } = useTranslation();

  // We also grab from the device API because the switch API doesn't have the user_assignment field (for the icon)
  const { data: appliance, isLoading: isDeviceLoading, isError: isDeviceError } = useGetDeviceQuery({ switchId });

  const isLoading = isSwitchLoading || isDeviceLoading;
  const isError = isSwitchError || isDeviceError;

  return (
    <SlidingAnimationPageBase
      backURL={backURL}
      includeBottomNav
      title={t('Devices.smart circuit')}
      customTopNavButton={
        <Button
          data-testid="edit-button"
          onClick={() => navigate(`../edit?direction=forward`)}
          variant={'ghost'}
          size={'sm'}
          hidden={isError}
          fontWeight={400}
          colorScheme="schneiderSkyBlue"
        >
          {t('Common.edit')}
        </Button>
      }
    >
      <Box data-testid="switch-detail-contents" px={3}>
        {isError ? (
          <Alert status="error" variant="left-accent" data-testid="error-alert">
            <AlertIcon />
            <Box>
              <AlertTitle>{t('Common.notice')}</AlertTitle>
              <AlertDescription>
                {t('Devices.error fetching device')}.{' '}
                {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}.
              </AlertDescription>
            </Box>
          </Alert>
        ) : (
          <>
            <SkeletonCircle
              isLoaded={!isLoading}
              w={DEVICE_ICON_RESPONSIVE_SIZE}
              h={DEVICE_ICON_RESPONSIVE_SIZE}
              m="auto"
            >
              {liveSwitch && appliance && (
                <DeviceStatusIcon assignment={appliance.user_assignment} isOn={liveSwitch.power > 0} />
              )}
            </SkeletonCircle>
            <Skeleton isLoaded={!isLoading} borderBottomRightRadius="25px" mt={2} mb={4}>
              {liveSwitch && (
                <SEHomeCard borderTopLeftRadius={5}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize={20} minH="30px" data-testid="switch-name" overflowWrap="anywhere">
                      {liveSwitch.site_switch_label}
                    </Text>
                    <SwitchToggle switchId={switchId} />
                  </Flex>
                </SEHomeCard>
              )}
            </Skeleton>
            <Skeleton isLoaded={!isLoading} borderBottomRightRadius="25px" mt={2} mb={4}>
              {liveSwitch && appliance && (
                <DeviceActivity applianceId={appliance.appliance_id} power={liveSwitch.power} />
              )}
            </Skeleton>
            <SwitchScheduleList switchId={switchId} />
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
