import React, { Fragment, useMemo } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { GridStatus } from 'clipsal-cortex-types/src/api/api-saturn-live-data';
import { formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';
import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';

import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import { SaturnLastUpdated } from '../../common/components/SaturnLastUpdated';
import { SEHomeCard } from '../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../common/components/SlidingAnimationPageBase';
import { useGetSavingsQuery } from '../savings/savingsApi';
import { useLiveData } from '../site/live-data/liveDataApi';
import { selectBatteries, selectCurrentDayForSite, selectInverters, selectMeters, selectSite } from '../site/siteSlice';
import { LiveEnergyAnimation } from './LiveEnergyAnimation';

export function LiveEnergyFlow() {
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const { t } = useTranslation();
  const siteHasMeter = useSelector(selectMeters).length > 0;
  const siteHasInverter = useSelector(selectInverters).length > 0;
  const siteHasBattery = useSelector(selectBatteries).length > 0;
  const {
    data: liveDataSummary,
    isInverterDataLoading,
    isFetching,
    isMeterDataLoading,
    isInverterError,
    isMeterError,
  } = useLiveData();
  const currentDate = useSelector(selectCurrentDayForSite);
  const { data: savingsData, isLoading: isSavingsLoading } = useGetSavingsQuery(currentDate, DateRangeType.Day);

  const { solar, grid, battery = 0, consumption } = liveDataSummary;

  const totalSaved = useMemo(() => {
    return savingsData.find((dailySavings) => dailySavings.date === formatDate(currentDate))?.total_saved || 0;
  }, [savingsData]);

  const isLoaded = useMemo(() => {
    // No matter what, if the site has an inverter, that's the source of truth.
    if (siteHasInverter) {
      if (isInverterError && siteHasMeter) {
        // Inverter error -> fallback to meter data
        return !isMeterDataLoading;
      }
      // Assume EIP connection to Sense meter via inverter, so inverter data is the source of truth
      return !isInverterDataLoading;
    }
    return !isMeterDataLoading;
  }, [isInverterError, isInverterDataLoading, isMeterDataLoading, siteHasInverter]);

  const isError = useMemo(() => {
    if (siteHasInverter && siteHasMeter) {
      return isMeterError && isInverterError;
    }
    return isMeterError || isInverterError;
  }, [siteHasInverter, siteHasMeter, isMeterError, isInverterError]);

  const gridStatusText = useMemo(() => {
    if (!isLoaded || isError) return '';
    if (grid < 0) return t('Live Power Flow.exporting');
    if (grid > 0) return t('Live Power Flow.importing');
    if (liveDataSummary.grid_status === GridStatus.GRID_OUTAGE) return t('Live Power Flow.outage');
    return t('Live Power Flow.idle');
  }, [grid, liveDataSummary.grid_status, isLoaded, isError]);

  const batteryStatusText = useMemo(() => {
    if (!isLoaded || isError) return t('Battery.battery');
    if (battery < 0) return t('Live Power Flow.battery discharging');
    if (battery > 0) return t('Live Power Flow.battery charging');
    return t('Live Power Flow.battery idle');
  }, [battery, isLoaded, isError]);

  return (
    <SlidingAnimationPageBase backURL={`../home?direction=back`} title={t('Live Power Flow.live power flow')}>
      <Box px={3} w="100vw">
        <SaturnLastUpdated />

        <Center mb={5} mt={1}>
          <LiveEnergyAnimation isParentLoaded={!isError && isLoaded && !isFetching} />
        </Center>

        <SimpleGrid gap={3} columns={2} data-testid="power-cards">
          <PowerSourceCard
            isLoaded={isLoaded}
            color={'solarGreenStatic.500'}
            value={solar}
            testId="live-solar-power-card"
            title={t('Solar.solar')}
            link={`/sites/${siteId}/solar/home`}
          />
          {siteHasBattery && (
            <PowerSourceCard
              isLoaded={isLoaded}
              color={'schneiderSkyBlue.500'}
              value={Math.abs(battery)}
              testId="live-battery-power-card"
              title={batteryStatusText}
              link={`/sites/${siteId}/battery/home`}
            />
          )}
          <PowerSourceCard
            isLoaded={isLoaded}
            color={'gridRedStatic.500'}
            value={Math.abs(grid)}
            testId="live-grid-power-card"
            title={t('Grid.grid')}
            subTitle={gridStatusText}
          />
          <PowerSourceCard
            isLoaded={isLoaded}
            color={'generatorOrangeStatic.500'}
            value={Math.max(consumption, 0)}
            testId="live-consumption-power-card"
            title={t('Live Power Flow.consumption')}
          />
        </SimpleGrid>

        <SEHomeCard
          data-testid="live-page-savings-link-btn"
          my={3}
          onClick={() => navigate(`../savings/home?backURL=/sites/${siteId}/live_energy_flow?direction=back`)}
          borderTopLeftRadius="5px"
        >
          <Flex justify="space-between">
            <Flex>
              <Text mr={1}>{t('Live Power Flow.current savings')}:</Text>
              <Skeleton isLoaded={!isSavingsLoading}>{formatDollarsCents(totalSaved)}</Skeleton>
            </Flex>
            <ChevronRightIcon w={6} h={6} />
          </Flex>
        </SEHomeCard>

        <SEHomeCard
          data-testid="live-page-activity-link-btn"
          onClick={() => navigate(`../activity`)}
          borderTopLeftRadius="5px"
          my={3}
        >
          <Flex justify="space-between">
            <Box>{t('Live Power Flow.home energy activity')}</Box>
            <ChevronRightIcon w={6} h={6} />
          </Flex>
        </SEHomeCard>
      </Box>
    </SlidingAnimationPageBase>
  );
}

interface PowerSourceCardProps {
  readonly isLoaded: boolean;
  readonly color: string;
  readonly value: number;
  readonly testId: string;
  readonly title: string;
  readonly subTitle?: string;
  readonly link?: string;
}

function PowerSourceCard({ isLoaded, color, value, testId, title, subTitle, link }: PowerSourceCardProps) {
  const { pathname } = useLocation();
  const linkProps = link
    ? {
        as: Link,
        to: {
          pathname: link,
          search: `?direction=forward&backURL=${pathname}`,
        },
      }
    : undefined;

  return (
    <SEHomeCard data-testid={testId} p={3} {...linkProps}>
      <Center flexDirection="column">
        <Skeleton isLoaded={isLoaded}>
          <Flex color={color} fontSize={'3xl'} fontWeight={'bold'} alignItems="baseline">
            {Intl.NumberFormat('en', { maximumFractionDigits: 2 }).format(value)}
            <Box fontWeight={'normal'} fontSize={'sm'} ml={1}>
              kW
            </Box>
          </Flex>
        </Skeleton>
        {subTitle ? (
          <Fragment>
            <Text lineHeight={1}>{title}</Text>
            <Text fontSize={12} lineHeight={1} data-testid="power-card-subtitle">
              {subTitle}
            </Text>
          </Fragment>
        ) : (
          <Text>{title}</Text>
        )}
      </Center>
    </SEHomeCard>
  );
}
