import React, { ChangeEvent, useCallback, useState } from 'react';
import { Switch, useDisclosure, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AlertDialogModal } from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import { getShutdownAlertUserPreference } from '../../account/settings/settings-helpers';
import { selectSite } from '../../site/siteSlice';
import { useUpdateSwitchMutation } from '../switches/switchApi';
import { useTurnLocalMatterDeviceOnOffMutation } from './localMatterDevicesApi';
import { useAylaSwitchData } from './matter-helpers';

type Props = {
  switchId: number;
};

export function MatterSwitchToggle({ switchId }: Props) {
  const { data: device, isLoading } = useAylaSwitchData(switchId, true);
  const [updateSwitch, { isLoading: updateIsLoading }] = useUpdateSwitchMutation();
  const [turnLocalMatterDeviceOnOff, { isLoading: localUpdateIsLoading }] = useTurnLocalMatterDeviceOnOffMutation();
  const toast = useToast({ isClosable: true });
  const { isOpen: isShutdownAlertOpen, onOpen: onOpenShutdownAlert, onClose: onCloseShutdownAlert } = useDisclosure();
  const [updateUserPreferenceRequested, setUpdateUserPreferenceRequested] = useState<boolean>(false);
  const { site_id: siteId } = useSelector(selectSite);
  const { t } = useTranslation();
  const showShutdownAlert = getShutdownAlertUserPreference();
  const deviceIsOn = device.state === 'closed';

  const handleSwitchToggle = useCallback(
    async (toggleOnRequested: boolean) => {
      const requestedState = toggleOnRequested ? 'closed' : 'open';

      try {
        if (device.localMatterDevice?.matterDeviceId) {
          // We attempt to turn on/off the device locally
          turnLocalMatterDeviceOnOff({
            matterDeviceId: device.localMatterDevice.matterDeviceId,
            state: requestedState,
          });
        }

        await updateSwitch({ siteId, switchId, body: { state: requestedState } }).unwrap();
      } catch (error) {
        console.error(error);
        toast({
          status: 'error',
          title: t('Devices.error updating device'),
          description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        });
      }
    },
    [device]
  );

  return (
    <>
      <Switch
        isChecked={deviceIsOn}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (!e.target.checked && showShutdownAlert) {
            onOpenShutdownAlert();
          } else handleSwitchToggle(e.target.checked);
        }}
        variant="solarGreen"
        size="lg"
        data-testid={`matter-switch-${switchId}-toggle`}
        isDisabled={isLoading || updateIsLoading || localUpdateIsLoading}
      />
      <AlertDialogModal
        isOpen={isShutdownAlertOpen}
        onClose={onCloseShutdownAlert}
        header={t('Devices.turn off device?', { name: device?.site_switch_label })}
        subHeader={t('Devices.are you sure turn off?')}
        confirmButtonName={t('Devices.turn off')}
        cancelButtonName={t('Common.cancel')}
        onConfirm={() => {
          updateUserPreferenceRequested && localStorage.setItem('showShutdownAlerts', 'false');
          handleSwitchToggle(false);
        }}
        onSelectDontShowAgain={() => setUpdateUserPreferenceRequested(true)}
        dontShowRadioText={t('Common.dont show alerts anymore')}
      />
    </>
  );
}
