import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Skeleton, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectSite } from '../../site/siteSlice';
import { useGetLiveSwitchQuery, useUpdateSwitchMutation } from '../switches/switchApi';

type Props = {
  switchId: number;
};

type State = {
  sliderValue: number;
  isDragging: boolean;
};

const INITIAL_STATE: State = {
  sliderValue: 50,
  isDragging: false,
};

export function DimmerSlider({ switchId }: Props) {
  const { data: liveSwitch, isLoading: isLiveSwitchLoading } = useGetLiveSwitchQuery(switchId);
  const [updateSwitch, { isLoading: updateIsLoading }] = useUpdateSwitchMutation();
  const toast = useToast({ isClosable: true });
  const { site_id: siteId } = useSelector(selectSite);
  const [state, setState] = useState(INITIAL_STATE);
  const { sliderValue, isDragging } = state;
  const { t } = useTranslation();

  const deviceIsOn = liveSwitch?.state === 'closed';

  const isLoaded = !isLiveSwitchLoading;
  const isDisabled = !deviceIsOn || updateIsLoading;

  const handleSliderChangeEnd = useCallback(
    async (value: number) => {
      try {
        setState({ ...state, isDragging: false });
        if (!liveSwitch) throw Error('Device not found');
        await updateSwitch({
          siteId,
          switchId: liveSwitch.id,
          body: { properties: [{ name: 'Level', value }] },
        }).unwrap();
      } catch (error) {
        console.error(error);
        toast({
          status: 'error',
          title: t('Devices.error updating device'),
          description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        });
      }
    },
    [liveSwitch]
  );

  const currentLevel = useMemo(() => {
    return liveSwitch?.properties.find((property) => property.name === 'Level')?.value;
  }, [liveSwitch]);

  useEffect(() => {
    // Update the sliderValue if the live data changes only if its not currently being dragged.
    if (!isDragging && currentLevel !== undefined) {
      setState({ ...state, sliderValue: currentLevel });
    }
  }, [currentLevel]);

  return (
    <Skeleton isLoaded={isLoaded} mx={4}>
      <Slider
        onChange={(value) => setState({ ...state, sliderValue: value })}
        onChangeStart={() => setState({ ...state, isDragging: true })}
        onChangeEnd={handleSliderChangeEnd}
        value={sliderValue}
        isDisabled={isDisabled}
        variant={isDisabled ? 'disabled' : undefined}
        size="lg"
        mb={1}
      >
        <SliderTrack height="0.5rem">
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb p="0.75rem" display="block" data-testid="switch-slider" />
        <SliderMark
          data-testid="switch-slider-marker"
          value={sliderValue}
          textAlign="center"
          mt="0.8rem"
          ml="-5"
          w="12"
        >
          {sliderValue}%
        </SliderMark>
      </Slider>
    </Skeleton>
  );
}
