import React, { useMemo, useState } from 'react';
import { ScanResult } from '@capacitor-community/barcode-scanner';
import { Wifi } from '@capacitor-community/wifi';
import { Network } from '@capacitor/network';
import { Center, Heading, Image, Text, useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import wifiConnectionFailed from '../../../../assets/images/sem_commissioning_unsuccessful.svg';
import semQrCodeImg from '../../../../assets/images/sem_qr_code_scan.svg';
import wifiConnectingImg from '../../../../assets/images/wifi_connecting.svg';
import { ScanBarcodeButton } from '../../../../common/components/ScanBarcodeButton';
import { SlidingAnimationPageBase } from '../../../../common/components/SlidingAnimationPageBase';
import { TopNavProgressLoader } from '../../../../common/components/TopNavProgressLoader';
import { selectSite } from '../../../site/siteSlice';
import { extractWifiCredentials } from '../helpers';
import { WirelessAccessPointConnectionStatus } from './types';
import { fetchAccountId } from './utils';

/* istanbul ignore next -- @preserve */
export function MeterScanBarcode() {
  const { site_id: siteId } = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const toast = useToast({ isClosable: true });
  const [connectionStatus, setConnectionStatus] = useState<WirelessAccessPointConnectionStatus>('NONE');
  const { t } = useTranslation();
  const isRegisteredDevice = search.get('shouldRegisterDevice') === 'false';
  const shouldSkipWifiSetup = search.get('shouldSkipWifiSetup') === 'true';

  async function handleScanBarcode(scanResult: ScanResult) {
    if (scanResult.content) {
      setConnectionStatus('CONNECTING');

      // Before proceeding with SEM connections, we need to ensure the user is connected to the internet.
      // They could still be on the SEM wifi network if they retry at this point. Due to iOS restrictions,
      // we are unable to manually disconnect them from the SEM wifi network.
      const { connected: isConnectedToInternet } = await Network.getStatus();
      if (!isConnectedToInternet) {
        setConnectionStatus('FAILED');
        toast({
          title: t('Common.not connected to internet'),
          description: `${t('Set Up Hardware.make sure you are connected')} ${t(
            'Set Up Hardware.you may need to reconnect'
          )}`,
          status: 'error',
        });
        return;
      }

      try {
        const { ssid, password } = extractWifiCredentials(scanResult.content);
        // The expected SSID structure is "Schneider Monitor <serial>"
        const serialNumber = ssid.split(' ')[2];
        search.set('serialNumber', serialNumber);

        // Before connecting to the Wi-Fi, we need to fetch the accountId for the site.
        const { account_id: accountId, status } = await fetchAccountId(siteId, serialNumber);

        // SEM is already registered in Prosumer cloud, is online, and we are not performing Wi-Fi reconfig --
        // -- on an existing SEM, we can skip the connection tests
        if (shouldSkipWifiSetup || (!!accountId && status === 'ONLINE' && !isRegisteredDevice))
          return navigate(`../register?${search.toString()}`);

        try {
          await Wifi.connect({ ssid, password, isHiddenSsid: false });
          search.set('accountId', accountId?.toString() ?? '');
          navigate(`../connection_tests?${search.toString()}`);
        } catch (e) {
          setConnectionStatus('FAILED');
          toast({
            title: t('Set Up Hardware.error connecting to wifi'),
            description: t('Common.please try again'),
            status: 'error',
          });
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        setConnectionStatus('INVALID_QR_CODE');
        toast({
          title: t('Common.invalid qr code'),
          description: t('Common.please try again'),
          status: 'error',
        });
      }
    }
  }

  let contents = (
    <>
      <Image w={'70%'} my={4} src={semQrCodeImg} alt="SEM sketch" />
      <Heading mt={2}>{t('Set Up Hardware.allow camera')}</Heading>
      <Text mt={1}>{t('Set Up Hardware.activate sem text')}</Text>

      <ScanBarcodeButton
        data-testid={'meter-open-camera'}
        onScan={handleScanBarcode}
        buttonText={t('Common.open camera')}
        onScanError={() => setConnectionStatus('NONE')}
        mt={8}
        w={'80%'}
      />
    </>
  );

  if (connectionStatus === 'CONNECTING') {
    contents = (
      <>
        <TopNavProgressLoader />
        <Image w={'70%'} src={wifiConnectingImg} alt="Connecting to meter" />
        <Heading mt={2}>{t('Set Up Hardware.searching wifi')}</Heading>
        <Text mt={1}>
          {t('Set Up Hardware.this allows the device', {
            device: t('Set Up Hardware.energy monitor'),
          })}
        </Text>
      </>
    );
  }

  if (connectionStatus === 'FAILED') {
    contents = (
      <>
        <Image w={'70%'} my={4} src={wifiConnectionFailed} alt="Connection failed" />
        <Heading mt={2}>{t('Set Up Hardware.unable to connect')}</Heading>
        <Text mt={1}>
          {t('Set Up Hardware.unable to connect to device wap', {
            device: t('Set Up Hardware.energy monitor'),
          })}
        </Text>
        <Text mt={1}>{t('Common.please try again')}</Text>
        <Text mt={1}>{t('Common.if this persists contact support')}</Text>
        <ScanBarcodeButton
          onScan={handleScanBarcode}
          onScanError={() => setConnectionStatus('NONE')}
          mt={8}
          w={'80%'}
          buttonText={t('Common.try again')}
        />
      </>
    );
  }

  if (connectionStatus === 'INVALID_QR_CODE') {
    contents = (
      <>
        <Image w={'70%'} src={wifiConnectionFailed} alt="QR Code scan failed" />
        <Heading mt={2}>{t('Set Up Hardware.unable to validate qr')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.ensure wifi enabled')}</Text>
        <Text my={1}>{t('Common.please try again')}</Text>
        <Text>{t('Common.if this persists contact support')}</Text>
      </>
    );
  }

  // This ensures when coming from hardware details page, we can go back to the same page when going back
  const urlSearchParamsToGoBack = useMemo(() => {
    search.set('direction', 'back');
    return search.toString();
  }, [search]);

  return (
    <SlidingAnimationPageBase
      title={t('Common.device setup')}
      backURL={`../connect_instructions?${urlSearchParamsToGoBack}`}
    >
      <Center flexDirection="column" px={3} mt={5} textAlign="center">
        {contents}
      </Center>
    </SlidingAnimationPageBase>
  );
}
