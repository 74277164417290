import React from 'react';
import { Box, Center, Flex, Skeleton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ENERGY_MODE_ICONS } from '../../../account/energy-modes/energy-modes-helpers';
import { useGetEnergyModesQuery } from '../../../account/energy-modes/energyModesApi';
import { selectSite } from '../../../site/siteSlice';
import { HomePageWidgetTile, HomePageWidgetTileProps } from '../HomePageWidgetTile';

export function EnergyModesWidgetTile({ item, index, isEditing, onRemoveGridItem }: HomePageWidgetTileProps) {
  const { site_id: siteId } = useSelector(selectSite);
  const { t } = useTranslation();
  // @TODO: poll..?
  const { data: energyModesData, isLoading: isEnergyModesLoading } = useGetEnergyModesQuery(siteId);

  const Icon = ENERGY_MODE_ICONS[energyModesData?.energy_mode ?? 'LOW_POWER'];
  const status = energyModesData?.energy_mode ?? 'LOW_POWER';

  // Note that the generic component is not used as an extra skeleton is required for the icon, which isn't worth
  // additional complexity in the abstraction
  return (
    <HomePageWidgetTile item={item} index={index} isEditing={isEditing} onRemoveGridItem={onRemoveGridItem}>
      <Center px={5} pt={5} pb={5}>
        <Skeleton isLoaded={!isEnergyModesLoading}>
          <Icon w={16} h={16} flexShrink={0.3} />
        </Skeleton>
        <Skeleton isLoaded={!isEnergyModesLoading} ml={2}>
          <Box fontSize={24}> {t(`Widgets.Energy Mode.${status}`)}</Box>
        </Skeleton>
      </Center>
      <Flex pl={2} pb={1}>
        {t(`Widgets.${item.title}.title`)}
      </Flex>
    </HomePageWidgetTile>
  );
}
