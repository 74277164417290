import React, { useEffect, useState } from 'react';
import { HTTP } from '@awesome-cordova-plugins/http';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { EyeIcon, EyeSlashIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { ArcButton } from '../../../common/components/ArcButton';
import { CommonTopNav } from '../../../common/components/CommonTopNav';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT } from '../../../common/constants';
import { WifiConfigAlertDialogModalConfirmOnly } from './WifiConfigAlertModal';
import { selectBearerToken } from './wifiConfigSlice';

const addNetworkSchema = yup.object().shape({
  wifiSSID: yup.string().required('New Password is a required field'),
  wifiPassword: yup.string().required('Confirm Password name is a required field'),
});

type FormValues = { wifiSSID: string; wifiPassword: string };

/* istanbul ignore next -- @preserve */
export const AddNetwork = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(addNetworkSchema),
    defaultValues: { wifiSSID: '', wifiPassword: '' },
  });
  const navigate = useNavigate();
  const bearerToken = useSelector(selectBearerToken);
  const [modalState, setModalState] = useState<null | 'error' | 'success'>(null);
  const [{ isLoading, isInitialFetching, isPinVisible }, setState] = useState({
    isLoading: false,
    isPinVisible: false,
    isInitialFetching: true,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const VisibleIcon = isPinVisible ? EyeSlashIcon : EyeIcon;
  const handleVisibleIconClick = () =>
    setState((prevState) => ({ ...prevState, isPinVisible: !prevState.isPinVisible }));
  const bgColor = useColorModeValue('#F7F7F7', '#111111');

  const { placeHolderColor, inputBackground } = useColorModeValue(
    { placeHolderColor: '#0F0F0F', inputBackground: '#FFFFFF' },
    { placeHolderColor: '#F7F7F7', inputBackground: '#293133' }
  );

  const isSuccessfulModalState = modalState === 'success';

  useEffect(() => {
    if (modalState) onOpen();
  }, [modalState]);

  useEffect(() => {
    const fetchCurrentWifiSettings = async () => {
      try {
        const { data } = await HTTP.get(
          'https://192.168.89.136/evse/v1/wificonnectivitysettings',
          {},
          { Accept: 'application/json', Authorization: `Bearer ${bearerToken}`, Host: '192.168.89.136' }
        );

        const { SSID, WifiPasscode } = JSON.parse(data);
        reset({ wifiSSID: SSID, wifiPassword: WifiPasscode });
      } catch (error) {
        console.error('Error fetching current wifi settings :(');
      }
      setState((prevState) => ({ ...prevState, isInitialFetching: false }));
    };

    fetchCurrentWifiSettings();
  }, []);

  const commonInputProps = {
    placeholder: 'From 6 to 16 digits',
    borderColor: '#9FA0A4',
    background: inputBackground,
    py: 6,
    _placeholder: {
      color: placeHolderColor,
    },
  };

  const onSubmit = async (values: FormValues) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      await HTTP.put(
        'https://192.168.89.136/evse/v1/wificonnectivitysettings',
        {
          SSID: values.wifiSSID,
          SecurityOption: true,
          WifiPasscode: values.wifiPassword,
        },
        { 'Content-type': 'application/json', Accept: 'application/json', Authorization: `Bearer ${bearerToken}` }
      );
      // restart after changing the connectivity settings
      await HTTP.post(
        'https://192.168.89.136/evse/v1/evsemanager/methods/restartdevice',
        {},
        { 'Content-type': 'application/json', Accept: 'application/json', Authorization: `Bearer ${bearerToken}` }
      );
      setModalState('success');
    } catch (error) {
      setModalState('error');
    }
    setState((prevState) => ({ ...prevState, isLoading: false }));
  };

  if (isInitialFetching)
    return (
      <Flex direction={'column'} align="center" justify={'space-between'} minH={COMPONENT_MIN_HEIGHT}>
        <CommonTopNav title="Add a network manually" backURL="../home" />
        <CenteredLoader isFullHeight />
      </Flex>
    );

  return (
    <Flex
      bgColor={bgColor}
      direction={'column'}
      align="center"
      justify={'space-between'}
      minH={COMPONENT_MIN_HEIGHT}
      pb={BOTTOM_NAV_HEIGHT}
    >
      <CommonTopNav title="Add a network manually" backURL="../home" />
      <Box px={4} mt={16}>
        <Text my={4}>Connect the Schneider Home to the Internet via an Access Point</Text>
        <Box>
          <FormControl isInvalid={!!errors?.wifiSSID} mb={12}>
            <FormLabel fontWeight={600}>Wifi-Network</FormLabel>
            <Input data-testid={'wifi-ssid-input'} {...commonInputProps} {...register('wifiSSID')} />
            <FormErrorMessage>{errors?.wifiSSID?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors?.wifiPassword}>
            <FormLabel fontWeight={600}>Wifi Password</FormLabel>
            <InputGroup>
              <Input
                data-testid={'wifi-password-input'}
                {...commonInputProps}
                type={isPinVisible ? 'text' : 'password'}
                {...register('wifiPassword')}
              />
              <InputRightElement h={12}>
                <VisibleIcon onClick={handleVisibleIconClick} w={6} h={6} color={placeHolderColor} />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors?.wifiPassword?.message}</FormErrorMessage>
          </FormControl>
        </Box>
      </Box>
      <ArcButton
        isLoading={isLoading}
        arcColor="#3DCD57"
        width="100%"
        maxWidth={'300px'}
        mb={16}
        onClick={handleSubmit(onSubmit)}
      >
        Save
      </ArcButton>
      <WifiConfigAlertDialogModalConfirmOnly
        {...{ isOpen, onClose }}
        header={isSuccessfulModalState ? 'Network saved successfully. Your charger will restart now.' : 'Unsuccesful'}
        subHeader={
          isSuccessfulModalState
            ? // eslint-disable-next-line max-len
              'This will take up to 5 minutes. Please verify you are connected to your home wifi or cellular network after it restarts.'
            : 'Your network configurations are not saved.'
        }
        confirmButtonName={isSuccessfulModalState ? 'Done' : 'Try Again'}
        onConfirm={() => {
          navigate(`../../home/device_details`);
          onClose();
        }}
      />
    </Flex>
  );
};
