import React, { useMemo } from 'react';
import { Box, Flex, IconButton, Link, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AlertBanner } from 'clipsal-cortex-ui/src/components/AlertBanner';

import { IS_IOS } from '../../../common/constants';
import { LiveDataSwitch } from '../../site/live-data/types';
import { selectSite } from '../../site/siteSlice';
import { DeviceListItem } from '../DeviceListItem';
import { useGetDevicesQuery } from '../devicesApi';
import { SortByOptions } from '../SortByPopover';
import { CombinedSwitch, useGetLiveAylaSwitchesQuery } from '../switches/switchApi';
import { getMatterDeviceType, MatterDeviceType, useTranslatedUIConfig } from './matter-helpers';
import { MatterSwitchToggle } from './MatterSwitchToggle';

export type Props = {
  sortBy: SortByOptions;
  state?: LiveDataSwitch['state'];
  title?: string;
};

type SwitchWithDeviceType = CombinedSwitch & { deviceType: MatterDeviceType };

export function MatterDevicesList({ sortBy, state, title }: Props) {
  const { site_id: siteId } = useSelector(selectSite);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    data: switches,
    isLoading: isLiveDataLoading,
    isSwitchesFetching,
    isError,
    isLiveDataError,
  } = useGetLiveAylaSwitchesQuery();
  const viewAllButtonColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  // We need to grab appliances for the `display_name` field to determine the device type
  const { data: appliances, isLoading: isDevicesLoading } = useGetDevicesQuery();
  const MATTER_DEVICES_UI_CONFIG = useTranslatedUIConfig();
  const isApiDataLoaded = !isLiveDataLoading && !isDevicesLoading && !isSwitchesFetching;

  const mappedSwitches = useMemo<SwitchWithDeviceType[]>(() => {
    const mappedSwitches = switches.map((aylaSwitch) => {
      const appliance = appliances.find((appliance) => appliance.switch_id === aylaSwitch.id);
      return {
        ...aylaSwitch,
        deviceType: getMatterDeviceType(appliance?.display_name),
      };
    });

    return mappedSwitches;
  }, [switches, appliances]);

  const sortedSwitches = useMemo(() => {
    // Filter by state
    let sortedSwitches = mappedSwitches;
    if (state) sortedSwitches = sortedSwitches.filter((matterSwitch) => matterSwitch.state === state);
    if (sortBy === 'ALPHABETICAL') {
      return orderBy(sortedSwitches, [(matterSwitch) => matterSwitch.site_switch_label.toLowerCase()], ['asc']);
    } else if (sortBy === 'POWER') {
      return orderBy(sortedSwitches, [(matterSwitch) => matterSwitch.power], ['desc']);
      // Costs are unavailable for Ayla devices atm, so we are unable to sort by cost.
    } else return sortedSwitches;
  }, [sortBy, mappedSwitches]);

  return (
    <Skeleton
      isLoaded={isApiDataLoaded}
      borderRadius={5}
      borderBottomRightRadius="25px"
      height={isApiDataLoaded ? 'unset' : 75}
      data-testid="matter-devices-list"
    >
      {!!title && (
        <Flex justifyContent="space-between" alignItems="center">
          <Text data-testid="matter-device-list-title">{title}</Text>
          <Flex justifyContent="space-between" alignItems="center">
            <Link
              onClick={() => navigate(`/sites/${siteId}/devices/list`)}
              color={viewAllButtonColor}
              data-testid="see-all-devices-link"
            >
              {t('Common.see all')}
            </Link>
            <IconButton
              data-testid="add-device-button"
              variant="ghost"
              color={viewAllButtonColor}
              aria-label="Add device"
              icon={<IoMdAddCircleOutline size={20} />}
              onClick={() =>
                navigate(`/sites/${siteId}/matter-devices/add/${IS_IOS ? 'start-ios' : 'start'}?backURL=${pathname}`)
              }
            />
          </Flex>
        </Flex>
      )}

      {isLiveDataError && (
        <Box mb={4}>
          <AlertBanner
            title={t('Devices.error fetching live data')}
            type="error"
            isCollapsible={false}
            isDismissable={false}
            containerTestId="matter-devices-error-alert"
          >
            {t('Common.please try again')} {t('Common.if this persists contact support')}
          </AlertBanner>
        </Box>
      )}

      {sortedSwitches.map(({ site_switch_label: siteSwitchLabel, id, power, deviceType }) => (
        <DeviceListItem
          name={siteSwitchLabel}
          Icon={MATTER_DEVICES_UI_CONFIG[deviceType].icon}
          currentPower={power}
          shouldShowCurrentPowerInKw={false}
          onClick={() => {
            navigate(`/sites/${siteId}/matter-devices/${id}/view?backURL=${pathname}?direction=forward`);
          }}
          data-testid={`matter-switch-list-item-${id}`}
          key={id}
        >
          <MatterSwitchToggle switchId={id} />
        </DeviceListItem>
      ))}
      {!sortedSwitches.length && !isError && <Text color="gray.400">{t('Devices.no devices found')}</Text>}
    </Skeleton>
  );
}
