import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SEHomeCard } from '../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../common/components/SlidingAnimationPageBase';
import { SEHomeInverterIcon } from '../../styles/custom-icons';

export function AddDeviceHome() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase title={t('Devices.add new device')} backURL={`../../list?direction=back`}>
      <Flex px={3}>
        <SEHomeCard
          width="100%"
          borderTopLeftRadius={'5px'}
          as={'button'}
          data-testid="add-matter-device-button"
          onClick={() => {
            navigate(`../../../matter-devices/add`);
          }}
        >
          <Flex justify={'space-between'} align={'center'} cursor={'pointer'} width="100%">
            <SEHomeInverterIcon w={8} h={8} />
            <Text fontSize={16} fontWeight="normal" ml={4}>
              {t('Devices.matter device')}
            </Text>
            <ChevronRightIcon color={'customGrey.400'} w={'26px'} h={'26px'} />
          </Flex>
        </SEHomeCard>
      </Flex>
    </SlidingAnimationPageBase>
  );
}
