import React, { useMemo } from 'react';
import { Box, Center, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CostsData, EnergyUsageV2, PowerUsageV2, SavingsAPIData } from 'clipsal-cortex-types/src/api';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { ActivityChart } from '../common/ActivityChart';
import { ActivityState } from '../common/ActivityView';
import { CostSavingChart } from './CostSavingsChart';
import { useSource } from './use-source';

interface Props {
  state: ActivityState;
  isLoaded: boolean;
  isError: boolean;
  costData: CostsData[];
  savingsData: SavingsAPIData[];
  powerUsageData: PowerUsageV2[];
  energyUsageData: EnergyUsageV2[];
  onChartChange: (isCostViewSelected: boolean) => void;
}

export function ChartSwitcher({
  state,
  isLoaded,
  isError,
  savingsData,
  costData,
  powerUsageData,
  energyUsageData,
  onChartChange,
}: Props) {
  const {
    selectedDateRangeType,
    rangeTypeToSelectedPeriod,
    selectedPowerSources,
    selectedDeviceId,
    selectedDeviceAssignment,
    isSelectedDeviceDisplayedInChart,
    isCostViewSelected,
  } = state;
  const source = useSource();
  const { t } = useTranslation();
  const color = useColorModeValue('#1E79B6', '#42B4E6');

  const isDayView = selectedDateRangeType === DateRangeType.Day;
  const isSolar = source === 'solar'; // Show savings instead of costs for solar

  const shouldShowNoSourcesSelectedError = useMemo(
    () =>
      Object.entries(selectedPowerSources).every(([key, value]) => {
        // Battery power source is only available on day view
        if (key === 'battery' && selectedDateRangeType !== DateRangeType.Day) return true;
        return !value;
      }) &&
      !source &&
      !isSelectedDeviceDisplayedInChart,
    [selectedPowerSources, selectedDateRangeType]
  );

  const titleLabel = isSolar ? t('Savings.savings').toUpperCase() : t('Common.cost').toUpperCase();
  const noSourceLabel = t('Activity.no source selected message', {
    type: selectedDateRangeType === DateRangeType.Day ? t('Activity.power') : t('Activity.energy').toLowerCase(),
  });

  if (isError) {
    return (
      <Center h="320px" data-testid="no-data-error">
        <Heading textAlign="center">{t('Activity.error fetching data')}</Heading>
      </Center>
    );
  }

  return (
    <>
      {!isDayView && (
        <Flex fontSize={12} flexDirection="row" mb={2}>
          <Text fontWeight={600}>{t('Activity.chart view').toUpperCase()}:</Text>
          <Box ml={2} onClick={() => onChartChange(true)} cursor={'pointer'} data-testid="chart-view-cost-btn">
            <Text color={!isCostViewSelected ? color : 'current'}>{titleLabel}</Text>
          </Box>

          <Text ml={2}>|</Text>
          <Box ml={2} onClick={() => onChartChange(false)} cursor={'pointer'} data-testid="chart-view-kw-btn">
            <Text color={isCostViewSelected ? color : 'current'}>{t('Activity.energy').toUpperCase()}</Text>
          </Box>
        </Flex>
      )}

      {!isDayView &&
        isCostViewSelected &&
        (isSolar ? (
          <CostSavingChart
            isLoaded={isLoaded}
            savingsData={savingsData}
            selectedDateRangeType={selectedDateRangeType}
            rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
          />
        ) : (
          <CostSavingChart
            isLoaded={isLoaded}
            costData={costData}
            selectedDateRangeType={selectedDateRangeType}
            rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
            isSelectedDeviceDisplayedInChart={!source && isSelectedDeviceDisplayedInChart}
            selectedDeviceAssignment={!source ? selectedDeviceAssignment : undefined}
          />
        ))}

      {!isCostViewSelected &&
        (shouldShowNoSourcesSelectedError ? (
          <Center h="320px" data-testid="no-sources-selected-error">
            <Heading textAlign="center">{noSourceLabel}</Heading>
          </Center>
        ) : (
          <ActivityChart
            isLoaded={isLoaded}
            energyUsageData={energyUsageData}
            powerUsageData={powerUsageData}
            selectedDateRangeType={selectedDateRangeType}
            rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
            selectedPowerSources={selectedPowerSources}
            isSelectedDeviceDisplayedInChart={!source && isSelectedDeviceDisplayedInChart}
            selectedDeviceId={!source ? selectedDeviceId : null}
          />
        ))}
    </>
  );
}
