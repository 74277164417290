import React from 'react';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AlertDialogModal } from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import { useReduxDispatch } from '../../../../app/store';
import { ArcButton } from '../../../../common/components/ArcButton';
import { IS_PRODUCTION } from '../../../../common/constants';
import { fetchSite, selectSite } from '../../../site/siteSlice';
import { useDeleteConnectionMutation } from './teslaApi';

export function TeslaDisconnectButton() {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [deleteTeslaAccountFromSite, { isLoading: isDeletingTeslaIntegration }] = useDeleteConnectionMutation();
  const { site_id: siteId } = useSelector(selectSite);
  const dispatch = useReduxDispatch();

  const { isOpen: isAlertDialogOpen, onOpen: onOpenAlertDialog, onClose: onCloseAlertDialog } = useDisclosure();

  async function handleDisconnect() {
    try {
      await deleteTeslaAccountFromSite(siteId).unwrap();
      await dispatch(fetchSite({ siteID: siteId, isProduction: IS_PRODUCTION }));
      toast({
        title: t('Integrations.Tesla.remove toast title'),
        description: t('Integrations.Tesla.remove toast description'),
        status: 'success',
      });
      navigate('../../../hardware/list?direction=back');
    } catch (e) {
      console.error(e);
      toast({
        title: t('Integrations.Tesla.remove toast error title'),
        description: t('Integrations.Tesla.remove toast error description'),
        status: 'error',
      });
    }
  }

  return (
    <>
      <ArcButton
        arcColor="#FF5B44"
        minWidth="280px"
        mx="auto"
        mt={10}
        py={6}
        isLoading={isDeletingTeslaIntegration}
        onClick={onOpenAlertDialog}
        data-testid="delete-tesla-integration-button"
      >
        {t('Integrations.Tesla.remove button')}
      </ArcButton>

      <AlertDialogModal
        header={t('Integrations.Tesla.remove header')}
        subHeader={t('Integrations.Tesla.remove subheader')}
        isOpen={isAlertDialogOpen}
        onClose={onCloseAlertDialog}
        confirmButtonTextColor={'customRed.500'}
        isConfirming={isDeletingTeslaIntegration}
        onConfirm={handleDisconnect}
      />
    </>
  );
}
