import React, { useEffect, useMemo } from 'react';
import { Box, Center, Divider, Heading, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BatteryIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { SlidingAnimationPageBase } from '../../../../common/components/SlidingAnimationPageBase';
import { selectSite } from '../../../site/siteSlice';
import { MIN_PAGE_HEIGHT } from '../constants';
import { useGetAvailableBatteriesQuery } from './teslaApi';
import { TeslaAvailableDevice } from './TeslaAvailableDevice';
import { TeslaDisconnectButton } from './TeslaDisconnectButton';
import { useTeslaIsConnected } from './useTeslaIsConnected';

export function TeslaConnect() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true });
  const { site_id: siteId } = useSelector(selectSite);
  const { isConnected, isLoading: isConnectedLoading, isError: isConnectedError } = useTeslaIsConnected();
  const { data: devices, isLoading: isBatteriesLoading } = useGetAvailableBatteriesQuery(siteId);
  const isLoading = isConnectedLoading || isBatteriesLoading;

  useEffect(() => {
    if (!isConnectedLoading) {
      if (isConnectedError) {
        /* istanbul ignore next -- @preserve */
        toast({
          title: t('Common.error fetching data'),
          status: 'error',
        });
      }
      if (!isConnected) {
        /* istanbul ignore next -- @preserve */
        navigate(`..`);
      }
    }
  }, [isConnected, isConnectedLoading]);

  const integratedDevices = useMemo(() => devices?.filter((device) => device.active), [devices]);
  const discoveredDevices = useMemo(() => devices?.filter((device) => !device.active), [devices]);

  return (
    <SlidingAnimationPageBase title={t('Integrations.manage')} backURL={'../home?direction=back'}>
      <Box px={3}>
        <CenteredLoader hidden={!isLoading} text={`${t('Common.loading')}...`} />
        {isConnected && !isLoading && (
          <>
            <Box overflowY={'auto'} height={`calc(${MIN_PAGE_HEIGHT} - 120px)`}>
              {!!devices?.length ? (
                <>
                  {!!integratedDevices?.length && (
                    <Box mb={1}>{t('Integrations.Tesla.integrated devices').toUpperCase()}</Box>
                  )}
                  {integratedDevices?.map((device) => (
                    <TeslaAvailableDevice key={`${device.site_id}-${device.device_name}`} device={device} />
                  ))}
                  {!integratedDevices?.length && <Text mb={2}>{t('Integrations.Tesla.integrated info')}</Text>}
                  {!!discoveredDevices?.length && (
                    <>
                      <Divider my={4} />
                      <Box mb={1}>{t('Integrations.Tesla.not integrated devices').toUpperCase()}</Box>
                      {discoveredDevices?.map((device) => (
                        <TeslaAvailableDevice key={`${device.site_id}-${device.device_name}`} device={device} />
                      ))}
                    </>
                  )}
                </>
              ) : (
                <Center flexDirection="column" px={3} mt={5} textAlign="center">
                  <BatteryIcon w="70%" h="70%" my={4} />
                  <Heading mt={2}>{t('Integrations.Tesla.no batteries title')}</Heading>
                  <Text mt={1}>{t('Integrations.Tesla.no batteries description')}</Text>
                </Center>
              )}
            </Box>

            <Center mt="auto" mb={5} flexDir={'column'}>
              <TeslaDisconnectButton />
            </Center>
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
