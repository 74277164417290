import React from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Box,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  UnorderedList,
  useColorModeValue,
} from '@chakra-ui/react';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';

import { IS_DEMO_LOGIN, TOP_BANNER_SPACING_AFFORDANCE } from '../../common/constants';

export function TermsOfUseModal({ isOpen, onClose }: DialogProps) {
  const bgColor = useColorModeValue('#fff', '#111');
  const linkColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');

  return (
    <Modal scrollBehavior={'inside'} isOpen={isOpen} onClose={onClose} size="full" motionPreset="slideInRight">
      <ModalContent
        data-testid="terms-of-use-modal"
        backgroundColor={bgColor}
        top={IS_DEMO_LOGIN ? TOP_BANNER_SPACING_AFFORDANCE : 'env(safe-area-inset-top)'}
      >
        <ModalHeader mx={'auto'} fontSize={22} fontWeight={500}>
          Terms Of Use
        </ModalHeader>
        <ModalCloseButton
          position={'absolute'}
          left={6}
          mt={1.5}
          fontSize={'17px'}
          data-testid="terms-of-use-back-button"
          onClick={onClose}
        >
          <ChevronLeftIcon w={'26px'} h={'26px'} />
        </ModalCloseButton>
        <ModalBody>
          <Box mb={16}>
            BTOC (BUSINESS TO CONSUMERS) DOWNLOADABLE MOBILE APPLICATION
            <Text my={1}>TERMS OF USE</Text>
            <Text my={1}>COUNTRY: USA</Text>
            <Text my={1}>PREAMBLE:</Text>
            <Text my={1}>THE PRESENT PREAMBLE FORMS AN INTEGRAL PART OF THESE TERMS OF USE.</Text>
            <Text my={1}>
              SCHNEIDER ELECTRIC USA, INC., A COMPANY INCORPORATED IN THE STATE OF DELAWARE, USA, WITH A PRINCIPAL PLACE
              OF BUSINESS LOCATED AT 201 WASHINGTON STREET, ONE BOSTON PLACE, SUITE 2700, BOSTON, MA 02108 USA
            </Text>
            <Text my={1}>
              THESE TERMS OF USE GOVERN YOUR USE OF THE MOBILE APPLICATION DEFINED HEREIN BELOW (HEREINAFTER “THE MOBILE
              APPLICATION”) DISTRIBUTED OR PUBLISHED IN USA BY SCHNEIDER ELECTRIC (AS HEREINAFTER DEFINED) AND MADE
              AVAILABLE FOR DOWNLOAD FROM MOBILE APPLICATIONS ONLINE STORES PROPRIETARY TO SCHNEIDER ELECTRIC GROUP OF
              COMPANIES OR THIRD-PARTY MOBILE APPLICATIONS ONLINE STORES OFFICIALLY AUTHORIZED THERETO BY SCHNEIDER
              ELECTRIC GROUP OF COMPANIES.
            </Text>
            <Text my={1}>NAME OF THE MOBILE APPLICATION: SCHNEIDER HOME</Text>
            <Text my={1}>SUMMARY DESCRIPTION OF THE APP’S FUNCTIONALITIES</Text>
            <Text my={1}>
              The Mobile Application seamlessly connects to Connected Devices (as described in Section 2 below) and
              helps homeowners monitor, control and automate their homes’ energy usage.
            </Text>
            <Text my={1}>Following is the main functionalities the Mobile Application can help achieve:</Text>
            <UnorderedList>
              <ListItem>
                Real time & historic energy production and consumption: User can monitor their real time and historic
                energy production and consumption.
              </ListItem>
              <ListItem>
                Load disaggregation with cost details: User can monitor energy consumption level and energy cost details
                of any electrical device that consumes energy at home and is monitored by a Connected Device when such
                Connected Device is an energy monitoring equipment.
              </ListItem>
              <ListItem>
                Monitor and control smart circuit breakers at the User’s home and thereby enable User to optimize home
                energy usage.
              </ListItem>
              <ListItem>
                Control smart electrical devices forming part of the Connected Device and thereby enable User to
                optimize home energy usage.
              </ListItem>
              <ListItem>
                Operating modes of the Connected Devices: User can program the Connected Devices to run in permanent or
                temporary operating mode.
              </ListItem>
              <ListItem>
                Send push notifications to User with information, warning and alarms related to the Connected Devices
                along with actionable tips to suggest actions that User may take based on the type of the notified
                information, warning or alarm.
              </ListItem>
            </UnorderedList>
            <Text my={2}>INTEROPERABILITY OF THE MOBILE APPLICATION:</Text>
            <Text my={1}>The Mobile Application is interoperable with the Connected Devices.</Text>
            <Text my={1}>
              An internet connection, not supplied by Schneider Electric, is necessary to enable such interoperability.
            </Text>
            <Text my={1}>
              The information on the type of mobile device and operating system on which the Mobile Application is
              compatible for use, is indicated on the mobile applications online stores that provide the Mobile
              Application for download.
            </Text>
            <Text my={2} fontSize={18}>
              1. ACCEPTANCE OF TERMS
            </Text>
            <Text my={1}>
              THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS
              CONDITIONS, LIMITATIONS, AND EXCLUSIONS THAT MIGHT APPLY TO YOU. PLEASE READ IT CAREFULLY.{' '}
            </Text>
            <Text my={1}>
              THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY
              TRIALS OR CLASS ACTIONS.
            </Text>
            <Text my={1}>
              BY DOWNLOADING OR USING THE MOBILE APPLICATION OR RELATED SERVICES, YOU ACCEPT AND ARE BOUND BY THESE
              TERMS OF USE.
            </Text>
            <Text my={1}>
              BEFORE ACCESSING AND USING THE MOBILE APPLICATION, PLEASE READ CAREFULLY THESE TERMS OF USE AND CONFIRM
              YOUR ACCEPTANCE OF THESE TERMS OF USE AND YOUR AGREEMENT TO BE BOUND BY THEM BY CLICKING ON THE
              APPROPRIATE BUTTON APPEARING ON YOUR SCREEN WITHIN THE DOWNLOAD OR INSTALLATION PROCESS OF THE MOBILE
              APPLICATION.
            </Text>
            <Text my={1}>
              IF YOU DO NOT AGREE WITH THESE TERMS OF USE AND TO BE BOUND BY THEM, CLICK THE APPROPRIATE BUTTON
              APPEARING ON YOUR SCREEN WITHIN THE DOWNLOAD OR INSTALLATION PROCESS OF THE MOBILE APPLICATION AND EXIT
              THE PAGE THAT MAKES THE MOBILE APPLICATION AVAILABLE FOR DOWNLOAD: THIS WILL AUTOMATICALLY ABORT THE
              DOWNLOAD OR INSTALLATION PROCESS. IF DESPITE THE FOREGOING, THE ENTIRE OR PART OF THE MOBILE APPLICATION
              HAS ALREADY BEEN DOWNLOADED AND/OR INSTALLED ON ANY OF YOUR DEVICES, YOU MUST IMMEDIATELY DELETE OR
              EXPUNGE THE MOBILE APPLICATION FROM ALL COMPUTERS, HARD DISKS, SERVERS, TABLETS, SMART PHONES OR OTHER
              MOBILE DEVICES ONTO WHICH YOU DOWNLOADED IT.
            </Text>
            <Text my={1}>
              BY USING THE MOBILE APPLICATION, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREED TO THESE TERMS
              OF USE AND THAT YOU AGREE TO BE BOUND BY THEM.
            </Text>
            <Text my={1}>
              THESE TERMS OF USE FORM A LEGAL AGREEMENT BETWEEN THE PERSON TO WHICH THE MOBILE APPLICATION HAS BEEN MADE
              AVAILABLE FOR DOWNLOAD, (HEREINAFTER “YOU”), AND SCHNEIDER ELECTRIC. YOU WARRANT, REPRESENT AND AGREE THAT
              YOU HAVE THE LEGAL AUTHORITY TO ENTER INTO THIS THESE TERMS OF USE AND CONFIRM YOUR ACCEPTANCE OF THESE
              TERMS OF USE.
            </Text>
            <Text my={1}>
              THE LEGAL AGREEMENT FORMED BETWEEN YOU AND SCHNEIDER ELECTRIC PURSUANT TO THESE TERMS OF USE, SHALL BE
              CONCLUDED AND ENTER INTO FORCE UPON YOUR ACCEPTANCE OF THESE TERMS OF USE.
            </Text>
            <Text my={1}>
              THESE TERMS OF USE CAN BE CONSULTED AT ANY TIME IN THE SETTINGS OF THE MOBILE APPLICATION.
            </Text>
            <Text my={2} fontSize={18}>
              2. DEFINITIONS
            </Text>
            <Text my={1}>
              For the purposes of these Terms of Use, the capitalized terms below shall have the meaning defined herein
              below:
            </Text>
            <Text my={1}>
              “Affiliates”: means, with respect to Schneider Electric, any corporation, company or other legal entity
              that is directly or indirectly (i) controlling Schneider Electric, or (ii) controlled by Schneider
              Electric, or (iii) under common control with, respectively, Schneider Electric, and the term “control”
              shall, for the purpose of this definition, mean the direct or indirect control of at least 50% of the
              stock capital and/or of the voting rights.
            </Text>
            <Text my={1}>
              “Authorized Users”: means any other person You authorize to access and make use of the Mobile Application.
            </Text>
            <Text my={1}>
              “Contents”: means, this list not being limitative, the structure of the Mobile Application, its editorial
              contents, the drawings, the illustrations, the images, the photos, the trademarks, the logos, the
              initials, the company names, the audiovisual or multimedia works, the visual, audio or sound contents, as
              well as any other file or element present within the Mobile Application or otherwise forming part thereof.
            </Text>
            <Text my={1}>
              “Intellectual Property Rights”: means any and all rights of any kind and any type arising under statute,
              regulation, ordinance, treaty, convention or otherwise, and including, without limitation any patents,
              utility models, moral rights, copyright and neighbouring rights, trademarks and trade dress rights, mask
              work rights, service marks, right in domain names, designs rights, rights in computer software, database
              rights, rights in confidential information (including but not limited to all rights in know-how and trade
              secrets) and any other intellectual property rights, in each case whether registered or not, including but
              not limited to applications and renewals, and all rights and forms of protection having equivalent or
              similar effect, recognized under the laws of each and every jurisdiction throughout the world.
            </Text>
            <Text my={1}>
              “Mobile Application”: means the Mobile Application defined in the preamble hereof, that provides the
              functions described or referred to in these Terms of Use, as published and made available by Schneider
              Electric or its Affiliates for download from mobile applications online stores proprietary to Schneider
              Electric or officially authorized third-party mobile application online stores. A reference to the Mobile
              Application in these Terms of Use will be deemed to include a reference to the Contents, the software
              programs, the programming and navigation tools, the data bases, and any other structural components of the
              Mobile Application, as well as any updates and upgrades thereto that may be provided by Schneider
              Electric.
            </Text>
            <Text my={1}>
              “Personal Information”: means any information relating to an identified or identifiable natural person; an
              identifiable natural person is one who can be identified, directly or indirectly, in particular by
              reference to an identifier such as a name, an identification number, location data, an online identifier
              or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or
              social identity of that natural person.
            </Text>
            <Text my={1}>
              “Schneider Electric”: means SCHNEIDER ELECTRIC USA, INC. as designated hereinabove in these Terms of Use.
            </Text>
            <Text my={1}>
              “Connected Devices”: means hardware devices designed to be installed at homes, such as solar inverters,
              energy monitoring equipment, electrical panels, home battery banks, power supply control switches,
              electrical vehicle chargers, smart circuit breakers and smart electrical devices, either manufactured by
              Schneider Electric, any of its Affiliates or by any third-party.
            </Text>
            <Text my={1}>
              “You” or “the User”: means any person who is a consumer under applicable law who downloads and makes use
              of the Mobile Application.
            </Text>
            <Text my={2} fontSize={18}>
              3. UPDATES OF THE MOBILE APPLICATION
            </Text>
            <Text my={1}>
              Schneider Electric may, at any time, update or upgrade the then current version of the Mobile Application
              to enhance current functionalities or to add new functionalities of the Mobile Application.
            </Text>
            <Text my={1}>
              The User shall be informed of any update or upgrade to the material characteristics of the Mobile
              Application and may refuse such update or upgrade. Any such update or upgrade to the Mobile Application
              will be published by Schneider Electric on the mobile applications’ online stores from which the Mobile
              Application can be downloaded. Schneider Electric recommends that every User carefully review the
              information provided concerning the Mobile Application on said mobile applications online stores or
              Schneider Electric’s corporate website accessible at:{' '}
              <Link
                my={6}
                fontWeight={600}
                color={linkColor}
                href="https://schneider-electric.com"
                target="_blank"
                data-testid="se-link"
              >
                https://schneider-electric.com
              </Link>
              .
            </Text>
            <Text my={2} fontSize={18}>
              4. REGISTRATION REQUIREMENTS
            </Text>
            <Text my={1}>
              To create Your user account, you agree you shall click on the “Sign Up” button or equivalent interface
              displayed on the screen that appears in the Mobile Application after you download it and you shall provide
              information required thereon with at least Your name and email address or any other individual person You
              authorize to use the Mobile Application (“the Authorized Users”), it being understood that, depending upon
              the jurisdiction, such information You provided to create Your user account may be considered as Personal
              Information.
            </Text>
            <Text my={1}>
              When creating Your user account, You agree (i) to create a unique password that You do not use with any
              other online product or service; (ii) to provide accurate, truthful, current and complete information;
              (iii) to promptly update upon any change the information provided to create Your user account; (iv)
              maintain the confidentiality and security of Your user account by protecting Your identification
              information and password and restricting access to Your user account; (v) promptly notify Schneider
              Electric if You discover or otherwise suspect any security breach related to Your user account or any
              misuse thereof; and (vi) in the case the Mobile Application is connected to a Smart Device, to delete Your
              User account when the Smart Device is no longer in Your possession or under Your control thereby allowing
              such Smart Device to be associated with a different user account.
            </Text>
            <Text my={1}>
              You may authorize Your Authorized Users to access to Your user account and make use of the Mobile
              Application for Your benefit and only for the purposes described in these Terms of Use, under your sole
              responsibility and to the extent permitted by law. You shall therefor be responsible to ensure these Terms
              of Use are made available in a legible manner to each of Your Authorized Users before any of them can log
              and make use of the Mobile Application. You shall be fully responsible to the extent permitted by law for
              the acts and omissions of Your Authorized Users or any other third party to whom You provide Your
              identification information and password, including for all activities that are made through the use of
              Your user account, and their compliance with these Terms of Use. Schneider Electric will have the right to
              rely upon any information received from Your user account.
            </Text>
            <Text my={2} fontSize={18}>
              5. FINANCIAL CONDITIONS
            </Text>
            <Text my={1}>
              The Mobile Application is free for download by the Users; this means that no license or other fee is
              payable by You to Schneider Electric in order to download, access or use the Mobile Application.
            </Text>
            <Text my={1}>
              Users shall solely bear the cost and expense of any toll charges related to or arising from such Users’
              access and use of the Mobile Application through the use of internet or telephone services, including but
              not limited to any costs and expenses related to subscribing to any third-party service that allows access
              to mobile telecommunications and the internet and any cost and expense related to the upload or download
              of any data in or from the Mobile Application.
            </Text>
            <Text my={2} fontSize={18}>
              6. GRANT OF LICENSE
            </Text>
            <Text my={1}>
              Subject to Your compliance with all of the terms and conditions contained in these Terms of Use, Schneider
              Electric grants to You a non-exclusive, non-transferable, revocable, limited and non-assignable license
              right to use the Mobile Application in object code form (machine-readable) only, without the right to
              grant any sub-license thereof, for the purpose for which it is intended to be used to the exclusion of any
              other purpose, and subject to the terms and conditions contained in these Terms of Use.
            </Text>
            <Text my={1}>
              The license to use the Mobile Application is granted to Users for the duration and in the geographies,
              which are made accessible or indicated on the mobile applications online stores’ webpage(s) that make the
              Mobile Application available for download. In the absence of restrictions set forth in these Terms of Use
              or on the mobile applications online store’s webpage(s) with respect to accessibility, duration or
              geographic territory, the license right to use the Mobile Application is granted to Users on perpetual
              (but subject to revocation as set forth herein) and worldwide basis.
            </Text>
            <Text my={1}>
              The Mobile Application is available to the Users in object code (machine-readable) form only and under no
              circumstances is Schneider Electric obligated to disclose or make available to Users the source code of
              the Mobile Application unless required by mandatory provisions of applicable law.
            </Text>
            <Text my={1}>
              You may download and use the Mobile Application on more than one single mobile device if the Mobile
              Application is used on each of said devices under the same user account under which the Mobile Application
              is first downloaded.
            </Text>
            <Text my={1}>
              These Terms of Use only give you some rights to use the Mobile Application and Schneider Electric and its
              licensors reserve all other rights. You do not acquire any rights other than those expressly granted in
              these Terms of Use. You agree that You will use the Mobile Application only as expressly permitted in
              these Terms of Use. You agree that You will comply with any and all technical limitations in the Mobile
              Application that only allow You to use the Mobile Application in certain ways and that You will not, nor
              will You permit others to, circumvent such limitations.
            </Text>
            <Text my={1}>
              If technological measures are designed to prevent unlicensed or illegal use of the Mobile Application, You
              agree that Schneider Electric may use those measures to prevent unlicensed or illegal use of for any other
              legal business purpose and You agree to comply with any requirements regarding such technological
              measures. Such measures do not constitute a defect in the Mobile Application, nor do they entitle You to
              any warranty rights.
            </Text>
            <Text my={1}>
              These Terms of Use describe and govern Your right to download, install and use the Mobile Application.
            </Text>
            <Text my={1}>
              These Terms of Use will also apply to You and shall remain fully enforceable between Schneider Electric
              and You even if You obtained the right to access and use the Mobile Application from an authorized
              Schneider Electric distributor or reseller or a certified Schneider Electric partner.
            </Text>
            <Text my={2} fontSize={18}>
              7. TITLE AND TRADEMARKS
            </Text>
            <Text my={1}>
              The Mobile Application, as well as all rights, title, interest, technology and know-how, whether patented
              or not, embodied in the Mobile Application, as well as all Intellectual Property Rights contained in,
              associated with or attached to the Mobile Application, including but not limited to copyrights, shall
              remain the sole property of Schneider Electric Industries SAS or any other Schneider Electric Affiliate,
              to the exclusion of any third-party software embedded in or bundled with the Mobile Application or
              otherwise provided to You with the Mobile Application.
            </Text>
            <Text my={1}>
              Nothing in these Terms of Use shall be deemed to convey to You any of Schneider Electric’s or its
              Affiliate(s)’ Intellectual Property Rights or other proprietary rights in the Mobile Application; all
              rights not specifically granted in these Terms of Use are reserved by Schneider Electric and, as
              applicable, its Affiliate(s) owning such rights. Schneider Electric does not sell the Mobile Application
              to You but only grants You the license rights defined in these Terms of Use.
            </Text>
            <Text my={1}>
              All Intellectual Property Rights or other proprietary rights pertaining to any third-party software or
              technology embedded in the Mobile Application or otherwise provided to You with the Mobile Application,
              shall remain vested in the relevant third-party and there will be no implied transfer of ownership to You
              of such third-party’s Intellectual Property Rights or other proprietary rights.
            </Text>
            <Text my={1}>
              Should You become aware of any infringement to Schneider Electric’s or its Affiliate(s)’ Intellectual
              Property Rights or other proprietary rights in the Mobile Application, You shall immediately inform
              Schneider Electric of such infringement and provide all relevant information and reasonable cooperation
              requested by Schneider Electric to defend its own interests and/or those of its Affiliate(s).
            </Text>
            <Text my={1}>
              Schneider Electric and other trademarks contained in the Mobile Application or otherwise used for the
              distribution or publication of the Mobile Application, are registered trademarks of Schneider Electric or
              its relevant Affiliate(s). Except as otherwise expressly prescribed by mandatory provisions of applicable
              law, You may not remove or alter any trademark, trade names, product names, logo, copyright or other
              proprietary notices, legends, symbols or labels in the Mobile Application. These Terms of Use do not
              authorise You to use any names or trademarks of Schneider Electric, its Affiliates or their third-party
              licensors or authorised resellers.
            </Text>
            <Text my={2} fontSize={18}>
              8. RESTRICTIONS ON USE
            </Text>
            <Text my={1}>
              You may only download, install, access, use and display the Mobile Application on the type of mobile
              devices and operating systems on which the Mobile Application is compatible for use as referred to in
              these Terms of Use, strictly in accordance with these Terms of Use and only for the specific purposes
              stated in these Terms of Use.
            </Text>
            <Text my={1}>
              Furthermore, unless otherwise expressly required or authorized by statute, You will not nor will You
              permit others to:
            </Text>
            <Text my={1} ml={1}>
              copy the Mobile Application except for backup purposes only in support of Your permitted use of the Mobile
              Application. Any such copy must include all copyright notices and any other proprietary legends present on
              the original Mobile Application. You may not sell, lease, license, rent or in other ways transfer any copy
              of the Mobile Application. If this Mobile Application contains a documentation that is provided only in
              electronic form or online, You may print one copy of such electronic documentation for each license
              acquired to the Mobile Application. If this Mobile Application contains documentation that is provided in
              printed form, You may make one copy of such printed documentation for each license acquired for the Mobile
              Application;
            </Text>
            <Text my={1} ml={1}>
              modify, adapt, translate, reverse engineer, decompile, disassemble or otherwise seek to reconstitute the
              source code of the Mobile Application, nor create derivative works from the Mobile Application. Further,
              in case of errors, bugs or defects of the Mobile Application, Schneider Electric expressly reserves the
              right to correct such errors, bugs or defects to the extent permitted by law but is not obligated to make
              such correction;
            </Text>
            <Text my={1} ml={1}>
              sub-license, lease, outsource or rent the Mobile Application, or permit a third-party to use the Mobile
              Application for its or anyone else’s benefit;
            </Text>
            <Text my={1} ml={1}>
              other than as expressly permitted in these Terms of Use, distribute in whole or part, modify, or create
              derivatives of, the Mobile Application or distribute applications created with the Mobile Application; or
            </Text>
            <Text my={1} ml={1}>
              directly or indirectly, export, re-export, download, or ship the Mobile Application in violation of the
              laws and regulations of the jurisdiction in which You use or are downloading the Mobile Application.
            </Text>
            <Text my={1}>
              The use of the Mobile Application is intended only for use with contents owned by You, public domain
              contents or properly licensed content. You may need a patent, copyright, or other license from a
              third-party to create, copy, download, record or save content files for use with the Mobile Application or
              to serve or distribute such files to be used with the Mobile Application; in such case, it is Your sole
              responsibility to obtain such license and you shall solely bear the cost and expense of obtaining such
              license.
            </Text>
            <Text my={1}>
              You may not use the Mobile Application in an attempt to, or in conjunction with any device, program or
              service designed to, circumvent technological measures employed to control access to, or the rights in, a
              content file or other work protected by the copyright or other laws of any jurisdiction.
            </Text>
            <Text my={1}>
              You agree that You shall only use the Mobile Application in a manner that complies with all applicable
              laws in the jurisdictions in which You use or are downloading the Mobile Application, including, but not
              limited to, applicable restrictions concerning copyright and other Intellectual Property Rights.
            </Text>
            <Text my={2} fontSize={18}>
              9. PERSONAL INFORMATION PROTECTION
            </Text>
            <Text my={1}>
              You are advised to check the privacy notices and privacy policies made available to you by Schneider
              Electric in relation to the Mobile Application.
            </Text>
            <Text my={1}>
              You are responsible for any third-party Personal Information that you upload, store, create, use, share,
              modify, delete or otherwise process during your use of the Mobile Application. You must comply with all
              applicable laws and regulations, obtain all required consents and make all required notifications in this
              respect.
            </Text>
            <Text my={2} fontSize={18}>
              10. WARRANTIES AND DISCLAIMERS OF WARRANTIES
            </Text>
            <Text my={1}>
              SCHNEIDER ELECTRIC WARRANTS THAT IT IS ENTITLED TO LICENSE AND OTHERWISE MAKE AVAILABLE THE MOBILE
              APPLICATION TO YOU IN ACCORDANCE WITH THE TERMS AND CONDITIONS SET OUT IN THESE TERMS OF USE.
            </Text>
            <Text my={1}>
              SCHNEIDER ELECTRIC PROVIDES A LIMITED WARRANTY UNDER WHICH SCHNEIDER ELECTRIC’S SOLE OBLIGATION AND YOUR
              SOLE REMEDY WITH RESPECT TO THE MOBILE APPLICATION SHALL BE, AT SCHNEIDER ELECTRIC’s OPTION, SCHNEIDER
              ELECTRIC SHALL FIX OR PROVIDE A WORK-AROUND TO, ANY DEFECT OR NON-COMPLIANCE OF THE MOBILE APPLICATION
              WITHOUT CHARGING ANY AMOUNT TO YOU, PROVIDED THAT (A) YOU GIVE NOTICE OF THE DEFECT TO SCHNEIDER ELECTRIC
              WITHIN A PERIOD OF NINETY (90) DAYS FROM THE DATE WHEN YOU FIRST DOWNLOADED THE MOBILE APPLICATION, AND
              (B) THE DEFECT DOES NOT FALL UNDER THE EXCLUSIONS SET FORTH IN SECTION 10.3 BELOW.
            </Text>
            <Text my={1}>
              SCHNEIDER ELECTRIC’S WARRANTY SHALL BE EXCLUDED AND SCHNEIDER ELECTRIC SHALL HAVE NO WARRANTY OBLIGATIONS
              TO THE EXTENT THE MOBILE APPLICATION IS ALTERED OR FAILS TO PERFORM IN ANY WAY AS THE RESULT OF A FAULTY
              OR NON-CONFORMING USE, CONFIGURATION OR INSTALLATION OF THE MOBILE APPLICATION BY YOU, ANY PERSON UNDER
              YOUR CONTROL OR ANY AUTHORIZED USER, INCLUDING BUT NOT LIMITED TO THE USE OF THE MOBILE APPLICATION WITH
              ANY HARDWARE, SOFTWARE, FIRMWARE, OPERATING SYSTEM OR SOLUTION NOT SUPPLIED, NOT RECOMMENDED OR NOT
              APPROVED BY SCHNEIDER ELECTRIC TO BE USED WITH THE MOBILE APPLICATION, OR THE UNAUTHORIZED MAINTENANCE OF
              THE MOBILE APPLICATION.
            </Text>
            <Text my={1}>
              SCHNEIDER ELECTRIC’S WARRANTY SHALL ALSO BE EXCLUDED IN CASE OF DEFECT OR NON-COMPLIANCE OF THE MOBILE
              APPLICATION, TO THE EXTENT SUCH DEFECT OR NON-COMPLIANCE COULD HAVE BEEN PREVENTED BY MAKING USE OF AN
              UPDATE OR UPGRADE TO THE MOBILE APPLICATION THAT SCHNEIDER ELECTRIC MADE AVAILABLE TO YOU IN ANY MANNER
              WHATSOEVER.
            </Text>
            <Text my={1}>
              WITHOUT PREJUDICE TO ANY STATUTORY WARRANTIES WHICH, AS THE CASE MAY BE, BENEFIT YOU UNDER THE LAW
              APPLICABLE TO THESE TERMS OF USE OR OTHER MANDATORY OR PUBLIC ORDER LAWS OR REGULATIONS (SUCH AS BUT NOT
              LIMITED TO THOSE LAWS AND REGULATIONS RELATED TO CONSUMERS’ RIGHTS) THAT ARE APPLICABLE, ON BOTH FEDERAL
              AND STATE LEVELS AS THE CASE MAY APPLY, IN ANY COUNTRY WHERE YOU HAVE YOUR RESIDENCE AS A CONSUMER,
              SCHNEIDER ELECTRIC MAKES NO OTHER WARRANTY THAN THOSE CONTAINED IN THIS SECTION 10 AND EXPRESSLY DISCLAIMS
              ALL OTHER WARRANTIES, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE MOBILE APPLICATION, THEIR UPDATES AND
              UPGRADES, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY AND FITNESS
              FOR A PARTICULAR PURPOSE. FURTHER, WHILE SCHNEIDER ELECTRIC HAS TAKEN REASONABLE STEPS TO ENSURE THE
              ACCURACY OF THE INFORMATION CONTAINED IN OR SHOWN BY THE MOBILE APPLICATION, SCHNEIDER ELECTRIC MAKES NO
              WARRANTY AS TO WHETHER THE MOBILE APPLICATION OR ANY INFORMATION CONTAINED IN OR SHOWN BY THE MOBILE
              APPLICATION WILL MEET YOUR REQUIREMENTS, EXPECTATIONS OR PURPOSES OR THAT THE OPERATION OF THE MOBILE
              APPLICATION WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT THE MOBILE APPLICATION WILL BE PROTECTED AGAINST
              ALL POSSIBLE SECURITY THREATS, INTERNET THREATS OR OTHER THREATS OR INTERRUPTIONS.
            </Text>
            <Text my={1}>
              EXCEPT FOR THE WARRANTIES STATED UNDER THIS SECTION 10, SCHNEIDER ELECTRIC SHALL NOT HAVE ANY OBLIGATION
              TO PROVIDE YOU WITH ANY MAINTENANCE, SUPPORT OR CORRECTION SERVICES IN CONNECTION WITH THE MOBILE
              APPLICATION OTHER THAN THOSE YOU AND SCHNEIDER ELECTRIC MAY HAVE AGREED UPON BY WAY OF A SEPARATE WRITTEN
              AGREEMENT.
            </Text>
            <Text my={1}>
              THE WARRANTIES FOUND IN THIS SECTION 10 ARE WITHOUT PREJUDICE TO ANY STATUTORY WARRANTIES AND IN ADDITION
              TO ANY OTHER RIGHTS AND REMEDIES YOU MAY HAVE UNDER APPLICABLE CONSUMER LAWS TO THE EXTENT SUCH RIGHTS AND
              REMEDIES CANNOT LEGALLY BE DISCLAIMED OR EXCLUDED.
            </Text>
            <Text my={2} fontSize={18}>
              11. LIABILITIES
            </Text>
            <Text my={1}>
              THE USE OF THE MOBILE APPLICATION SHALL BE UNDER YOUR SOLE RESPONSIBILITY AND AT YOUR SOLE RISK.
            </Text>
            <Text my={1}>
              YOUR DOWNLOAD OR UPLOAD OF ANY DATA FROM OR TO THE MOBILE APPLICATION, YOUR CREATION OR MODIFICATION OF
              ANY DATA IN CONNECTION WITH THE USE OF THE MOBILE APPLICATION SHALL BE YOUR SOLE RESPONSIBILITY, AS WELL
              AS ANY USE OF SUCH DATA BY YOU OR ANY THIRD PARTY ACTING ON YOUR BEHALF.
            </Text>
            <Text my={1}>
              THE EXCLUSION AND LIMITATIONS OF LIABILITY STATED IN THESE TERMS OF USE SHALL APPLY ONLY TO THE EXTENT
              PERMITTED BY THE LAW APPLICABLE TO THESE TERMS OF USE AND DO NOT AFFECT OR PREJUDICE STATUTORY RIGHTS
              WHICH, AS THE CASE MAY BE, BENEFIT YOU UNDER MANDATORY OR PUBLIC ORDER LAWS OR REGULATIONS (SUCH AS BUT
              NOT LIMITED TO THOSE LAWS AND REGULATIONS RELATED TO CONSUMERS’ RIGHTS) APPLICABLE IN ANY COUNTRY WHERE
              YOU WILL DOWNLOAD, INSTALL OR USE THE MOBILE APPLICATION OR IN THE COUNTRY WHERE YOU HAVE YOUR RESIDENCE
              AS A CONSUMER.
            </Text>
            <Text my={2} fontSize={18}>
              LIABILITY LIMITATION
            </Text>
            <Text my={1}>
              (A) EXCEPT AS SET FORTH IN SUBSECTION (C) BELOW, IN NO CASE SHALL SCHNEIDER ELECTRIC, ITS OFFICERS,
              DIRECTORS, EMPLOYEES, AFFILIATES OR LICENSORS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, OR
              SPECIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR CORRUPTION OR LOSS OF DATA, LOSS OF PROFITS,
              LOSS OF BUSINESS OR BUSINESS INTERRUPTION, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE
              MOBILE APPLICATION OR RELATED SERVICES OR ANY THIRD PARTY SOFTWARE OR SERVICES IN CONNECTION WITH THE
              MOBILE APPLICATION OR RELATED SERVICES, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT,
              TORT OR OTHERWISE) AND EVEN IF SCHNEIDER ELECTRIC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CERTAIN KINDS OF DAMAGES, SO
              THIS LIMITATION MAY NOT APPLY TO YOU.
            </Text>
            <Text my={1}>
              (B) EXCEPT AS SET FORTH IN SUBSECTION (C) BELOW, IN NO CASE SHALL SCHNEIDER ELECTRIC, ITS OFFICERS,
              DIRECTORS, EMPLOYEES, AFFILIATES OR LICENSORS TOTAL LIABILITY TO YOU FOR ALL DAMAGES EXCEED FIVE DOLLARS
              ($5 USD). THE FOREGOING LIMITATIOMN SHALL APPLY EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
            </Text>
            <Text my={1}>
              (C) THE EXCLUSION OF DAMAGES AND LIMITATION OF LIABILITY SET FORTH IN SUBSECTIONS (A) AND (B) ABOVE SHALL
              NOT APPLY TO:
            </Text>
            <Text my={2} ml={2}>
              DAMAGES OR CLAIMS WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW;
            </Text>
            <Text my={2} ml={2}>
              DAMAGES OR CLAIMS ARISING FROM BODILY INJURY OR DEATH;
            </Text>
            <Text my={2} ml={2}>
              OR DAMAGES OR CLAIMS CAUSED BY FRAUD OR INTENTIONAL MISCONDUCT.
            </Text>
            <Text my={2} fontSize={18}>
              12. INDEMNIFICATION
            </Text>
            <Text my={1}>
              12.1. You guarantee that You shall indemnify Schneider Electric, its Affiliates and each of their
              respective officers, directors, employees, licensors, contractors, successors and assigns against any and
              all damages arising from or related to any of the following circumstances: (i) any breach by You of any of
              Your obligations under these Terms of Use; (ii) any failure by You to comply with any applicable law
              and/or regulation in connection with Your use of the Mobile Application, (iii) any use of the Mobile
              Application with any hardware, software, firmware, operating system or solution not supplied, not
              recommended or otherwise not approved by Schneider Electric to be used with the Mobile Application; and/or
              (iv) any modification, alteration or maintenance of the Mobile Application by anyone other than Schneider
              Electric without written approval of Schneider Electric.
            </Text>
            <Text my={1}>
              12.2. Subject to the provisions of Section 11, Schneider Electric will defend and indemnify You against a
              third-party claim that the Mobile Application infringes any copyright enforceable in the jurisdiction
              where Schneider Electric has its registered office or principal place of business, or misappropriates any
              trade secret protected under the laws of such jurisdiction (the “Included Jurisdiction”), provided that:
              (i) You notify Schneider Electric of the claim in writing immediately upon the Your awareness of such
              claim; (ii) Schneider Electric has sole authority and control of the defence of the claim and all related
              settlement negotiations; and (iii) You provide Schneider Electric with the assistance, information and
              authority necessary in order for Schneider Electric to handle the defense and settlement of such claim and
              perform its obligations under this Section 12.2. If the Mobile Application is held or believed by
              Schneider Electric to constitute an infringement or misappropriation as per this Section 12.2, then
              Schneider Electric will have the option, at its expense, to: (i) obtain for You a license to continue
              using the Mobile Application; (ii) replace or modify the Mobile Application to make it non-infringing; or
              (iii) if in Schneider Electric’s sole discretion, it is not economically or commercially reasonable to
              perform either (i) or (ii) above then Schneider Electric may terminate the license for the infringing
              Mobile Application and refund to You the license or other fee (if any) You paid to Schneider Electric for
              the infringing Mobile Application. Without prejudice to mandatory or public order laws or regulations in
              the country where You have Your residence as a consumer and to the extent permitted under such laws or
              regulations, this Section 12.2 states Your sole and exclusive remedy and Schneider Electric’s and its
              Affiliates’ sole liability for any claim under this Section 12.2.
            </Text>
            <Text my={1}>
              12.3. To the extent permitted by law including the law of the country where You have Your residence as a
              consumer, Schneider Electric will not bear or owe You the warranty defined under Section 12.2. of these
              Terms of Use for any claim that arises out of or relates to: (i) any circumstance defined under Section
              12.1, or (ii) Your use of the Mobile Application after You received the information from Schneider
              Electric or any competent authority that the Mobile Application infringes any third-party’s Intellectual
              Property Right; or (iii) Your failure to use corrections or enhancements made available by Schneider
              Electric.
            </Text>
            <Text my={2} fontSize={18}>
              13. THIRD-PARTY WEBSITES AND CONTENTS
            </Text>
            <Text my={1}>
              In case the Mobile Application enables the User to indicate the web address of, to access to or to create
              a link providing access to, any third party’s website or third-party’s contents, products, services or
              information, the User shall, to the extent permitted by law, be responsible and bear all risks associated
              with the access and use of such third parties’ websites and /or contents, products, services and
              information. Schneider Electric does not control and is not responsible for such third-parties’ websites
              or any such third-parties’ contents, products, services and information accessible from or provided
              through such third-parties’ websites. Any access by the User to third parties’ websites or any use it
              makes of third parties’ contents, products, services or information through the User’s access and/or use
              of the Mobile Application shall be subject to said third parties’ own terms of use or other legal document
              governing said third-party’s websites, contents, products, services or information.
            </Text>
            <Text my={2} fontSize={18}>
              14. ASSIGNMENT
            </Text>
            <Text my={1}>
              Your rights and obligations under these Terms of Use may not be sold, sub-licensed, rented, assigned,
              delegated, transferred or otherwise conveyed by You or Your representatives without Schneider Electric’s
              prior express written consent. The license granted to You under these Terms of Use is granted in
              consideration of Your person (“intuitu personae”). Schneider Electric may assign its rights and
              obligations under these Terms of Use to any Affiliate or to any company Schneider Electric may acquire
              control of or merge with. Subject to the foregoing, these Terms of Use shall be binding upon and inure to
              the benefit of the parties hereto, their successors and permitted assigns.
            </Text>
            <Text my={2} fontSize={18}>
              15. TERM OF CONTRACT - TERMINATION
            </Text>
            <Text my={1}>
              15.1. The legal agreement formed between You and Schneider Electric pursuant to these Terms of Use, is a
              contract of indefinite duration which can be terminated by the parties as indicated in Section 15.
            </Text>
            <Text my={1}>
              15.2. You may at any time uninstall the Mobile Application, without prior notice; such uninstallation will
              automatically and by right cause the termination of the legal agreement formed under these Terms of Use.
            </Text>
            <Text my={1}>
              15.3. Either party may terminate the legal agreement formed under these Terms of Use in case the other
              party fails or breaches any of its contractual, legal or regulatory obligations in the frame of the
              performance of said legal agreement, or in case the other party infringes any Intellectual Property Rights
              when accessing or using the Mobile Application.
            </Text>
            <Text my={1}>
              15.4. You undertake to cease using the Mobile Application immediately and by right upon the effective date
              of expiration or termination of the legal agreement formed under these Terms of Use, and to remove the
              Mobile Application from any device or medium on which You will have downloaded it. Upon Schneider
              Electric’s demand, You will provide a written statement that You have taken the measures stated in this
              Section 15.4.
            </Text>
            <Text my={1}>
              15.5. Termination by either party of the legal agreement formed under these Terms of Use shall not affect
              any right or claim that either party acquired before said termination.
            </Text>
            <Text my={2} fontSize={18}>
              16. MISCELLANEOUS
            </Text>
            <Text my={1}>
              Schneider Electric is an independent contractor. Each of You and Schneider Electric agree that no
              partnership, joint venture, or agency relationship exists or is created by these Terms of Use between You
              and Schneider Electric. Neither You nor Schneider Electric is granted any right or authority to assume or
              create any obligation or responsibility, express or implied, on behalf of or in the name of one another.
            </Text>
            <Text my={1}>
              No third-party beneficiary relationship is created by these Terms of Use. These Terms of Use shall not be
              construed to confer any rights or remedies to any third-party.
            </Text>
            <Text my={1}>
              These Terms of Use constitute the entire agreement between You and Schneider Electric in relation to Your
              right to use the Mobile Application and replaces any previous agreement or understanding, whether oral,
              electronic or written, in relation to the same subject matter.
            </Text>
            <Text my={1}>
              Should any provision of these Terms of Use be declared invalid or unenforceable by any competent
              jurisdiction and provided said invalidity or unenforceability does not void the legal agreement formed
              under these Terms of Use, all other provisions of these Terms of Use shall remain valid and in full force
              and effect.
            </Text>
            <Text my={1}>
              Failure by either party to these Terms of Use to insist on or enforce strict performance of these Terms of
              Use shall not be construed as a waiver by such party of any right to enforce these Terms of Use, nor shall
              any course of conduct between the parties or with any third-party be deemed to modify any provision of
              these Terms of Use.
            </Text>
            <Text my={1}>
              All notices sent in pursuance of these Terms of Use shall be in writing. Notices of a general purpose
              related to the Mobile Application shall be given by Schneider Electric on Schneider Electric’s website,
              the online mobile application stores’ webpage(s) that make the Mobile Application available for download
              and/or through the Mobile Application itself. Notices specifically addressed to You shall be given by
              Schneider Electric by electronic mail to the electronic address on record in the information You provided
              when downloading the Mobile Application or, when applicable, when creating Your user account. Any notice
              by the User to Schneider Electric shall be in writing and sent by electronic mail to the electronic
              address or by registered letter to the address of Schneider Electric’s registered office as respectively
              indicated on the first page of these Terms of Use.
            </Text>
            <Text my={1}>
              Headings in these Terms of Use are just for ease of reference and will not affect its interpretation.
            </Text>
            <Text my={1}>Words expressed in the singular include the plural and vice versa.</Text>
            <Text my={1}>
              Section 7 “Title and trademarks”, Section 10 “Warranties and disclaimers of warranties”, Section 11
              “Liabilities”, Section 12 “Indemnification”, Section 15 “Term of Contract - Termination”, Section 16
              “Miscellaneous” and Section 19 “Applicable Law and Disputes Resolution” of these Terms of Use shall
              survive termination of the contract formed between You and Schneider Electric by these Terms of Use.
              Furthermore, provisions which by their nature are intended to survive termination of these Terms of Use,
              shall continue to produce their effects notwithstanding termination of these Terms of Use.
            </Text>
            <Text my={2} fontSize={18}>
              17. AMENDMENT TO THESE TERMS OF USE
            </Text>
            <Text my={1}>
              Schneider Electric may at any time update these Terms of Use to appropriately reflect our services and
              practices. Unless provided to the contrary by law, Schneider Electric shall inform You through the Mobile
              Application before revising these Terms of Use and shall give you the opportunity to review and, as you
              may decide, to refuse the revised Terms of Use before it enters into force.Subject to the foregoing, You
              shall be bound by the revised Terms of Use by effect of your continued use of the Mobile Application after
              the revised Terms of Use enter into force.
            </Text>
            <Text my={2} fontSize={18}>
              18. COMPLAINTS BY THE USER
            </Text>
            <Text my={1}>
              Any complaint from the User to Schneider Electric in relation to the Mobile Application shall be sent to
              Schneider Electric by electronic mail to the electronic address indicated on the first page of these Terms
              of Use. Such complaints shall be processed by Schneider Electric as soon as possible.
            </Text>
            <Text my={2} fontSize={18}>
              19. APPLICABLE LAW AND DISPUTE RESOLUTION
            </Text>
            <Text my={1}>
              19.1. These Terms of Use shall be governed by the laws of the State of Massachusetts, USA, to the
              exclusion of its conflict of law rules. To the extent possible, Schneider Electric and You agree that the
              United Nations Convention on the International Sale of Goods shall not apply to these Terms of Use. As a
              consumer, You additionally enjoy the protection afforded to You by mandatory or public order provisions of
              any law or regulation of Your country of residence for the protection of Your consumer rights.
            </Text>
            <Text my={1}>19.2. DISPUTE RESOLUTION AND BINDING ARBITRATION.</Text>
            <Text my={1}>
              (A) YOU AND SCHNEIDER ELECTRIC ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT OR BEFORE
              A JURY, OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS
              THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.
            </Text>
            <Text my={1}>
              ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT
              OR FUTURE, AND INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW, INTENTIONAL TORT, INJUNCTIVE AND
              EQUITABLE CLAIMS) BETWEEN YOU AND SCHNEIDER ELECTRIC ARISING FROM OR RELATING IN ANY WAY TO YOUR USE OF OR
              INABILITY TO USE THE MOBILE APPLICATION OR RELATED SERVICES OR THESE TERMS OF USE WILL BE RESOLVED
              EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.
            </Text>
            <Text my={1}>
              THE ARBITRATION WILL BE ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION ("AAA") IN ACCORDANCE WITH
              THE CONSUMER ARBITRATION RULES (THE "AAA RULES") THEN IN EFFECT, EXCEPT AS MODIFIED BY THIS SECTION 19.2
              (THE AAA RULES ARE AVAILABLE AT ADR.ORG OR BY CALLING THE AAA AT 1-800-778-7879.) THE UNITED STATES
              FEDERAL ARBITRATION ACT WILL GOVERN THE INTERPRETATION AND ENFORCEMENT OF THIS SECTION.
            </Text>
            <Text my={1}>
              THE ARBITRATOR WILL HAVE EXCLUSIVE AUTHORITY TO RESOLVE ANY DISPUTE RELATING TO ARBITRABILITY AND/OR
              ENFORCEABILITY OF THIS ARBITRATION PROVISION, INCLUDING ANY UNCONSCIONABILITY CHALLENGE OR ANY OTHER
              CHALLENGE THAT THE ARBITRATION PROVISION OR THE AGREEMENT IS VOID, VOIDABLE OR OTHERWISE INVALID. THE
              ARBITRATOR WILL BE EMPOWERED TO GRANT WHATEVER RELIEF WOULD BE AVAILABLE IN COURT UNDER LAW OR IN EQUITY.
              ANY AWARD OF THE ARBITRATOR(S) WILL BE FINAL AND BINDING ON EACH OF THE PARTIES AND MAY BE ENTERED AS A
              JUDGMENT IN ANY COURT OF COMPETENT JURISDICTION.
            </Text>
            <Text my={1}>
              (B) SCHNEIDER ELECTRIC WILL BE RESPONSIBLE FOR PAYING ANY INDIVIDUAL CONSUMER’s ARBITRATOR FEES. IF YOU
              PREVAIL ON ANY CLAIM THAT AFFORDS THE PREVAILING PARTY ATTORNEYS’ FEES, THE ARBITRATOR MAY AWARD
              REASONABLE FEES TO YOU UNDER THE STANDARDS FOR FEE SHIFTING PROVIDED BY LAW.
            </Text>
            <Text my={1}>
              (C) YOU MAY ELECT TO PURSUE YOUR CLAIM IN SMALL-CLAIMS COURT RATHER THAN ARBITRATION IF YOU PROVIDE
              SCHNEIDER ELECTRIC WITH WRITTEN NOTICE OF YOUR INTENTION DO SO WITHIN 60 DAYS OF YOUR DOWLOADING OR USE OF
              THE MOBILE APPLICATION OR RELATED SERVICES WHICHEVER OCCURS FIRST. THE ARBITRATION OR SMALL-CLAIMS COURT
              PROCEEDING WILL BE LIMITED SOLELY TO YOUR INDIVIDUAL DISPUTE OR CONTROVERSY.
            </Text>
            <Text my={1}>
              (D) YOU AGREE TO AN ARBITRATION ON AN INDIVIDUAL BASIS. IN ANY DISPUTE, NEITHER YOU NOR SCHNEIDER ELECTRIC
              WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS IN COURT OR IN ARBITRATION OR
              OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY
              GENERAL CAPACITY. THE ARBITRAL TRIBUNAL MAY NOT CONSOLIDATE MORE THAN ONE PERSON’s CLAIMS, AND MAY NOT
              OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. THE ARBITRAL TRIBUNAL HAS NO
              POWER TO CONSIDER THE ENFORCEABILITY OF THIS CLASS ARBITRATION WAIVER AND ANY CHALLENGE TO THE CLASS
              ARBITRATION WAIVER MAY ONLY BE RAISED IN A COURT OF COMPETENT JURISDICTION.
            </Text>
            <Text my={1}>
              (E) IF ANY PROVISION OF THIS ARBITRATION AGREEMENT IS FOUND UNENFORCEABLE, THE UNENFORCEABLE PROVISION
              WILL BE SEVERED AND THE REMAINING ARBITRATION TERMS WILL BE ENFORCED.
            </Text>
            <Text my={1}>
              If You are a consumer having Your residence in the European Union or in the United Kingdom, You may bring
              a claim against Schneider Electric to enforce Your consumer rights in connection with these Terms of Use
              and/or the Mobile Application, in either of the courts of the country where Schneider Electric has its
              registered office or the courts of Your country of residence.
            </Text>
            <Text my={1}>
              If You are a consumer having Your residence in the European Union, the European Commission provides for an
              online and out-of-court consumers disputes resolution platform, which You can access under the following
              link:{' '}
              <Link
                my={6}
                fontWeight={600}
                color={linkColor}
                href="https://ec.europa.eu/consumers/odr"
                target="_blank"
                data-testid="eu-odr-link"
              >
                https://ec.europa.eu/consumers/odr
              </Link>
              .
            </Text>
            <Text my={1}>
              19.3 To the extent permitted by applicable law at both federal and state levels when applicable, in the
              country where Schneider Electric has its registered office or principal place of business and/or, as
              relevant in the context, in the country where you have Your residence as a consumer, You acknowledge and
              accept that Schneider Electric will be irreparably damaged (and damages at law may be an inadequate
              remedy) if You breach any provision of these Terms of Use and such provision is not specifically enforced.
              Therefore, in the event of a breach or threatened breach by You of these Terms of Use, Schneider Electric
              shall be entitled, in addition to all other rights or remedies, to (i) an injunction or other injunctive
              relief restraining such breach, without being required to show any actual damage or to post an injunction
              or other bond; and/or (ii) a decree for specific performance of the applicable provision of these Terms of
              Use, to the extent permitted by applicable law in the country where Schneider Electric has its registered
              office or principal place of business and/or, as relevant in the context, where You will access and/or use
              the Mobile Application, on both federal and state levels when applicable.
            </Text>
            <Text my={1}>© 2023 - Schneider Electric. All rights reserved.</Text>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
