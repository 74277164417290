import React, { useMemo } from 'react';
import { Box, Flex, Link, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AlertBanner } from 'clipsal-cortex-ui/src/components/AlertBanner';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { useGetCostsQuery } from '../../site/costApi';
import { LiveDataSwitch } from '../../site/live-data/types';
import { selectCurrentDayForSite, selectSite } from '../../site/siteSlice';
import { DeviceListItem } from '../DeviceListItem';
import { getIconForDevice } from '../devices-helper';
import { useGetDevicesQuery } from '../devicesApi';
import { SortByOptions } from '../SortByPopover';
import { CombinedSwitch, useGetLiveSenseSwitchesQuery } from './switchApi';
import { SwitchToggle } from './SwitchToggle';

export type SwitchesListProps = {
  sortBy: SortByOptions;
  limit?: number;
  state?: LiveDataSwitch['state'];
  title?: string;
};

type SwitchWithCostAndUserAssignment = CombinedSwitch & { costToday: number; userAssignment: string | null };

export function SwitchesList({ sortBy, limit, state, title }: SwitchesListProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const { t } = useTranslation();
  const { data: switches, isLoading: isSwitchesLoading, isError, isLiveDataError } = useGetLiveSenseSwitchesQuery();
  // We need to grab appliances because switches don't include the user_assignment field (for the icon)
  const { data: appliances, isLoading: isDeviceLoading } = useGetDevicesQuery();
  const currentDay = useSelector(selectCurrentDayForSite);
  const { data: costsData, isLoading: isCostsLoading } = useGetCostsQuery(currentDay, DateRangeType.Day);
  const isApiDataLoaded = !isSwitchesLoading && !isCostsLoading && !isDeviceLoading;
  const linkColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');

  const switchesWithCostToday = useMemo<SwitchWithCostAndUserAssignment[]>(() => {
    let mappedSwitches = switches.map((senseSwitch) => {
      // Match cost entries by the appliance id of the switch
      const costForSwitch =
        costsData.devices.find((appliance) => appliance.appliance_id === senseSwitch.appliance_id)?.cost || 0;
      // Get the user_assignment value from our devices list
      const userAssignmentForSwitch =
        appliances.find((appliance) => appliance.appliance_id === senseSwitch.appliance_id)?.user_assignment || null;

      return {
        ...senseSwitch,
        costToday: costForSwitch,
        userAssignment: userAssignmentForSwitch,
      };
    });

    // Filter by state
    if (state) mappedSwitches = mappedSwitches.filter((switches) => switches.state === state);

    // Limit the number of switches
    if (limit) mappedSwitches = mappedSwitches.slice(0, limit);

    return mappedSwitches;
  }, [costsData, switches, appliances, state, limit]);

  const sortedSwitches: SwitchWithCostAndUserAssignment[] = useMemo(() => {
    if (sortBy === 'ALPHABETICAL') {
      return orderBy(switchesWithCostToday, [(senseSwitch) => senseSwitch.site_switch_label.toLowerCase()], ['asc']);
    } else if (sortBy === 'POWER') {
      return orderBy(switchesWithCostToday, [(senseSwitch) => senseSwitch.power], ['desc']);
    } else if (sortBy === 'COST') {
      return orderBy(switchesWithCostToday, [(senseSwitch) => senseSwitch.costToday], ['desc']);
    } else return switchesWithCostToday;
  }, [sortBy, switchesWithCostToday]);

  const handleViewSwitch = (senseSwitch: SwitchWithCostAndUserAssignment) => {
    navigate(`/sites/${siteId}/switches/${senseSwitch.id}/view?backURL=${pathname}?direction=forward`);
  };

  return (
    <Skeleton
      isLoaded={isApiDataLoaded}
      borderRadius={5}
      borderBottomRightRadius="25px"
      height={isApiDataLoaded ? 'unset' : 75}
      data-testid="switches-list"
    >
      {!!title && (
        <Flex justifyContent="space-between" mb={2}>
          <Text>{title}</Text>
          <Link
            onClick={() => navigate(`/sites/${siteId}/devices/list`)}
            color={linkColor}
            data-testid="view-all-devices-link"
          >
            {t('Common.view all')}
          </Link>
        </Flex>
      )}
      {isLiveDataError && (
        <Box mb={4}>
          <AlertBanner
            title={t('Devices.error fetching live data')}
            type="error"
            isCollapsible={false}
            isDismissable={false}
            containerTestId="switches-error-alert"
          />
        </Box>
      )}
      {sortedSwitches.map((senseSwitch, i) => {
        const Icon = getIconForDevice(senseSwitch.userAssignment);
        return (
          <DeviceListItem
            name={senseSwitch.site_switch_label}
            Icon={Icon}
            currentPower={senseSwitch.power}
            costToday={senseSwitch.costToday}
            onClick={() => handleViewSwitch(senseSwitch)}
            key={senseSwitch.id}
            data-testid={`${senseSwitch.appliance_label.split('__')[0].replace('_', '-')}-${i}-switch-list-item`}
          >
            <SwitchToggle switchId={senseSwitch.id} />
          </DeviceListItem>
        );
      })}
      {!sortedSwitches.length && !isError && <Text color="gray.400">{t('Devices.no devices found')}</Text>}
    </Skeleton>
  );
}
