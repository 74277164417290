import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SiteListData } from 'clipsal-cortex-types/src/api/api-site';
import { PaginatedResponse } from 'clipsal-cortex-types/src/common/types';

import { RootState } from '../../app/store';
import { get } from '../../common/api/api-helpers';

export type SitesResponse = PaginatedResponse<SiteListData>;

export const getSites = createAsyncThunk<
  { data: SiteListData[]; item_count: number; pageSize: number },
  { pageSize: number; offset: number },
  { state: RootState }
>('user/getSites', async ({ pageSize, offset }, { getState }) => {
  const { sites } = getState();
  let uri = `/v1/sites?limit=${pageSize}&offset=${offset}`;

  if (sites.searchTerm) {
    // If the search term is already in the store, append it.
    uri += `&search_term=${sites.searchTerm}`;
  }

  const siteData = await get<SitesResponse>(uri);

  return { ...siteData, pageSize };
});

type SitesState = {
  sites: SiteListData[];
  isLoaded: boolean;
  pageSize: number;
  totalSites: number;
  searchTerm: string | null;
};

const initialState: SitesState = {
  sites: [],
  isLoaded: false,
  pageSize: 10,
  totalSites: 0,
  searchTerm: null,
};

export const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setNotLoaded: (state) => {
      return {
        ...state,
        isLoaded: false,
      };
    },
    setSearchTerm: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        isLoaded: false,
        offset: 0,
        searchTerm: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSites.fulfilled, (state, action) => {
      return {
        ...state,
        sites: action.payload.data,
        totalSites: action.payload.item_count,
        isLoaded: true,
        pageSize: action.payload.pageSize,
      };
    });
  },
});

export const { setNotLoaded, setSearchTerm } = sitesSlice.actions;

export const selectSites = (state: RootState) => {
  return state.sites;
};

export const { reducer: sitesReducer } = sitesSlice;
