/**
 * Generates a code verifier string with a specified length.
 *
 * @param length - The length of the code verifier string. Defaults to 86.
 * @returns A random string with the specified length.
 */
export function generateCodeVerifier(length = 86) {
  const array = new Uint8Array(length / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => dec.toString(16).padStart(2, '0')).join('');
}

/**
 * Converts a hashed ArrayBuffer to a base64 encoded verifier string, to be used as a part of PKCE authentication.
 *
 * @param buf - The array buffer to encode.
 * @returns The base64 encoded string generated from the provided array buffer.
 */
function base64Encode(buf: ArrayBuffer) {
  let str = '';
  const bytes = new Uint8Array(buf);
  for (let i = 0; i < bytes.byteLength; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

/**
 * Generates a code challenge from a verifier string by base64 encoding a hashed (sha246) version of the string.
 *
 * @param verifier - The verifier string to encode.
 * @returns A base64 encoded string version of the original verifier string.
 */
export async function generateCodeChallengeFromVerifier(verifier: string) {
  const buf = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(verifier));
  return base64Encode(buf);
}

/**
 * Parses a JWT (OAuth 2.0 access token) and returns its payload (claims).
 *
 * @param {string} token - The JWT access token
 * @returns {object|null} The parsed claims as an object, or null if parsing fails
 */
export function parseJwt(token: string) {
  try {
    // A JWT has three parts separated by dots: header, payload, signature
    const base64Url = token.split('.')[1];
    if (!base64Url) {
      throw new Error('Invalid token format');
    }

    // Convert from base64Url to base64
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    // Decode the payload
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        // For each character, get its character code in hex, and prepend a '%' to turn it into a percent-encoded string
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    // Parse the JSON string
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error(error);
    return null;
  }
}
