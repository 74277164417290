import React, { useMemo } from 'react';
import { toZonedTime } from 'date-fns-tz';
import { useSelector } from 'react-redux';

import { IS_DEMO_LOGIN } from '../../common/constants';
import { selectSite } from '../site/siteSlice';
import { useGetSunriseSunsetQuery } from '../site/sunriseSunsetApi';
import { useGetWeatherQuery } from '../site/weatherApi';
import { MoonAnimation, StormAnimation, SunAnimation } from './animation-icons';

function getWeatherAnimation(condition: string) {
  const weatherIconMap: Record<string, JSX.Element> = {
    rain: <StormAnimation />,
    squall: <StormAnimation />,
    storm: <StormAnimation />,
    thunderstorm: <StormAnimation />,
    tornado: <StormAnimation />,
  };
  return weatherIconMap[condition.toLowerCase()];
}

interface Props {
  sunToSolarPanelLineRef: React.RefObject<SVGPathElement>; // Ref to the sun ray line between the sun and solar panel
}

export function WeatherAnimation({ sunToSolarPanelLineRef }: Props) {
  const { data: weather } = useGetWeatherQuery();
  const { timezone } = useSelector(selectSite);
  const currentTime = toZonedTime(new Date(), timezone);
  const { data: sunTimes } = useGetSunriseSunsetQuery(currentTime);

  const isDay = useMemo(() => {
    if (IS_DEMO_LOGIN) return true;
    return !!sunTimes && currentTime < new Date(sunTimes.sunset) && currentTime > new Date(sunTimes.sunrise);
  }, [sunTimes, currentTime]);

  const WeatherIcon = useMemo(() => {
    if (weather) {
      // Use weather data to check if we can show a special weather animation
      const weatherAnimation = getWeatherAnimation(weather.condition);
      if (weatherAnimation) return weatherAnimation;
    }

    // Otherwise use sunrise/sunset data to show the sun or moon
    if (isDay) {
      return <SunAnimation sunToSolarPanelLineRef={sunToSolarPanelLineRef} />;
    }
    return <MoonAnimation />;
  }, [isDay, weather, sunToSolarPanelLineRef]);

  return (
    <svg x="183" data-testid="weather-container">
      {WeatherIcon}
    </svg>
  );
}
