import React from 'react';
import { Flex, Link } from '@chakra-ui/react';

import { ArcButton } from '../../common/components/ArcButton';

export const CallSupportButton = () => {
  return (
    <Flex w="100%">
      <ArcButton arcColor="#FF5B44" w={'80%'} mx="auto" mt={6} minW={300}>
        <Link data-testid={'notification-support-phone'} isExternal href={'tel:+18777346631'}>
          Call: 877-SE HOME 1 (877-734-6631)
        </Link>
      </ArcButton>
    </Flex>
  );
};
