import React, { useCallback, useMemo, useState } from 'react';
import { Center, Heading, Image, Text } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Device } from 'clipsal-cortex-types/src/api';
import { useOnMount } from 'clipsal-cortex-utils/src/hooks/use-on-mount';

import { useReduxDispatch } from '../../../../app/store';
import semCommissioningSuccessful from '../../../../assets/images/sem_commissioning_successful.svg';
import semCommissioningUnsuccessful from '../../../../assets/images/sem_commissioning_unsuccessful.svg';
import { post } from '../../../../common/api/api-helpers';
import { ArcButton } from '../../../../common/components/ArcButton';
import { SlidingAnimationPageBase } from '../../../../common/components/SlidingAnimationPageBase';
import { TopNavProgressLoader } from '../../../../common/components/TopNavProgressLoader';
import { addSiteDevice, selectSite } from '../../../site/siteSlice';

type State = {
  registrationResults: 'IN_PROGRESS' | 'SUCCESS' | 'REGISTRATION_FAILED' | 'ERROR_MISSING_SERIAL';
};

const INITIAL_STATE: State = {
  registrationResults: 'IN_PROGRESS',
};

/* istanbul ignore next -- @preserve */
export function MeterRegistration() {
  const { site_id: siteId, devices } = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const [{ registrationResults }, setState] = useState(INITIAL_STATE);
  const dispatch = useReduxDispatch();
  const serialNumber = search.get('serialNumber');
  const { t } = useTranslation();
  const urlSearchParamsToGoBack = useMemo(() => {
    search.set('direction', 'back');
    return search.toString();
  }, []);

  const registerDeviceToSite = useCallback(async () => {
    try {
      if (!serialNumber) {
        setState((p) => ({ ...p, registrationResults: 'ERROR_MISSING_SERIAL' }));
        Sentry.captureException({ message: 'Missing serial number for registration' });
        return;
      }
      const commissionedDevice = await post<Device>(`/v1/sites/${siteId}/register_sense_device`, {
        monitor_serial: serialNumber,
      });
      // Add directly to the store to avoid having to re-fetch the site
      if (!devices.find((d) => d.id === commissionedDevice.id)) {
        dispatch(addSiteDevice(commissionedDevice));
      }
      navigate(`../finish?${search.toString() || ''}`);
    } catch (e) {
      Sentry.captureException(e);
      setState((p) => ({ ...p, registrationResults: 'REGISTRATION_FAILED' }));
    }
  }, [serialNumber]);

  useOnMount(registerDeviceToSite);

  let content;

  if (registrationResults === 'IN_PROGRESS') {
    content = (
      <>
        <TopNavProgressLoader />
        <Image mb={3} w={'70%'} src={semCommissioningSuccessful} alt="SEM configuration complete" />
        <Heading mt={3} size="md">
          {t('Set Up Hardware.sem connected to internet')}
        </Heading>
        <Text mt={2}>{t('Set Up Hardware.give us a minute')}</Text>
      </>
    );
  }

  if (registrationResults === 'REGISTRATION_FAILED') {
    content = (
      <>
        <Image mb={3} w={'70%'} src={semCommissioningUnsuccessful} alt="Monitor registraion failed" />
        <Heading size="md">{t('Set Up Hardware.error registering sem')}</Heading>
        <Text my={2}>{t('Set Up Hardware.please ensure the network has internet')}</Text>
        <Text>{t('Common.if this persists contact support')}</Text>
        <ArcButton onClick={registerDeviceToSite} mt={8} w={'80%'} arcColor="#3DCD57" data-testid="try-again-button">
          {t('Common.try again')}
        </ArcButton>
      </>
    );
  }

  if (registrationResults === 'ERROR_MISSING_SERIAL') {
    content = (
      <>
        <Image mb={3} w={'70%'} src={semCommissioningUnsuccessful} alt="Monitor registraion failed" />
        <Heading size="md">{t('Set Up Hardware.error registering sem')}</Heading>
        <Text>{t('Common.if this persists contact support')}</Text>
        <ArcButton
          onClick={() => navigate(`../power_up_instructions?${urlSearchParamsToGoBack}`)}
          mt={8}
          w={'80%'}
          arcColor="#3DCD57"
          data-testid="try-again-button"
        >
          {t('Common.try again')}
        </ArcButton>
      </>
    );
  }

  return (
    <SlidingAnimationPageBase
      title={t('Common.device setup')}
      backURL={`../power_up_instructions?${urlSearchParamsToGoBack}`}
    >
      <Center flexDirection="column" px={4} mt={6} textAlign="center">
        {content}
      </Center>
    </SlidingAnimationPageBase>
  );
}
