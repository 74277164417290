import React from 'react';
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';

type Option = {
  label: string;
  value: string;
};

type Props = {
  options: Option[];
  title: string;
  closeText?: string;
  onSelectOption: (value: string) => void;
} & DialogProps;

export function OptionSelectAlertDrawer({ options, onClose, isOpen, title, onSelectOption, closeText }: Props) {
  const bgColor = useColorModeValue('#fff', '#293133');
  const titleColor = useColorModeValue('customGrey.700', 'customGrey.500');
  const buttonTextColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');

  const { t } = useTranslation();
  if (!closeText) {
    closeText = t('Common.cancel');
  }
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent data-testid="option-select-drawer" bg={'transparent'}>
        <DrawerBody px={1}>
          <Box borderRadius={'15px'} bg={bgColor}>
            <Center borderBottom={'1px solid'} borderColor={'customGrey.500'} py={3}>
              <Text textAlign={'center'} color={titleColor} fontSize={12}>
                {title}
              </Text>
            </Center>
            {options.map((option, i) => (
              <Center
                as={'button'}
                w={'100%'}
                onClick={() => onSelectOption(option.value)}
                key={i}
                borderBottom={i !== options.length - 1 ? '1px solid' : undefined}
                borderColor={'customGrey.500'}
                py={3}
                data-testid={`option-select-item-${i}`}
              >
                <Text>{option.label}</Text>
              </Center>
            ))}
          </Box>

          <Center
            data-testid="close-option-select-drawer"
            as={'button'}
            w={'100%'}
            onClick={onClose}
            py={3}
            mt={3}
            borderRadius={'15px'}
            bg={bgColor}
          >
            <Text color={buttonTextColor}>{closeText}</Text>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
