import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Device, GenericLiveBatteryData, InverterStatus, SaturnLiveDataSummary } from 'clipsal-cortex-types/src/api';

import { MANUFACTURER_ID_SATURN } from '../devices/devices-helper';
import { useGetBatteryLiveDataQuery, useGetInverterLiveDataQuery } from '../site/live-data/liveDataApi';
import { selectBatteries, selectInverters, selectSite } from '../site/siteSlice';
import { GENERIC_BATTERIES_MANUFACTURER_IDS } from './constants';

export type BatteryStatus = 'CHARGING' | 'DISCHARGING' | 'IDLE' | 'EMPTY' | 'ERROR';

interface BatteryExtended {
  battery?: number;
  status: BatteryStatus;
  manufacturerId: number;
}

export type BatteryLiveData = (GenericLiveBatteryData | SaturnLiveDataSummary | undefined) & BatteryExtended;

// 0.1 kW above or below 0 is usually just noise
const BATTERY_THRESHOLD_KW = 0.1;

/**
 * A hacky solution to combining live battery data from two sources.
 * If a site has a battery with their Saturn inverter, we call the Saturn API via polling.
 * If a site has a generic battery (ie. Tesla Powerwall), we call the generic battery API via polling
 * If a site has both, we prioritize Saturn battery data over generic battery data).
 *
 * Ideally we're going to consolidate the Saturn battery API data into the generic battery API data.
 *
 * @returns A query result-like object with the combined data and other metadata about the response/s.
 */
export function useLiveBatteryData() {
  const { site_id: siteId } = useSelector(selectSite);
  const siteInverters = useSelector(selectInverters);
  const siteBatteries = useSelector(selectBatteries);
  const [isSaturnBatteryOffline, setIsSaturnBatteryOffline] = useState(false);

  const genericBattery = findDeviceByManufacturer(siteBatteries, GENERIC_BATTERIES_MANUFACTURER_IDS);
  const saturnInverter = findDeviceByManufacturer(siteInverters, [MANUFACTURER_ID_SATURN]);

  const genericBatteryQuery = useBatteryQuery(genericBattery?.id || 0, useGetBatteryLiveDataQuery, !genericBattery?.id);
  const saturnBatteryQuery = useBatteryQuery(siteId, useGetInverterLiveDataQuery, !saturnInverter?.id);

  // Track the last known status to persist 'ERROR' status when refetching
  const lastStatusRef = useRef<BatteryStatus>('IDLE');
  const baseData: BatteryLiveData = {
    status: lastStatusRef.current,
    manufacturerId: 0,
  };

  const liveBatteryData = {
    ...baseData,
    ...(genericBatteryQuery.data ?? {}),
    ...(saturnBatteryQuery.data ?? {}),
  };

  // Persist 'ERROR' state if the query is refetching
  if (genericBatteryQuery.isError || saturnBatteryQuery.isError) {
    lastStatusRef.current = 'ERROR';
  } else if (liveBatteryData.status !== 'ERROR') {
    lastStatusRef.current = liveBatteryData.status; // Update last known status only if not an error
  }

  useEffect(() => {
    const hasStatusProperty = (error: any): error is { status: number } => {
      return error && typeof error.status === 'number';
    };
    const hasCodeProperty = (error: any): error is { code: string } => {
      return error && typeof error.code === 'string';
    };
    if (saturnBatteryQuery.isError && saturnBatteryQuery.error) {
      if (
        (hasStatusProperty(saturnBatteryQuery.error) && saturnBatteryQuery.error.status === 500) ||
        (hasCodeProperty(saturnBatteryQuery.error) && saturnBatteryQuery.error.code === '500')
      ) {
        setIsSaturnBatteryOffline(true);
      } else {
        setIsSaturnBatteryOffline(false);
      }
    }
  }, [saturnBatteryQuery.isError, saturnBatteryQuery.error]);

  return {
    ...genericBatteryQuery,
    ...saturnBatteryQuery,
    data: liveBatteryData,
    isSaturnBatteryOffline,
  };
}

function useBatteryQuery<T extends { data?: GenericLiveBatteryData | SaturnLiveDataSummary }>(
  id: number,
  queryHook: (id: number, options: { pollingInterval: number; skip: boolean }) => T,
  skip: boolean
) {
  const queryResult = queryHook(id, { pollingInterval: 5000, skip });
  return {
    ...queryResult,
    data: formatBatteryQueryData(queryResult),
  };
}

function formatBatteryQueryData<T extends GenericLiveBatteryData | SaturnLiveDataSummary>(query: {
  data?: T;
}): Omit<BatteryExtended, 'manufacturerId'> | undefined {
  if (!query.data) return;
  const { battery_soc_fraction: batterySocFraction } = query.data;
  const batteryW = 'battery_w' in query.data ? query.data.battery_w : undefined;
  const batteryKw = 'battery' in query.data ? query.data.battery : undefined;

  let battery = batteryW != null ? batteryW / 1000 : batteryKw ?? 0;
  if (Math.abs(battery) < BATTERY_THRESHOLD_KW) battery = 0;

  let status: BatteryStatus =
    battery < 0 ? 'DISCHARGING' : battery > 0 ? 'CHARGING' : batterySocFraction ? 'IDLE' : 'EMPTY';

  // For Saturn batteries specifically - we need to check the status based on the inverter
  if ('inverter_status' in query.data) {
    status =
      query.data.inverter_status !== InverterStatus.NORMAL && query.data.inverter_status !== InverterStatus.UNDEFINED
        ? 'ERROR'
        : status;
  }

  return { ...query.data, battery, status };
}

function findDeviceByManufacturer(devices: Device[], manufacturerIds: number[]) {
  return devices.find(({ manufacturer_id: manufacturerId }) => manufacturerIds.includes(manufacturerId));
}
