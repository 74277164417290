import React from 'react';
import { Box, Flex, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { orderBy } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { CustomSelect, SelectOption } from '../../common/components/CustomSelect';
import { CIRCUIT_TYPE_TO_UI_CONFIG } from './devices-helper';

export type EditDeviceFormData = {
  userAssignment: SelectOption;
  displayName: string;
};

type Props = {
  onSubmit: (formValues: EditDeviceFormData) => void;
  onUserAssignmentChange: (userAssignment: string | null) => void;
  userAssignment: string | null;
  displayName: string;
  formId: string;
};

// This form is used to update the display name and user assignment of a device/switch
export function EditDeviceForm({ displayName, userAssignment, onSubmit, onUserAssignmentChange, formId }: Props) {
  const { t } = useTranslation();
  const editDeviceSchema = yup.object().shape({
    displayName: yup.string().trim().max(45).label(t('Common.name')).required(),
  });
  const USER_ASSIGNMENT_OPTIONS = orderBy(
    Object.keys(CIRCUIT_TYPE_TO_UI_CONFIG).map((option) => ({
      value: option,
      label: t(`Devices.types.${CIRCUIT_TYPE_TO_UI_CONFIG[option].displayName}`),
      icon: CIRCUIT_TYPE_TO_UI_CONFIG[option].icon,
    })),
    [(option) => option.label.toLowerCase()],
    ['asc']
  );
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditDeviceFormData>({
    resolver: yupResolver(editDeviceSchema),
    defaultValues: {
      displayName,
      userAssignment: userAssignment
        ? {
            label: t(`Devices.types.${CIRCUIT_TYPE_TO_UI_CONFIG[userAssignment].displayName}`),
            value: userAssignment,
            icon: CIRCUIT_TYPE_TO_UI_CONFIG[userAssignment].icon,
          }
        : undefined,
    },
  });

  return (
    <Box as="form" id={formId} onSubmit={handleSubmit(onSubmit)} data-testid="edit-switch-form">
      <Flex flexDirection="column">
        <Controller
          control={control}
          name="userAssignment"
          render={({ field: { onChange, value, ref } }) => (
            <FormControl mb={4} data-testid="edit-user-assignment-input">
              <CustomSelect
                value={value}
                onChange={(e) => {
                  onUserAssignmentChange(e?.value ?? null);
                  onChange(e);
                }}
                options={USER_ASSIGNMENT_OPTIONS}
                ref={ref}
                placeholder={`${t('Devices.select device')}...`}
                isSearchable={false}
              />
            </FormControl>
          )}
        />
        <FormControl isInvalid={!!errors?.displayName}>
          <Input
            data-testid="edit-device-name-input"
            placeholder={`${t('Devices.name your device')}...`}
            {...register(`displayName`)}
            borderRadius={2}
            background="#F1F1F1"
            _dark={{ background: 'customGrey.800' }}
            height="50px"
            border="1px solid #9FA0A4"
          />
          <FormErrorMessage>{errors?.displayName?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
    </Box>
  );
}
