import React, { useMemo } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Flex,
  Skeleton,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Device } from 'clipsal-cortex-types/src/api/api-site';
import { TESLA_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { createCirclePercentageFilledIcon } from '../../../styles/create-circle-percentage-filled-icon';
import { selectInverters, selectSite } from '../../site/siteSlice';
import { useGetFirmwareVersion } from '../accountApi';
import { HARDWARE_TYPE_TO_UI_CONFIG } from './constants';
import { VisibleHardwareType } from './types';

export function HardwareDetails() {
  const { devices } = useSelector(selectSite);
  const { deviceId } = useParams<{ deviceId: string }>();
  const device = devices.find((device) => device.id === Number(deviceId));
  const hasFirmware =
    useSelector(selectInverters).length > 0 && ['METER', 'INVERTER'].includes(device?.device_type ?? '');
  const { data, isLoading } = useGetFirmwareVersion(Number(deviceId), !hasFirmware);

  const { t } = useTranslation();
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');

  const CircleIcon = createCirclePercentageFilledIcon(100);
  const { icon: DeviceIcon, title } =
    HARDWARE_TYPE_TO_UI_CONFIG[(device?.device_type as VisibleHardwareType) ?? 'INVERTER'];

  return (
    <SlidingAnimationPageBase title={t('Hardware.hardware')} backURL={`../../list?direction=back`} pb={6}>
      {device ? (
        <Box px={5}>
          <Center position="relative" px={3} flexDirection="column" textAlign="center" mt={8}>
            <DeviceIcon
              w={[100, 130, 150]}
              h={[100, 130, 150]}
              color={iconColor}
              pos="absolute"
              data-testid="status-icon"
            />
            <CircleIcon
              w={[220, 290, 330]}
              h={[220, 290, 330]}
              color={iconColor}
              data-testid="circle-icon"
              zIndex={3}
            />
          </Center>

          <Text textAlign={'center'} fontSize={24} data-testid="device-name" mt={4}>
            {title}
          </Text>

          <Flex direction={'column'} w="100%" data-testid="device-details" mt={8}>
            <Flex direction={'column'}>
              {(device.serial_number ?? device.site_identifier) && (
                <Flex direction={'column'} data-testid="device-sn">
                  <Text fontWeight={'semibold'}>{t('Common.serial number')}</Text>
                  <Text>{device.serial_number ?? device.site_identifier}</Text>
                </Flex>
              )}
              <Flex direction={'column'} mt={6} data-testid="device-model">
                <Text fontWeight={'semibold'}>{t('Common.model name')}</Text>
                <Text>{device.model}</Text>
              </Flex>
              <Flex direction={'column'} mt={6} data-testid="device-manufacturer">
                <Text fontWeight={'semibold'}>{t('Hardware.manufacturer')}</Text>
                <Text>{device.manufacturer_name ?? 'Schneider Electric'}</Text>
              </Flex>
              {/* Firmware version is only supported by Saturn inverters currently. Meter firmware version can only
                  be retrieved when the meter is connected to an inverter (not standalone) */}
              {hasFirmware && (
                <Flex direction={'column'} mt={6} data-testid="device-firmware">
                  <Text fontWeight={'semibold'}>{t('Common.firmware')}</Text>
                  <Skeleton isLoaded={!isLoading}>
                    <Text>{data?.firmware_version ?? t('Common.not found')}</Text>
                  </Skeleton>
                </Flex>
              )}
            </Flex>

            <ButtonConfiguration device={device} />
          </Flex>
        </Box>
      ) : (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{t('Hardware.error fetching device')}</AlertTitle>
          <AlertDescription>
            {t('Common.please try again')} {t('Common.if this persists contact support')}
          </AlertDescription>
        </Alert>
      )}
    </SlidingAnimationPageBase>
  );
}

function ButtonConfiguration({ device }: { device: Device }) {
  const { id, device_type: deviceType, manufacturer_id: manufacturerId, site_identifier: siteIdentifier } = device;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const hidden = useMemo(
    () =>
      deviceType === 'BACKUP_GRID_CONTROL_SYSTEM' ||
      (deviceType === 'BATTERY_PACK' && manufacturerId !== TESLA_MANUFACTURER_ID) ||
      IS_DEMO_LOGIN,
    [deviceType]
  );

  return (
    <Center
      hidden={hidden}
      justifyContent={'space-between'}
      role={'button'}
      mt={6}
      py={6}
      px={4}
      mx={-5}
      bg={'#E7E6E6'}
      _dark={{
        bg: '#363E40',
      }}
      data-testid="wifi-config-button"
      onClick={() => {
        const routeEnd = `/power_up_instructions?shouldRegisterDevice=false&deviceId=${id}&direction=forward`;
        if (deviceType === 'EV_CHARGER') {
          navigate(`../../../../ev_charger/wifi/home`);
        } else if (deviceType === 'INVERTER') {
          navigate(`../../configure/inverter${routeEnd}`);
        } else if (deviceType === 'METER') {
          // Providing the SEM serial number here means the user can skip the connect instructions screen so the user -
          // - doesnt need to enter the serial number (we already have the serial number via the site identifier)
          navigate(`../../configure/meter${routeEnd}&serialNumber=${siteIdentifier}`);
        } else if (deviceType === 'BATTERY_PACK') {
          navigate(`../../configure/tesla`);
        } else {
          toast({
            title: t('Hardware.wifi config unavailable'),
            description: t('Hardware.this feature is currently'),
            status: 'info',
            isClosable: true,
            duration: 5000,
          });
        }
      }}
    >
      <Text>{t('Hardware.config')}</Text>
      <ChevronRightIcon color={'customGrey.400'} h={6} w={6} />
    </Center>
  );
}
