import React, { useMemo, useState } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, SimpleGrid, Skeleton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ApplianceBase, EnergyUsageV2, PowerUsageV2 } from 'clipsal-cortex-types/src/api/api-usage-v2';

import { DataSummaryCard } from '../../common/components/DataSummaryCard';
import { DateRangeType, DateRangeTypePicker } from '../../common/components/DateRangeTypePicker';
import { useGetUsageQuery } from '../activity/activityApi';
import { ActivityChart } from '../activity/common/ActivityChart';
import { useGetCostsQuery } from '../site/costApi';
import { selectCurrentDayForSite } from '../site/siteSlice';

function getInitialSelectedDates(currentDay: Date) {
  return {
    [DateRangeType.Day]: new Date(currentDay),
    [DateRangeType.Week]: new Date(currentDay),
    [DateRangeType.Month]: new Date(currentDay),
    [DateRangeType.Year]: new Date(currentDay),
  };
}

function checkIfDeviceHasDataInPeriod(applianceId: number, dataPoint?: EnergyUsageV2 | PowerUsageV2) {
  if (!dataPoint?.appliances) return false;
  return !!(dataPoint.appliances as ApplianceBase[]).find((a) => a.id === applianceId);
}

type Props = {
  applianceId: number;
  power: number;
  shouldShowCurrentPowerInKw?: boolean;
};

type State = {
  selectedDateRangeType: DateRangeType;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
};

// This component displays the activity chart and data summary cards for either an Appliance OR a Switch
// This may need to be abstracted depending on how the Switch usage & costs data diverge from current Appliance data
// But for now we can use this one component for both, since we get the data via the appliance_id for both device types.
export function DeviceActivity({ applianceId, power, shouldShowCurrentPowerInKw = true }: Readonly<Props>) {
  const currentDay = useSelector(selectCurrentDayForSite);
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    selectedDateRangeType: DateRangeType.Day,
    rangeTypeToSelectedPeriod: getInitialSelectedDates(currentDay),
  });

  const { selectedDateRangeType, rangeTypeToSelectedPeriod } = state;
  const {
    data,
    isLoading: isUsageLoading,
    isFetching: isUsageFetching,
  } = useGetUsageQuery(rangeTypeToSelectedPeriod[selectedDateRangeType], selectedDateRangeType);
  const { energyUsageData, powerUsageData } = data;

  const {
    data: costsData,
    isLoading: isCostsLoading,
    isFetching: isCostsFetching,
  } = useGetCostsQuery(rangeTypeToSelectedPeriod[selectedDateRangeType], selectedDateRangeType);
  const isApiDataLoaded = !isCostsLoading && !isUsageLoading;

  const costForDevice = useMemo(() => {
    return costsData?.devices.find((costDataDevice) => costDataDevice.appliance_id === applianceId)?.cost;
  }, [costsData, applianceId]);

  const deviceHasDataInPeriod = useMemo(() => {
    return (
      applianceId &&
      checkIfDeviceHasDataInPeriod(
        applianceId,
        selectedDateRangeType === DateRangeType.Day ? powerUsageData[0] : energyUsageData[0]
      )
    );
  }, [applianceId, data, selectedDateRangeType]);

  function getPeriodCopy() {
    const dateRangeToPeriod = {
      [DateRangeType.Day]: t('Activity.today'),
      [DateRangeType.Week]: t('Activity.this week'),
      [DateRangeType.Month]: t('Activity.this month'),
      [DateRangeType.Year]: t('Activity.this year'),
    };

    return dateRangeToPeriod[selectedDateRangeType];
  }

  function getDisplayedCostPrefix() {
    if (costForDevice && costForDevice < 0) return '-$';
    return '$';
  }

  const DATE_RANGE_OPTIONS = [
    {
      label: t('Common.day')[0],
      value: DateRangeType.Day,
    },
    {
      label: t('Common.week')[0],
      value: DateRangeType.Week,
    },
    {
      label: t('Common.month')[0],
      value: DateRangeType.Month,
    },
    {
      label: t('Common.year')[0],
      value: DateRangeType.Year,
    },
  ];

  return (
    <Box>
      <Skeleton isLoaded={isApiDataLoaded}>
        <DateRangeTypePicker
          dateRangeOptions={DATE_RANGE_OPTIONS}
          selectedDateRangeType={selectedDateRangeType}
          setSelectedDateRangeType={(selectedDateRangeType) => {
            if (isApiDataLoaded) {
              setState((prevState) => ({ ...prevState, selectedDateRangeType }));
            }
          }}
        />
      </Skeleton>

      <SimpleGrid gridGap={5} my={5} minChildWidth="45%">
        {!!costForDevice && (
          <DataSummaryCard
            title={`${t('Common.cost')} ${getPeriodCopy()}`}
            isLoaded={isApiDataLoaded && !isCostsFetching}
            value={Intl.NumberFormat('en', { maximumFractionDigits: 2 }).format(Math.abs(costForDevice))}
            prefix={getDisplayedCostPrefix()}
            data-testid="device-cost-card"
          />
        )}

        <DataSummaryCard
          title={t('Common.current power')}
          isLoaded={isApiDataLoaded}
          value={Intl.NumberFormat('en', { maximumFractionDigits: shouldShowCurrentPowerInKw ? 2 : 0 }).format(
            (shouldShowCurrentPowerInKw ? power : power * 1000) || 0
          )}
          suffix={shouldShowCurrentPowerInKw ? 'kW' : 'W'}
          data-testid="device-current-power-card"
        />
      </SimpleGrid>

      <Skeleton isLoaded={!isUsageFetching}>
        {deviceHasDataInPeriod ? (
          <ActivityChart
            isLoaded={isApiDataLoaded}
            powerUsageData={powerUsageData}
            energyUsageData={energyUsageData}
            selectedDateRangeType={selectedDateRangeType}
            rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
            isSelectedDeviceDisplayedInChart={true}
            selectedDeviceId={applianceId}
            chartOpts={{
              height: 200,
              applianceArea: {
                color: '#27AE60',
                fillGradientStart: 'rgba(39, 174, 96, 0.3)',
                fillGradientStop: 'rgba(39, 174, 96, 0)',
              },
            }}
          />
        ) : (
          <Alert data-testid="no-data-alert" status="info" variant="left-accent">
            <AlertIcon />
            <Box>
              <AlertTitle>{t('Common.notice')}</AlertTitle>
              <AlertDescription>{t('Devices.this device has no data')}</AlertDescription>
            </Box>
          </Alert>
        )}
      </Skeleton>
    </Box>
  );
}
