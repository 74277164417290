import React, { PropsWithChildren } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Center, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { DragHandleIcon } from '../../../styles/custom-icons';
import { selectSite } from '../../site/siteSlice';
import { HomepageWidget } from './default-home-helpers';
import { DraggableSEHomeCard } from './DraggableSEHomeCard';

export type HomePageWidgetTileProps = PropsWithChildren<{
  item: HomepageWidget;
  index: number;
  isEditing: boolean;
  onRemoveGridItem: (id: number) => void;
}>;

export function HomePageWidgetTile({ item, index, isEditing, onRemoveGridItem, children }: HomePageWidgetTileProps) {
  const color = useColorModeValue('customGrey.900', 'customGrey.100');
  const { site_id: siteId } = useSelector(selectSite);
  const linkProps = isEditing
    ? undefined
    : {
        as: Link,
        to: `/sites/${siteId}${item.link}?direction=forward&backURL=/sites/${siteId}/home?direction=back`,
      };

  return (
    <DraggableSEHomeCard
      className="draggable-se-home-card"
      data-testid={`${item.type.toLowerCase().replace('_', '-')}-widget`}
      data-testindex={index}
      key={item.id}
      dragId={item.id}
      p={0}
      color={color}
      style={{
        touchAction: isEditing ? 'none' : undefined, // Note: not supported by chakra style props
      }}
      {...linkProps}
    >
      <SimpleGrid columns={3} h={5}>
        {isEditing && (
          <>
            <Center data-testid="drag-handle" mt={1} gridColumn={2}>
              <DragHandleIcon w={5} h={3} />
            </Center>{' '}
            <Box mt={1} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} pr={1} gridColumn={3}>
              <CloseIcon
                data-testid={`${item.type.toLowerCase().replace('_', '-')}-widget-remove-btn`}
                mr={1}
                as={'button'}
                onClick={() => onRemoveGridItem(item.id)}
                w={3}
                h={3}
              />
            </Box>
          </>
        )}
      </SimpleGrid>

      {children}
    </DraggableSEHomeCard>
  );
}
