import React, { PropsWithChildren, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { useNetworkStatus } from 'clipsal-cortex-ui/src/components/NetworkStatusProvider';

import { selectUser } from '../../../features/user/userSlice';

export const RouteAuthCheck = ({ children }: PropsWithChildren) => {
  const { isLoggedIn } = useSelector(selectUser);
  const { isOnline } = useNetworkStatus();
  const { t } = useTranslation();
  const toast = useToast({ isClosable: true });

  useEffect(() => {
    if (!isOnline) {
      if (!toast.isActive('offline')) {
        toast({
          id: 'offline',
          status: 'warning',
          title: t('Offline.title'),
          description: t('Offline.desc'),
          duration: null,
        });
      }
    } else {
      toast.closeAll();
    }
  }, [isOnline]);

  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" state={{ originalPath: `${window.location.pathname}${window.location.search}` }} replace />
  );
};
