import React, { Fragment } from 'react';
import { Box, Center, Grid, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { formatSecondsToHMM } from 'clipsal-cortex-utils/src/formatting/number-formatting';

import { createBatteryReserveIcon } from '../../../styles/create-battery-reserve-icon';
import { useLiveData } from '../../site/live-data/liveDataApi';

// Anything under 20% is considered low @TODO: check with product
const LOW_BATTERY_THRESHOLD_PERCENTAGE = 20;

interface Props extends React.PropsWithChildren {
  readonly message: string;
}

export function GridOutage({ children, message }: Props) {
  const textColor = useColorModeValue('customGrey.900', 'customGrey.400');
  const { data } = useLiveData();
  const { battery_duration_sec: batterySecondsRemaining, battery_soc_fraction: soc } = data;
  const stateOfChargePercentage = (soc ?? 0) * 100;
  const hasLowBattery = stateOfChargePercentage <= LOW_BATTERY_THRESHOLD_PERCENTAGE;
  const borderColor = useColorModeValue('black', 'white');
  const Icon = createBatteryReserveIcon(stateOfChargePercentage, hasLowBattery ? '#BA3430' : '#3DCD58', borderColor);
  const { t } = useTranslation();

  function getTitle() {
    if (hasLowBattery) return t('Widgets.Grid Outage.low battery warning').toUpperCase();
    return t('Widgets.Grid Outage.battery status').toUpperCase();
  }

  return (
    <Fragment>
      <Box mb={2}>
        <Heading size={'lg'}>{t('Widgets.Grid Outage.heading')}</Heading>
        <Text color={textColor}>{message}</Text>
      </Box>
      <Box
        borderBottomLeftRadius={'5px'}
        borderBottomRightRadius={'25px'}
        borderTopRightRadius={'5px'}
        borderTopLeftRadius={'25px'}
        p={4}
        border={'2px solid'}
        bg={'transparent'}
        borderColor={hasLowBattery ? '#BA3430' : '#3DCD58'}
        data-testid="grid-outage-widget"
      >
        <Box>
          <Grid templateColumns={'0.25fr 0.75fr'}>
            <Center>
              <Icon w={'50px'} h={'50px'} color="#42B4E6" />
              <Box
                pos={'absolute'}
                w={'75px'}
                h={'75px'}
                border={'3px solid'}
                borderColor={borderColor}
                borderRadius={100}
              />
            </Center>
            <Box ml={3}>
              <Text data-testid="grid-outage-title" fontSize={16}>
                {getTitle()}
              </Text>
              <Grid templateColumns="0.5fr 0.5fr">
                <Box data-testid="grid-outage-battery-time-remaining" lineHeight={1} textAlign={'start'} fontSize={50}>
                  {formatSecondsToHMM(batterySecondsRemaining ?? 0)}
                </Box>
                <Box ml={3} fontSize={16}>
                  <Text>{t('Widgets.Grid Outage.time').toUpperCase()}</Text>
                  <Text>{t('Widgets.Grid Outage.remaining').toUpperCase()}</Text>
                </Box>
              </Grid>
            </Box>
          </Grid>
          {children}
        </Box>
      </Box>
    </Fragment>
  );
}
