import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Device, SiteDetailData } from 'clipsal-cortex-types/src/api/api-site';
import { getCurrentDayInTimezone } from 'clipsal-cortex-utils/src/calculations/date-utils';

import { baseApi } from '../../app/services/baseApi';
import { RootState } from '../../app/store';
import { get } from '../../common/api/api-helpers';

type SiteState = Omit<SiteDetailData, 'has_valid_subscription' | 'integrations'> & {
  isOnboarding: boolean;
};

// @NOTE: isProduction might be used later for analytics inside this thunk
export const fetchSite = createAsyncThunk<
  Omit<SiteState, 'isOnboarding'>,
  { siteID: number; isProduction: boolean },
  { state: RootState }
>('site/fetchSite', async ({ siteID }, { dispatch }) => {
  dispatch(baseApi.util.invalidateTags(['MeterLiveData'])); // Invalidates any site live data WS connections
  return await get<SiteState>(`/v1/sites/${siteID}`);
});

const initialState: SiteState = {
  site_name: '',
  lat: 0, // Signed float
  lon: 0, // Signed float
  city: '',
  post_code: '',
  state: '',
  country: '',
  address: '',
  timezone: '',
  distributor_id: 0,
  tenant_id: 0,
  is_consumption_site: false,
  monitoring_start: '',
  nmi: '',
  site_id: 0,
  devices: [],
  has_controlled_load: false,
  has_ac_battery: false,
  has_dc_battery: false,
  commissioning_status: 'PENDING',
  isOnboarding: false,
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setCurrentSite: (_state, action: PayloadAction<SiteState>) => {
      return action.payload;
    },
    clearCurrentSite: () => {
      return initialState;
    },
    addSiteDevice: (state, action: PayloadAction<Device>) => {
      state.devices = [...state.devices, action.payload];
    },
    removeSiteDevice: (state, action: PayloadAction<number>) => {
      state.devices = state.devices.filter((d) => d.id !== action.payload);
    },
    addEvCharger: (state, action: PayloadAction<Device>) => {
      return { ...state, devices: [...(state?.devices || []), action.payload] };
    },
    removeEvCharger: (state, action: PayloadAction<string>) => {
      return { ...state, devices: state?.devices?.filter((device) => device.site_identifier !== action.payload) || [] };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSite.fulfilled, (state, action) => {
      return { isOnboarding: state.isOnboarding, ...action.payload };
    });
  },
});

export const { setCurrentSite, clearCurrentSite, addEvCharger, removeEvCharger, addSiteDevice, removeSiteDevice } =
  siteSlice.actions;

export const selectSite = (state: RootState) => state.site;

export const selectDevices = (state: RootState) => {
  return state.site.devices;
};

export const selectEvChargers = createSelector([(state: RootState) => state.site.devices], (devices) => {
  return devices.filter((device) => device.device_type === 'EV_CHARGER');
});

export const selectMeters = createSelector([(state: RootState) => state.site.devices], (devices) => {
  return devices.filter((device) => device.device_type === 'METER');
});

export const selectInverters = createSelector([(state: RootState) => state.site.devices], (devices) => {
  return devices.filter((device) => device.device_type === 'INVERTER');
});

export const selectBatteries = createSelector([(state: RootState) => state.site.devices], (devices) => {
  return devices.filter((device) => device.device_type === 'BATTERY_PACK');
});

export const selectCurrentDayForSite = createSelector([(state: RootState) => state.site.timezone], (timezone) => {
  return getCurrentDayInTimezone(timezone);
});

export const { reducer: siteReducer } = siteSlice;
