import React, { useEffect, useMemo } from 'react';
import { Box, Text, useToast, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { ArcButton } from '../../common/components/ArcButton';
import { SlidingAnimationPageBase } from '../../common/components/SlidingAnimationPageBase';
import { COMPONENT_MIN_HEIGHT } from '../../common/constants';
import { useGetSiteConsentByTypeQuery } from './new-user-journey/consentApi';

export function ConsentError() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const siteId = useMemo(() => Number(search.get('siteId')), []);
  const { isFetching, isError, refetch } = useGetSiteConsentByTypeQuery('SHARE_WITH_INSTALLER', siteId);
  const toast = useToast({ isClosable: true, duration: 3000 });
  const { t } = useTranslation();

  useEffect(() => {
    // If for some reason, users land on this page with no siteId, redirect them to the sites page
    if (!siteId) navigate('/sites');
    // If for some reason, users land on this page with no consent error, redirect them to the home page
    else if (!isError && !isFetching) navigate(`/sites/${siteId}/home`);
  }, [siteId, isFetching]);

  const handleClick = async () => {
    try {
      await refetch().unwrap();
      navigate(`/sites/${siteId}/home`);
    } catch (error) {
      toast({
        title: t('Common.error retrieving consent'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  };

  return siteId && isError && !isFetching ? (
    <SlidingAnimationPageBase title={'Consent Error'}>
      <VStack px={4} mt={4}>
        <Text>
          {t('Common.error retrieving consent')} {t('Common.please try again')}{' '}
          {t('Common.if this persists contact support')}
        </Text>
        <ArcButton data-testid={'try-again-button'} onClick={handleClick} mt={8} w={'80%'} arcColor="#3DCD57">
          {t('Common.try again')}
        </ArcButton>
      </VStack>
    </SlidingAnimationPageBase>
  ) : (
    <Box height={COMPONENT_MIN_HEIGHT}>
      <CenteredLoader text={`${t('Common.loading')}...`} />
    </Box>
  );
}
