import React, { useMemo, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Skeleton,
  SkeletonCircle,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { SlidingAnimationPageBase } from '../../common/components/SlidingAnimationPageBase';
import { selectSite } from '../site/siteSlice';
import { DEVICE_ICON_RESPONSIVE_SIZE } from './devices-helper';
import { useGetLiveDevice, useUpdateDeviceMutation } from './devicesApi';
import { DeviceStatusIcon } from './DeviceStatusIcon';
import { EditDeviceForm, EditDeviceFormData } from './EditDeviceForm';

export function EditDevice() {
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const { deviceId: id } = useParams<{ deviceId: string }>();
  const deviceId = Number(id);
  const { data: device, isLoading, isError } = useGetLiveDevice(deviceId);
  const toast = useToast({ isClosable: true });
  const [updateDevice] = useUpdateDeviceMutation();
  const { t } = useTranslation();

  // This is used to 'watch' the user assignment field so we can update the icon
  const [userAssignmentSelected, setUserAssignmentSelected] = useState<string | null>(null);
  const handleUserAssignmentChange = (userAssignment: string | null) => {
    setUserAssignmentSelected(userAssignment);
  };

  const formId = 'edit-device-form'; // Passed to the form to be able to submit with an external button
  const handleFormSubmit = async ({ displayName, userAssignment }: EditDeviceFormData) => {
    const updateDeviceRequestBody = {
      display_name: displayName,
      user_assignment: userAssignment?.value || null,
    };

    try {
      await updateDevice({ siteId, deviceId, body: updateDeviceRequestBody }).unwrap();
    } catch {
      toast({
        status: 'error',
        title: t('Devices.error updating device'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
      });
    }
    navigate(`../view?direction=back`);
  };

  const backURL = useMemo(
    () => (!isError && device ? `../view?direction=back` : `../../list?direction=back`),
    [isError, device]
  );

  return (
    <SlidingAnimationPageBase
      backURL={backURL}
      includeBottomNav
      title={t('Devices.edit device')}
      customTopNavButton={
        <Button
          data-testid="save-button"
          variant={'ghost'}
          size={'sm'}
          hidden={isError}
          type="submit"
          form={formId}
          fontWeight={400}
          colorScheme="schneiderSkyBlue"
        >
          {t('Common.save')}
        </Button>
      }
    >
      <Box data-testid="device-detail-contents" px={3}>
        {isError ? (
          <Alert status="error" variant="left-accent" data-testid="error-alert">
            <AlertIcon />
            <Box>
              <AlertTitle>{t('Common.notice')}</AlertTitle>
              <AlertDescription>
                {t('Devices.error fetching device')}.{' '}
                {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}.
              </AlertDescription>
            </Box>
          </Alert>
        ) : (
          <>
            <SkeletonCircle
              isLoaded={!isLoading}
              w={DEVICE_ICON_RESPONSIVE_SIZE}
              h={DEVICE_ICON_RESPONSIVE_SIZE}
              m="auto"
            >
              {device && (
                <DeviceStatusIcon
                  assignment={userAssignmentSelected || device.user_assignment}
                  isOn={device.power > 0}
                />
              )}
            </SkeletonCircle>
            <Skeleton isLoaded={!isLoading} borderBottomRightRadius="25px" minHeight="137px" mt={2}>
              {device && (
                <EditDeviceForm
                  displayName={device.display_name}
                  userAssignment={userAssignmentSelected || device.user_assignment || null}
                  onUserAssignmentChange={handleUserAssignmentChange}
                  onSubmit={handleFormSubmit}
                  formId={formId}
                />
              )}
            </Skeleton>
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
