import React, { useCallback, useState } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DAY_OF_WEEK, DayType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import { TimePicker } from 'clipsal-cortex-ui/src/components/time-picker/TimePicker';

import { SEHomeCard } from '../../../common/components/SEHomeCard';
import { useTranslatedCommonInputProps } from '../tariff-constants';
import { useTariffFormContext } from '../useTariffFormContext';
import { TOUTariffFormKey, useTranslatedTouRateTypeToConfig } from './tou-tariff-constants';
import { convert12HourTo24HourFormat, convert24HourTo12HourFormat } from './tou-tariff-helpers';

type TimeSelectState = {
  hours: number;
  minutes: number | string; // This is a string when prefixed by a `0`, e.g. any number < 9
  amOrPm: 'AM' | 'PM';
};

type PeakRateCardProps = {
  seasonIndex: number;
  touTariffType: TOUTariffFormKey;
  dayType: DayType;
  rateIndex: number;
};

export const PeakRateCard = ({ seasonIndex, touTariffType, rateIndex, dayType }: PeakRateCardProps) => {
  const { t } = useTranslation();
  const COMMON_INPUT_PROPS = useTranslatedCommonInputProps();
  const TOU_RATE_TYPE_TO_CONFIG = useTranslatedTouRateTypeToConfig();
  const [{ editType, isDrawerOpen, initialTimeState, periodIndex }, setState] = useState<{
    editType: 'start' | 'end';
    isDrawerOpen: boolean;
    initialTimeState: TimeSelectState;
    periodIndex: number;
  }>({
    editType: 'start',
    isDrawerOpen: false,
    initialTimeState: {
      hours: 0,
      minutes: 0,
      amOrPm: 'AM',
    },
    periodIndex: 0,
  });
  const { errors, register, control, setValue } = useTariffFormContext();

  const rates = useWatch({ control, name: `tariff.rates.${seasonIndex}.${dayType}.${touTariffType}` }) || [];
  const periods = rates?.[rateIndex]?.timeOfUse?.periods || [];
  const rate = rates?.[rateIndex];

  const isWeekend = dayType === 'weekend';

  const getFormattedTime = useCallback(
    (periodIndex = 0) => {
      const { fromHour = 0, fromMinute = 0, toHour = 0, toMinute = 0 } = rate?.timeOfUse?.periods?.[periodIndex] || {};
      const { hour: startHours } = convert24HourTo12HourFormat(fromHour);
      const startMinutes = fromMinute < 10 ? `0${fromMinute}` : fromMinute;
      const { hour: endHours } = convert24HourTo12HourFormat(toHour);
      const endMinutes = toMinute < 10 ? `0${toMinute}` : toMinute;
      const startAmOrPm = fromHour >= 12 ? 'PM' : 'AM';
      const endAmOrPm = toHour >= 12 ? 'PM' : 'AM';

      return {
        startTime: `${startHours}:${startMinutes} ${startAmOrPm}`,
        endTime: `${endHours}:${endMinutes} ${endAmOrPm}`,
        startTimeState: { hours: startHours, minutes: startMinutes, amOrPm: startAmOrPm } as TimeSelectState,
        endTimeState: { hours: endHours, minutes: endMinutes, amOrPm: endAmOrPm } as TimeSelectState,
      };
    },
    [rate]
  );

  return (
    <React.Fragment>
      <Center w="100%" justifyContent={'space-between'} mt={4}>
        <Text lineHeight={'19px'}>
          {TOU_RATE_TYPE_TO_CONFIG[touTariffType].title} {rates.length > 1 ? rateIndex + 1 : ''}
        </Text>
        {
          <IconButton
            aria-label={`delete-${touTariffType}-${rateIndex}`}
            variant={'ghost'}
            minH={'fit-content'}
            minW={'fit-content'}
            h={7}
            w={7}
            borderRadius={50}
            ml={4}
            onClick={() => {
              const newRates = rates.filter((_, i) => i !== rateIndex);
              setValue(`tariff.rates.${seasonIndex}.${dayType}.${touTariffType}`, newRates, {
                shouldDirty: true,
              });
            }}
            data-testid={`delete-${touTariffType}-${rateIndex}`}
          >
            <DeleteIcon color="red.500" />
          </IconButton>
        }
      </Center>
      <SEHomeCard borderTopLeftRadius={'5px'} borderBottomRightRadius={'39px'} mt={1} pr={0} pb={4}>
        <Center justifyContent={'space-between'} mr={4}>
          {DAY_OF_WEEK.map((day, index) => {
            const localizedName = t(`Common.${day.toLowerCase()}`).toUpperCase();
            const isSelected = isWeekend ? index > 4 : index < 5;
            const bgColor = isSelected || dayType === 'allWeek' ? '#3DCD58' : 'customGrey.400';
            return (
              <Center borderRadius={50} minW={9} w={9} h={9} bg={bgColor} color="black" fontWeight={500} key={day}>
                {localizedName.charAt(0)}
              </Center>
            );
          })}
        </Center>
        <Divider bg={'#C6C6C6'} my={4} />
        {periods.map((_, periodIndex, periods) => {
          const { startTime, endTime, startTimeState, endTimeState } = getFormattedTime(periodIndex);
          const showPeriodNumber = periods.length > 1;
          return (
            <Box key={`${seasonIndex}-${dayType}-${touTariffType}-${rateIndex}-${periodIndex}-time-select`}>
              <Center justifyContent={'space-between'}>
                <Text>
                  {t('Common.start time')} {showPeriodNumber ? `- ${periodIndex + 1}` : ''}
                </Text>
                <Center
                  w={93}
                  mr={4}
                  color="#fff"
                  bg={'#6F6F76'}
                  _light={{ color: '#000', bg: '#ddd' }}
                  rounded={4}
                  h={34}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isDrawerOpen: true,
                      editType: 'start',
                      initialTimeState: startTimeState,
                      periodIndex,
                    }))
                  }
                  // eslint-disable-next-line max-len
                  data-testid={`season-${seasonIndex}-${dayType}-${touTariffType}-${rateIndex}-${periodIndex}-start-time`}
                >
                  {startTime}
                </Center>
              </Center>
              <Divider bg={'#C6C6C6'} my={4} />
              <Center justifyContent={'space-between'}>
                <Text>
                  {t('Common.end time')} {showPeriodNumber ? `- ${periodIndex + 1}` : ''}
                </Text>
                <Center
                  w={93}
                  mr={4}
                  color="customGrey.100"
                  bg={'#6F6F76'}
                  _light={{ color: 'customGrey.900', bg: '#ddd' }}
                  rounded={4}
                  h={34}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isDrawerOpen: true,
                      editType: 'end',
                      initialTimeState: endTimeState,
                      periodIndex,
                    }))
                  }
                  data-testid={`season-${seasonIndex}-${dayType}-${touTariffType}-${rateIndex}-${periodIndex}-end-time`}
                >
                  {endTime}
                </Center>
              </Center>
              <Divider bg={'#C6C6C6'} my={4} />
            </Box>
          );
        })}

        <Text lineHeight={'19px'}>{t('Energy Rates.peak rates')}</Text>
        <FormControl
          mt={2}
          isInvalid={
            !!errors?.tariff?.rates?.[seasonIndex]?.[dayType]?.[touTariffType]?.[rateIndex]?.rateBands?.[0]?.rate
              ?.message
          }
          data-testid={`season-${seasonIndex}-${dayType}-${touTariffType}-${rateIndex}-rate`}
          pr={4}
        >
          <InputGroup>
            <InputLeftElement pointerEvents="none" color="gray.400" h={12}>
              $
            </InputLeftElement>
            <Input
              {...COMMON_INPUT_PROPS}
              {...register(`tariff.rates.${seasonIndex}.${dayType}.${touTariffType}.${rateIndex}.rateBands.0.rate`, {
                valueAsNumber: true,
              })}
              pr={16}
            />
            <InputRightElement pointerEvents="none" color="gray.400" h={12} w={16}>
              / kWh
            </InputRightElement>
          </InputGroup>

          <FormErrorMessage>
            {
              errors?.tariff?.rates?.[seasonIndex]?.[dayType]?.[touTariffType]?.[rateIndex]?.rateBands?.[0]?.rate
                ?.message
            }
          </FormErrorMessage>
        </FormControl>

        {isDrawerOpen && (
          <TimeSelectDrawer
            initialState={initialTimeState}
            isOpen={isDrawerOpen}
            onClose={() => setState((prevState) => ({ ...prevState, isDrawerOpen: false }))}
            onConfirm={(time) => {
              const editHourType = editType === 'start' ? 'fromHour' : 'toHour';
              setValue(
                // eslint-disable-next-line max-len
                `tariff.rates.${seasonIndex}.${dayType}.${touTariffType}.${rateIndex}.timeOfUse.periods.${periodIndex}.${editHourType}`,
                convert12HourTo24HourFormat(time.hours, time.amOrPm) as never,
                {
                  shouldDirty: true,
                }
              );

              const editMinuteType = editType === 'start' ? 'fromMinute' : 'toMinute';
              setValue(
                // eslint-disable-next-line max-len
                `tariff.rates.${seasonIndex}.${dayType}.${touTariffType}.${rateIndex}.timeOfUse.periods.${periodIndex}.${editMinuteType}`,
                Number(time.minutes) as never,
                {
                  shouldDirty: true,
                }
              );
            }}
          />
        )}
      </SEHomeCard>
    </React.Fragment>
  );
};

type TimeSelectDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (value: TimeSelectState) => void;
  initialState: TimeSelectState;
};

const MINUTES_5_MIN_INTERVAL = Array.from(Array(12)).map((_, i) => {
  const minuteNumber = i * 5;
  return minuteNumber < 10 ? `0${minuteNumber}` : minuteNumber;
});

function TimeSelectDrawer({ onClose, isOpen, initialState, onConfirm }: Readonly<TimeSelectDrawerProps>) {
  const [state, setState] = useState<TimeSelectState>(initialState);
  const { hours, minutes, amOrPm } = state;
  const timePickerGradientColor = useColorModeValue('253, 253, 253', '41, 49, 51');
  const timePickerBorderColor = useColorModeValue('#293133', '#fff');
  const bgColor = useColorModeValue('#fff', '#293133');
  const titleColor = useColorModeValue('customGrey.700', 'customGrey.400');
  const buttonTextColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const { t } = useTranslation();

  function handleSave() {
    onConfirm(state);
    onClose();
  }

  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent data-testid="time-select-drawer" bg={'transparent'}>
        <DrawerBody px={1}>
          <Box borderRadius={'15px'} bg={bgColor}>
            <Center borderBottom={'1px solid'} borderColor={'#C6C6C6'} py={3}>
              <Text textAlign={'center'} color={titleColor} fontSize={'sm'}>
                {t('Common.select time')}
              </Text>
            </Center>

            <Center as={'button'} w={'100%'} borderBottom={'1px solid'} borderColor={'#C6C6C6'} py={3}>
              <TimePicker
                minuteOptions={MINUTES_5_MIN_INTERVAL}
                sliderColumnWidth={'75px'}
                defaultHours={hours}
                defaultMinutes={minutes}
                defaultAmOrPm={amOrPm}
                onChange={(value) => {
                  setState((prevState) => {
                    return { ...prevState, ...value };
                  });
                }}
                gradientColor={timePickerGradientColor}
                borderColor={timePickerBorderColor}
              />
            </Center>

            <Center
              data-testid="save-time-select-drawer"
              as={'button'}
              w={'100%'}
              py={3}
              borderRadius={'15px'}
              bg={bgColor}
              onClick={handleSave}
              fontWeight={550}
            >
              <Text color={buttonTextColor}>{t('Common.confirm')}</Text>
            </Center>
          </Box>

          <Center
            data-testid="close-time-select-drawer"
            as={'button'}
            w={'100%'}
            onClick={onClose}
            py={3}
            mt={3}
            borderRadius={'15px'}
            bg={bgColor}
          >
            <Text>{t('Common.cancel')}</Text>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
