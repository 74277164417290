import React, { ChangeEvent } from 'react';
import { Alert, AlertIcon, Button, Center, Flex, Skeleton, Switch, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SEHomeCard } from '../../../../common/components/SEHomeCard';
import { LowPowerIcon } from '../../../../styles/custom-icons';
import { useEnergyModeChange } from '../../../account/energy-modes/use-energy-mode-change';
import { MANUFACTURER_ID_SE_SEM, MANUFACTURER_ID_SEM } from '../../../devices/devices-helper';
import { useGetSwitchesByManufacturerQuery } from '../../../devices/switches/switchApi';
import { SenseAccordionItem } from '../common/SenseAccordionItem';

export function PowerModes() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isPending, isLoading, isError, selectedMode, handleChangeEnergyMode } = useEnergyModeChange();
  const { data: switches } = useGetSwitchesByManufacturerQuery([MANUFACTURER_ID_SEM, MANUFACTURER_ID_SE_SEM]);

  const handleSettingsNavigation = () => {
    navigate(`../account/energy-modes/low-power-mode?backURL=../../../home`);
  };

  const handlePowerModeToggle = (event: ChangeEvent<HTMLInputElement>) => {
    handleChangeEnergyMode(event.target.checked ? 'LOW_POWER' : 'SELF_CONSUMPTION');
  };

  return !switches.length ? null : (
    <SenseAccordionItem title={t('Home Sense.power modes')} dataTestId="power-modes-list">
      <Skeleton
        isLoaded={!isLoading}
        borderRadius={5}
        borderBottomRightRadius="25px"
        height={!isLoading ? 'unset' : 75}
      >
        {isError ? (
          <Alert status="error" variant="left-accent" data-testid="error-alert">
            <AlertIcon />
            {t('Energy Modes.error fetching mode')}
          </Alert>
        ) : (
          <SEHomeCard my={4}>
            <Flex align="center" data-testid="low-power-mode">
              <Center width={'100%'}>
                <LowPowerIcon w={10} h={10} mr={4} />
                <Flex direction="column">
                  <Text fontSize={17} fontWeight={400} lineHeight={1.2} overflowWrap="anywhere">
                    {t('Energy Modes.LOW_POWER.title')}
                  </Text>
                  <Button
                    colorScheme={'schneiderSkyBlue'}
                    variant="link"
                    fontSize={15}
                    fontWeight={400}
                    lineHeight={1.1}
                    onClick={handleSettingsNavigation}
                  >
                    {t('Energy Modes.edit circuits')}
                  </Button>
                </Flex>
                <Switch
                  data-testid={'low-power-mode-switch'}
                  isDisabled={isPending}
                  isChecked={selectedMode === 'LOW_POWER'}
                  onChange={handlePowerModeToggle}
                  size="lg"
                  variant="solarGreen"
                  ml="auto"
                />
              </Center>
            </Flex>
          </SEHomeCard>
        )}
      </Skeleton>
    </SenseAccordionItem>
  );
}
