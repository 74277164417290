import { Auth } from 'aws-amplify';
import { IS_RUNNING_CYPRESS_TESTS } from 'clipsal-cortex/src/common/constants';

import { convertEpochTimeToDate } from 'clipsal-cortex-utils/src/calculations/date-utils';
import { IS_NATIVE } from 'clipsal-cortex-utils/src/constants/common-constants';
import { parseJwt } from 'clipsal-cortex-utils/src/cryptography/oauth';

import { post } from '../../common/api/api-helpers';
import { ENV_TYPE_SHORTHAND } from '../../env-type';
import { AWS_REGION, REGION_SHORTHAND } from '../../region-type';

export const IDENTITY_URL =
  ENV_TYPE_SHORTHAND === 'STAGING'
    ? 'https://int.ecostruxure.se.app/api/identity'
    : 'https://ecostruxure.se.app/api/identity';

export const CIAM_IDMS_BASE_URL = ENV_TYPE_SHORTHAND === 'STAGING' ? 'https://api.qa.se.com' : 'https://api.se.com';

export function getAppRedirectURI(useFullAppUrl = false) {
  if (IS_NATIVE && useFullAppUrl) return 'schneiderhome://';
  if (IS_NATIVE) return 'schneiderhome:/';
  if (import.meta.env.MODE !== 'development' && !IS_RUNNING_CYPRESS_TESTS) return 'https://prosumer.clipsalcortex.com';
  return 'http://localhost:1337';
}

// Note: This is the OIDC client id, not Cognito
const IDP_CLIENT_ID =
  ENV_TYPE_SHORTHAND === 'STAGING' ? 'XOPHUummKLBFGDuJHD1Kc2HzCd178F2M' : 'doQedO0NES38i28qd14Up72JVL4nqaoQ';
const HOSTED_UI_DOMAIN = ENV_TYPE_SHORTHAND === 'STAGING' ? 'clippy-staging' : 'clippy-prod';

/**
 * Gets IdP logout URL with pointless redirect. We need to visit this url to end the EIM session.
 * @returns {Promise<string>} - The IdP logout URL
 */
export async function getIdpLogOutUrl() {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const idToken = cognitoUser.signInUserSession.idToken.payload['custom:id_token'];
  if (!idToken) throw new Error('ID token not found on Cognito user object');

  // NOTE: post_logout_redirect_uri is required but does nothing in this case - just leads to a broken link
  const idpLogoutUrl = `${IDENTITY_URL}/oidc/logout?client_id=${IDP_CLIENT_ID}&id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(
    `https://${HOSTED_UI_DOMAIN}.auth.${AWS_REGION}.amazoncognito.com/oauth2/idpresponse`
  )}&federated=true`;
  return idpLogoutUrl;
}

/**
 * Get Cognito logout URL with pointless redirect. We need to visit this url to kill the Cognito cookies.
 * @returns {string} - The Cognito logout URL
 */
export function getCognitoLogOutUrl() {
  return (
    `https://${HOSTED_UI_DOMAIN}.auth.${AWS_REGION}.amazoncognito.com/logout` +
    `?client_id=${
      import.meta.env?.[`VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_CLIENT_ID`]
    }&logout_uri=${encodeURIComponent('https://prosumer.clipsalcortex.com')}`
  );
}

export async function getIdpEditProfileUrl() {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const idToken = cognitoUser.signInUserSession.idToken.payload['custom:id_token'];
  const parsedIdToken = parseJwt(idToken);
  return parsedIdToken.profile;
}

// 29 minutes
const EIM_TOKEN_EXPIRY_SECONDS = 1740;

/**
 * Check if the access token has expired based on the `iat` claim and a hardcoded expiry time (based on the latest EIM token expiry time).
 * We would like to use the `exp` claim instead but we can't directly map any access token claims when defining the OIDC client in Cognito (`iat` claim comes from ID token).
 * Nor can we map the entire `access_token` itself because it can be too long for Cognito's custom attributes.
 * @returns {Promise<boolean>} - Whether the EIM session has expired
 */
export async function hasEimSessionExpired(): Promise<boolean> {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const accessTokenIatAttribute = cognitoUser.signInUserSession.idToken.payload['custom:acc_token_issued_at'];
  if (!accessTokenIatAttribute) {
    console.error('Access token issued at attribute not found on Cognito user object');
    return true;
  }
  let accessTokenIat: Date;
  const accessTokenIatNumber = Number(accessTokenIatAttribute);
  // This attribute has historically come in epoch time but now seems to be converted by Cognito into a format like `Wed Mar 05 05:06:44 UTC 2025`
  if (!isNaN(accessTokenIatNumber)) accessTokenIat = convertEpochTimeToDate(accessTokenIatAttribute);
  else accessTokenIat = new Date(accessTokenIatAttribute);
  const accessTokenExpiry = new Date(accessTokenIat.getTime() + EIM_TOKEN_EXPIRY_SECONDS * 1000);
  if (new Date() >= accessTokenExpiry) {
    return true;
  }
  return false;
}

/**
 * This function returns the user's EIM ID token from the Cognito user object.
 * @returns {Promise<{idToken: string}>} - The user's EIM ID token
 */
export async function getEimIdToken(): Promise<{ idToken: string }> {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const cognitoIdTokenPayload = cognitoUser.signInUserSession.idToken.payload;
  const idToken = cognitoIdTokenPayload['custom:id_token'];
  return {
    idToken,
  };
}

type FreshUserTokens = {
  accessToken: string;
  idToken: string;
  ciamIdpAccessToken: string;
};

const MAX_CUSTOM_COGNITO_ATTRIBUTE_LENGTH = 2048;

/**
 * This function returns a fresh access and ID token for the user to interact with EIM (EcoStruxure Platform Identity Module) APIs.
 * In the process, it updates these same attributes on their Cognito user object, as applicable.
 * @returns {Promise<FreshUserTokens>} - The user's fresh access token, id token and CIAM IDP access token
 */
export async function getFreshEimTokens(): Promise<FreshUserTokens> {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const cognitoIdTokenPayload = cognitoUser.signInUserSession.idToken.payload;
  let accessToken = cognitoIdTokenPayload['custom:access_token'];
  let idToken = cognitoIdTokenPayload['custom:id_token'];
  let ciamIdpAccessToken = cognitoIdTokenPayload['custom:ciam_idp_acc_token'];

  if (!!accessToken) {
    // Have to inspect access token `exp` claim to see if it's stale
    try {
      const parsedAccessToken = parseJwt(accessToken);
      if (!!parsedAccessToken.exp) {
        const expiry = convertEpochTimeToDate(parsedAccessToken.exp.toString());
        if (new Date() >= expiry) {
          accessToken = '';
        }
      }
    } catch (e) {
      console.error('Unexpected access token format:', e);
      accessToken = '';
    }
  }

  if (!accessToken || !idToken || !ciamIdpAccessToken || (await hasEimSessionExpired())) {
    let refreshToken = cognitoIdTokenPayload['custom:refresh_token'];
    // Fetch new access and ID tokens, then save them to Cognito
    const tokenResponse = await post('/v1/eim_refresh_token', { refresh_token: refreshToken });
    accessToken = tokenResponse['access_token'];
    const parsedAccessToken = parseJwt(accessToken);
    ciamIdpAccessToken = parsedAccessToken.idpAccessToken;
    idToken = tokenResponse['id_token'];
    refreshToken = tokenResponse['refresh_token'];

    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:acc_token_issued_at': parsedAccessToken.iat.toString(),
      'custom:id_token': idToken,
      'custom:ciam_idp_acc_token': !!ciamIdpAccessToken ? ciamIdpAccessToken : '',
      'custom:refresh_token': refreshToken,
      // We only save the access token if it's not too long for Cognito's custom attributes (which, hopefully, it isn't).
      // Otherwise we will have to get a new one every time we call this function
      'custom:access_token':
        !!accessToken && accessToken.length <= MAX_CUSTOM_COGNITO_ATTRIBUTE_LENGTH ? accessToken : '',
    });
  }

  return {
    accessToken,
    idToken,
    ciamIdpAccessToken,
  };
}
