import React from 'react';
import { Browser } from '@capacitor/browser';
import { Box, Divider, Flex, Skeleton, Text, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AlertDialogModal } from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { TESLA_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import { SEHomeCard } from '../../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { SEHomeBatteryIcon, SEHomeInverterIcon, SEHomeMeterIcon } from '../../../styles/custom-icons';
import { getAppRedirectURI } from '../../auth/auth-helpers';
import { GENERIC_BATTERIES_MANUFACTURER_IDS } from '../../battery/constants';
import { selectInverters, selectMeters, selectSite } from '../../site/siteSlice';
import { AccountMenuButton } from '../AccountMenuButton';
import { TESLA_CLIENT_ID, TESLA_SCOPES } from './tesla/constants';
import { useTeslaIsConnected } from './tesla/useTeslaIsConnected';

export function HardwareConfiguration() {
  const inverters = useSelector(selectInverters);
  const meters = useSelector(selectMeters);
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const { t } = useTranslation();
  const { isOpen: isAlertDialogOpen, onOpen: onOpenAlertDialog, onClose: onCloseAlertDialog } = useDisclosure();
  const { isConnected, isLoading: isConnectedLoading } = useTeslaIsConnected();

  const handleTeslaClick = () => {
    if (isConnected) {
      /* istanbul ignore next -- @preserve */
      navigate(`../tesla`);
    } else {
      onOpenAlertDialog();
    }
  };

  /* istanbul ignore next -- @preserve */
  async function handleTeslaConfirm() {
    const redirectURI = `${getAppRedirectURI()}/app/tesla`;
    const url =
      'https://auth.tesla.com/oauth2/v3/authorize' +
      `?client_id=${TESLA_CLIENT_ID}` +
      `&redirect_uri=${redirectURI}` +
      `&response_type=code` +
      `&scope=${TESLA_SCOPES.join(' ')}` +
      `&state=siteID:${siteId}`;
    /* istanbul ignore if -- @preserve */
    await Browser.open({ url, windowName: '_self' });
  }

  return (
    <SlidingAnimationPageBase title={t('Set Up Hardware.configure new hardware')} backURL={`../../list?direction=back`}>
      <Box px={3}>
        <Box mb={1}>{t('Set Up Hardware.schneider electric').toUpperCase()}</Box>

        <SEHomeCard borderTopLeftRadius={'5px'} borderBottomRightRadius={'0px'} borderBottomLeftRadius={'0px'} p={0}>
          <AccountMenuButton
            data-testid="configure-meter"
            isDisabled={!!meters.length}
            onClick={() => {
              navigate(`../meter`);
            }}
          >
            <Flex align={'center'}>
              <SEHomeMeterIcon w={8} h={8} />
              <Text fontSize={13} fontWeight="normal" ml={3}>
                {t('Set Up Hardware.new device', { device: t('Set Up Hardware.schneider energy monitor') })}
              </Text>
            </Flex>
          </AccountMenuButton>
        </SEHomeCard>

        <SEHomeCard borderTopLeftRadius={'0px'} borderTopRightRadius={'0px'} p={0} mb={4}>
          <AccountMenuButton
            data-testid="configure-inverter"
            isDisabled={!!inverters.length}
            includeBottomDivider={false}
            onClick={() => {
              navigate(`../inverter`);
            }}
          >
            <Flex align={'center'}>
              <SEHomeInverterIcon w={8} h={8} />
              <Text fontSize={13} fontWeight="normal" ml={3}>
                {t('Set Up Hardware.new device', { device: t('Set Up Hardware.schneider inverter') })}
              </Text>
            </Flex>
          </AccountMenuButton>
        </SEHomeCard>
        <Divider my={4} />
        {!IS_DEMO_LOGIN && !GENERIC_BATTERIES_MANUFACTURER_IDS.includes(TESLA_MANUFACTURER_ID) && (
          <>
            <Box mb={1}>{t('Integrations.title').toUpperCase()}</Box>
            <Skeleton
              isLoaded={!isConnectedLoading}
              borderBottomLeftRadius={'5px'}
              borderBottomRightRadius={'25px'}
              borderTopRightRadius={'5px'}
              borderTopLeftRadius={'5px'}
            >
              <SEHomeCard borderTopLeftRadius={'5px'} borderBottomLeftRadius={'5px'} p={0}>
                <AccountMenuButton
                  data-testid="configure-tesla"
                  includeBottomDivider={false}
                  onClick={handleTeslaClick}
                >
                  <Flex align={'center'}>
                    <SEHomeBatteryIcon w={8} h={8} />
                    <Text fontSize={13} fontWeight="normal" ml={3}>
                      {t('Integrations.Tesla.title')}
                    </Text>
                  </Flex>
                </AccountMenuButton>
              </SEHomeCard>
            </Skeleton>
            <AlertDialogModal
              header={t('Integrations.Tesla.add toast header')}
              subHeader={t('Integrations.Tesla.add toast subHeader')}
              isOpen={isAlertDialogOpen}
              onClose={onCloseAlertDialog}
              confirmButtonTextColor={'customRed.500'}
              onConfirm={handleTeslaConfirm}
            />
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
