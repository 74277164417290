import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLiveBatteryData } from '../../../battery/liveBatteryDataApi';
import { HomePageWidgetTileProps } from '../HomePageWidgetTile';
import { GenericHomePageWidgetTile } from './GenericHomePageWidgetTile';

export function BatteryWidgetTile({ item, index, isEditing, onRemoveGridItem }: HomePageWidgetTileProps) {
  const { data, isLoading } = useLiveBatteryData();
  const { t } = useTranslation();

  return (
    <GenericHomePageWidgetTile
      isLoaded={!isLoading}
      item={{
        ...item,
        title: t('Widgets.Battery.title'),
        status: `${Math.round((data.battery_soc_fraction || 0) * 100)}%`,
      }}
      index={index}
      isEditing={isEditing}
      onRemoveGridItem={onRemoveGridItem}
    />
  );
}
