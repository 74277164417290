import React, { useState } from 'react';
import { Box, Divider, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SEHomeCard } from '../../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { useParamBackUrl } from '../../../common/hooks/use-param-back-url';
import { HOMEPAGE_WIDGETS } from '../../home/default-home/constants';
import { getWidgetIDs, getWidgetIDsForDevices } from '../../home/default-home/default-home-helpers';
import { selectDevices, selectSite } from '../../site/siteSlice';
import { SettingSwitch } from './SettingSwitch';

export function WidgetSettings() {
  const site = useSelector(selectSite);
  const devices = useSelector(selectDevices) || [];
  const siteId = site.site_id;
  const [selectedWidgets, setSelectedWidgets] = useState<number[]>(getWidgetIDs({ devices, siteId }));
  const availableWidgets = getWidgetIDsForDevices(devices);
  const displayedWidgetSettings = HOMEPAGE_WIDGETS.filter((widget) => availableWidgets.includes(widget.id));
  const { t } = useTranslation();
  const backURL = useParamBackUrl('../home?direction=back');

  return (
    <SlidingAnimationPageBase backURL={backURL} title={t('Account.homepage widgets')}>
      <Box mx={4}>
        <Text my={2}>{t('Account.toggle widgets')}</Text>
        <SEHomeCard pt={1} pb={5} pr={0} borderTopLeftRadius="5px">
          {displayedWidgetSettings.length ? (
            displayedWidgetSettings.map((widget, index) => {
              const isLastElement = index == displayedWidgetSettings.length - 1;

              return (
                <Box key={`widget-${widget.id}`}>
                  <SettingSwitch
                    mt={4}
                    onChange={() => {
                      if (selectedWidgets.includes(widget.id)) {
                        const newSelectedWidgets = selectedWidgets.filter((id) => id !== widget.id);
                        setSelectedWidgets(newSelectedWidgets);
                        localStorage.setItem(`homeGridItems_${siteId}`, JSON.stringify(newSelectedWidgets));
                      } else {
                        const newSelectedWidgets = [...selectedWidgets, widget.id];
                        setSelectedWidgets(newSelectedWidgets);
                        localStorage.setItem(`homeGridItems_${siteId}`, JSON.stringify(newSelectedWidgets));
                      }
                    }}
                    isChecked={selectedWidgets.includes(widget.id)}
                    data-testid={`widget-setting-${widget.type.toLowerCase().replace('_', '-')}`}
                    text={t(`Account.widget types.${widget.type}`)}
                    Icon={widget.Icon}
                  />
                  {!isLastElement && <Divider borderColor={'rgba(151, 151, 151, 0.3)'} mt={4} />}
                </Box>
              );
            })
          ) : (
            <EmptyWidgetSettings />
          )}
        </SEHomeCard>
      </Box>
    </SlidingAnimationPageBase>
  );
}

function EmptyWidgetSettings() {
  const navigate = useNavigate();
  const textColor = useColorModeValue('customGrey.800', 'customGrey.200');
  const linkButtonColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const { t } = useTranslation();
  return (
    <Box>
      <Text mt={3} color={textColor}>
        {t('Account.no available widgets')}
      </Text>
      <Text
        color={linkButtonColor}
        fontSize={14}
        onClick={() => navigate(`../../hardware/list?direction=forward`)}
        data-testid="link-to-hardware"
      >
        {t('Account.get started by')}
      </Text>
    </Box>
  );
}
