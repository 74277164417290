import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  AylaLiveDataSummary,
  GenericLiveBatteryData,
  GridStatus,
  InverterStatus,
  SaturnLiveDataSummary,
} from 'clipsal-cortex-types/src/api';
import { useAppVisibility } from 'clipsal-cortex-utils/src/hooks/use-app-visibility';

import { baseApi } from '../../../app/services/baseApi';
import { RTKQError } from '../../../common/api/api-helpers';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { useSiteDeviceCheck } from '../../../common/hooks/use-site-device-check';
import { GENERIC_BATTERIES_MANUFACTURER_IDS } from '../../battery/constants';
import { selectBatteries, selectSite } from '../siteSlice';
import { liveDataWebhookQueryFn, onCacheEntryAddedHandler } from './liveDataWebhookApi';
import { CombinedLiveData, LiveDataResult, LiveSenseData } from './types';

// 0.1 kW above or below 0 is usually just noise
const POWER_THRESHOLD_KW = 0.1;

export const liveDataApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBatteryLiveData: build.query<GenericLiveBatteryData, number>({
      query: (batteryId) => `/v1/batteries/${batteryId}/status`,
      providesTags: ['LiveData'],
    }),
    getInverterLiveData: build.query<SaturnLiveDataSummary, number>({
      providesTags: ['InverterLiveData'],
      query: (siteId) => `/v1/sites/${siteId}/saturn_live_data_summary`,
    }),
    getAylaLiveData: build.query<AylaLiveDataSummary[], number>({
      providesTags: ['AylaLiveData'],
      query: (siteId) => `/v1/sites/${siteId}/ayla_live_data_summary`,
    }),
    getMeterLiveData: build.query<
      LiveSenseData & {
        isLoaded: boolean;
        isError: boolean;
        error: string | null;
      },
      { siteId: number; trigger: number }
    >({
      providesTags: ['MeterLiveData'],
      queryFn: liveDataWebhookQueryFn,
      keepUnusedDataFor: Infinity,
      onCacheEntryAdded: onCacheEntryAddedHandler,
    }),
  }),
});

export const {
  useGetBatteryLiveDataQuery,
  useGetMeterLiveDataQuery,
  useGetInverterLiveDataQuery,
  useGetAylaLiveDataQuery,
} = liveDataApi;

/**
 * A hacky solution to combining live data from two sources.
 * If a site has an inverter, we call the Saturn API via polling.
 * If a site has a meter, we use a websocket to create an incoming data stream.
 * If a site has both, we prioritize meter data over inverter data for grid/solar/consumption (obviously not battery).
 *
 * Ideally we're going to move this logic to the back-end.
 * @param skip - Param to skip API fetching, defaults to false
 * @param trigger - Use this param to force a re-fetch of the meter data. NOTE: This is the only way to invalidate
 *  meter query data, as invalidating the tags does _NOT_ re-fetch data if the cache key has any data in it already.
 *
 * @returns A query result-like object with the combined data and other metadata about the response/s.
 */
export function useLiveData(skip = false, trigger = 0): LiveDataResult {
  const { site_id: siteId } = useSelector(selectSite);
  const isAppVisible = useAppVisibility() || IS_DEMO_LOGIN; // No need to skip API calls in demo mode
  const { siteHasSaturnInverter, siteHasSenseMeter } = useSiteDeviceCheck();

  // METER DATA
  const { data: liveMeterData } = useGetMeterLiveDataQuery(
    { siteId, trigger },
    {
      skip: !siteHasSenseMeter || skip || !isAppVisible,
    }
  );
  // INVERTER DATA
  const {
    data: liveInverterData,
    isLoading: isInverterDataLoading,
    isError: isInverterDataError,
    isFetching: isInverterDataFetching,
    error: inverterError,
  } = useGetInverterLiveDataQuery(siteId, {
    pollingInterval: 5000,
    skip: !siteHasSaturnInverter || skip || !isAppVisible,
  });

  const isInverterStatusError =
    liveInverterData?.inverter_status !== InverterStatus.NORMAL &&
    liveInverterData?.inverter_status !== InverterStatus.UNDEFINED;

  // BATTERY DATA
  const siteBatteries = useSelector(selectBatteries);
  const genericBattery = siteBatteries.find(({ manufacturer_id: manufacturerId }) =>
    GENERIC_BATTERIES_MANUFACTURER_IDS.includes(manufacturerId)
  );
  const siteHasGenericBattery = !!genericBattery;
  const {
    data: liveBatteryData,
    isLoading: isBatteryDataLoading,
    isError: isBatteryDataError,
    isFetching: isBatteryDataFetching,
    error: batteryError,
  } = useGetBatteryLiveDataQuery(genericBattery?.id ?? 0, {
    pollingInterval: 5000,
    skip: !siteHasGenericBattery || skip || !isAppVisible,
  });

  const liveData = useMemo(() => {
    const dataBase: CombinedLiveData = {
      last_updated: '',
      appliances: [],
      switches: [],
      solar: 0,
      grid: 0,
      consumption: 0,
      self_powered_fraction: 0,
      blackout: false,
      hybrid_inverter: 0,
      grid_status: GridStatus.UNDEFINED, // Only used by Saturn data, no way to get this from Sense yet
      inverter_status: InverterStatus.UNDEFINED,
    };
    let dataMeter: CombinedLiveData = { ...dataBase };
    let dataInverter: CombinedLiveData = { ...dataBase };
    let dataBattery: CombinedLiveData = { ...dataBase };

    // Saturn Battery has priority over Generic Battery
    if (siteHasGenericBattery && !isBatteryDataLoading && !isBatteryDataError && liveBatteryData) {
      const batteryKw = (liveBatteryData.battery_w ?? 0) / 1000;
      const isBatteryBelowThreshold = Math.abs(batteryKw) < POWER_THRESHOLD_KW;

      dataBattery = {
        ...dataMeter,
        ...('battery_w' in liveBatteryData
          ? {
              battery: isBatteryBelowThreshold ? 0 : batteryKw,
              battery_capacity_wh: liveBatteryData.battery_capacity_wh,
              battery_soc_fraction: liveBatteryData.battery_soc_fraction,
              battery_duration_sec: liveBatteryData.battery_duration_sec,
              battery_total_capacity_wh: liveBatteryData.battery_total_capacity_wh,
            }
          : {}),
      };
    }

    // Lowest priority is Sense meter data, except for consumption and grid data.
    // Many of the values here are overwritten if a site has a Saturn inverter.
    if (siteHasSenseMeter && !liveMeterData?.isError && liveMeterData?.isLoaded && liveMeterData) {
      // Note: Sense data is in W, so convert to kW
      dataMeter = {
        ...dataBattery,
        appliances: liveMeterData.devices.map((device, index) => {
          return {
            assignment: device.assignment,
            power: (device.w ?? 0) / 1000,
            display_name: device.name ?? `Device ${index + 1}`,
            appliance_id: device.appliance_id,
            control_device_id: null,
          };
        }),
        switches: liveMeterData.switches.map((senseSwitch) => {
          return {
            id: senseSwitch.switch_id,
            state: senseSwitch.state,
            status: senseSwitch.status,
            assignment: senseSwitch.assignment,
            power: (senseSwitch.w ?? 0) / 1000,
            display_name: senseSwitch.name,
            appliance_id: senseSwitch.appliance_id,
            control_device_id: null,
          };
        }),
        // Ensures solar can't be negative (mis-configured site)
        solar: Math.max(liveMeterData.solar / 1000, 0),
        grid: liveMeterData.ac_load_net / 1000,
        consumption: liveMeterData.consumption / 1000,
        hybrid_inverter: (liveMeterData.hybrid_inverter ?? 0) / 1000,
        last_updated: new Date(liveMeterData.epoch * 1000).toISOString(),
      };
    }

    // Highest priority is inverter data -- if a site has an inverter, we over-write _everything_ from Sense, except
    // for appliances and switches.
    if (siteHasSaturnInverter && !isInverterDataLoading && !isInverterDataError && liveInverterData) {
      const isBatteryBelowThreshold = Math.abs(liveInverterData.battery ?? 0) < POWER_THRESHOLD_KW;
      const isSolarBellowThreshold = Math.abs(liveInverterData.solar ?? 0) < POWER_THRESHOLD_KW;

      dataInverter = {
        ...dataBattery,
        ...dataMeter,
        ...('battery' in liveInverterData
          ? {
              battery: isBatteryBelowThreshold ? 0 : liveInverterData.battery,
              battery_capacity_wh: liveInverterData.battery_capacity_wh,
              battery_soc_fraction: liveInverterData.battery_soc_fraction,
              battery_duration_sec: liveInverterData.battery_duration_sec,
            }
          : {}),
        solar: isSolarBellowThreshold ? 0 : liveInverterData.solar,
        grid: liveInverterData.grid,
        consumption: liveInverterData.consumption,
        last_updated: liveInverterData.last_updated,
        grid_status: liveInverterData.grid_status,
      };
      return { ...dataInverter };
    }

    return { ...dataMeter };
  }, [
    liveInverterData,
    liveMeterData,
    siteHasSaturnInverter,
    siteHasSenseMeter,
    isInverterDataLoading,
    isInverterDataError,
  ]);

  return {
    isMeterError: siteHasSenseMeter && !!liveMeterData?.isError,
    isMeterDataLoading: siteHasSenseMeter && !liveMeterData?.isLoaded,
    isInverterError: siteHasSaturnInverter && (isInverterDataError || isInverterStatusError),
    isInverterDataLoading: siteHasSaturnInverter && isInverterDataLoading,
    isBatteryError: siteHasGenericBattery && isBatteryDataError,
    isBatteryDataLoading: siteHasGenericBattery && isBatteryDataLoading,
    data: liveData,
    error: (inverterError?.message ?? null) || (liveMeterData?.error ?? null) || (batteryError?.message ?? null),
    isLoading: (siteHasSaturnInverter && isInverterDataLoading) || (siteHasSenseMeter && !liveMeterData?.isLoaded),
    isFetching: (siteHasSaturnInverter && isInverterDataFetching) || (siteHasGenericBattery && isBatteryDataFetching),
    isInverterOffline: (inverterError as RTKQError)?.status == 500,
  };
}
