import React from 'react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Box, IconButton, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MobileTopNav } from 'clipsal-cortex-ui/src/components/MobileTopNav';

import { selectSite } from '../../features/site/siteSlice';
import { IS_DEMO_LOGIN, TOP_BANNER_SPACING_AFFORDANCE } from '../constants';
import { SchneiderHamButton } from './SchneiderHamButton';

type CommonTopNavProps = (
  | {
      backURL: string;
      onClickBack?: never;
    }
  | { onClickBack: () => void; backURL?: never }
) &
  CommonProps;

type CommonProps = {
  title: string | React.JSX.Element;
  includeHamButton?: boolean;
  includeSchneiderHamButton?: boolean;
  customTopNavButton?: JSX.Element;
};

export const CommonTopNav = ({
  backURL,
  onClickBack,
  title,
  includeHamButton,
  includeSchneiderHamButton,
  customTopNavButton,
}: CommonTopNavProps) => {
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const backProps = backURL ? { backURL: backURL! } : { onClickBack: onClickBack! };

  return (
    <MobileTopNav
      background="#F1F1F1"
      darkModeBackground="#111111"
      opacity={1}
      py={3}
      {...backProps}
      width={'100vw'}
      top={IS_DEMO_LOGIN ? TOP_BANNER_SPACING_AFFORDANCE : 'env(safe-area-inset-top)'}
    >
      <Box position={'absolute'} left={0}>
        {includeSchneiderHamButton && <SchneiderHamButton />}
      </Box>
      <Text
        data-testid="top-nav-title"
        fontSize={'18px'}
        textAlign="center"
        overflow="auto"
        whiteSpace="nowrap"
        maxW={'calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right) - 90px)'}
      >
        {title}
      </Text>
      <Box position={'absolute'} right={0} maxW="25%">
        {customTopNavButton && customTopNavButton}
        {includeHamButton && (
          <IconButton
            data-testid="settings-ham-button"
            onClick={() => navigate(`/sites/${siteId}/account/dashboard?direction=forward`)}
            variant={'ghost'}
            mr={2}
            aria-label="Settings"
            icon={<HamburgerIcon w={6} h={6} />}
          />
        )}
      </Box>
    </MobileTopNav>
  );
};
