import React from 'react';
import { Accordion, Box } from '@chakra-ui/react';

import { Weather } from '../Weather';
import { WelcomeMessage } from '../WelcomeMessage';
import { PowerChart } from './power-chart/PowerChart';
import { PowerConsumers } from './power-consumers/PowerConsumers';
import { PowerModes } from './power-modes/PowerModes';
import { PowerSources } from './power-sources/PowerSources';
import { SummaryInfo } from './summary-info/SummaryInfo';
import { TopPowerConsumers } from './top-power-consumers/TopPowerConsumers';

// This is the home page for Sense-only sites
export function SenseOnlyHome() {
  return (
    <Box data-testid="sense-only-home-page">
      <Weather />
      <WelcomeMessage />
      <PowerChart />
      <SummaryInfo />
      <Accordion allowToggle>
        <PowerSources />
        <TopPowerConsumers />
        <PowerConsumers />
        <PowerModes />
      </Accordion>
    </Box>
  );
}
