import React, { useState } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, useColorModeValue } from '@chakra-ui/react';
import { t } from 'i18next';

import { useLiveData } from '../../../../features/site/live-data/liveDataApi';

// This is an error alert for Sense live data errors, it gives the user the ability to retry the connection.
export function SenseLiveDataErrorAlert() {
  const linkColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const [trigger, setTrigger] = useState(0);
  const {} = useLiveData(false, trigger);
  const handleRetry = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // Force a new websocket connection by invalidating the cache
    setTrigger((prev) => prev + 1); // Changing trigger will cause reconnection
  };

  return (
    <Box pb={2} px={3}>
      <Alert status="error" data-testid="error-alert">
        <AlertIcon />
        <AlertTitle>{t('Widgets.Live Power.unable to connect to sem')}</AlertTitle>
        <AlertDescription>
          <Button onClick={handleRetry} variant={'ghost'} data-testid="retry-connection" color={linkColor}>
            {t('Common.retry')}
          </Button>
        </AlertDescription>
      </Alert>
    </Box>
  );
}
