import { getCurrentSession } from '../../../common/api/api-helpers';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { ENV_TYPE_SHORTHAND } from '../../../env-type';
import { REGION_SHORTHAND } from '../../../region-type';
import demoLiveData from '../../demo-login/stubs/sense-live-data.json';
import { LiveDataMessage } from './types';

/**
 * This is a special query that uses a websocket to get live data. A breakdown of what happens:
 * 1. A component subscribes to this query by calling the hook
 * 2. The queryFn is called, which returns a dummy value to the cache
 * 3. The `onCacheEntry` callback is called, which creates a websocket connection and updates the cache
 *    with new values coming from the live data stream. This stream updates once every 500ms, and lasts for 5
 *    minutes. Once this time has elapsed, and the connection is still active, the server returns a `reconnect`
 *    type message -- if we're still connected, we just re-subscribe to the site.
 *
 * In the event of an unknown message structure (generally an error), we don't do anything, as there are multiple
 * no-op errors which come from the websocket (e.g. API gateway function timeout, which doesn't actually affect
 * the data stream).
 */

const WEBSOCKET_ENDPOINT = import.meta.env[
  `VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_WEBSOCKET_API_URL`
] as string;
let socket: WebSocket | null = null;
let numTimesRetried = 0;
let timeoutHandle: ReturnType<typeof setTimeout>;
const MAX_WEBSOCKET_RETRY_ATTEMPTS = 1;
const WEBSOCKET_RETRY_TIMEOUT_MS = 5_000;

const connectWebSocket = (
  siteId: number,
  token: string,
  onMessage: (data: LiveDataMessage) => void,
  onError: (err: string) => void
) => {
  socket = new WebSocket(`${WEBSOCKET_ENDPOINT}/?token=${token}`);

  socket.onopen = () => {
    socket?.send(JSON.stringify({ action: 'subscribeSiteLiveData', clipsal_solar_id: siteId }));
  };

  socket.onmessage = (event) => {
    const parsed: LiveDataMessage = JSON.parse(event.data);
    onMessage(parsed);
  };

  socket.onerror = (event) => {
    console.error('WebSocket error:', event);
    onError('WEBSOCKET_ERROR');
  };

  socket.onclose = () => {
    onError('WEBSOCKET_ERROR');
  };
};

/*
  The following timeout is used to keep track of WS messages being received.
  Once the timeout elapses and no data has been received within the timeout --
    -- the WS connection is closed and a new connection is established.

  This helps handle the following scenarios:
  1. The WS stops sending data unexpectedly (without issuing a `close` message).
      If it resumes sending data, the timeout is reset.

  2. The user's network connection disconnects then reconnects.
      If the network reconnects, the timeout is reset.
*/
const monitorTimeout = (onTimeout: () => void) => {
  clearTimeout(timeoutHandle);
  timeoutHandle = setTimeout(() => {
    if (numTimesRetried <= MAX_WEBSOCKET_RETRY_ATTEMPTS) {
      numTimesRetried += 1;
      onTimeout();
    }
  }, WEBSOCKET_RETRY_TIMEOUT_MS);
};

export const liveDataWebhookQueryFn = () => {
  if (IS_DEMO_LOGIN) {
    return {
      data: {
        ...(demoLiveData.payload as any),
        isLoaded: true,
        isError: false,
        error: null,
      },
    };
  }

  // We don't actually use the siteId param, it's just for caching.
  return {
    data: {
      ac_load_net: 0,
      consumption: 0,
      devices: [],
      switches: [],
      epoch: 0,
      solar: 0,
      hybrid_inverter: 0,
      isLoaded: false,
      isError: false,
      error: null,
    },
  };
};

export const onCacheEntryAddedHandler = async (
  arg: { siteId: number; trigger: number },
  api: {
    // TODO make types more specific.
    updateCachedData: any;
    cacheDataLoaded: any;
    cacheEntryRemoved: any;
  }
) => {
  const { siteId } = arg;
  const { updateCachedData, cacheEntryRemoved } = api;

  if (IS_DEMO_LOGIN) {
    updateCachedData(() => ({
      ...(demoLiveData.payload as any),
      isLoaded: true,
      isError: false,
      error: null,
    }));
    return;
  }
  const { token } = await getCurrentSession();

  const handleMessage = (data: LiveDataMessage) => {
    if (data.type === 'close' || data?.type == 'error' || data?.message === 'Internal server error') {
      socket?.close();
      updateCachedData((draft: object) => ({
        ...draft,
        isLoaded: true,
        isError: true,
        error: 'WEBSOCKET_ERROR',
      }));
      clearTimeout(timeoutHandle);
      return;
    }
    /* istanbul ignore else -- @preserve */
    if (data?.type == 'realtime_update') {
      updateCachedData(() => ({
        ...data.payload,
        isLoaded: true,
        isError: false,
        error: null,
      }));
    } else if (data?.type == 'reconnect') {
      socket?.send(JSON.stringify({ action: 'subscribeSiteLiveData', clipsal_solar_id: siteId }));
    } else if (data?.type == 'open') {
      // No-op
    } else if (data?.type == 'info') {
      // No-op
    } else {
      console.log('UNKNOWN MESSAGE: ', data);
    }

    monitorTimeout(reconnect);
  };

  const handleError = (error: string) => {
    updateCachedData((draft: object) => ({
      ...draft,
      isLoaded: true,
      isError: true,
      error,
    }));
  };

  const reconnect = () => {
    if (socket) {
      socket.close();
    }
    connectWebSocket(siteId, token, handleMessage, handleError);
  };
  /* istanbul ignore next -- @preserve */
  const handleOffline = () => {
    socket?.close();
    updateCachedData((draft: object) => ({
      ...draft,
      isLoaded: true,
      isError: true,
      error: 'NETWORK_ERROR',
    }));
  };

  window.addEventListener('offline', handleOffline);
  window.addEventListener('online', reconnect);

  connectWebSocket(siteId, token, handleMessage, handleError);

  monitorTimeout(reconnect);

  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;

  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
  socket?.send(JSON.stringify({ action: 'unsubscribeSiteLiveData', clipsal_solar_id: siteId }));
  clearTimeout(timeoutHandle);
  socket?.close();
  socket = null;

  window.removeEventListener('offline', handleOffline);
  window.removeEventListener('online', reconnect);
};
