import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { capitalizeFirst } from 'clipsal-cortex-utils/src/formatting/formatting';

import '../activity.scss';

import { SwitchOption } from 'clipsal-cortex-ui/src/components/MultiToggleSwitch';

import { DateRangeType, DateRangeTypePicker } from '../../../common/components/DateRangeTypePicker';
import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { useGetDevicesQuery } from '../../devices/devicesApi';
import { useGetSavingsQuery } from '../../savings/savingsApi';
import { useGetCostsQuery } from '../../site/costApi';
import { selectCurrentDayForSite, selectMeters, selectSite } from '../../site/siteSlice';
import { useGetUsageWithCostsQuery } from '../activityApi';
import { ActivitySummaryCards } from '../default/ActivitySummaryCards';
import { BatterySOCChart } from '../default/BatterySOCChart';
import { ChartSwitcher } from '../default/ChartSwitcher';
import { FilterByDevice } from '../default/FilterByDevice';
import { PowerSourceSelect } from '../default/PowerSourceSelect';
import { useInitialSelectedPowerSources } from '../default/use-initial-selected-power-sources';
import { useSource } from '../default/use-source';
import { ActivityDataSource, PowerSourceConfiguration } from '../types';
import { getInitialSelectedDates } from '../utils';
import { ActivityDatePicker } from './ActivityDatePicker';
import { useDateRangeOptions } from './use-date-range-options';

highchartsAccessibility(Highcharts);

export interface ActivityState {
  selectedDateRangeType: DateRangeType;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  selectedPowerSources: PowerSourceConfiguration;
  selectedDeviceId: number | null;
  selectedDeviceAssignment?: string;
  isSelectedDeviceDisplayedInChart: boolean;
  isCostViewSelected: boolean;
}

interface Props {
  display?: {
    dateRange?: boolean;
    datePicker?: boolean;
    summaryCards?: boolean;
    activityChart?: boolean;
    batteryChart?: boolean;
    sourcesToggle?: boolean;
    devicesList?: boolean;
  };
  options?: {
    powerSources: PowerSourceConfiguration;
    dateRange: SwitchOption<DateRangeType>[];
  };
}

export function ActivityView({
  display = {
    dateRange: true,
    datePicker: true,
    summaryCards: true,
    activityChart: true,
    batteryChart: true,
    sourcesToggle: true,
    devicesList: true,
  },
  options,
}: Props) {
  const { t } = useTranslation();
  const source = useSource();
  const { devices: hardwareDevices } = useSelector(selectSite);
  const siteHasMeter = useSelector(selectMeters).length > 0;
  const currentDay = useSelector(selectCurrentDayForSite);
  const { data: devices, isError: isDevicesError } = useGetDevicesQuery();
  const DATE_RANGE_OPTIONS = useDateRangeOptions();
  const initialSelectedPowerSources = useInitialSelectedPowerSources(hardwareDevices);

  const [state, setState] = useState<ActivityState>({
    selectedDateRangeType: DateRangeType.Day,
    rangeTypeToSelectedPeriod: getInitialSelectedDates(currentDay),
    selectedPowerSources: initialSelectedPowerSources,
    selectedDeviceId: devices[0]?.appliance_id ?? 0,
    selectedDeviceAssignment: devices[0]?.assignment,
    isSelectedDeviceDisplayedInChart: false,
    isCostViewSelected: false,
  });
  const {
    selectedDateRangeType,
    rangeTypeToSelectedPeriod,
    selectedPowerSources,
    isSelectedDeviceDisplayedInChart,
    selectedDeviceId,
    isCostViewSelected,
  } = state;

  const {
    data: usageData,
    isLoading: isUsageLoading,
    isFetching: isUsageFetching,
    isError: isUsageError,
  } = useGetUsageWithCostsQuery(rangeTypeToSelectedPeriod[selectedDateRangeType], selectedDateRangeType, false, false);
  const { energyUsageData, powerUsageData, displayedDevices, energyIndependencePercentage } = usageData;

  const {
    data: costsData,
    isLoading: isCostsLoading,
    isFetching: isCostsFetching,
    isError: isCostsError,
  } = useGetCostsQuery(rangeTypeToSelectedPeriod[selectedDateRangeType], selectedDateRangeType);
  const { costData } = costsData;

  const { data: savingsData, isLoading: isSavingsLoading } = useGetSavingsQuery(
    rangeTypeToSelectedPeriod[selectedDateRangeType],
    selectedDateRangeType
  );

  const isError = isDevicesError || isUsageError || isCostsError;
  const isLoaded = !isUsageFetching && !isUsageLoading && !isCostsFetching && !isCostsLoading && !isSavingsLoading;

  const title = useMemo(
    () =>
      source ? t(`${capitalizeFirst(source.toLowerCase())}.${source.toLowerCase()} activity`) : t('Activity.activity'),
    [source]
  );

  useEffect(() => {
    // We use a separate `isLoaded` value to RTK query to ensure we select a device after the data has loaded.
    // Whenever the displayed devices change, we want to update the selected device to the first one in the list.
    if (isLoaded) {
      setState((prevState) => ({
        ...prevState,
        selectedDeviceId: displayedDevices[0]?.appliance_id ?? 0,
        selectedDeviceAssignment: displayedDevices[0]?.assignment,
      }));
    }
  }, [displayedDevices, isLoaded]);

  async function handleChangeSelectedDate(newDate: Date | null) {
    setState((prevState) => ({
      ...prevState,
      rangeTypeToSelectedPeriod: {
        ...rangeTypeToSelectedPeriod,
        [selectedDateRangeType]: newDate,
      },
    }));
  }

  async function handleChangeSelectedDateRangeType(newDateRangeType: DateRangeType) {
    // Ensure cost chart is only available on aggregate date range types
    setState((prevState) => ({
      ...prevState,
      selectedDateRangeType: newDateRangeType,
      isCostViewSelected: newDateRangeType === DateRangeType.Day ? false : prevState.isCostViewSelected,
    }));
  }

  const handleSelectChart = (isCostViewSelected: boolean) => {
    setState((prevState) => ({ ...prevState, isCostViewSelected }));
  };

  const handleSelectedPowerSources = (property: ActivityDataSource, value: boolean) => {
    setState((prevState) => ({ ...prevState, selectedPowerSources: { ...selectedPowerSources, [property]: value } }));
  };

  const handleSelectDevice = (selectedDeviceId: number, selectedDeviceAssignment: string) => {
    setState((prevState) => ({ ...prevState, selectedDeviceId, selectedDeviceAssignment }));
  };

  const handleToggleDeviceDisplay = () => {
    setState((prevState) => ({ ...prevState, isSelectedDeviceDisplayedInChart: !isSelectedDeviceDisplayedInChart }));
  };

  return (
    <SlidingAnimationPageBase
      includeBottomNav={!source}
      includeTopNavHamButton={!source}
      backURL={source ? `../${source}/home?direction=back` : undefined}
      title={title}
      includeSchneiderHamButton={!source}
    >
      <Box px={3}>
        {display.dateRange && source !== 'battery' && (
          <DateRangeTypePicker
            dateRangeOptions={options?.dateRange ?? DATE_RANGE_OPTIONS}
            selectedDateRangeType={selectedDateRangeType}
            setSelectedDateRangeType={handleChangeSelectedDateRangeType}
          />
        )}

        {display.datePicker && (
          <ActivityDatePicker
            isLoaded={isLoaded}
            selectedDateRangeType={selectedDateRangeType}
            rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
            onChangeDate={handleChangeSelectedDate}
          />
        )}

        {display.summaryCards && (
          <ActivitySummaryCards
            isLoaded={isLoaded}
            costData={costData}
            savingsData={savingsData}
            energyUsageData={energyUsageData}
            powerUsageData={powerUsageData}
            isCostViewSelected={isCostViewSelected}
            energyIndependencePercentage={energyIndependencePercentage}
            selectedDateRangeType={selectedDateRangeType}
          />
        )}

        {display.activityChart && (
          <ChartSwitcher
            state={state}
            isLoaded={isLoaded}
            isError={isError}
            costData={costData}
            savingsData={savingsData}
            energyUsageData={energyUsageData}
            powerUsageData={powerUsageData}
            onChartChange={handleSelectChart}
          />
        )}

        {/* Display the SOC chart when in day view for battery activity */}
        {display.batteryChart &&
          selectedDateRangeType === DateRangeType.Day &&
          source === 'battery' &&
          !!powerUsageData.length && (
            <BatterySOCChart
              isLoaded={isLoaded}
              powerUsageData={powerUsageData}
              selectedDate={rangeTypeToSelectedPeriod[selectedDateRangeType]}
            />
          )}
      </Box>

      <Box id="filters-list">
        {display.sourcesToggle && !isCostViewSelected && !source && (
          <PowerSourceSelect
            powerSources={
              options?.powerSources ?? {
                solar: true,
                grid: true,
                battery: selectedDateRangeType === DateRangeType.Day,
              }
            }
            selectedDateRangeType={selectedDateRangeType}
            selectedPowerSources={selectedPowerSources}
            onSetSelectedPowerSources={handleSelectedPowerSources}
          />
        )}

        {/* Only show appliance-level data if the site has a meter */}
        {display.devicesList && siteHasMeter && !source && !isError && (
          <FilterByDevice
            displayedDevices={displayedDevices}
            selectedDateRangeType={selectedDateRangeType}
            selectedDeviceId={selectedDeviceId}
            isLoaded={isLoaded}
            onSelectDevice={handleSelectDevice}
            isSelectedDeviceDisplayedInChart={isSelectedDeviceDisplayedInChart}
            onToggleDeviceDisplay={handleToggleDeviceDisplay}
          />
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
