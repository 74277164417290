import React from 'react';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SEHomeCard } from '../../common/components/SEHomeCard';
import { selectSite } from '../site/siteSlice';
import { NotificationListItem } from './NotificationListItem';
import { NotificationGrouping, useGetNotifications } from './notificationsApi';
import { NotificationsListSkeletonLoader } from './NotificationsListSkeletonLoader';

type Props = {
  groupBy: NotificationGrouping;
  isArchived?: boolean;
};

export function NotificationsListPriorityStatus({ groupBy, isArchived = false }: Props) {
  const textColor = useColorModeValue('#111111', '#EEEEEE');
  const {
    data: { alarm, info, warning },
    isLoading,
    isError,
  } = useGetNotifications(groupBy, { limit: 10, offset: 0, archived: isArchived });
  const navigate = useNavigate();
  const site = useSelector(selectSite);
  const { t } = useTranslation();
  const alarmNotifications = alarm?.data?.results;
  const totalNumAlarmNotifications = alarm?.data?.count;
  const infoNotifications = info?.data?.results;
  const totalNumInfoNotifications = info?.data?.count;
  const warningNotifications = warning?.data?.results;
  const totalNumWarningNotifications = warning?.data?.count;

  const alarmNotificationsList = isLoading ? (
    <NotificationsListSkeletonLoader />
  ) : (
    alarmNotifications?.map((notification, notificationIndex) => (
      <NotificationListItem notification={notification} key={notificationIndex} isArchived={isArchived} />
    ))
  );
  const infoNotificationsList = isLoading ? (
    <NotificationsListSkeletonLoader />
  ) : (
    infoNotifications?.map((notification, notificationIndex) => (
      <NotificationListItem notification={notification} key={notificationIndex} isArchived={isArchived} />
    ))
  );
  const warningNotificationsList = isLoading ? (
    <NotificationsListSkeletonLoader />
  ) : (
    warningNotifications?.map((notification, notificationIndex) => (
      <NotificationListItem notification={notification} key={notificationIndex} isArchived={isArchived} />
    ))
  );

  if (isError) {
    return (
      <Alert status="error" my={4}>
        <AlertIcon w={8} h={8} />
        <AlertDescription py={4}>
          {t('Notifications.error fetching notifications')}.{' '}
          {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <Box>
      <Text data-testid="list-type-title-alarm" color={textColor}>
        {t('Notifications.alarm').toUpperCase()}
      </Text>
      <Box data-testid="notifications-list-alarm" mb={3}>
        {!alarmNotifications?.length && !isLoading ? (
          <Center data-testid="no-alarm-notifications" my={4}>
            <Text>{t('Notifications.no type notifications', { type: 'alarm' })}.</Text>
          </Center>
        ) : (
          alarmNotificationsList
        )}

        {!!totalNumAlarmNotifications && totalNumAlarmNotifications > 10 && (
          <SEHomeCard
            mt={2}
            cursor="pointer"
            onClick={() =>
              navigate(
                `/sites/${site.site_id}/notifications/list_by_type/alarm?archived=${isArchived}?direction=forward`
              )
            }
            borderTopLeftRadius="5px"
            mb={2}
            as={'button'}
            w="100%"
            data-testid="view-all-alarm-notifications-btn"
          >
            <Flex justify="space-between" align="center">
              <Text>{t('Common.view all')}</Text>
              <ChevronRightIcon color={'customGrey.400'} w={'26px'} h={'26px'} />
            </Flex>
          </SEHomeCard>
        )}
      </Box>
      <Text data-testid="list-type-title-warning" color={textColor}>
        {t('Notifications.warning').toUpperCase()}
      </Text>
      <Box data-testid="notifications-list-warning" mb={3}>
        {!warningNotifications?.length && !isLoading ? (
          <Center data-testid="no-warning-notifications" my={4}>
            <Text>{t('Notifications.no type notifications', { type: 'warning' })}.</Text>
          </Center>
        ) : (
          warningNotificationsList
        )}

        {!!totalNumWarningNotifications && totalNumWarningNotifications > 10 && (
          <SEHomeCard
            mt={2}
            cursor="pointer"
            onClick={() =>
              navigate(
                `/sites/${site.site_id}/notifications/list_by_type/warning?archived=${isArchived}?direction=forward`
              )
            }
            borderTopLeftRadius="5px"
            mb={2}
            as={'button'}
            w="100%"
            data-testid="view-all-warning-notifications-btn"
          >
            <Flex justify="space-between" align="center">
              <Text>{t('Common.view all')}</Text>
              <ChevronRightIcon color={'customGrey.400'} w={'26px'} h={'26px'} />
            </Flex>
          </SEHomeCard>
        )}
      </Box>
      <Text data-testid="list-type-title-info" color={textColor}>
        {t('Notifications.info').toUpperCase()}
      </Text>
      <Box data-testid="notifications-list-info">
        {!infoNotifications?.length && !isLoading ? (
          <Center data-testid="no-warning-notifications" my={4}>
            <Text>{t('Notifications.no type notifications', { type: 'info' })}.</Text>
          </Center>
        ) : (
          infoNotificationsList
        )}

        {!!totalNumInfoNotifications && totalNumInfoNotifications > 10 && (
          <SEHomeCard
            mt={2}
            cursor="pointer"
            onClick={() =>
              navigate(
                `/sites/${site.site_id}/notifications/list_by_type/info?archived=${isArchived}?direction=forward`
              )
            }
            borderTopLeftRadius="5px"
            mb={2}
            as={'button'}
            w="100%"
            data-testid="view-all-info-notifications-btn"
          >
            <Flex justify="space-between" align="center">
              <Text>{t('Common.view all')}</Text>
              <ChevronRightIcon color={'customGrey.400'} w={'26px'} h={'26px'} />
            </Flex>
          </SEHomeCard>
        )}
      </Box>
    </Box>
  );
}
