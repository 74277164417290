import React, { useMemo } from 'react';
import { Center, Heading, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import semPowerImg from '../../../../assets/images/activate_sem.svg';
import semPowerCycleGuideImg from '../../../../assets/images/sem_power_cycle_guide.jpg';
import { ArcButton } from '../../../../common/components/ArcButton';
import { SlidingAnimationPageBase } from '../../../../common/components/SlidingAnimationPageBase';

export function MeterPowerUpInstructions() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { t } = useTranslation();
  const serialNumberFromParams = search.get('serialNumber');

  const backURL = useMemo(() => {
    const deviceId = search.get('deviceId');
    // If we have a deviceId, we are coming from the meter details page
    return deviceId ? `../../../${deviceId}/view?direction=back` : `../start?direction=back`;
  }, [search]);

  const handleClickContinue = () => {
    search.set('direction', 'forward');
    // If know the serial number, we can skip past the connection instructions and go -
    // - to the manual serial entry screen prefilled with the serial number. This can happen if:
    // a) user has previously entered a serial number and navigated back to this screen
    // b) user was redirected back here on a failed registration attempt
    // c) user arrived here from the meter details page
    if (serialNumberFromParams) {
      navigate(`../manual_serial_entry?${search.toString()}`);
    } else {
      navigate(`../connect_instructions?${search.toString()}`);
    }
  };

  return (
    <SlidingAnimationPageBase title={t('Common.device setup')} backURL={backURL}>
      <Center flexDirection="column" px={3} my={1} textAlign="center">
        <Image w={'70%'} src={semPowerImg} alt="energy monitor power icon" />

        <Heading mt={2}>{t('Set Up Hardware.power cycle sem title')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.power cycle sem text')}</Text>
        <Image borderRadius="5%" w={'70%'} my={2} src={semPowerCycleGuideImg} alt="energy monitor power cycle guide" />

        <ArcButton data-testid={'continue-btn'} onClick={handleClickContinue} mt={5} w={'80%'} arcColor="#3DCD57">
          {t('Common.continue')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
