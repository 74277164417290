import React from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Grid,
  GridItem,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';

import { IS_DEMO_LOGIN, TOP_BANNER_SPACING_AFFORDANCE } from '../../../../common/constants';

export function PrivacyNoticeModal({ isOpen, onClose }: DialogProps) {
  const bgColor = useColorModeValue('#fff', '#111');
  const linkColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const { t } = useTranslation();

  return (
    <Modal scrollBehavior={'inside'} isOpen={isOpen} onClose={onClose} size="full" motionPreset="slideInRight">
      <ModalContent
        data-testid="privacy-notice-modal"
        backgroundColor={bgColor}
        top={IS_DEMO_LOGIN ? TOP_BANNER_SPACING_AFFORDANCE : 'env(safe-area-inset-top)'}
      >
        <ModalHeader mx={'auto'} fontSize={22} fontWeight={500}>
          {t('Privacy Notice.privacy notice')}
        </ModalHeader>
        <ModalCloseButton
          position={'absolute'}
          left={6}
          mt={1.5}
          fontSize={'17px'}
          data-testid="privacy-notice-back-button"
          onClick={onClose}
        >
          <ChevronLeftIcon w={'26px'} h={'26px'} />
        </ModalCloseButton>
        <ModalBody>
          <Box mb={16}>
            <Text my={2}>{t('Privacy Notice.we commit to')}</Text>
            <Text my={2}>
              {t('Privacy Notice.our')}{' '}
              <Link
                fontWeight={600}
                color={linkColor}
                textDecoration="underline"
                isExternal
                data-testid="data-privacy-link"
                href={'https://www.se.com/us/en/about-us/legal/data-privacy.jsp'}
              >
                {t('Privacy Notice.schneider electric data privacy policy')}
              </Link>{' '}
              {t('Privacy Notice.explains how and why')} {t('Privacy Notice.this notice provides')}
            </Text>

            <Heading fontSize={18} my={2} mt={4}>
              1. {t('Privacy Notice.what is the scope')}
            </Heading>
            <Text my={2}>{t('Privacy Notice.schneider home is a')}</Text>
            <Text my={3}>{t('Privacy Notice.we encourage you')}</Text>

            <Heading fontSize={18} my={2} mt={4}>
              2. {t('Privacy Notice.who oversees')}
            </Heading>
            <Text my={2}>
              {t('Privacy Notice.the processing of')} {t('Privacy Notice.we may share')}
            </Text>

            <Heading fontSize={18} my={2} mt={4}>
              3. {t('Privacy Notice.what types of')}
            </Heading>
            <Text my={2} mb={4}>
              {t('Privacy Notice.we process the')}
            </Text>
            <Box overflow="scroll">
              <Box minWidth="300px">
                <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(7, 1fr)" fontSize="13px">
                  <GridItem colSpan={1} fontWeight="bold">
                    {t('Privacy Notice.categories of')}
                  </GridItem>
                  <GridItem colSpan={1} pl={1} fontWeight="bold">
                    {t('Privacy Notice.source')}
                  </GridItem>
                  <GridItem colSpan={4} pl={4} mx="auto" fontWeight="bold">
                    {t('Privacy Notice.reasons for use')}
                  </GridItem>
                  <GridItem colSpan={1} fontWeight="bold">
                    {t('Privacy Notice.retention')}
                  </GridItem>
                </Grid>
                <Divider my={1} />
                <Grid gap={2} templateRows="repeat(3, 1fr)" templateColumns="repeat(7, 1fr)" fontSize="13px">
                  <GridItem rowSpan={3} colSpan={7}>
                    <Grid templateRows="repeat(3, 1fr)" templateColumns="repeat(7, 1fr)" fontSize="13px">
                      <GridItem colSpan={2} rowSpan={3}>
                        <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(2, 1fr)" fontSize="13px">
                          <GridItem>{t('Privacy Notice.contact info')}</GridItem>
                          <GridItem pl={2}>{t('Privacy Notice.you')}</GridItem>
                        </Grid>
                        <Divider my={1} px={7} />
                        <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(2, 1fr)" fontSize="13px">
                          <GridItem>{t('Privacy Notice.iot and sensor data')}</GridItem>
                          <GridItem pl={3}>{t('Privacy Notice.smart home devices')}</GridItem>
                        </Grid>
                        <Divider my={1} px={7} />
                        <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(2, 1fr)" fontSize="13px">
                          <GridItem>{t('Privacy Notice.inferred and derived')}</GridItem>
                          <GridItem pl={1}>{t('Privacy Notice.generated based on')}</GridItem>
                        </Grid>
                      </GridItem>
                      <GridItem colSpan={4} rowSpan={3} pl={16}>
                        {t('Privacy Notice.to manage the account')} {t('Privacy Notice.for contract management')}{' '}
                        {t('Privacy Notice.for our business purposes')} {t('Privacy Notice.to understand and analyze')}{' '}
                        {t('Privacy Notice.to ensure the')}
                        {t('Privacy Notice.this is based on our')}{' '}
                        {t('Privacy Notice.we will also ask for your consent')}
                      </GridItem>
                      <GridItem pl={1} colSpan={1} rowSpan={3}>
                        {t('Privacy Notice.energy data is retained')}
                      </GridItem>
                    </Grid>
                    <Divider my={1} />
                  </GridItem>
                </Grid>
                <Grid gap={2} templateRows="repeat(1, 1fr)" templateColumns="repeat(7, 1fr)" fontSize="13px">
                  <GridItem rowSpan={1} colSpan={7}>
                    <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(7, 1fr)" fontSize="13px">
                      <GridItem colSpan={1} overflowWrap="normal">
                        {t('Privacy Notice.user preferences')}
                      </GridItem>
                      <GridItem colSpan={1} pl={2}>
                        {t('Privacy Notice.you')}
                      </GridItem>
                      <GridItem colSpan={4} pl={12}>
                        {t('Privacy Notice.to enable product')}
                      </GridItem>
                      <GridItem colSpan={1} mb={2}>
                        {t('Privacy Notice.for the lifetime')}
                      </GridItem>
                    </Grid>
                    <Divider />
                  </GridItem>
                </Grid>
              </Box>
            </Box>
            <Text my={2}>
              {t('Privacy Notice.we may collect and use')}
              {t('Privacy Notice.depending on the circumstance')}
            </Text>
            <Text my={2}>{t('Privacy Notice.we use info to provide')}</Text>
            <Text my={2}>{t('Privacy Notice.this is based on our legitimate')}</Text>
            <Text my={2}>{t('Privacy Notice.we will also ask for')}</Text>
            <Text my={2}>
              {t('Privacy Notice.we retain personal data')} {t('Privacy Notice.data retention per data')}
            </Text>
            <Heading fontSize={18} my={2} mt={4}>
              4. {t('Privacy Notice.who do we disclose')}
            </Heading>
            <Text my={2}>
              {t('Privacy Notice.personal information will be')} {t('Privacy Notice.we take measures to')}
              {t('Privacy Notice.we do not sell')}
            </Text>
            <Text my={2}>
              {t('Privacy Notice.our subsidiaries and suppliers')} {t('Privacy Notice.as we are a global company')}
            </Text>
            <Text my={2}>
              {t('Privacy Notice.we have internal policies')} {t('Privacy Notice.our group has adopted')}{' '}
              {t('Privacy Notice.in addition we enter')} {t('Privacy Notice.to obtain more details')}{' '}
              <Link
                data-testid={'support-email'}
                isExternal
                href="mailto:global-data-privacy@schneider-electric.com"
                textDecoration="underline"
              >
                global-data-privacy@schneider-electric.com
              </Link>
              .
            </Text>
            <Text my={2}>{t('Privacy Notice.we may also disclose')}</Text>
            <Text ml={4} my={1}>
              - {t('Privacy Notice.to competent authorities')}
            </Text>
            <Text ml={4} my={1}>
              - {t('Privacy Notice.to defend our legal rights')}
            </Text>
            <Text ml={4} my={1}>
              - {t('Privacy Notice.as instructed by you')}
            </Text>
            <Text ml={4} my={1}>
              - {t('Privacy Notice.to third parties in the context')}
            </Text>
            <Text ml={4} my={1}>
              - {t('Privacy Notice.in other ways described')}
            </Text>
            <Text my={2}>
              {t('Privacy Notice.if you authorize third parties')} {t('Privacy Notice.we do not control these')}{' '}
              {t('Privacy Notice.you should review their')}
            </Text>
            <Text fontWeight="bold" my={2}>
              {t('Privacy Notice.google analytics')}
            </Text>
            <Text my={2}>
              {t('Privacy Notice.this service includes google')} {t('Privacy Notice.google publishes info')}
              <Link
                ml={1}
                fontWeight={600}
                color={linkColor}
                textDecoration="underline"
                isExternal
                data-testid="partner-sites-link"
                href={'https://www.google.com/policies/technologies/partner-sites'}
              >
                https://www.google.com/policies/technologies/partner-sites
              </Link>
            </Text>
            <Text my={2}>{t('Privacy Notice.google analytics features are used')}</Text>
            <Heading fontSize={18} my={2} mt={4}>
              5. {t('Privacy Notice.how do we secure')}
            </Heading>
            <Text my={2}>{t('Privacy Notice.we follow accepted')}</Text>
            <Text my={2}>
              {t('Privacy Notice.however no method')} {t('Privacy Notice.therefore we cannot')}
            </Text>
            <Heading fontSize={18} my={2} mt={4}>
              6. {t('Privacy Notice.how to exercise')}
            </Heading>
            <Text my={2}>{t('Privacy Notice.to exercise your choices')}</Text>
            <Text my={2} ml={4}>
              - {t('Privacy Notice.make a request using')}
              <Link
                ml={1}
                data-testid={'support-email'}
                isExternal
                href="mailto:global-data-privacy@schneider-electric.com"
                textDecoration="underline"
              >
                global-data-privacy@schneider-electric.com
              </Link>
              ). {t('Privacy Notice.to ensure the security')}
            </Text>
            <Text my={2}>
              {t('Privacy Notice.please understand that')} {t('Privacy Notice.for example we may require')}
            </Text>
            <Text my={2}>{t('Privacy Notice.our policy contains additional')}</Text>
            <Text my={2}>
              {t('Privacy Notice.for any suggestion')} {t('Privacy Notice.you may address questions')}
            </Text>
            <Link
              mt={2}
              data-testid={'support-email'}
              isExternal
              href="mailto:dpo@schneider-electric.com"
              textDecoration="underline"
            >
              dpo@schneider-electric.com
            </Link>
            <Text>DPO Schneider Electric</Text>
            <Text>35 rue Joseph Monier</Text>
            <Text>CS 30323</Text>
            <Text>92506 Rueil Malmaison</Text>
            <Text>France</Text>
            <Text my={2}>
              {t('Privacy Notice.if you believe')} {t('Privacy Notice.last updated')}
            </Text>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
