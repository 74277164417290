import React, { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { SEHomeCard } from '../../../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../../../common/components/SlidingAnimationPageBase';
import { AccountMenuButton } from '../../AccountMenuButton';
import { toURL, useLicenses } from './useLicenses';

export function LicensesPackagesList() {
  const { publisherId } = useParams<{ publisherId: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const licenses = useLicenses();

  const publisherLicenses = useMemo(
    () => licenses.find((license) => license.publisherId === toURL(publisherId))?.licenses ?? [],
    [licenses, publisherId]
  );

  return (
    <SlidingAnimationPageBase title={t('Account.packages')} backURL={`..?direction=back`}>
      <Box px={5}>
        <SEHomeCard pr={0} pl={2} py={0} mb={4} borderTopLeftRadius="5px">
          {publisherLicenses.map(({ packageId, packageName }, index) => (
            <AccountMenuButton
              key={packageId}
              includeBottomDivider={index !== publisherLicenses.length - 1}
              data-testid={`license-package-button-${packageId}`}
              onClick={() => {
                navigate(`${packageId}`);
              }}
            >
              <Text fontSize={16} fontWeight="normal" overflow="auto">
                {packageName}
              </Text>
            </AccountMenuButton>
          ))}
        </SEHomeCard>
      </Box>
    </SlidingAnimationPageBase>
  );
}
