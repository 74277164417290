import { useEffect } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

import { VersionCheckResult } from 'clipsal-cortex-types/src/api/api-version-check-result';
import { useServiceWorker } from 'clipsal-cortex-utils/src/providers/service-worker-provider';

import { post } from '../api/api-helpers';
import { APP_VERSION, IS_NATIVE } from '../constants';

export function useAppUpdate() {
  const navigate = useNavigate();
  const { isUpdateRequired, onReloadPage } = useServiceWorker();

  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    async function redirectIfUpdateRequired() {
      try {
        const response = await post<VersionCheckResult>('/v1/version_check', {
          platform: Capacitor.getPlatform().toUpperCase(),
          version: APP_VERSION,
          app_name: 'SE_HOME',
        });
        if (response.update_required) {
          navigate('/update-required', { replace: true });
        }
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
    }

    /* istanbul ignore next -- @preserve */
    if (IS_NATIVE) {
      redirectIfUpdateRequired();
      CapacitorApp.addListener('appStateChange', (state) => {
        if (state.isActive) {
          redirectIfUpdateRequired();
        }
      });
    } else if (isUpdateRequired) {
      onReloadPage();
    }
  }, []);
}
