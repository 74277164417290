import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Heading,
  SkeletonCircle,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BatteryReserve } from 'clipsal-cortex-types/src/api';
import { CircularSlider } from 'clipsal-cortex-ui/src/components/circular-slider/CircularSlider';

import { ArcButton } from '../../common/components/ArcButton';
import { SlidingAnimationPageBase } from '../../common/components/SlidingAnimationPageBase';
import { createBatteryReserveIcon } from '../../styles/create-battery-reserve-icon';
import { useGetBatteryId, useGetBatteryReserve, useUpdateBatteryReserve } from './batteryReserveApi';

export function SetReserveCapacity() {
  const { batteryId } = useGetBatteryId();
  const { t } = useTranslation();
  const trackColor = useColorModeValue('#eee', '#363E40');
  const knobColor = useColorModeValue('#bbb', '#fff');
  const toast = useToast({ isClosable: true });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [reserveValue, setReserveValue] = useState(0);
  const BatteryReserveIcon = createBatteryReserveIcon(Math.round(reserveValue));
  const [hasPendingValue, setHasPendingValue] = useState(false);
  const pollingInterval = hasPendingValue ? 3000 : 0;
  const { data: batteryReserve, isLoading, isError } = useGetBatteryReserve({ pollingInterval });
  const [saveReserveCapacity, { isLoading: isUpdateLoading }] = useUpdateBatteryReserve();

  // A re-render is required to update the polling interval when a pending value exists -- we can't use the state from
  // the query itself, as this creates a circular reference, so state tracks the pending value.
  useEffect(() => {
    if (batteryReserve && !isLoading && !isError) {
      setHasPendingValue('pending' in batteryReserve ? !!batteryReserve.pending : false);
    }
  }, [batteryReserve, isLoading, isError]);

  useEffect(() => {
    if (batteryReserve && !isLoading && !isError) {
      if ('pending' in batteryReserve) {
        const reserve = batteryReserve as BatteryReserve;
        setReserveValue(reserve.pending?.reserve_percentage || 0);
      } else {
        setReserveValue(batteryReserve.reserve_percentage || 0);
      }
    }
  }, [batteryReserve, isLoading, isError]);

  async function handleSaveReserveCapacity() {
    try {
      await saveReserveCapacity({
        deviceId: batteryId!,
        body: { reserve_percentage: reserveValue },
      }).unwrap();
      toast({
        title: t('Common.success'),
        status: 'success',
      });
    } catch (e) {
      toast({
        title: t('Battery.error updating reserve capacity'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
    setIsButtonDisabled(true);
  }

  if (isError) {
    return (
      <SlidingAnimationPageBase px={2} backURL={`../home?direction=back`} title={'Reserve Capacity'}>
        <Alert status="error" variant="left-accent">
          <AlertIcon />
          {t('Battery.error fetching reserve capacity')}.{' '}
          {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}
        </Alert>
      </SlidingAnimationPageBase>
    );
  }

  return (
    // Pull to refresh is disabled for this page as it interferes with the slider
    <SlidingAnimationPageBase
      backURL={`../home?direction=back`}
      title={t('Battery.reserve capacity')}
      disablePullToRefresh={true}
    >
      <Center mt={1} px={3} flexDirection="column">
        <SkeletonCircle isLoaded={!isLoading} h="100%" w="auto">
          <Center
            pos="relative"
            data-testid="battery-reserve-slider"
            id="reserve-capacity-slider-container"
            opacity={hasPendingValue ? 0.6 : 1}
          >
            <CircularSlider
              knobColor={knobColor}
              trackColor={trackColor}
              value={reserveValue}
              isDisabled={hasPendingValue}
              preventIndefiniteSliding
              onChange={(newValue) => {
                setReserveValue(newValue);
                setIsButtonDisabled(false);
              }}
            />

            <Box data-testid="four-quarters" pos={'absolute'} top="0" right={'50%'} />
            <Box data-testid="zero" pos={'absolute'} top="0" right={'49%'} />
            <Box data-testid="one-quarter" pos={'absolute'} top="50%" right={0} />
            <Box data-testid="three-quarter" pos={'absolute'} top="50%" left={0} />

            <Center pos="absolute">
              <BatteryReserveIcon data-testid="battery-reserve-icon" w={'130px'} h={'130px'} />
            </Center>
            <Center pos="absolute">
              <Text fontWeight={600}>{reserveValue}%</Text>
            </Center>
          </Center>
        </SkeletonCircle>

        <Heading textAlign="center" mt={5} size="lg">
          {t('Battery.set reserve capacity')}
        </Heading>
        <Text textAlign="center" mt={1} mb={8}>
          {t('Battery.set your minimum reserve')}.
        </Text>

        <ArcButton
          isDisabled={isLoading || hasPendingValue || isButtonDisabled}
          isLoading={isUpdateLoading || hasPendingValue}
          data-testid="save-battery-reserve-button"
          w={'80%'}
          arcColor="#3DCD57"
          onClick={handleSaveReserveCapacity}
        >
          {t('Common.save')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
