import React, { useEffect, useMemo } from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { toZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { BsThreeDots } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { formatDateTime } from 'clipsal-cortex-utils/src/formatting/formatting';

import { OptionSelectAlertDrawer } from '../../../common/components/OptionSelectAlertDrawer';
import { SEHomeCard } from '../../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { useParamBackUrl } from '../../../common/hooks/use-param-back-url';
import { selectInverters, selectSite } from '../../site/siteSlice';
import { CustomVppIcon } from './CustomVPPIcon';
import { getProgramByStatus } from './helpers';
import { useGetVppEvents, useGetVppProgramsStatus } from './vppApi';
import { VPPHelpGuide } from './VPPHelpGuide';

export function ActiveProgram() {
  const { timezone } = useSelector(selectSite);
  const [{ id: inverterId }] = useSelector(selectInverters);
  const {
    data: programsStatus,
    isLoading: isProgramsStatusLoading,
    isError: isProgramStatusError,
  } = useGetVppProgramsStatus(inverterId);
  const { data: eventsData, isLoading: isVppEventsLoading, isError: isEventsError } = useGetVppEvents(inverterId);
  const isLoaded = !isProgramsStatusLoading && !isVppEventsLoading;
  const isError = isProgramStatusError || isEventsError;
  const program = useMemo(() => getProgramByStatus(programsStatus, 'Enrolled'), [programsStatus]);
  const programEventsData = useMemo(
    () => eventsData?.find((equipmentAndEvents) => program?.equipment.m_rid === equipmentAndEvents.m_rid),
    [program, eventsData]
  );

  const textColor = useColorModeValue('customGrey.900', 'customGrey.300');
  const inactiveEventiconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const activeEventIconColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const bgColor = useColorModeValue('#fff', '#111');
  const { isOpen: isOptionDrawerOpen, onOpen: onOpenOptionDrawer, onClose: onCloseOptionDrawer } = useDisclosure();
  const {
    isOpen: isLearnMoreModalOpen,
    onOpen: onLearnMoreModalOpen,
    onClose: onCloseLearnMoreModal,
  } = useDisclosure();
  const { t } = useTranslation();
  const toast = useToast({ duration: 3000, isClosable: true });
  const backURL = useParamBackUrl('../../../account/dashboard?direction=back');

  // TODO: Update below when the API provides the event status
  const isVppEventInProgress = false;

  function handleSelectOptionDrawerOption(value: string) {
    if (value === 'LEARN_MORE') {
      onLearnMoreModalOpen();
    }
    onCloseOptionDrawer();
  }

  useEffect(() => {
    if (isLoaded) {
      if (isError) {
        toast.closeAll();
        toast({
          title: t('VPP.error getting vpp data'),
          description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
          status: 'error',
        });
      }
    }
  }, [isLoaded, isError]);

  return (
    <SlidingAnimationPageBase mx={4} mb={4} backURL={backURL} title={t('VPP.title')}>
      <Box data-testid="active-program">
        <Flex position="absolute" right={0}>
          <IconButton
            aria-label="vpp settings"
            variant="unstyled"
            height="auto"
            icon={<BsThreeDots size={26} />}
            data-testid="three-dots-btn"
            onClick={onOpenOptionDrawer}
          />
        </Flex>
        <OptionSelectAlertDrawer
          isOpen={isOptionDrawerOpen}
          onClose={onCloseOptionDrawer}
          onSelectOption={handleSelectOptionDrawerOption}
          options={[
            {
              value: 'LEARN_MORE',
              label: t('VPP.learn more'),
            },
          ]}
          title={t('VPP.more about vpp')}
        />
        <CustomVppIcon
          isLoaded={isLoaded}
          iconColor={isVppEventInProgress ? activeEventIconColor : inactiveEventiconColor}
        />

        <Heading size="lg" textAlign="center" my={2}>
          {program?.equipment_program?.program.name}
        </Heading>

        <Text textAlign="center" color={textColor} lineHeight={1.2}>
          {isVppEventInProgress ? t('VPP.participating subtitle') : t('VPP.not participating subtitle')}
        </Text>
        <Text textAlign="center" color={textColor} mb={4} lineHeight={1.2}>
          {isVppEventInProgress ? t('VPP.participating description') : t('VPP.not participating description')}
        </Text>

        <Divider my={2} />

        {/* TODO: Add current event below when the API has these available */}
        {/* <Text>{t('VPP.current event').toUpperCase()}</Text>
      <SimpleGrid gridGap={5} mt={2} mb={4} minChildWidth="45%">
        <DataSummaryCard title="Start Time" isLoaded={!isLoading} value="12:00" suffix={'PM'} />
        <DataSummaryCard title={'End Time'} isLoaded={!isLoading} value={'6:00'} suffix={'PM'} />
      </SimpleGrid>
      <Divider my={2} /> */}

        <Text>{t('VPP.upcoming events').toUpperCase()}</Text>
        {!isLoaded ? (
          <CenteredLoader text={`${t('Common.loading')}...`} mt={12} />
        ) : (
          programEventsData?.vpp_events.map((event) => (
            <SEHomeCard
              cursor="pointer"
              p={4}
              borderTopLeftRadius="5px"
              mb={2}
              key={event.m_rid}
              data-testid="event-card"
            >
              <Flex align="center">
                <Center marginRight="auto">
                  <Flex direction="column">
                    <Text fontSize={17} fontWeight={400} lineHeight={1.2} overflowWrap="anywhere">
                      {programEventsData.name}
                    </Text>
                    <Text fontSize={15} color={textColor} fontWeight={400} lineHeight={1.1}>
                      {formatDateTime(toZonedTime(new Date(event.interval.start), timezone))}
                    </Text>
                  </Flex>
                </Center>
              </Flex>
            </SEHomeCard>
          ))
        )}

        <Modal
          scrollBehavior={'inside'}
          isOpen={isLearnMoreModalOpen}
          onClose={onCloseLearnMoreModal}
          size="full"
          motionPreset="slideInRight"
        >
          <ModalContent data-testid="learn-more-modal" backgroundColor={bgColor} top={'env(safe-area-inset-top)'}>
            <ModalCloseButton
              position={'absolute'}
              left={6}
              mt={1.5}
              fontSize={'17px'}
              data-testid="learn-more-modal-close-button"
              onClick={onCloseLearnMoreModal}
            >
              <ChevronLeftIcon w={'26px'} h={'26px'} />
            </ModalCloseButton>
            <ModalBody>
              <VPPHelpGuide />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </SlidingAnimationPageBase>
  );
}
