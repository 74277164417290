import React, { useState } from 'react';
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';
import { OffsetTimePicker } from 'clipsal-cortex-ui/src/components/time-picker/OffsetTimePicker';

import { useReduxDispatch } from '../../../../app/store';
import { selectSchedules, setSchedulerState } from './schedulerSlice';
import { formatScheduleOffset } from './utils';

type Props = { scheduleIndex: number; scheduleType: 'SUNRISE' | 'SUNSET' } & DialogProps;

type OffsetSelectState = {
  hours: string;
  minutes: string;
};

export function OffsetTimeSelectDrawer({ onClose, isOpen, scheduleIndex, scheduleType }: Props) {
  const dispatch = useReduxDispatch();
  const { schedules, timeEditType } = useSelector(selectSchedules);
  const selectedSchedule = schedules[scheduleIndex];
  const initialState = getInitialState();
  const [state, setState] = useState<OffsetSelectState>(initialState);
  const { hours, minutes } = state;
  const timePickerGradientColor = useColorModeValue('253, 253, 253', '41, 49, 51');
  const timePickerBorderColor = useColorModeValue('#293133', '#fff');
  const bgColor = useColorModeValue('#fff', '#293133');
  const titleColor = useColorModeValue('customGrey.600', 'customGrey.400');
  const buttonTextColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const { t } = useTranslation();

  function getInitialState() {
    const isDifferentScheduleType = selectedSchedule[`${timeEditType!}SubType`] !== scheduleType;
    if ((!selectedSchedule.startOffset && !selectedSchedule.endOffset) || isDifferentScheduleType) {
      return { hours: '0', minutes: '00' };
    }
    const offsetTime =
      timeEditType === 'start'
        ? formatScheduleOffset(selectedSchedule.startOffset)
        : formatScheduleOffset(selectedSchedule.endOffset);

    // Convert saved offset time back to 12 hour format
    const [hoursFromOffset, minutesFromOffset] = offsetTime.split(':');
    const minutes = offsetTime.includes('-') ? `-${minutesFromOffset}` : `+${minutesFromOffset}`;
    return {
      hours: hoursFromOffset,
      minutes,
    };
  }

  function handleSave() {
    const offset = Number(minutes) + Number(hours) * 60;
    const clonedSchedules = cloneDeep(schedules);
    const schedule = clonedSchedules[scheduleIndex];
    schedule[`${timeEditType!}SubType`] = scheduleType;
    schedule[`${timeEditType!}Offset`] = offset;
    schedule[`${timeEditType!}Time`] = null; // Reset schedule time if it exists
    dispatch(
      setSchedulerState({
        hasUnsavedChanges: false,
        schedules: clonedSchedules,
        timeEditType: null,
      })
    );

    onClose();
  }

  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent data-testid="offset-select-drawer" bg={'transparent'}>
        <DrawerBody px={1}>
          <Box borderRadius={'15px'} bg={bgColor}>
            <Center borderBottom={'1px solid'} borderColor={'customGrey.500'} py={3}>
              <Text textAlign={'center'} color={titleColor} fontSize={'sm'}>
                {t('Devices.add time offset to')} {t(`Devices.${scheduleType.toLowerCase()}`)}
              </Text>
            </Center>

            <Center as={'button'} w={'100%'} borderBottom={'1px solid'} borderColor={'customGrey.500'} py={3}>
              <OffsetTimePicker
                sliderColumnWidth={'115px'}
                defaultHours={initialState.hours}
                defaultMinutes={initialState.minutes}
                onChange={(value) => {
                  setState((prevState) => {
                    return { ...prevState, ...value };
                  });
                }}
                gradientColor={timePickerGradientColor}
                borderColor={timePickerBorderColor}
                labels={{ hours: t('Devices.hours'), minutes: t('Devices.minutes') }}
              />
            </Center>

            <Center
              data-testid="save-offset-select-drawer"
              as={'button'}
              w={'100%'}
              py={3}
              borderRadius={'15px'}
              bg={bgColor}
              onClick={handleSave}
            >
              <Text fontWeight={600} color={buttonTextColor}>
                {t('Common.confirm')}
              </Text>
            </Center>
          </Box>

          <Center
            data-testid="close-time-select-drawer"
            as={'button'}
            w={'100%'}
            onClick={onClose}
            py={3}
            mt={3}
            borderRadius={'15px'}
            bg={bgColor}
          >
            <Text color={buttonTextColor}>{t('Common.close')}</Text>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
