import React, { useMemo } from 'react';
import { BoxProps, Center, SimpleGrid } from '@chakra-ui/react';
import { sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { CostsData } from 'clipsal-cortex-types/src/api/api-costs';
import { SavingsAPIData } from 'clipsal-cortex-types/src/api/api-savings';
import { EnergyUsageV2, PowerUsageV2 } from 'clipsal-cortex-types/src/api/api-usage-v2';

import { DataSummaryCard } from '../../../common/components/DataSummaryCard';
import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { SEHomeCard } from '../../../common/components/SEHomeCard';
import { ActivityDataSource } from '../types';
import { useSource } from './use-source';

type Props = {
  isLoaded: boolean;
  selectedDateRangeType: DateRangeType;
  powerUsageData: PowerUsageV2[];
  energyUsageData: EnergyUsageV2[];
  costData: CostsData[];
  savingsData: SavingsAPIData[];
  isCostViewSelected: boolean;
  energyIndependencePercentage: number;
};

export function ActivitySummaryCards(props: Props) {
  const {
    isLoaded,
    selectedDateRangeType,
    powerUsageData,
    energyUsageData,
    costData,
    savingsData,
    isCostViewSelected,
    energyIndependencePercentage,
  } = props;
  const source = useSource();
  const sourceToDisplayedCards: Record<ActivityDataSource, JSX.Element> = {
    solar: (
      <>
        <TotalSavingsCard mr={2} isLoaded={isLoaded} savingsData={savingsData} />
        <TotalSolarCard
          ml={2}
          isLoaded={isLoaded}
          selectedDateRangeType={selectedDateRangeType}
          energyUsageData={energyUsageData}
        />
      </>
    ),
    battery: (
      <>
        <TotalDischargeCard mr={2} isLoaded={isLoaded} powerUsageData={powerUsageData} />
        <BatteryHealthCard ml={2} isLoaded={isLoaded} energyUsageData={energyUsageData} />
      </>
    ),
    grid: (
      <>
        <PlaceholderCard mr={2} />
        <PlaceholderCard ml={2} />
      </>
    ),
    // generator: (
    //   <>
    //     <PlaceholderCard mr={2} />
    //     <PlaceholderCard ml={2} />
    //   </>
    // ),
  };

  const defaultDisplayedCards = (
    <>
      {isCostViewSelected ? (
        <TotalCostCard isLoaded={isLoaded} costData={costData} mr={2} />
      ) : (
        <TotalConsumptionCard
          mr={2}
          isLoaded={isLoaded}
          selectedDateRangeType={selectedDateRangeType}
          energyUsageData={energyUsageData}
        />
      )}
      <EnergyIndependenceCard energyIndependencePercentage={energyIndependencePercentage} isLoaded={isLoaded} ml={2} />
    </>
  );

  const displayedCards = source ? sourceToDisplayedCards[source] : defaultDisplayedCards;

  return (
    <SimpleGrid my={3} columns={2}>
      {displayedCards}
    </SimpleGrid>
  );
}

function TotalConsumptionCard({
  isLoaded,
  selectedDateRangeType,
  energyUsageData,
  ...boxProps
}: Pick<Props, 'isLoaded' | 'selectedDateRangeType' | 'energyUsageData'> & BoxProps) {
  const { t } = useTranslation();
  const consumptionUsageSum = useMemo(() => {
    return energyUsageData.reduce((acc, interval) => acc + (interval?.consumed ?? 0), 0);
  }, [energyUsageData, selectedDateRangeType]);

  return (
    <DataSummaryCard
      title={t('Activity.total consumption')}
      isLoaded={isLoaded}
      value={Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 0 }).format(consumptionUsageSum)}
      suffix="kWh"
      data-testid="total-consumption-card"
      px={1}
      {...boxProps}
    />
  );
}

function TotalSolarCard({
  isLoaded,
  selectedDateRangeType,
  energyUsageData,
  ...boxProps
}: Pick<Props, 'isLoaded' | 'selectedDateRangeType' | 'energyUsageData'> & BoxProps) {
  const { t } = useTranslation();
  const solarUsageSum = useMemo(() => {
    return energyUsageData.reduce((acc, interval) => acc + (interval?.solar ?? 0), 0);
  }, [energyUsageData, selectedDateRangeType]);

  return (
    <DataSummaryCard
      title={t('Activity.total energy')}
      isLoaded={isLoaded}
      value={Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 0 }).format(solarUsageSum)}
      suffix="kWh"
      data-testid="total-solar-card"
      px={1}
      {...boxProps}
    />
  );
}

function TotalDischargeCard({
  isLoaded,
  powerUsageData,
  ...boxProps
}: Pick<Props, 'powerUsageData' | 'isLoaded'> & BoxProps) {
  const { t } = useTranslation();
  const batteryDischargeSum = useMemo(() => {
    return powerUsageData.reduce((acc, { battery }) => {
      if (!battery || battery > 0) return acc;
      return acc + battery / 4;
    }, 0);
  }, [powerUsageData]);

  return (
    <DataSummaryCard
      title={t('Activity.total discharge')}
      isLoaded={isLoaded}
      value={Intl.NumberFormat('en', { notation: 'compact' }).format(batteryDischargeSum)}
      suffix="kWh"
      data-testid="total-discharge-card"
      {...boxProps}
    />
  );
}

function BatteryHealthCard({
  isLoaded,
  energyUsageData,
  ...boxProps
}: Pick<Props, 'isLoaded' | 'energyUsageData'> & BoxProps) {
  const { t } = useTranslation();
  // Energy usage data in the battery UI context is always summary data, so we can use the first entry
  const batteryHealth = Math.round(energyUsageData[0]?.battery_soh ?? 0);

  return (
    <DataSummaryCard
      title={t('Activity.battery health')}
      isLoaded={isLoaded}
      value={batteryHealth.toString()}
      suffix="%"
      data-testid="battery-health-card"
      {...boxProps}
    />
  );
}

function EnergyIndependenceCard({
  isLoaded,
  energyIndependencePercentage,
  ...boxProps
}: Pick<Props, 'isLoaded' | 'energyIndependencePercentage'> & BoxProps) {
  const { t } = useTranslation();
  return (
    <DataSummaryCard
      title={t('Activity.energy independent')}
      isLoaded={isLoaded}
      value={energyIndependencePercentage.toString()}
      suffix="%"
      data-testid="energy-independence-card"
      {...boxProps}
    />
  );
}

function PlaceholderCard(props: BoxProps) {
  return (
    <SEHomeCard borderBottomRightRadius={'5px'} borderTopLeftRadius={'5px'} as={Center} {...props}>
      PLACEHOLDER
    </SEHomeCard>
  );
}

function TotalCostCard({ isLoaded, costData, ...boxProps }: Pick<Props, 'costData' | 'isLoaded'> & BoxProps) {
  const { t } = useTranslation();
  let totalCost = sumBy(costData.map((interval) => interval.total_cost));
  let prefix = '$';
  if (totalCost < 0) {
    totalCost = totalCost * -1; // Convert to positive value to support prefix as -${totalCost}
    prefix = '-$';
  }
  return (
    <DataSummaryCard
      title={t('Activity.total cost')}
      isLoaded={isLoaded}
      value={`${totalCost.toFixed(2)}`}
      prefix={prefix}
      data-testid="total-cost-card"
      {...boxProps}
    />
  );
}

function TotalSavingsCard({ isLoaded, savingsData, ...boxProps }: Pick<Props, 'savingsData' | 'isLoaded'> & BoxProps) {
  const { t } = useTranslation();
  let totalCost = Object.values(savingsData).reduce((acc, savings) => acc + savings.total_saved, 0);
  let prefix = '$';
  if (totalCost < 0) {
    totalCost = totalCost * -1; // Convert to positive value to support prefix as -${totalCost}
    prefix = '-$';
  }
  return (
    <DataSummaryCard
      title={t('Savings.total savings')}
      isLoaded={isLoaded}
      value={`${totalCost.toFixed(2)}`}
      prefix={prefix}
      data-testid="total-savings-card"
      {...boxProps}
    />
  );
}
