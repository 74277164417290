import { Preferences } from '@capacitor/preferences';
import * as Sentry from '@sentry/react';
import { v4 as uuid } from 'uuid';

export type WifiDetails = { id: string; siteId: number; ssid: string; password: string; isDefault: boolean };

/**
 *  Gets wifi details from local storage for a site
 *
 * @param siteId - The siteId to fetch saved details for
 *
 * @returns A list of locally stored wifi connection details for the given siteId
 */
export async function getSavedWifiDetails(siteId: number): Promise<WifiDetails[]> {
  const { value } = await Preferences.get({ key: `wifiConnections_${siteId}` });
  try {
    if (value) {
      return JSON.parse(value) as WifiDetails[];
    }
    return [];
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    return [];
  }
}

/**
 * Adds wifi connection details to local storage for a site
 *
 * @param siteId - siteId to save wifi details for
 * @param ssid - network ssid to save
 * @param password - network password to save
 * @param isDefault - whether to save the network as default
 * @returns void
 */
export async function addWifiDetails(siteId: number, ssid: string, password: string, isDefault: boolean) {
  try {
    const savedWifiDetails = await getSavedWifiDetails(siteId);
    const wifiConnections = JSON.stringify([...savedWifiDetails, { id: uuid(), siteId, ssid, password, isDefault }]);
    await Preferences.set({ key: `wifiConnections_${siteId}`, value: wifiConnections });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}

/**
 * Edits existing wifi connection details in local storage for a site
 *
 * @param siteId - siteId to save wifi details for
 * @param id - id of the locally saved wifi connection to edit
 * @param ssid - network ssid to save
 * @param password - network password to save
 * @param isDefault - whether to save the network as default
 * @returns void
 */
export async function editWifiDetails(siteId: number, id: string, ssid: string, password: string, isDefault: boolean) {
  try {
    const savedWifiDetails = await getSavedWifiDetails(siteId);
    const updatedWifiDetails = JSON.stringify(
      savedWifiDetails.map((wifiDetails) =>
        wifiDetails.id === id ? { ...wifiDetails, ssid, password, isDefault } : wifiDetails
      )
    );
    await Preferences.set({ key: `wifiConnections_${siteId}`, value: updatedWifiDetails });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}
