import React from 'react';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SiteDataToSave } from 'clipsal-cortex-types/src/api/api-site';

import { useReduxDispatch } from '../../app/store';
import { patch } from '../../common/api/api-helpers';
import { useOnboardingWizardProgress } from '../home/useOnboardingWizardProgress';
import { selectSite, setCurrentSite } from '../site/siteSlice';
import { AddressFormData, SiteForm } from './SiteForm';

export function UpdateSiteForm() {
  const site = useSelector(selectSite);
  const toast = useToast({ isClosable: true });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const { needsToProvideInstallerConsent } = useOnboardingWizardProgress();

  async function handleSubmit(values: AddressFormData) {
    const body: SiteDataToSave = {
      lat: values.latitude,
      lon: values.longitude,
      city: values.city,
      post_code: values.postCode,
      state: values.state,
      country: values.country,
      address: values.address,
      timezone: values.timezone,
      site_name: values.name,
    };

    try {
      const siteData = await patch<SiteDataToSave>(`/v1/sites/${site.site_id}`, body);
      dispatch(setCurrentSite({ ...site, ...siteData }));

      if (needsToProvideInstallerConsent) {
        navigate(`../set_installer_consent`);
      } else {
        navigate(`../home`);
      }
    } catch (e) {
      console.log(e);
      toast({ title: t('Set Address.error saving address'), status: 'error', duration: 3000 });
    }
  }

  return <SiteForm onSubmit={handleSubmit} />;
}
