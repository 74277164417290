import React, { useMemo } from 'react';
import {
  Box,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Portal,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { TariffType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { ArcButton } from '../../common/components/ArcButton';
import { CustomDatePicker } from '../../common/components/custom-date-picker/CustomDatePicker';
import { SEHomeCard } from '../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../common/components/SlidingAnimationPageBase';
import { useParamBackUrl } from '../../common/hooks/use-param-back-url';
import { getLocaleForDateFns, SupportedLocales, useTranslatedMonths } from '../../utils/common/common-utils';
import { TARIFF_TO_DEFAULT_FUNCTION_MAP, TARIFF_TYPE_CONFIG, useTranslatedCommonInputProps } from './tariff-constants';
import { useTariffs } from './tariffApi';
import { useTariffFormContext } from './useTariffFormContext';

export const TariffDetails = () => {
  const { register, errors, setValue, control, getValues, clearErrors } = useTariffFormContext();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const { id, tariff, effectiveDate } = useWatch({ control });
  const { tariffType, retailer, utility } = tariff || {};
  const { isLoading, tariffs } = useTariffs();
  const params = useParams<{ id: string; tariffId: string }>();
  const { i18n, t } = useTranslation();
  const COMMON_INPUT_PROPS = useTranslatedCommonInputProps();
  const MONTHS = useTranslatedMonths();

  // Effective dates have to be unique
  const excludedDates = useMemo(() => {
    return tariffs.filter((t) => t.id !== id).map((t) => new Date(t.tariff_effective_date));
  }, [tariffs, id]);

  const populateTariffRatesByType = () => {
    const { seasons, tariffType } = getValues().tariff;
    const getDefaultTariffRates = TARIFF_TO_DEFAULT_FUNCTION_MAP[tariffType];

    // season index starts from 1 in backend
    const newRates = seasons.map((_, seasonIndex) => getDefaultTariffRates(seasonIndex + 1));
    setValue('tariff.rates', newRates);
    clearErrors('tariff.rates');
  };

  const backURL = useParamBackUrl(params.tariffId === 'new' ? '../home?direction=back' : '../../home?direction=back');

  const handleContinueToRateConfiguration = () => {
    // populate default rates based on tariff type and only do this if tariff is new
    // as tariff type cannot be changed once it is saved and we don't want to override
    if (params.tariffId === 'new') populateTariffRatesByType();
    const paramBackUrl = search.get('backURL');
    const backURL = paramBackUrl ? `?backURL=../tariff-details?backURL=${paramBackUrl}` : '';
    navigate(`../select-season${backURL}`);
  };

  if (isLoading) return <CenteredLoader text={`${t('Common.loading')}...`} minHeight={'50vh'} />;

  return (
    <SlidingAnimationPageBase
      title={t('Energy Rates.manual energy rates set up')}
      includeTopNav
      px={4}
      backURL={backURL}
      data-testid="tariff-details"
    >
      <Text>{t('Energy Rates.enter your energy providers name')}</Text>

      <VStack align={'flex-start'} mt={4}>
        <Text lineHeight={'19px'} fontWeight={500} mt={4}>
          {t('Energy Rates.energy provider')}
        </Text>
        <Input
          {...COMMON_INPUT_PROPS}
          type="text"
          placeholder={t('Energy Rates.no retailer selected')}
          value={retailer?.name || utility?.name || ''}
          isDisabled
          _disabled={{ opacity: 0.8 }}
        />

        <Text lineHeight={'19px'} fontWeight={500} mt={4}>
          {t('Energy Rates.plan name')}
        </Text>
        <FormControl isInvalid={!!errors?.tariff?.planName?.message}>
          <Input
            {...{
              borderRadius: 0,
              borderColor: '#9FA0A4',
              py: 6,
              _dark: { background: '#27282A', borderColor: '#9FA0A4' },
              size: 'md',
              placeholder: `${t('Energy Rates.enter plan name')}...`,
              _placeholder: { color: '#929497' },
            }}
            {...register('tariff.planName')}
            data-testid="tariff-plan-name"
          />
          <FormErrorMessage>{errors?.tariff?.planName?.message}</FormErrorMessage>
        </FormControl>

        <Text lineHeight={'19px'} fontWeight={500} mt={4} mb={0.5}>
          {t('Energy Rates.effective date')}
        </Text>
        <FormControl isInvalid={!!errors?.effectiveDate?.message}>
          <Box
            className="tariff-date-picker tariff-date-picker-with-bg"
            {...{
              border: '1px solid',
              borderRadius: '1px',
              borderColor: '#9FA0A4',
              py: 1,
              px: 2,
              _dark: { background: '#27282A', borderColor: '#9FA0A4' },
              width: '100%',
            }}
          >
            <CustomDatePicker
              portalId="tariff-effective-date-picker"
              inputProps={{
                border: 'none',
                'data-testid': 'effective-date-select-date-picker',
                fontSize: '15px',
                textAlign: 'left',
                paddingRight: 0,
                width: '100vw',
                placeholder: t('Common.select a date'),
              }}
              inputGroupProps={{
                justifyContent: 'center',
              }}
              excludeDates={excludedDates}
              showMovePeriodButtons
              selected={new Date(effectiveDate || Date.now())}
              onChange={(date) => {
                const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
                if (formattedDate) setValue('effectiveDate', formattedDate, { shouldDirty: true });
              }}
              months={MONTHS}
              locale={getLocaleForDateFns(i18n.language as SupportedLocales)}
            />
          </Box>
          <FormErrorMessage>{errors?.effectiveDate?.message}</FormErrorMessage>
        </FormControl>
      </VStack>

      {/* This ensures react date picker is portaled. */}
      <Portal>
        <Box id="tariff-effective-date-picker" />
      </Portal>

      <SEHomeCard borderTopLeftRadius={'5px'} mt={6} p={0}>
        <RadioGroup
          onChange={(type) => setValue('tariff.tariffType', type as TariffType, { shouldDirty: true })}
          value={tariffType}
        >
          {TARIFF_TYPE_CONFIG.map(({ type }) => {
            // Once tariff is saved, tariff type cannot be changed
            const isHidden = params.tariffId !== 'new' && tariffType !== type;
            if (isHidden) return null;
            return (
              <Box key={type} py={2} onClick={() => setValue('tariff.tariffType', type, { shouldDirty: true })}>
                <Box pl={4}>
                  <Text fontWeight={600}>
                    {t(`Energy Rates.types.${type}.label`, { tou: t('Widgets.Energy Mode.TOU') })}
                  </Text>
                  <Flex mt={2} pr={4}>
                    <Text mr={4}>{t(`Energy Rates.types.${type}.description`)}</Text>
                    <Radio value={type} h="fit-content" my="auto" ml={'auto'} data-testid={`${type}-radio-button`} />
                  </Flex>
                  <Divider mt={4} />
                </Box>
              </Box>
            );
          })}
          <Text px={4} pb={2}>
            {t('Energy Rates.note tariff type cant be changed')}.
          </Text>
        </RadioGroup>
      </SEHomeCard>

      <Center>
        <ArcButton
          arcColor="#3DCD58"
          minWidth="280px"
          my={10}
          py={6}
          onClick={handleContinueToRateConfiguration}
          data-testid="continue-button"
        >
          {t('Common.continue')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
};
