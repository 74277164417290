import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  Skeleton,
  SkeletonCircle,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ErrorResponse } from '../../../common/api/api-helpers';
import { SEHomeCard } from '../../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { selectSite } from '../../site/siteSlice';
import { DEVICE_ICON_RESPONSIVE_SIZE } from '../devices-helper';
import { useGetDeviceQuery, useUpdateDeviceMutation } from '../devicesApi';
import { DeviceStatusIcon } from '../DeviceStatusIcon';
import { EditDeviceForm, EditDeviceFormData } from '../EditDeviceForm';
import { SwitchScheduleList } from './schedules/SwitchScheduleList';
import { SenseSwitchPatch, useGetLiveSwitchQuery, useUpdateSwitchMutation } from './switchApi';

const FORM_ID = 'edit-device-form'; // Passed to the form to be able to submit with an external button

export function EditSwitch() {
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const { switchId: id } = useParams<{ switchId: string }>();
  const switchId = Number(id);
  const { data: liveSwitch, isLoading: isSwitchLoading, isSwitchError } = useGetLiveSwitchQuery(switchId);
  const { t } = useTranslation();

  // We also grab from the device API because the switch API doesn't have the user_assignment field (for the icon)
  const { data: appliance, isLoading: isDeviceLoading, isError: isDeviceError } = useGetDeviceQuery({ switchId });

  const isLoading = isSwitchLoading || isDeviceLoading;
  const isError = isSwitchError || isDeviceError;
  const toast = useToast({ isClosable: true });
  const [updateSwitch, { isLoading: isUpdateSwitchLoading }] = useUpdateSwitchMutation();
  const [updateDevice] = useUpdateDeviceMutation();

  // This is used to 'watch' the user assignment field so we can update the icon
  const [userAssignmentSelected, setUserAssignmentSelected] = useState<string | null>(null);
  const handleUserAssignmentChange = (userAssignment: string | null) => {
    setUserAssignmentSelected(userAssignment);
  };

  const handleUpdateError = (error: AxiosError<ErrorResponse>) => {
    console.error('Error updating device: ', error);
    toast({
      status: 'error',
      title: t('Devices.error updating device'),
      description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
    });
  };

  const handleFormSubmit = async ({ displayName, userAssignment }: EditDeviceFormData) => {
    try {
      const bodyForSwitch = {
        site_switch_label: displayName,
      };

      await updateSwitch({ siteId, switchId, body: bodyForSwitch }).unwrap();

      // We need to make a 2nd API call here because the switch API doesn't have the user_assignment field
      if (userAssignment?.value) {
        const bodyForAppliance = {
          user_assignment: userAssignment.value,
        };

        await updateDevice({ siteId, deviceId: appliance!.appliance_id, body: bodyForAppliance }).unwrap();
      }
    } catch (error) {
      handleUpdateError(error as AxiosError<ErrorResponse>);
    }
    navigate(`../view?direction=back`);
  };

  const handleRestoreStateToggle = async (bodyForSwitch: Partial<SenseSwitchPatch>) => {
    try {
      await updateSwitch({ siteId, switchId, body: bodyForSwitch }).unwrap();
    } catch (error) {
      handleUpdateError(error as AxiosError<ErrorResponse>);
    }
  };

  const backURL = useMemo(
    () => (!isError && liveSwitch ? `../view?direction=back` : `../../../devices/list?direction=back`),
    [isError, liveSwitch]
  );

  return (
    <SlidingAnimationPageBase
      backURL={backURL}
      includeBottomNav
      title={t('Devices.edit device')}
      customTopNavButton={
        <Button
          data-testid="save-button"
          variant={'ghost'}
          size={'sm'}
          colorScheme={'schneiderSkyBlue'}
          hidden={isError}
          type="submit"
          form={FORM_ID}
          fontWeight={400}
        >
          {t('Common.save')}
        </Button>
      }
    >
      <Box data-testid="switch-detail-contents" px={3}>
        {isError ? (
          <Alert status="error" variant="left-accent" data-testid="error-alert">
            <AlertIcon />
            <Box>
              <AlertTitle>{t('Common.notice')}</AlertTitle>
              <AlertDescription>
                {t('Devices.error fetching device')}.{' '}
                {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}.
              </AlertDescription>
            </Box>
          </Alert>
        ) : (
          <>
            <SkeletonCircle
              isLoaded={!isLoading}
              w={DEVICE_ICON_RESPONSIVE_SIZE}
              h={DEVICE_ICON_RESPONSIVE_SIZE}
              m="auto"
            >
              {liveSwitch && appliance && (
                <DeviceStatusIcon
                  assignment={userAssignmentSelected || appliance.user_assignment}
                  isOn={liveSwitch.power > 0}
                />
              )}
            </SkeletonCircle>
            <Skeleton isLoaded={!isLoading} borderBottomRightRadius="25px">
              {liveSwitch && appliance && (
                <EditDeviceForm
                  displayName={liveSwitch.site_switch_label}
                  userAssignment={appliance.user_assignment || null}
                  onUserAssignmentChange={handleUserAssignmentChange}
                  onSubmit={handleFormSubmit}
                  formId={FORM_ID}
                />
              )}
            </Skeleton>
            <SwitchScheduleList switchId={switchId} />

            <Text mt={3} mb={1} borderBottom="1px solid" borderColor="#C6C6C6">
              {t('Devices.power restoration state').toUpperCase()}
            </Text>
            <Text>{t('Devices.after power outage')}:</Text>

            <Skeleton isLoaded={!isLoading} borderRadius={5} borderBottomRightRadius="25px">
              <SEHomeCard borderTopLeftRadius={5} mt={2} py={6}>
                {liveSwitch && (
                  <>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text>{t('Devices.when power returns').toUpperCase()}</Text>
                      <Switch
                        isChecked={liveSwitch.restore_requested_state}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleRestoreStateToggle({ restore_requested_state: e.target.checked })
                        }
                        variant="solarGreen"
                        size="lg"
                        data-testid={`restore-state-toggle`}
                        isDisabled={isSwitchLoading || isUpdateSwitchLoading}
                      />
                    </Flex>
                    <Divider my={4} />

                    <Flex alignItems="center" justifyContent="space-between">
                      <Text>{t('Devices.when backup power returns').toUpperCase()}</Text>

                      <Switch
                        isChecked={liveSwitch.restore_requested_state_on_backup}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleRestoreStateToggle({ restore_requested_state_on_backup: e.target.checked })
                        }
                        variant="solarGreen"
                        size="lg"
                        data-testid={`restore-state-on-backup-toggle`}
                        isDisabled={isSwitchLoading || isUpdateSwitchLoading}
                      />
                    </Flex>
                  </>
                )}
              </SEHomeCard>
            </Skeleton>
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
