import { isEqual, isInteger, some } from 'lodash';

import {
  DAY_TYPES,
  DayType,
  SiteTariff,
  SiteTariffToSave,
  TariffRate,
  TariffType,
} from 'clipsal-cortex-types/src/api/api-tariffs-v2';

import { TOU_RATE_TYPE_TO_FORM_TERM_MAP } from './tariff-constants';
import { Season } from './tariff-season/tariff-season-helpers';
import { Rate, TariffData, TariffRatesPerType, TOUTariffRatesPerType } from './tariff-types';
import { TOU_TARIFF_FORM_KEYS } from './tou-tariff/tou-tariff-constants';

/**
 * @description Format date to be used in season based on api requirements
 * @param month - month to be used in season end date
 * @param date - date to be used in season end date
 * @param offset - add X days in the date
 */
export const adjustSeasonDate = (month: number, date: number, offset?: number) => {
  const formattedDate = new Date(new Date().getFullYear(), month, date);

  // date is not inclusive in api, hence add 1 to include the date if required
  if (offset) formattedDate.setDate(formattedDate.getDate() + offset);

  return formattedDate;
};

const mapTariffApiRateToForm = (
  {
    id,
    season,
    charge_period: chargePeriod,
    charge_type: chargeType,
    charge_class: chargeClass,
    transaction_type: transactionType,
    rate_bands: rateBands,
    time_of_use: timeOfUse,
  }: TariffRate,
  dataIndex: number | null = null
) => {
  return {
    id,
    ...(dataIndex !== null ? { seasonIndex: season?.season_index || dataIndex + 1 } : {}),
    chargePeriod,
    chargeType,
    chargeClass,
    transactionType,
    timeOfUse: timeOfUse
      ? {
          touName: timeOfUse.tou_name,
          touRateType: timeOfUse.tou_rate_type,
          periods: timeOfUse.periods.map(
            ({
              days,
              from_hour: fromHour,
              from_minute: fromMinute,
              to_hour: toHour,
              to_minute: toMinute,
              public_holiday: publicHoliday,
            }) => ({
              days,
              fromHour,
              fromMinute,
              toHour,
              toMinute,
              publicHoliday: !!publicHoliday,
            })
          ),
        }
      : null,
    rateBands: rateBands.map(
      ({
        id,
        sequence_number: sequenceNumber,
        rate,
        has_consumption_limit: hasConsumptionLimit,
        consumption_upper_limit: consumptionUpperLimit,
        has_demand_limit: hasDemandLimit,
        demand_upper_limit: demandUpperLimit,
      }) => ({
        id,
        sequenceNumber,
        rate,
        hasConsumptionLimit: !!hasConsumptionLimit,
        consumptionUpperLimit,
        hasDemandLimit: !!hasDemandLimit,
        demandUpperLimit,
      })
    ),
  };
};

const mapTariffFormRateToApi = (
  { id, chargeType, seasonIndex, chargeClass, chargePeriod, transactionType, rateBands, timeOfUse }: Rate,
  tariffType: TariffType = 'FLAT'
): TariffRate => {
  return {
    ...(id ? { id } : {}), // only append id if it exists i.e. for POST/PUT
    ...(isInteger(seasonIndex) ? { season_index: seasonIndex } : {}), // only append season_index if it exists
    charge_type: chargeType,
    charge_class: chargeClass,
    ...(chargePeriod ? { charge_period: chargePeriod } : {}),
    ...(transactionType ? { transaction_type: transactionType } : {}),
    time_of_use: timeOfUse
      ? {
          tou_name: timeOfUse.touName,
          tou_rate_type: timeOfUse.touRateType,
          periods: timeOfUse.periods.map(({ days, fromHour, fromMinute, toHour, toMinute, publicHoliday }) => ({
            days,
            from_hour: fromHour,
            from_minute: fromMinute,
            to_hour: toHour,
            to_minute: toMinute,
            public_holiday: publicHoliday,
          })),
        }
      : null,
    rate_bands: rateBands.map(
      ({ id, rate, hasConsumptionLimit, hasDemandLimit, consumptionUpperLimit, demandUpperLimit }, idx, arr) => {
        const isTieredTariff = tariffType === 'TIERED';
        const isLastIndex = idx === arr.length - 1;

        const newConsumptionUpperLimit = isLastIndex && isTieredTariff ? null : consumptionUpperLimit;
        return {
          ...(id ? { id } : {}),
          sequence_number: idx + 1,
          rate: rate ?? 0,
          has_consumption_limit: hasConsumptionLimit,
          has_demand_limit: hasDemandLimit,
          ...(consumptionUpperLimit ? { consumption_upper_limit: newConsumptionUpperLimit } : {}),
          ...(demandUpperLimit ? { demand_upper_limit: demandUpperLimit } : {}),
        };
      }
    ),
  };
};

export const getTariffRatesPerType = (
  seasons: Season[],
  rates: TariffRate[],
  tariffType: TariffType
): TariffRatesPerType[] => {
  return seasons.map((season, dataIndex) => {
    return rates
      .filter((rate) => rate.season?.season_index === season.seasonIndex)
      .reduce(
        (acc, tariffRate) => {
          const rate = mapTariffApiRateToForm(tariffRate, dataIndex);
          // segregate rates based on charge type or transaction type
          // this is will help in rendering dynamic forms i.e. tiered/tou tariff
          if (rate.transactionType === 'BUY_IMPORT') {
            // for tou tariffs, segregate rates based on tou rate type
            if (tariffType === 'TOU' && rate.timeOfUse) {
              const { touRateType, periods } = rate.timeOfUse;
              if (touRateType) {
                const formTerm = TOU_RATE_TYPE_TO_FORM_TERM_MAP[touRateType];

                // Get the set of all day types this rate qualifies for
                const dayTypes = new Set<DayType>();

                for (const period of periods) {
                  if (period.days.length > 5) {
                    dayTypes.add('allWeek');
                  } else if (period.days.includes('SAT') || period.days.includes('SUN')) {
                    dayTypes.add('weekend');
                  } else {
                    dayTypes.add('weekday');
                  }
                }

                // Only push once per dayType + formTerm
                dayTypes.forEach((dayType) => {
                  const alreadyExists = some(acc[dayType][formTerm], (existing) => isEqual(existing, rate));
                  if (!alreadyExists) {
                    acc[dayType][formTerm].push(rate);
                  }
                });
              }
            } else {
              // for flat/tiered tariffs, add to import rates
              const alreadyExists = some(acc.import, (existing) => isEqual(existing, rate));
              if (!alreadyExists) {
                acc.import.push(rate);
              }
            }
          } else if (rate.transactionType === 'SELL_EXPORT') {
            const alreadyExists = some(acc.export, (existing) => isEqual(existing, rate));
            if (!alreadyExists) {
              acc.export.push(rate);
            }
          }

          return acc;
        },
        {
          import: [] as Rate[],
          export: [] as Rate[],
          weekday: {
            peak: [],
            offPeak: [],
            partialPeak: [],
          } as TOUTariffRatesPerType,
          weekend: {
            peak: [],
            offPeak: [],
            partialPeak: [],
          } as TOUTariffRatesPerType,
          allWeek: {
            peak: [],
            offPeak: [],
            partialPeak: [],
          } as TOUTariffRatesPerType,
        }
      );
  });
};

export const mapTariffApiToForm = (
  { id, tariff_effective_date: effectiveDate, tariff }: SiteTariff,
  utility?: Partial<TariffData['tariff']['utility']>
): TariffData => {
  const {
    tariff_type: tariffType,
    plan_name: planName,
    rates,
    retailer,
    distributor_id: distributorId,
    holiday_country: holidayCountry,
    holiday_subdiv: holidaySubdiv,
  } = tariff;

  const seasons =
    tariff.seasons?.map(
      ({
        name,
        season_index: seasonIndex,
        from_month: fromMonth,
        from_date: fromDate,
        to_month: toMonth,
        to_date: toDate,
      }) => {
        // backend season end date is exclusive, hence remove 1 day to get the correct date
        const endDate = adjustSeasonDate(toMonth - 1, toDate, -1);
        return {
          name,
          seasonIndex,
          fromMonth: fromMonth - 1,
          fromDate: fromDate,
          toMonth: endDate.getMonth(),
          toDate: endDate.getDate(),
        };
      }
    ) ?? [];

  const deliveryChargeRate = rates.find((rate) => rate.charge_type === 'FIXED_PRICE');

  return {
    id,
    effectiveDate,
    tariff: {
      tariffType: tariffType,
      planName: planName || '',
      deliveryCharge: deliveryChargeRate ? mapTariffApiRateToForm(deliveryChargeRate) : undefined,
      seasons,
      rates: getTariffRatesPerType(seasons, rates, tariffType),
      retailer: {
        id: retailer?.id ?? 0,
        name: retailer?.name ?? '',
      },
      utility: {
        id: utility?.id ?? 0,
        name: utility?.name ?? '',
        state: utility?.state ?? '',
      },
      distributorId: distributorId,
      holidayCountry: holidayCountry,
      holidaySubdiv: holidaySubdiv,
    },
  };
};

export const mapTariffDataToApi = ({ effectiveDate, tariff, id }: TariffData): SiteTariffToSave => {
  const {
    planName,
    retailer,
    distributorId,
    holidayCountry,
    holidaySubdiv,
    rates,
    seasons,
    tariffType,
    deliveryCharge,
  } = tariff;
  return {
    ...(id ? { id } : {}), // only append id if it exists i.e. for POST/PUT
    tariff_effective_date: effectiveDate,
    tariff: {
      plan_name: planName,
      tariff_type: tariffType,
      retailer_id: retailer.id || null,
      ...(distributorId ? { distributor_id: distributorId } : {}),
      ...(holidayCountry ? { holiday_country: holidayCountry } : {}),
      ...(holidaySubdiv ? { holiday_subdiv: holidaySubdiv } : {}),
      seasons: seasons.map(({ name, seasonIndex, fromDate, fromMonth, toDate, toMonth }) => {
        // toDate is mutually exclusive in api, hence add 1 to include the date
        const endDate = adjustSeasonDate(toMonth, toDate, 1);

        return {
          name,
          ...(id ? { tariff_id: id } : {}), // only append id if it exists i.e. for POST/PUT
          season_index: seasonIndex,
          from_date: fromDate,
          from_month: fromMonth + 1, // api wants month as 1-12
          to_date: endDate.getDate(),
          to_month: endDate.getMonth() + 1, // api wants month as 1-12
        };
      }),
      rates: [
        ...(() => {
          const seen = new Set<string>();
          const dedupedRates: Rate[] = [];

          rates.forEach((seasonRates) => {
            const allRates = [
              ...seasonRates.import,
              ...seasonRates.export,
              ...DAY_TYPES.flatMap((dayType) =>
                TOU_TARIFF_FORM_KEYS.flatMap((formKey) => seasonRates?.[dayType]?.[formKey] ?? [])
              ),
            ];

            allRates.forEach((rate) => {
              const key = JSON.stringify(rate); // Deep equality check
              if (!seen.has(key)) {
                seen.add(key);
                dedupedRates.push(rate);
              }
            });
          });

          return dedupedRates.map((rate) => mapTariffFormRateToApi(rate));
        })(),
        // Since we separate delivery charge from other rates
        // in the form, add delivery charge to rates in api
        ...(deliveryCharge ? [mapTariffFormRateToApi(deliveryCharge)] : []),
      ],
    },
  };
};

/**
 *
 * @param amount dollar value to be formatted
 * @returns cents if amount is less than 1, else dollars
 */
export const formatDollars = (amount: number) => {
  const isCents = amount < 1;
  if (isCents) return amount * 100 + '¢';
  return '$' + amount;
};
