import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';

import { getPageTransition, NavigationDirection } from '../../utils/common/transition-utils';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT, TOP_NAV_SPACING_AFFORDANCE } from '../constants';
import { CommonTopNav } from './CommonTopNav';
import { PullToRefresh } from './pull-to-refresh/PullToRefresh';

type SlidingAnimationPageBaseProps = React.PropsWithChildren<
  {
    // NOTE: If both `backURL` and `onClickBack` are provided, `onClickBack` takes precedence
    backURL?: string;
    onClickBack?: () => void;
    title: string | React.JSX.Element;
    includeTopNav?: boolean;
    includeSchneiderHamButton?: boolean;
    includeTopNavHamButton?: boolean;
    includeBottomNav?: boolean;
    customNavigationDirection?: NavigationDirection; // Overrides the navigation direction based on the URL query params
    customTopNavButton?: React.JSX.Element;
    disablePullToRefresh?: boolean;
  } & Omit<BoxProps, 'title'>
>;

// Shares common top nav and bottom nav spacing affordance (for mobile) and a minimum height for the page content.
// Components that use this component will have a sliding animation when navigating between pages.
// Implemented in a separate component to avoid breaking existing components that use PageBase.
export const SlidingAnimationPageBase = React.forwardRef<HTMLDivElement, SlidingAnimationPageBaseProps>(
  (
    {
      children,
      backURL,
      onClickBack,
      title,
      customNavigationDirection,
      includeTopNavHamButton,
      includeTopNav = true,
      includeSchneiderHamButton = false,
      includeBottomNav = false,
      customTopNavButton,
      disablePullToRefresh = false,
      ...boxProps
    },
    ref
  ) => {
    const [search] = useSearchParams();
    const isNavigatingFromRedirect = search.get('redirect') === 'true';
    const navigationDirection = customNavigationDirection ?? (search.get('direction') as NavigationDirection);
    const backProps = backURL ? { backURL: backURL! } : { onClickBack: onClickBack! };
    const pageTransition = getPageTransition({ navigationDirection, isNavigatingFromRedirect });

    return (
      <PullToRefresh isDisabled={disablePullToRefresh}>
        <>
          {includeTopNav && (
            <CommonTopNav
              includeHamButton={includeTopNavHamButton}
              title={title}
              customTopNavButton={customTopNavButton}
              includeSchneiderHamButton={includeSchneiderHamButton}
              {...backProps}
            />
          )}
          <Box
            className={'sliding-pane'}
            maxW="100vw"
            w={'100%'}
            as={motion.div}
            initial="out"
            animate="in"
            variants={pageTransition}
            pb={includeBottomNav ? BOTTOM_NAV_HEIGHT : undefined}
            pt={includeTopNav ? TOP_NAV_SPACING_AFFORDANCE : 'env(safe-area-inset-top)'}
            minH={COMPONENT_MIN_HEIGHT}
            ref={ref}
            {...boxProps}
          >
            {children}
          </Box>
        </>
      </PullToRefresh>
    );
  }
);

SlidingAnimationPageBase.displayName = 'SlidingAnimationPageBase';
