import React from 'react';
import { Box, Flex, Link, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { InfoBottomDrawer } from 'clipsal-cortex-ui/src/components/InfoBottomDrawer';
import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';

import { ReactComponent as EVLinkIOSWifiSetting } from '../../../assets/images/evlink_wifi_settings_ios.svg';
import { ArcButton } from '../../../common/components/ArcButton';
import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { COMPONENT_MIN_HEIGHT } from '../../../common/constants';
import { useViewportType } from '../../../common/hooks/use-viewport-type';

/* istanbul ignore next -- @preserve */
export const EvWifiConfigurationHome = () => {
  const { isDesktopViewport } = useViewportType();
  const navigate = useNavigate();
  const {
    isOpen: isWhyImportantDrawerOpen,
    onOpen: onOpenWhyImportantDrawer,
    onClose: onCloseWhyImportantDrawer,
  } = useDisclosure();
  const {
    isOpen: isPowerCycleDrawerOpen,
    onOpen: onOpenPowerCycleDrawer,
    onClose: onClosePowerCycleDrawer,
  } = useDisclosure();
  const { headerBackgroundColor, drawerBackgroundColor, linkColor } = useColorModeValue(
    { headerBackgroundColor: '#FFFFFF', drawerBackgroundColor: '#F5F6F8', linkColor: 'schneiderSkyBlue.600' },
    { headerBackgroundColor: '#363E40', drawerBackgroundColor: '#111111', linkColor: 'schneiderSkyBlue.200' }
  );

  return (
    <SlidingAnimationPageBase title="Wi-Fi Configuration" backURL={`../../home/ev_charger_status`}>
      <Flex
        px={8}
        pt={TOP_NAV_SPACING_AFFORDANCE}
        pb={16}
        minHeight={COMPONENT_MIN_HEIGHT}
        direction={'column'}
        maxHeight="750px"
        justifyContent="space-between"
        alignItems={'center'}
      >
        <Box>
          <Text>
            Connect to the Schneider Home via Wi-Fi Direct.
            <Link color={linkColor} ml={1} onClick={onOpenWhyImportantDrawer}>
              Why is this important?
            </Link>
          </Text>
          <Text my={2}>
            1. Power cycle your Schneider Home to switch to Wi-Fi Direct mode.
            <Link color={linkColor} ml={1} onClick={onOpenPowerCycleDrawer}>
              How to Power Cycle?
            </Link>
          </Text>
          <Text>2. Enable Wi-Fi on this device.</Text>
          <Text my={2}>3. Select your Schneider Home from the network list then click Next below.</Text>
        </Box>
        <Flex mx="auto" my={4}>
          <EVLinkIOSWifiSetting
            style={{ width: 'fit-content', height: 'auto', maxHeight: isDesktopViewport ? '600px' : '400px' }}
          />
        </Flex>
        <ArcButton arcColor="#3DCD57" width="100%" maxWidth={'300px'} mt={4} onClick={() => navigate('../enter-pin')}>
          Next
        </ArcButton>
      </Flex>
      <InfoBottomDrawer
        onClose={onCloseWhyImportantDrawer}
        isOpen={isWhyImportantDrawerOpen}
        header="Why is this important"
        headerBgColor={headerBackgroundColor}
        drawerBgColor={drawerBackgroundColor}
      >
        <InfoBottomDrawer.Content>
          <Text my={4}>
            To connect your SE Charge to your home's Wi-Fi network, you will need to connect to the charger via the
            charger's Wi-Fi Direct access point. This allows you to change some of your charger's settings.
          </Text>
          <Text>
            The charger only broadcasts the Wi-Fi direct access point on bootup for a limited period of time so you will
            need to reboot your charger to connect your charger to your home wifi network.
          </Text>
        </InfoBottomDrawer.Content>
        <InfoBottomDrawer.ConfirmButton>
          <ArcButton
            arcColor="#3DCD57"
            width="100%"
            maxWidth={'300px'}
            mt={4}
            mb={16}
            mx="auto"
            onClick={onClosePowerCycleDrawer}
          >
            Ok I understand
          </ArcButton>
        </InfoBottomDrawer.ConfirmButton>
      </InfoBottomDrawer>
      <InfoBottomDrawer
        onClose={onClosePowerCycleDrawer}
        isOpen={isPowerCycleDrawerOpen}
        header="How to Power Cycle"
        headerBgColor={headerBackgroundColor}
        drawerBgColor={drawerBackgroundColor}
      >
        <InfoBottomDrawer.Content>
          <Text mt={4}>
            Turn off the power supply to the charging station, wait for at least 10 seconds, and then turn the power
            supply back on.
          </Text>
          <Text my={4}>
            Wait for the LED on the charging station to show solid green before searching for the network from your
            device.
          </Text>
          <Text>The device will require another power cycle after 5 minutes as it becomes unavailable.</Text>
        </InfoBottomDrawer.Content>
        <InfoBottomDrawer.ConfirmButton>
          <ArcButton
            arcColor="#3DCD57"
            width="100%"
            maxWidth={'300px'}
            mt={4}
            mb={16}
            mx="auto"
            onClick={onClosePowerCycleDrawer}
          >
            Ok I understand
          </ArcButton>
        </InfoBottomDrawer.ConfirmButton>
      </InfoBottomDrawer>
    </SlidingAnimationPageBase>
  );
};
