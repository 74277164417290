import React, { useMemo } from 'react';
import { Box, BoxProps, Flex, useColorModeValue } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MultiToggleSwitch } from 'clipsal-cortex-ui/src/components/MultiToggleSwitch';

import { ExclamationIcon } from '../../styles/custom-icons';
import { useTariffFormContext } from './useTariffFormContext';

type SeasonMultiToggleSwitchProps = { value: number; onChange: (newIndex: number) => void; containerProps?: BoxProps };

export const SeasonMultiToggleSwitch = ({ value, onChange, containerProps }: SeasonMultiToggleSwitchProps) => {
  const { control, errors } = useTariffFormContext();
  const seasons = useWatch({ control, name: `tariff.seasons` });
  const multiToggleSwitchClassName = useColorModeValue('light-mode-toggle-switch', 'dark-mode-toggle-switch');
  const { t } = useTranslation();
  const seasonOptions = useMemo(() => {
    if (!seasons?.length) return [{ label: t('Energy Rates.no seasons'), value: '0' }];
    return seasons.map(({ name }, index) => {
      return {
        label: name,
        value: index.toString(),
      };
    });
  }, [seasons]);

  return (
    <Box fontWeight={'bold'} className={`${multiToggleSwitchClassName} season-select`} {...containerProps}>
      <Flex w="100%">
        {/* Show error signal for tabs */}
        {seasons?.map(({ seasonIndex }, index, allSeasons) => {
          const isError = errors?.tariff?.rates?.[index];
          return (
            <Box
              w={`${100 / allSeasons.length}%`}
              bg="red.500"
              borderRight={'1px solid'}
              borderColor={'black'}
              key={`season-${seasonIndex}`}
              position="relative"
              zIndex={9}
            >
              {isError && (
                <Box position="absolute" top={0} right={0} mt={1}>
                  <ExclamationIcon />
                </Box>
              )}
            </Box>
          );
        })}
      </Flex>
      <MultiToggleSwitch
        switchOptions={seasonOptions}
        value={value.toString()}
        onChange={(index: string) => {
          const newIndex = parseInt(index) || 0;

          // only update if the index is different
          if (newIndex !== value) onChange(newIndex);
        }}
      />
    </Box>
  );
};
