import React from 'react';
import { Clipboard } from '@capacitor/clipboard';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  pairingCode: string;
};

/* istanbul ignore next -- @preserve */
export const PairingCodeModal = ({ isOpen, onClose, pairingCode }: Props) => {
  const closeRef = React.useRef<HTMLButtonElement | null>(null);
  const buttonBorderColor = useColorModeValue('#D9D9D9', '#494B50');
  const backgroundColor = useColorModeValue('#ffffff', 'customGrey.800');
  const codeBackgroundColor = useColorModeValue('#ffffff', '#293133');
  const buttonTextColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const toast = useToast({ isClosable: true });
  const { t } = useTranslation();

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={closeRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent top={'220px'} bgColor={backgroundColor} mx={8} rounded={16} mt={30} overflowX="hidden">
          <AlertDialogHeader textAlign="center" pt={6} pb={0} fontSize="lg" fontWeight="bold" mx="auto">
            {t('Devices.share pair code modal title')}
          </AlertDialogHeader>

          <AlertDialogBody pb={4} mx="auto" textAlign="center">
            {t('Devices.share pair code modal text')}
            <Text my={2} rounded={4} p={3} bg={codeBackgroundColor} border={`1px solid #9FA0A4`}>
              {pairingCode}
            </Text>
          </AlertDialogBody>

          <Flex h="4rem" borderTop={`0.5px solid ${buttonBorderColor}`}>
            <Button
              w="50%"
              h="100%"
              rounded={0}
              fontWeight="normal"
              variant="ghost"
              ref={closeRef}
              onClick={onClose}
              borderRight={`0.5px solid ${buttonBorderColor}`}
            >
              {t('Common.close')}
            </Button>
            <Button
              w="50%"
              h="100%"
              rounded={0}
              fontWeight="normal"
              variant="ghost"
              color={buttonTextColor}
              ref={closeRef}
              onClick={async () => {
                await Clipboard.write({
                  string: pairingCode,
                });
                toast({ title: t('Devices.code copied to clipboard') });
                onClose();
              }}
            >
              {t('Devices.copy code')}
            </Button>
          </Flex>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
