import { Browser } from '@capacitor/browser';
import { Amplify, API } from 'aws-amplify';

import { LocalForageStorage } from '../../common/classes/local-forage';
import { ENDPOINT, IS_RUNNING_CYPRESS_TESTS } from '../../common/constants';
import { ENV_TYPE_SHORTHAND } from '../../env-type';
import { getAppRedirectURI } from '../../features/auth/auth-helpers';
import { AWS_REGION, REGION_SHORTHAND } from '../../region-type';

/**
 * Configures the AWS Amplify end-point setup for this repo.
 *
 * Note that we need to separately configure the `API` class, as for some reason in this monorepo architecture it does
 * not correctly initialize itself.
 *
 * see: https://github.com/aws-amplify/amplify-js/issues/5756
 */
export function setupAPI() {
  const apiConfig = {
    endpoints: [
      {
        name: 'site',
        endpoint: ENDPOINT,
        region: AWS_REGION,
      },
    ],
  };

  const authOpts: Record<string, any> = {
    region: AWS_REGION,
    userPoolId: import.meta.env?.[`VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_COGNITO_USER_POOL_ID`],
    userPoolWebClientId: import.meta.env?.[`VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_CLIENT_ID`],
  };

  // Don't use indexedDB in Cypress tests since it causes flakes
  if (!IS_RUNNING_CYPRESS_TESTS) {
    authOpts.storage = new LocalForageStorage();
  }

  Amplify.configure({
    Auth: authOpts,
    API: apiConfig,
    oauth: {
      domain: `${
        ENV_TYPE_SHORTHAND === 'STAGING' ? 'clippy-staging' : 'clippy-prod'
      }.auth.${AWS_REGION}.amazoncognito.com`,
      // Uncomment this when working in preview environments
      // domain: `clippy-preview.auth.ap-southeast-2.amazoncognito.com`,
      scope: ['openid', 'profile', 'email', 'aws.cognito.signin.user.admin'],
      redirectSignIn: getAppRedirectURI(true),
      redirectSignOut: getAppRedirectURI(true),
      responseType: 'code',
      urlOpener: async (url: string) => {
        await Browser.open({ url, windowName: '_self' });
      },
    },
  });
  API.configure(apiConfig);
}
