import React from 'react';
import { Center, Heading, Image } from '@chakra-ui/react';

import underConstructionImg from '../../assets/images/under_construction.svg';
import { SlidingAnimationPageBase } from '../../common/components/SlidingAnimationPageBase';

export function Eco() {
  return (
    <SlidingAnimationPageBase backURL={`../../home?direction=back`} title={'Eco'}>
      <Center flexDirection="column" data-testid="eco">
        <Heading mb={2} size={'lg'}>
          Coming soon...
        </Heading>
        <Image src={underConstructionImg} alt={'Under construction'} />
      </Center>
    </SlidingAnimationPageBase>
  );
}
