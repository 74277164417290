import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { IS_PRODUCTION } from './common/constants';

export const SUPPORTED_LOCALES: Record<string, string> = {
  'en-US': 'English (US)',
  'fr-CA': 'French (CA)',
  'es-US': 'Spanish (US)',
};

// We use i18n for app localization whereby all user-facing text is available into multiple languages.
// Any time copy is updated - it will need to be updated in the public/locale files.
// See https://translate.i18next.com/ for generating translations
// See https://learn.microsoft.com/en-us/globalization/reference/microsoft-style-guides for guidelines
i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: Object.keys(SUPPORTED_LOCALES),
    fallbackLng: {
      es: ['es-US'],
      fr: ['fr-CA'],
      en: ['en-US'],
      default: ['en-US'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    load: 'currentOnly',
    debug: !IS_PRODUCTION,
  });

export { i18n };
