import { useEffect, useState } from 'react';
import { isAfter, subMinutes } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { useSelector } from 'react-redux';

import { CombinedLiveData } from '../../../site/live-data/types';
import { selectSite } from '../../../site/siteSlice';

export interface LiveDataCache {
  last_updated: string;
  solar: number;
  grid: number;
  consumption: number;
  battery: number;
}

const CACHE_DURATION_MINUTES = 15;
const CACHE_KEY = 'liveData';
const CACHE_DECIMALS = 2;

export function useLiveCachedData(liveData: CombinedLiveData) {
  const { site_id: siteId } = useSelector(selectSite);
  const { timezone: timeZone } = useSelector(selectSite);
  const [data, setData] = useState<LiveDataCache[]>([]);

  useEffect(() => {
    // Get Data From Storage.
    const storageData = localStorage.getItem(`${CACHE_KEY}_${siteId}`);
    const convertedData: LiveDataCache[] = storageData ? JSON.parse(storageData) : [];
    const { last_updated: lastUpdated, solar, grid, consumption, battery } = liveData;

    // Check if the data is already in storage.
    const isExisting = convertedData.some((interval) => interval.last_updated === lastUpdated);
    if (!isExisting && lastUpdated) {
      // Filter out data older than X minutes.
      const oldestInterval = subMinutes(toDate(toDate(new Date(), { timeZone }), { timeZone }), CACHE_DURATION_MINUTES);
      const sanitizedData = convertedData.filter((interval) =>
        isAfter(new Date(interval.last_updated), oldestInterval)
      );
      // Reduce the number of decimals.
      const newData = {
        last_updated: lastUpdated,
        solar: parseFloat(solar.toFixed(CACHE_DECIMALS)),
        grid: parseFloat(grid.toFixed(CACHE_DECIMALS)),
        consumption: parseFloat(consumption.toFixed(CACHE_DECIMALS)),
        battery: battery ? parseFloat(battery.toFixed(CACHE_DECIMALS)) : 0,
      };
      // Add new data to the beginning of the array.
      const combinedData = [newData, ...sanitizedData];
      // Save data to storage.
      localStorage.setItem(`${CACHE_KEY}_${siteId}`, JSON.stringify(combinedData));
      setData(combinedData);
    }
  }, [liveData, siteId, timeZone]);

  return data;
}
