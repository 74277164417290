import React, { useMemo, useState } from 'react';
import { Box, Center, Divider, ListItem, Text, UnorderedList, useColorModeValue } from '@chakra-ui/react';
import Highcharts, { Options } from 'highcharts';
import Chart from 'highcharts-react-official';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { COLOURS } from 'clipsal-cortex-utils/src/constants/colors';

import { ArcButton } from '../../../common/components/ArcButton';
import { SEHomeCard } from '../../../common/components/SEHomeCard';
import { ConfirmTariffPageBase } from '../ConfirmTariffPageBase';
import { SeasonMultiToggleSwitch } from '../SeasonMultiToggleSwitch';
import { adjustSeasonDate, formatDollars } from '../tariff-helpers';
import { useHandleSubmitForm } from '../useHandleSubmitForm';
import { useTariffFormContext } from '../useTariffFormContext';

export const ConfirmTieredTariff = () => {
  const { control, isDirtyForm } = useTariffFormContext();
  const tariff = useWatch({ control, name: 'tariff' });
  const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0);
  const { t } = useTranslation();
  const { handleSubmitForm, isSubmitting } = useHandleSubmitForm();
  const chartOptions = useChartOptions(selectedSeasonIndex);

  const { formattedStartDate, formattedEndDate } = useMemo(() => {
    const startDate = adjustSeasonDate(
      tariff?.seasons[selectedSeasonIndex]?.fromMonth ?? 1,
      tariff?.seasons[selectedSeasonIndex]?.fromDate ?? 1
    );

    const endDate = adjustSeasonDate(
      tariff?.seasons[selectedSeasonIndex]?.toMonth ?? 1,
      tariff?.seasons[selectedSeasonIndex]?.toDate ?? 1
    );

    const formattedStartDate = startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    const formattedEndDate = endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

    return { formattedStartDate, formattedEndDate };
  }, [selectedSeasonIndex]);

  return (
    <ConfirmTariffPageBase>
      <SEHomeCard borderTopLeftRadius={'5px'} borderBottomRightRadius={'39px'} pr={0} pb={4}>
        <Text fontSize={20}>{tariff?.planName}</Text>

        <Text fontSize={17} fontWeight={500}>
          {t('Energy Rates.tiered plan').toUpperCase()}
        </Text>
        <Divider bg={'#9797974D'} mb={2} />

        <Box
          color={'#111111'}
          _dark={{
            color: '#CBCBCB',
          }}
        >
          <Text mt={4}>
            {tariff?.deliveryCharge?.chargePeriod && t(`Energy Rates.labels.${tariff.deliveryCharge.chargePeriod}`)}{' '}
            {t(`Energy Rates.delivery charge`)}
          </Text>
          <Text>
            ${tariff?.deliveryCharge?.rateBands[0].rate} /{' '}
            {tariff?.deliveryCharge?.chargePeriod && t(`Energy Rates.units.${tariff.deliveryCharge.chargePeriod}`)}
          </Text>

          <Box>
            <Text mt={4}>{t('Energy Rates.import rate')}</Text>
            <UnorderedList ml={6}>
              {tariff?.rates?.[selectedSeasonIndex]?.import?.[0]?.rateBands.map((rateBand, rateBandIndex) => {
                return (
                  <ListItem key={rateBand.sequenceNumber}>
                    {t('Energy Rates.tier')} {rateBandIndex + 1} {t('Energy Rates.price')}: ${rateBand.rate}
                    {' / kWh'}
                  </ListItem>
                );
              })}
            </UnorderedList>
          </Box>

          <Text mt={4}>{t('Energy Rates.export rate')}</Text>
          <Text>
            {t('Energy Rates.credit')}: ${tariff?.rates?.[selectedSeasonIndex]?.export?.[0]?.rateBands[0].rate} / kWh
          </Text>
        </Box>
      </SEHomeCard>

      <SEHomeCard borderTopLeftRadius={'5px'} borderBottomRightRadius={'39px'} my={4}>
        <SeasonMultiToggleSwitch
          value={selectedSeasonIndex}
          onChange={(newIndex) => setSelectedSeasonIndex(newIndex)}
        />
        <Text my={4}>
          {formattedStartDate} - {formattedEndDate}
        </Text>

        <Box>
          <Chart highcharts={Highcharts} options={chartOptions} />
        </Box>
      </SEHomeCard>

      <Center mt="auto" mb={4} flexDir={'column'}>
        <ArcButton
          arcColor="#3DCD58"
          minWidth="280px"
          py={6}
          isLoading={isSubmitting}
          data-testid="primary-button"
          onClick={handleSubmitForm}
        >
          {isDirtyForm ? t('Energy Rates.confirm rates') : t('Energy Rates.back to tariff list')}
        </ArcButton>
      </Center>
    </ConfirmTariffPageBase>
  );
};

function useChartOptions(selectedSeasonIndex: number) {
  const { control } = useTariffFormContext();
  const tariff = useWatch({ control, name: `tariff` });
  const textColor = useColorModeValue('black', 'white');
  const { t } = useTranslation();

  return useMemo(() => {
    const importRateBands = tariff?.rates?.[selectedSeasonIndex]?.import?.[0]?.rateBands;

    const rateBandSeries: Highcharts.SeriesOptionsType[] =
      importRateBands?.map((rateBand, rateBandIndex, rateBands) => {
        const isFirstIndex = rateBandIndex === 0;
        const isLastIndex = rateBandIndex === rateBands.length - 1;

        const getConsumptionUpperLimitLegendLabel = () => {
          const currentConsumptionUpperLimit = rateBand.consumptionUpperLimit || 0;
          if (isFirstIndex) return `< ${currentConsumptionUpperLimit}`;

          const previousConsumptionUpperLimit = rateBands[rateBandIndex - 1]?.consumptionUpperLimit || 0;
          if (isLastIndex) return `> ${previousConsumptionUpperLimit}`;
          return `${previousConsumptionUpperLimit}-${currentConsumptionUpperLimit}`;
        };

        return {
          name: isLastIndex ? t('Energy Rates.high usage') : `${t('Energy Rates.tier')} ${rateBandIndex + 1}`,
          type: 'column',
          color: COLOURS[rateBandIndex],
          custom: { consumptionUpperLimitLegendLabel: getConsumptionUpperLimitLegendLabel() },
          data: [
            {
              borderColor: COLOURS[rateBandIndex],
              y: rateBand.rate || 0,
              marker: { symbol: 'square' },
            },
          ],
        };
      }) || [];

    const exportRate = Number(tariff?.rates?.[selectedSeasonIndex]?.export?.[0]?.rateBands[0].rate) || 0;

    const exportSeries: Highcharts.SeriesOptionsType = {
      name: t('Energy Rates.export rate'),
      type: 'column',
      color: '#F19F39',
      data: [
        {
          borderColor: '#F19F39',
          y: exportRate,
          marker: { symbol: 'square' },
        },
      ],
    };

    const series = [...rateBandSeries, exportSeries];

    const options: Options = {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        height: 240,
        animation: false,
        marginBottom: 120,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: [''],
        labels: { useHTML: true },
        crosshair: true,
        lineColor: 'transparent',
        accessibility: { enabled: true, description: t('Energy Rates.rates') },
      },
      yAxis: {
        title: {
          text: `${t('Energy Rates.rate')} ( $ / kWh )`,
          style: { color: textColor },
        },
        tickAmount: 6,
        labels: {
          style: { color: textColor, fontSize: '12px' },
          formatter: function () {
            const value = Number(this.value);
            if (!value) return '0';
            if (value < 1) return value * 100 + '¢';
            return '$' + this.value;
          },
          align: 'right',
          x: -2,
        },
      },
      tooltip: {
        animation: false,
        valueSuffix: '¢',
        style: {
          color: 'white',
          fontSize: '16px',
        },
        backgroundColor: 'rgba(0,0,0,0.7)',
        borderRadius: 50,
        borderWidth: 0,
        shadow: false,
        padding: 12,
        formatter: function () {
          return `<span style="color:${this.color}; font-size: 24px;">∎</span>${
            this.series.name
          }: <span>${formatDollars(Number(this.y) || 0)} ${t('Energy Rates.per')} kWh</span><br/>`;
        },
      },
      plotOptions: {
        column: {
          borderRadius: 0,
        },
      },
      legend: {
        align: 'left',
        itemMarginTop: 6,
        labelFormatter: function () {
          // @ts-expect-error - not in types but accessible
          const yValue = this.yData[0];
          const consumptionUpperLimitLegendLabel = series
            ? series[this.index]?.custom?.consumptionUpperLimitLegendLabel
            : '';

          const style = `color: ${textColor}; font-size: 16px; fontWeight: 400;`;
          return `<span style="${style}">${this.name}: $${yValue} ${t('Energy Rates.per')} kWh ${
            consumptionUpperLimitLegendLabel ? consumptionUpperLimitLegendLabel + ' kWh' : ''
          }</span>`;
        },
        symbolRadius: 0,
      },
      credits: {
        enabled: false,
      },
      series,
    };

    return options;
  }, [selectedSeasonIndex]);
}
