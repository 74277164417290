import React from 'react';
import { Box, Center, SkeletonCircle } from '@chakra-ui/react';

import { CircleIcon, VPPIcon } from '../../../styles/custom-icons';

type CustomVppIconProps = {
  iconColor: string;
  isLoaded?: boolean;
  h?: string;
  w?: string;
};

export function CustomVppIcon({ iconColor, isLoaded = true, h = '175px', w = '175px' }: CustomVppIconProps) {
  return (
    <Center flexDirection="column" textAlign="center" h={h} w={w} mx="auto">
      {!isLoaded ? (
        <SkeletonCircle h={h} w={w} />
      ) : (
        <>
          <Box position={'absolute'}>
            <VPPIcon w="35%" h="35%" color={iconColor} data-testid={'vpp-icon'} />
          </Box>
          <CircleIcon color={iconColor} data-testid="circle-icon" zIndex={3} height="100%" width="100%" />
        </>
      )}
    </Center>
  );
}
