import React from 'react';
import { QuestionIcon } from '@chakra-ui/icons';
import { Center, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SlidingAnimationPageBase } from '../SlidingAnimationPageBase';

export function RouteNotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase onClickBack={() => navigate(-1)} title="">
      <Center flexDir="column" m={6} mt={20}>
        <QuestionIcon boxSize={30} />
        <Text fontSize={24} mx={10} pt={6}>
          {t('Page Not Found')}
        </Text>
      </Center>
    </SlidingAnimationPageBase>
  );
}
