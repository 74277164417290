import React from 'react';
import { Box, Button, ButtonProps, Link, useColorModeValue } from '@chakra-ui/react';

type ArcButtonProps = {
  arcColor: string;
  children: React.ReactNode | React.ReactNode[];
} & ButtonProps;

export function ArcButton({ arcColor, children, ...rest }: ArcButtonProps) {
  const buttonTheme = useColorModeValue(
    {
      variant: 'outline',
      background: 'customGrey.200',
      _hover: {
        background: 'customGrey.200',
      },
      borderColor: 'customGrey.300',
    },
    {
      variant: 'solid',
      background: 'customGrey.500',
      _hover: {
        background: 'customGrey.500',
      },
      borderColor: 'customGrey.500',
    }
  );
  return (
    <Button
      py={6}
      zIndex={5}
      overflow="hidden"
      minWidth="100px"
      position={'relative'}
      fontWeight={500}
      rounded={24}
      {...buttonTheme}
      {...rest}
    >
      <Box zIndex={8}>{children}</Box>
      <Box
        zIndex={6}
        position="absolute"
        w="40%"
        h="90%"
        border="2px solid"
        borderColor={`${arcColor} ${arcColor} ${arcColor} transparent`}
        background={buttonTheme.background}
        borderTopRightRadius="100px"
        borderBottomRightRadius="100px"
        right="2px"
      />
      <Box zIndex={7} position="absolute" w={'100%'} h="90%" background={buttonTheme.background} right="25px" />
    </Button>
  );
}

type ArcButtonWithLinkProps = ArcButtonProps & { href: string };

export const ArcButtonWithLink = ({ href, ...arcButtonProps }: ArcButtonWithLinkProps) => {
  return (
    <Link href={href}>
      <ArcButton {...arcButtonProps} />
    </Link>
  );
};
