import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Progress,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { formatDollarsNoCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';

import { SEHomeCard } from '../../common/components/SEHomeCard';
import { SlidingAnimationPageBase } from '../../common/components/SlidingAnimationPageBase';
import { IS_DEMO_LOGIN } from '../../common/constants';
import { useParamBackUrl } from '../../common/hooks/use-param-back-url';
import { useGetInvestmentsQuery } from './savingsApi';
import { SavingsStatusIcon } from './SavingsStatusIcon';

export function SavingsHome() {
  const navigate = useNavigate();
  const { data: investmentsData, isLoading } = useGetInvestmentsQuery();
  const savingsPercentage = useMemo(() => {
    if (investmentsData?.total_savings && investmentsData?.total_investment) {
      return (investmentsData.total_savings / investmentsData.total_investment) * 100;
    }
    return 0;
  }, [investmentsData]);
  const backURL = useParamBackUrl('../../home?direction=back');
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase backURL={backURL} title={t('Savings.savings')}>
      <Box flexDirection="column" mx={4}>
        <SavingsStatusIcon />
        <Text textAlign={'center'} fontSize={22} fontWeight={500} mb={1} mt={3}>
          {t('Savings.savings')}
        </Text>
        <Text textAlign={'center'} fontSize={16} fontWeight={400} mx={1} mb={3}>
          {t('Savings.shows the payback period')}{' '}
        </Text>
        <Text hidden={!investmentsData}>{t('Savings.total savings').toUpperCase()}</Text>
        <Skeleton borderRadius={5} borderBottomRightRadius={25} isLoaded={!isLoading} w="100%">
          <SEHomeCard borderTopLeftRadius={5} mb={4}>
            {investmentsData?.investments.length ? (
              <>
                <Heading mt={2} fontSize={16}>
                  {t('Savings.return on investment')}
                </Heading>

                <Flex justifyContent={'space-between'} my={1} flexWrap="wrap">
                  <Flex>
                    <Text fontSize={16} mr={1} data-testid="total-savings" overflowWrap="anywhere">
                      {formatDollarsNoCents(investmentsData.total_savings)}
                    </Text>
                    <Text alignSelf="end">{t('Savings.saved')}</Text>
                  </Flex>
                  <Flex hidden={investmentsData.payback_remaining < 0}>
                    <Text fontSize={16} mr={1} data-testid="payback-remaining" overflowWrap="anywhere">
                      {formatDollarsNoCents(investmentsData.payback_remaining)}
                    </Text>
                    <Text alignSelf="end">{t('Savings.left')}</Text>
                  </Flex>
                </Flex>

                <Progress
                  value={savingsPercentage}
                  colorScheme="primaryBranding"
                  background="#D9D9D9"
                  borderRadius="5px"
                />

                <Flex justifyContent={'space-between'} mb={5} mt={1}>
                  <Text fontSize={14} data-testid="investment-start-date">
                    {t('Savings.since')}: {investmentsData.start_date}
                  </Text>
                  <Text fontSize={14} data-testid="investment-projected-payoff-date">
                    {t('Savings.return estimated')} {investmentsData.projected_payoff_date}
                  </Text>
                </Flex>

                <Divider my={4} />

                <Accordion allowToggle>
                  <AccordionItem border={0}>
                    <AccordionButton p={0} data-testid="investments-expand-button" flexWrap="wrap">
                      <Heading fontSize={16} marginRight="auto" my={2}>
                        {t('Savings.total investments')}
                      </Heading>
                      <Text overflowWrap="anywhere">{formatDollarsNoCents(investmentsData.total_investment)}</Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p={0}>
                      {investmentsData.investments.map((investment) => (
                        <Flex justifyContent={'space-between'} mb={2} key={investment.id}>
                          <Text width="70%" data-testid={`investment-${investment.id}-description`}>
                            {investment.description}
                          </Text>
                          <Text overflowWrap="anywhere" data-testid={`investment-${investment.id}-amount`}>
                            {investment.amount && formatDollarsNoCents(investment.amount)}
                          </Text>
                        </Flex>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>

                <Divider my={4} />
                <Button
                  data-testid="edit-investment-cost-link-btn"
                  onClick={() => navigate(`../edit_investment_cost`)}
                  variant={'ghost'}
                  size={'sm'}
                  colorScheme={'schneiderSkyBlue'}
                  fontWeight={400}
                  fontSize={16}
                  pl={0}
                >
                  {t('Savings.edit investment cost')}
                </Button>
              </>
            ) : (
              <Box>
                <Text>{t('Savings.you have no investments')}</Text>
                <Divider my={4} />

                <Button
                  isDisabled={IS_DEMO_LOGIN}
                  data-testid="edit-investment-cost-link-btn"
                  onClick={() => navigate(`../edit_investment_cost`)}
                  variant={'ghost'}
                  size={'sm'}
                  colorScheme={'schneiderSkyBlue'}
                  fontWeight={400}
                  fontSize={16}
                  pl={0}
                >
                  {t('Savings.add investments')}
                </Button>
              </Box>
            )}
          </SEHomeCard>
        </Skeleton>
      </Box>
    </SlidingAnimationPageBase>
  );
}
