import React, { useEffect, useRef, useState } from 'react';
import { Wifi } from '@capacitor-community/wifi';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Select,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import {
  addWifiDetails,
  editWifiDetails,
  getSavedWifiDetails,
  WifiDetails,
} from '../../features/account/settings/wifi-settings/wifi-settings-helpers';
import { selectSite } from '../../features/site/siteSlice';
import { PasswordInput } from './PasswordInput';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: ({ password, ssid }: WifiNetworkFormData) => void;
  isLoading?: boolean;
  shouldConnectToNetwork?: boolean;
};
type WifiNetworkFormData = { password: string; ssid: string };

type State = {
  locallySavedWifiDetails: WifiDetails[];
  selectedSavedWifiDetails: WifiDetails | null;
  shouldSaveWifiDetails: boolean;
  shouldSetDefaultNetwork: boolean;
};

const INITIAL_STATE: State = {
  locallySavedWifiDetails: [],
  selectedSavedWifiDetails: null,
  shouldSaveWifiDetails: false,
  shouldSetDefaultNetwork: false,
};

export function SelectWifiDialog({
  isOpen,
  onClose,
  onConfirm,
  isLoading = false,
  shouldConnectToNetwork = false,
}: Props) {
  const { site_id: siteId } = useSelector(selectSite);
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const buttonBorderColor = useColorModeValue('#D9D9D9', '#494B50');
  const backgroundColor = useColorModeValue('white', 'customGrey.800');
  const [state, setState] = useState<State>(INITIAL_STATE);
  const { locallySavedWifiDetails, selectedSavedWifiDetails, shouldSaveWifiDetails, shouldSetDefaultNetwork } = state;
  const { t } = useTranslation();
  const toast = useToast({ isClosable: true });
  const schema = yup.object().shape({
    ssid: yup.string().required().label(t('Common.wifi ssid')),
    password: yup.string().required().label(t('Common.wifi pw')),
  });
  const {
    register,
    setValue,
    handleSubmit: handleFormSubmit,
    setError,
    clearErrors,
    formState: { errors },
    control,
  } = useForm<WifiNetworkFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
    },
  });
  const ssidInput = register('ssid');

  async function handleSubmit(values: WifiNetworkFormData) {
    const { ssid, password } = values;
    if (selectedSavedWifiDetails && shouldSetDefaultNetwork) {
      // Update the local saved wifi details if the user wants to set the network as default
      await editWifiDetails(siteId, selectedSavedWifiDetails.id, ssid, password, true);
    }
    if (!selectedSavedWifiDetails && shouldSaveWifiDetails) {
      // Save the inputted wifi details if the user wants to save them
      await addWifiDetails(siteId, ssid, password, false);
    }
    const body: WifiNetworkFormData = {
      ssid: values.ssid,
      password: values.password,
    };

    if (shouldConnectToNetwork) {
      try {
        await Wifi.connect({ ssid, password });
        toast({
          title: `Successfully connected to network ${ssid}`,
          status: 'success',
        });
      } catch (e) {
        setError('ssid', { message: 'Invalid credentials' });
        setError('password', { message: 'Invalid credentials' });
        toast({
          title: 'Invalid network provided',
          description: 'Check the SSID and/or password and try again',
          status: 'error',
        });
        return;
      }
    }

    onConfirm(body);
  }

  useEffect(() => {
    async function fetchSavedWifi() {
      const savedWifiDetails = await getSavedWifiDetails(siteId);
      const defaultWifiNetwork = savedWifiDetails.find((wifi) => wifi.isDefault);
      if (defaultWifiNetwork) {
        setValue('ssid', defaultWifiNetwork.ssid);
        setValue('password', defaultWifiNetwork.password);
      }
      setState((p) => ({
        ...p,
        locallySavedWifiDetails: savedWifiDetails,
        selectedSavedWifiDetails: defaultWifiNetwork ?? null,
        shouldSetDefaultNetwork: !!defaultWifiNetwork,
      }));
    }

    fetchSavedWifi();
  }, []);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent
          bgColor={backgroundColor}
          mx={8}
          rounded={16}
          overflowX="hidden"
          onSubmit={handleFormSubmit(handleSubmit)}
          as={'form'}
        >
          <AlertDialogHeader textAlign="center" pt={6} pb={0} fontSize="lg" fontWeight="bold" mx="auto">
            {t('Devices.enter your wifi')}
          </AlertDialogHeader>

          <AlertDialogBody my={2}>
            <FormControl isInvalid={!!errors.ssid} position="relative">
              <Input
                {...ssidInput}
                onChange={async (e) => {
                  clearErrors();
                  // Reset any selected saved wifi details when input changes
                  setState((p) => ({ ...p, selectedSavedWifiDetails: null }));
                  await ssidInput.onChange(e);
                }}
                defaultValue={selectedSavedWifiDetails?.ssid}
                borderRadius={0}
                type={'text'}
                background={backgroundColor}
                borderColor={'#9FA0A4'}
                placeholder={`${t('Common.wifi ssid')}...`}
                data-testid="edit-ssid-input"
                id="wifi-ssid"
              />
              <FormErrorMessage>{errors?.ssid?.message}</FormErrorMessage>
            </FormControl>

            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value, ref } }) => (
                <FormControl mt={2} isInvalid={!!errors.password} position="relative">
                  <PasswordInput
                    onChange={(e) => {
                      clearErrors();
                      // Reset any selected saved wifi details when input changes
                      setState((p) => ({ ...p, selectedSavedWifiDetails: null }));
                      onChange(e);
                    }}
                    defaultValue={selectedSavedWifiDetails?.password}
                    value={value}
                    ref={ref}
                    borderColor={'#9FA0A4'}
                    placeholder={`${t('Common.wifi pw')}...`}
                    data-testid="edit-password-input"
                  />
                  <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
                </FormControl>
              )}
            />

            <Checkbox
              defaultChecked={shouldSaveWifiDetails}
              isDisabled={!!selectedSavedWifiDetails}
              onChange={() => setState((p) => ({ ...p, shouldSaveWifiDetails: !shouldSaveWifiDetails }))}
              mt={2}
              data-testid="save-wifi-checkbox"
            >
              {t('Devices.save wifi')}
            </Checkbox>
            {locallySavedWifiDetails.length > 0 && (
              <>
                <Divider my={4} />
                <Select
                  data-testid="select-saved-wifi-details"
                  placeholder={`${t('Devices.or choose network')}...`}
                  mb={2}
                  defaultValue={selectedSavedWifiDetails?.id ?? undefined}
                  onChange={(e) => {
                    if (!e.target.value) setState((p) => ({ ...p, selectedSavedWifiDetails: null }));
                    else {
                      const wifiDetail = locallySavedWifiDetails.find((wifi) => wifi.id === e.target.value);
                      setValue('ssid', wifiDetail!.ssid);
                      setValue('password', wifiDetail!.password);
                      setState((p) => ({
                        ...p,
                        selectedSavedWifiDetails: wifiDetail!,
                        shouldSetDefaultNetwork: wifiDetail!.isDefault,
                      }));
                    }
                  }}
                >
                  {locallySavedWifiDetails.map((wifiDetail) => (
                    <option key={wifiDetail.id} value={wifiDetail.id}>
                      {wifiDetail.ssid}
                    </option>
                  ))}
                </Select>
                <Checkbox
                  colorScheme="primaryBranding"
                  isChecked={shouldSetDefaultNetwork}
                  isDisabled={!selectedSavedWifiDetails}
                  iconColor="#fff"
                  onChange={() => setState((p) => ({ ...p, shouldSetDefaultNetwork: !shouldSetDefaultNetwork }))}
                  data-testid="set-default-network-checkbox"
                >
                  {t('Account.set default network')}
                </Checkbox>
              </>
            )}
          </AlertDialogBody>

          <Flex w="100%" justifyContent={'space-around'}>
            <Button
              w="100%"
              rounded={0}
              py={6}
              fontWeight="normal"
              borderRight="1px solid"
              borderTop="1px solid"
              borderColor={buttonBorderColor}
              variant="ghost"
              onClick={onClose}
              ref={cancelRef}
            >
              {t('Common.cancel')}
            </Button>

            <Button
              color="#008A16"
              w="100%"
              rounded={0}
              py={6}
              variant="ghost"
              borderTop="1px solid"
              borderColor={buttonBorderColor}
              type="submit"
              data-testid="confirm-button"
              isLoading={isLoading}
            >
              {t('Common.confirm')}
            </Button>
          </Flex>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
