import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';
import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { IS_DEMO_LOGIN, TOP_BANNER_SPACING_AFFORDANCE } from '../../../common/constants';
import { BoltIcon } from '../../../styles/custom-icons';

export function EnergyModesLoadingModal({ isOpen, onClose }: DialogProps) {
  const bgColor = useColorModeValue('#fff', '#111');
  const { t } = useTranslation();

  return (
    <Modal scrollBehavior={'inside'} isOpen={isOpen} onClose={onClose} size="full" motionPreset="slideInRight">
      <ModalContent
        data-testid="energy-modes-modal"
        backgroundColor={bgColor}
        top={IS_DEMO_LOGIN ? TOP_BANNER_SPACING_AFFORDANCE : 'env(safe-area-inset-top)'}
      >
        <ModalCloseButton
          position={'absolute'}
          right={6}
          mt={1.5}
          fontSize={'17px'}
          data-testid="energy-modes-back-button"
          onClick={onClose}
        >
          <CloseIcon w="14px" h="14px" />
        </ModalCloseButton>
        <ModalBody display="flex" alignItems="center" justifyContent="center">
          <Center flexDirection="column">
            <Box width="130px" height="130px" position="relative" mb={2}>
              <BoltIcon
                width="48px"
                height="48px"
                position="absolute"
                top="45px"
                left="40px"
                color="primaryBranding.500"
              />
              <CenteredLoader text={''} progressProps={{ size: '130px', thickness: '8px' }} />
            </Box>
            <Text fontSize="24px">{t('Energy Modes.pending setting mode')}</Text>
            <Text>{t('Energy Modes.pending setting mode desc')}</Text>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
