import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLiveData } from '../../../site/live-data/liveDataApi';
import { HomePageWidgetTileProps } from '../HomePageWidgetTile';
import { GenericHomePageWidgetTile } from './GenericHomePageWidgetTile';

export function SolarWidgetTile({ item, index, isEditing, onRemoveGridItem }: HomePageWidgetTileProps) {
  const { data: liveDataSummary, isInverterDataLoading: isLoading } = useLiveData();
  const { t } = useTranslation();

  return (
    <GenericHomePageWidgetTile
      isLoaded={!isLoading}
      item={{
        ...item,
        title: t('Widgets.Solar.title'),
        status: liveDataSummary.solar > 0 ? t('Widgets.Solar.on').toUpperCase() : t('Widgets.Solar.off').toUpperCase(),
      }}
      index={index}
      isEditing={isEditing}
      onRemoveGridItem={onRemoveGridItem}
    />
  );
}
