import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { formatInTimeZone } from 'date-fns-tz';

import { EvChargerData, GenericStatusResponse } from 'clipsal-cortex-types/src/api/api-evcharger';

import { RootState } from '../../../app/store';
import { post } from '../../../common/api/api-helpers';
import { MANUFACTURER_ID_SCHNEIDER } from '../../devices/devices-helper';
import { addEvCharger } from '../../site/siteSlice';
import { addEnrolledEvChargerDetails } from '../dashboard/evChargerSlice';

type BarCodeValues = { ref: string; sn: string; cpid: string };
export type DeviceSetupState = {
  status: GenericStatusResponse['status'];
  message: string;
  isLoaded: boolean;
  hasInitiatedEnroll: boolean;
};

export const enrolEvCharger = createAsyncThunk<GenericStatusResponse, BarCodeValues, { state: RootState }>(
  'site/enrolEvCharger',
  async ({ ref: model, sn: serialNumber, cpid: chargerId }, { getState, dispatch }) => {
    // Charger QR scanning gives the following three attributes::
    // ref = Model of the charger
    // sn = Serial number of the charger
    // cpid = ID of the charger

    try {
      const state = getState();
      const siteID = state.site.site_id;
      const body = { charger_id: chargerId, serial_number: serialNumber, model_number: model };
      const response = await post<EvChargerData>(`/v1/sites/${siteID}/ev_chargers`, body);
      // add the charger to the site
      dispatch(
        addEvCharger({
          id: 0, // @TODO: update API for this...
          device_type: 'EV_CHARGER',
          manufacturer_id: MANUFACTURER_ID_SCHNEIDER,
          manufacturer_name: 'Schneider Electric',
          manufacturer: 'Schneider Electric',
          model,
          created: formatInTimeZone(new Date(), state.site.timezone, 'yyyy-MM-dd'),
          site_identifier: chargerId,
          serial_number: chargerId,
          operating_state: null,
          is_hybrid_inverter: false,
          ja12_compliance: false,
        })
      );

      // need to pull fresh data from the server as the redux store is not updated yet
      dispatch(addEnrolledEvChargerDetails(response));

      return { status: 'success' };
    } catch (error) {
      const message = ((<AxiosError>error) as any)?.response?.data.message || '';
      return { status: 'fail', message };
    }
  }
);

const initialState: DeviceSetupState = {
  isLoaded: false,
  hasInitiatedEnroll: false,
  status: 'success',
  message: '',
};

export const deviceSetupSlice = createSlice({
  name: 'deviceSetup',
  initialState,
  reducers: {
    resetEnrollState: (state) => {
      return { ...state, hasInitiatedEnroll: false };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(enrolEvCharger.pending, (state) => {
      return { ...state, hasInitiatedEnroll: true, isLoaded: false };
    });
    builder.addCase(enrolEvCharger.fulfilled, (state, { payload }) => {
      return { ...state, ...payload, isLoaded: true };
    });
    builder.addCase(enrolEvCharger.rejected, (state) => {
      return { ...state, status: 'fail', isLoaded: true };
    });
  },
});

export const { resetEnrollState } = deviceSetupSlice.actions;

export const selectDeviceSetupState = (state: RootState) => {
  return state.deviceSetup;
};

export const { reducer: deviceSetupReducer } = deviceSetupSlice;
