import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Center, Flex, Skeleton, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AvailableTeslaBattery } from 'clipsal-cortex-types/src/api/api-tesla';

import { useReduxDispatch } from '../../../../app/store';
import { SEHomeCard } from '../../../../common/components/SEHomeCard';
import { IS_PRODUCTION } from '../../../../common/constants';
import { SEHomeBatteryIcon } from '../../../../styles/custom-icons';
import { useCommissionBatteryMutation, useDecommissionBatteryMutation } from '../../../battery/batteryApi';
import { fetchSite, selectSite } from '../../../site/siteSlice';

interface Props {
  device: AvailableTeslaBattery;
}

export function TeslaAvailableDevice({ device }: Props) {
  const {
    site_id: externalSiteId,
    device_name: deviceName,
    active,
    site_name: siteName,
    serial_number: serialNumber,
  } = device;
  const { site_id: siteId } = useSelector(selectSite);
  const { t } = useTranslation();
  const textColor = useColorModeValue('customGrey.800', 'customGrey.300');
  const toast = useToast({ isClosable: true });
  const dispatch = useReduxDispatch();
  const [commissionBattery, { isLoading: isCreateLoading }] = useCommissionBatteryMutation();
  const [decommissionBattery, { isLoading: isDeleteLoading }] = useDecommissionBatteryMutation();
  const isLoading = isCreateLoading || isDeleteLoading;

  const handleCommission = async () => {
    try {
      await commissionBattery({
        siteId: Number(siteId),
        body: {
          external_site_id: externalSiteId,
          provider: 'TESLA',
        },
      }).unwrap();
      await dispatch(fetchSite({ siteID: siteId, isProduction: IS_PRODUCTION }));
      /* istanbul ignore next -- @preserve */
      toast({
        title: t('Integrations.Tesla.commission battery success'),
        status: 'success',
      });
    } catch (e) {
      /* istanbul ignore next -- @preserve */
      toast({
        title: t('Integrations.Tesla.commission battery error'),
        status: 'error',
      });
    }
  };
  const handleDeCommission = async () => {
    try {
      await decommissionBattery({
        siteId: Number(siteId),
        body: {
          external_site_id: externalSiteId,
          provider: 'TESLA',
        },
      }).unwrap();
      await dispatch(fetchSite({ siteID: siteId, isProduction: IS_PRODUCTION }));
      /* istanbul ignore next -- @preserve */
      toast({
        title: t('Integrations.Tesla.decommission battery success'),
        status: 'success',
      });
    } catch (e) {
      /* istanbul ignore next -- @preserve */
      toast({
        title: t('Integrations.Tesla.decommission battery error'),
        status: 'error',
      });
    }
  };

  return (
    <Skeleton isLoaded={!isLoading} borderRadius={5}>
      <SEHomeCard
        cursor="pointer"
        p={4}
        borderTopLeftRadius="5px"
        mb={2}
        data-testid={'tesla-device'}
        onClick={!active ? () => handleCommission() : undefined}
      >
        <Flex align="flex-start">
          <Center marginRight="auto">
            <SEHomeBatteryIcon w={8} h={8} mr={4} />
            <Flex direction="column">
              <Text fontSize={17} fontWeight={400} lineHeight={1.2} overflowWrap="anywhere">
                {deviceName}
              </Text>
              <Text fontSize={15} color={textColor} fontWeight={400} lineHeight={1.1} mr={1}>
                {`S/N: ${serialNumber ?? 'N/A'}`}
              </Text>
              <Text fontSize={15} color={textColor} fontWeight={400} lineHeight={1.1} mr={1}>
                {siteName}
              </Text>
            </Flex>
          </Center>
          <Flex>
            {active && <CloseIcon data-testid="tesla-device-close" boxSize={3} onClick={() => handleDeCommission()} />}
          </Flex>
        </Flex>
      </SEHomeCard>
    </Skeleton>
  );
}
