import React from 'react';
import { Button, useColorModeValue } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useTariffFormContext } from './useTariffFormContext';

export const ManualTariffSetupButton = () => {
  const { errors, setValue, clearErrors, control } = useTariffFormContext();
  const navigate = useNavigate();
  const { planName } = useWatch({ name: 'tariff', control });
  const linkButtonColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const { t } = useTranslation();

  const handleClick = () => {
    if (errors.tariff?.utility?.id) clearErrors('tariff.utility.id');
    if (errors.zipCode) clearErrors('zipCode');
    if (!planName) setValue('tariff.planName', 'Manual Tariff', { shouldDirty: true });
    navigate('../tariff-details');
  };

  return (
    <Button
      variant="link"
      px={0}
      minWidth={'fit-content'}
      w="fit-content"
      fontWeight={400}
      fontSize={15}
      mt={4}
      color={linkButtonColor}
      onClick={handleClick}
      data-testid="manual-tariff-setup-button"
    >
      {t('Energy Rates.set up manually')}
    </Button>
  );
};
