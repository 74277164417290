import { useSelector } from 'react-redux';

import { selectSite } from '../../../site/siteSlice';
import { useGetConnectionStatusQuery } from './teslaApi';

export function useTeslaIsConnected() {
  const { site_id: siteId } = useSelector(selectSite);
  const { data: connectionStatus, isLoading, isError, error } = useGetConnectionStatusQuery(siteId);
  const isConnected = !isError && !isLoading && connectionStatus?.status === 'success';
  // As the API returns a 404 error when there is NO Tesla connection, we need to ignore that error.
  const isRealError = isError && ('status' in error ? error.status !== 404 : isError);
  return {
    isConnected,
    isLoading,
    isError: isRealError,
    error,
  };
}
