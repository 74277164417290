import React from 'react';
import { Center, ChakraProvider, ColorModeScript, Heading } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { Trans } from 'react-i18next';
import { Provider } from 'react-redux';

import { NetworkStatusProvider } from 'clipsal-cortex-ui/src/components/NetworkStatusProvider';
import { ServiceWorkerProvider } from 'clipsal-cortex-utils/src/providers/service-worker-provider';

import { App } from './App';
import { store } from './app/store';
import { COMPONENT_MIN_HEIGHT } from './common/constants';
import { getTheme } from './styles/theme';

export const AppProvider = () => {
  const theme = getTheme();

  return (
    <React.StrictMode>
      <Sentry.ErrorBoundary
        fallback={
          <Center minH={COMPONENT_MIN_HEIGHT} w={'100%'}>
            <Heading textAlign={'center'}>
              <Trans i18nKey="Common.something went wrong" /> <Trans i18nKey="Common.please refresh the page" />{' '}
              <Trans i18nKey="Common.if this error persists" />
            </Heading>
          </Center>
        }
      >
        <NetworkStatusProvider>
          <Provider store={store}>
            <ChakraProvider theme={theme}>
              <ServiceWorkerProvider>
                <ColorModeScript />
                <App />
              </ServiceWorkerProvider>
            </ChakraProvider>
          </Provider>
        </NetworkStatusProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
};
