import { useEffect } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';

import { IS_IOS, IS_NATIVE } from '../constants';

export function useAuthRedirect() {
  useEffect(() => {
    if (!IS_NATIVE) return;
    // Used for OIDC auth
    CapacitorApp.addListener('appUrlOpen', async (data) => {
      const params = new URLSearchParams(data.url.split('?')?.[1]);
      const code = params.get('code');
      const state = params.get('state');

      if (!code) return;

      // The root domain of the webview differs according to the platform.
      // We reload the page with the passed query parameters to authorize with cIAM through federated identities.
      const rootDomain = IS_IOS ? 'capacitor://localhost' : 'http://localhost';
      const paramsString = `?code=${code}&state=${state ?? ''}`;

      if (data.url.includes('app/tesla')) {
        window.location.replace(`${rootDomain}/app/tesla${paramsString}`);
      } else {
        window.location.replace(`${rootDomain}/${paramsString}`);
      }

      // Not sure if this is a bug, but the browser does not close itself on iOS, so we have to do so manually.
      if (IS_IOS) await Browser.close();
    });
  }, []);
}
