import React from 'react';

import { HomePageWidgetTileProps } from '../HomePageWidgetTile';
import { GenericHomePageWidgetTile } from './GenericHomePageWidgetTile';

// @TODO: note that this component serves only to wrap `GenericHomePageWidgetTile` and provide the `isLoaded` prop as
//        false, for tiles with no back-end integration
export function IncompleteWidgetTile({ item, index, isEditing, onRemoveGridItem }: HomePageWidgetTileProps) {
  return (
    <GenericHomePageWidgetTile
      isLoaded={true}
      item={item}
      index={index}
      isEditing={isEditing}
      onRemoveGridItem={onRemoveGridItem}
    />
  );
}
