import React, { useMemo, useState } from 'react';
import { Button, Center, Flex, Skeleton, SkeletonCircle, Text, useColorModeValue } from '@chakra-ui/react';
import Highcharts, { Options } from 'highcharts';
import Chart from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SenseLiveDataErrorAlert } from '../common/SenseLiveDataErrorAlert';
import { useContainerSize } from '../common/use-container-size';
import { CHART_DEFAULT_OPTIONS } from '../constants';
import { useDevicesChartData } from './use-devices-chart-data';
import { useLiveChartData } from './use-live-chart-data';
import { useSourcesChartData } from './use-sources-chart-data';

export function PowerChart() {
  const { t } = useTranslation();
  const [showDial, setShowDial] = useState(true);

  // This is here to prefetch data ASAP.
  const { ref, size } = useContainerSize();
  const { isLoading, options, isError, error } = useLiveChartData(size?.width ?? null);

  return (
    <>
      <Text fontSize="xs">
        {t('Home Sense.charts')}:
        <Button
          ml={1}
          fontSize="xs"
          onClick={() => setShowDial(true)}
          variant={'link'}
          color={showDial ? 'schneiderSkyBlue.200' : undefined}
          _hover={{ color: 'schneiderSkyBlue.200' }}
          data-testid="dial-chart-button"
        >
          {t('Home Sense.chart dial')}
        </Button>
        {` | `}
        <Button
          fontSize="xs"
          onClick={() => setShowDial(false)}
          variant={'link'}
          color={!showDial ? 'schneiderSkyBlue.200' : undefined}
          _hover={{ color: 'schneiderSkyBlue.200' }}
          data-testid="line-chart-button"
        >
          {t('Home Sense.chart line')}
        </Button>
      </Text>
      <Center height="300px" my={1}>
        {isError && error === 'WEBSOCKET_ERROR' ? (
          <SenseLiveDataErrorAlert />
        ) : (
          <>
            <Center hidden={!showDial} position="absolute" height="300px" width="calc(100vw - 24px)">
              <DialChart />
            </Center>
            <Center hidden={showDial} position="absolute" height="300px" width="calc(100vw - 24px)" ref={ref}>
              <LineChart isLoading={isLoading} options={options} />
            </Center>
          </>
        )}
      </Center>
    </>
  );
}

function DialChart() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const backgroundColor = useColorModeValue('#F1F1F1', '#2A3039');
  const textColor = useColorModeValue('customGrey.900', 'customGrey.400');
  const { isLoading: isDevicesLoading, options: devicesOptions } = useDevicesChartData();
  const { data, isLoading: isSourcesLoading, options: sourcesOptions } = useSourcesChartData();
  const isLoaded = !isDevicesLoading && !isSourcesLoading;

  const currentUse = useMemo(
    () =>
      Intl.NumberFormat('en', {
        notation: 'compact',
        maximumFractionDigits: 2,
      }).format(data.consumption ?? 0),
    [data.consumption]
  );

  const backgroundOptions = useMemo(
    () => ({
      ...CHART_DEFAULT_OPTIONS,
      plotOptions: {
        pie: {
          ...CHART_DEFAULT_OPTIONS.plotOptions.pie,
          color: backgroundColor,
          borderWidth: 40,
        },
      },
    }),
    [backgroundColor]
  );

  return !isLoaded ? (
    <SkeletonCircle isLoaded={isLoaded} size="300px" position="absolute" />
  ) : (
    <Center data-testid="power-dial-chart" onClick={() => navigate(`../live_energy_flow`)}>
      <Flex position="absolute">
        <Chart highcharts={Highcharts} options={backgroundOptions} />
      </Flex>
      <Flex position="absolute" data-testid="sources-chart">
        <Chart highcharts={Highcharts} options={sourcesOptions} />
      </Flex>
      <Flex position="absolute" data-testid="devices-chart">
        <Chart highcharts={Highcharts} options={devicesOptions} />
      </Flex>
      <Center flexDirection="column" margin="auto" textAlign={'center'}>
        <Text fontSize={36} lineHeight={6} data-testid="current-use-value">
          {currentUse}
          <Text as="span" ml={1} fontSize="md">
            kW
          </Text>
        </Text>
        <Text fontSize="sm" color={textColor} maxW={100}>
          {t('Home Screen.current use').toUpperCase()}
        </Text>
      </Center>
    </Center>
  );
}

interface LineChartProps {
  isLoading: boolean;
  options: Options;
}

function LineChart({ isLoading, options }: LineChartProps) {
  return isLoading ? (
    <Skeleton isLoaded={!isLoading} height="300px" width="100%" position="absolute" borderRadius={5} />
  ) : (
    <Center>
      <Flex position="absolute" width="100%" mt={2} data-testid="line-chart">
        <Chart highcharts={Highcharts} options={options} />
      </Flex>
    </Center>
  );
}
