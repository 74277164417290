import React, { useState } from 'react';
import {
  Box,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ArcButton } from '../../../common/components/ArcButton';
import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { useParamBackUrl } from '../../../common/hooks/use-param-back-url';
import { MinimumDeliveryCharge } from '../MinimumDeliveryCharge';
import { SeasonMultiToggleSwitch } from '../SeasonMultiToggleSwitch';
import { MIN_TARIFF_PAGE_HEIGHT, useTranslatedCommonInputProps } from '../tariff-constants';
import { useTariffFormContext } from '../useTariffFormContext';

export const FlatTariffRates = () => {
  const { errors, register, control, handleSubmit } = useTariffFormContext();
  const { tariff } = useWatch({ control });
  const seasons = tariff?.seasons || [];
  const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0);
  const navigate = useNavigate();
  const backURL = useParamBackUrl('../../select-season?direction=back');

  const { t } = useTranslation();
  const COMMON_INPUT_PROPS = useTranslatedCommonInputProps();

  return (
    <SlidingAnimationPageBase
      title={t('Energy Rates.manual energy rates set up')}
      includeTopNav
      px={4}
      backURL={backURL}
      data-testid="flat-tariff-rates"
    >
      <VStack align={'initial'} minHeight={MIN_TARIFF_PAGE_HEIGHT}>
        <Text>
          {t('Energy Rates.you chose flat rate')} {t('Energy Rates.enter the price for your flat service')}
        </Text>

        <Box mt={3}>
          <MinimumDeliveryCharge />
        </Box>

        <SeasonMultiToggleSwitch
          value={selectedSeasonIndex}
          onChange={(newIndex) => setSelectedSeasonIndex(newIndex)}
          containerProps={{ mt: 4 }}
        />

        {/* Without mapping, uncontrolled components are not re-rendered by react */}
        {seasons.map((season, index) => {
          return (
            <Box mb={4} key={season.seasonIndex} display={index === selectedSeasonIndex ? undefined : 'none'}>
              <Box mt={3}>
                <Text lineHeight={'19px'}>{t('Energy Rates.import rate').toUpperCase()}</Text>
                <Divider borderColor={'#C6C6C6'} mb={4} mt={1} />
                <Text fontWeight={'bold'} lineHeight={'19px'} mb={3}>
                  {t('Energy Rates.enter price per kwh imported')}
                </Text>
                <FormControl
                  isInvalid={!!errors?.tariff?.rates?.[index]?.import?.[0]?.rateBands?.[0]?.rate?.message}
                  data-testid={`season-${index}-import-rate-form`}
                >
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" color="gray.300" h={12}>
                      $
                    </InputLeftElement>
                    <Input
                      {...COMMON_INPUT_PROPS}
                      {...register(`tariff.rates.${index}.import.0.rateBands.0.rate`, { valueAsNumber: true })}
                      pr={16}
                    />
                    <InputRightElement pointerEvents="none" color="gray.300" h={12} w={16}>
                      / kWh
                    </InputRightElement>
                  </InputGroup>

                  <FormErrorMessage>
                    {errors?.tariff?.rates?.[index]?.import?.[0]?.rateBands?.[0]?.rate?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box mt={3}>
                <Text lineHeight={'19px'}>{t('Energy Rates.export rate').toUpperCase()}</Text>
                <Divider borderColor={'#C6C6C6'} mb={4} mt={1} />
                <Text fontWeight={'bold'} lineHeight={'19px'} mb={3}>
                  {t('Energy Rates.enter price per kwh exported')}
                </Text>
                <FormControl
                  isInvalid={!!errors?.tariff?.rates?.[index]?.export?.[0]?.rateBands?.[0]?.rate?.message}
                  data-testid={`season-${index}-export-rate-form`}
                >
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" color="gray.300" h={12}>
                      $
                    </InputLeftElement>
                    <Input
                      {...COMMON_INPUT_PROPS}
                      {...register(`tariff.rates.${index}.export.0.rateBands.0.rate`, { valueAsNumber: true })}
                      pr={16}
                    />
                    <InputRightElement pointerEvents="none" color="gray.300" h={12} w={16}>
                      / kWh
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors?.tariff?.rates?.[index]?.export?.[0]?.rateBands?.[0]?.rate?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </Box>
          );
        })}

        <Center mt="auto" mb={6} flexDir={'column'}>
          <ArcButton
            arcColor="#3DCD58"
            minWidth="280px"
            py={6}
            onClick={() => {
              handleSubmit(
                () => {
                  // using `handleSubmit` triggers validation and activates revalidation modes
                  // using `trigger` does not trigger revalidation
                  // Reaching here means form is valid. Thus, go to confirm page
                  navigate('../confirm');
                },
                (errors) => {
                  // Reaching here means form is invalid. Take user to the first tab with errors
                  const firstIndexWithError = seasons.findIndex((_, index) => errors?.tariff?.rates?.[index]);
                  if (firstIndexWithError >= 0) setSelectedSeasonIndex(firstIndexWithError);
                }
              )();
            }}
            data-testid="continue-button"
          >
            {t('Common.continue')}
          </ArcButton>
        </Center>
      </VStack>
    </SlidingAnimationPageBase>
  );
};
