import React, { useEffect, useState } from 'react';
import { Browser } from '@capacitor/browser';
import { Button, CircularProgress, Flex, Image, Text } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { IS_RUNNING_CYPRESS_TESTS } from 'clipsal-cortex/src/common/constants';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import loggedOutImg from '../../../assets/images/logged_out.svg';
import { COMPONENT_MIN_HEIGHT, IS_ANDROID } from '../../../common/constants';
import { getCognitoLogOutUrl, getIdpLogOutUrl } from '../auth-helpers';
import styles from './Logout.module.scss';

export function Logout() {
  const navigate = useNavigate();
  const [isLoaded, setLoaded] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    /**
     * Log out of the application.
     * This is done in 3 steps:
     *  1. Log out of the EIM IdP session
     *  2. Log out of the Cognito session
     *  3. Clear out everything else (reset Redux store and call `await Auth.signOut()`)
     */
    async function logOut() {
      if (!IS_RUNNING_CYPRESS_TESTS) {
        let hasOpenedCognitoLogOutUrl = false;
        // This closes the browser after handling EIM log out redirect, then opens the browser for Cognito log out.
        const pageLoadedListener = Browser.addListener('browserPageLoaded', async () => {
          await Browser.close();
          if (hasOpenedCognitoLogOutUrl) {
            pageLoadedListener.remove();
            return;
          }
          try {
            setTimeout(
              async () =>
                await Browser.open({
                  url: getCognitoLogOutUrl(),
                  windowName: '_self',
                }),
              // Android requires refractory period before opening another browser
              IS_ANDROID ? 1000 : 0
            );
          } catch (error) {
            console.error('Error getting Cognito log out URL', error);
          }
          hasOpenedCognitoLogOutUrl = true;
        });

        // Log out of EIM
        try {
          await Browser.open({
            url: await getIdpLogOutUrl(),
            windowName: '_self',
          });
        } catch (error) {
          console.error('Error getting IDP log out URL', error);
        }
      }
      // EIM Session has expired so we just log out of Cognito
      await Auth.signOut();

      dispatch({ type: 'store/reset' });

      setLoaded(true);
    }
    logOut();
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setShouldRedirect(true);
    }, 7000);
  });

  useEffect(() => {
    if (isLoaded && shouldRedirect) navigate('/');
  }, [isLoaded, shouldRedirect]);

  return isLoaded ? (
    <>
      <div className={styles.container} id="logged-out-card">
        <div className={styles.logoutContainer}>
          <Image mb={4} w="60%" src={loggedOutImg} alt="Page not found" />
          <Text mb={2} fontSize="md">
            {t('Account.you have logged out')}
          </Text>
          <Button variant="outline">
            <Link to="/login">{t('Account.click here to log in')}</Link>
          </Button>
        </div>
      </div>
    </>
  ) : (
    <Flex direction="column" height={COMPONENT_MIN_HEIGHT} align="center" justify="center">
      <Text fontSize="lg" mb={2}>
        {t('Common.loading')}
      </Text>
      <CircularProgress isIndeterminate color="green" />
    </Flex>
  );
}
