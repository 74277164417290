import React, { useMemo, useState } from 'react';
import { Box, Center, Divider, Text, useColorModeValue } from '@chakra-ui/react';
import Highcharts, { Options } from 'highcharts';
import Chart from 'highcharts-react-official';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ArcButton } from '../../../common/components/ArcButton';
import { SEHomeCard } from '../../../common/components/SEHomeCard';
import { ConfirmTariffPageBase } from '../ConfirmTariffPageBase';
import { SeasonMultiToggleSwitch } from '../SeasonMultiToggleSwitch';
import { adjustSeasonDate, formatDollars } from '../tariff-helpers';
import { useHandleSubmitForm } from '../useHandleSubmitForm';
import { useTariffFormContext } from '../useTariffFormContext';

export const ConfirmFlatTariff = () => {
  const { control, isDirtyForm } = useTariffFormContext();
  const tariff = useWatch({ control, name: `tariff` });
  const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0);
  const { handleSubmitForm, isSubmitting } = useHandleSubmitForm();
  const { i18n, t } = useTranslation();

  const chartOptions = useChartOptions(selectedSeasonIndex);

  const { formattedStartDate, formattedEndDate } = useMemo(() => {
    const startDate = adjustSeasonDate(
      tariff?.seasons[selectedSeasonIndex]?.fromMonth,
      tariff?.seasons[selectedSeasonIndex]?.fromDate
    );

    const endDate = adjustSeasonDate(
      tariff?.seasons[selectedSeasonIndex]?.toMonth,
      tariff?.seasons[selectedSeasonIndex]?.toDate
    );

    const formattedStartDate = startDate.toLocaleDateString(i18n.language, { month: 'short', day: 'numeric' });
    const formattedEndDate = endDate.toLocaleDateString(i18n.language, { month: 'short', day: 'numeric' });

    return { formattedStartDate, formattedEndDate };
  }, [selectedSeasonIndex]);

  return (
    <ConfirmTariffPageBase>
      <SEHomeCard borderTopLeftRadius={'5px'} borderBottomRightRadius={'39px'} pr={0} pb={4}>
        <Text fontSize={20}>{tariff?.planName}</Text>
        <Divider borderColor={'#9797974D'} my={2} />

        <Text fontSize={17} fontWeight={500}>
          {t('Energy Rates.flat plan').toUpperCase()}
        </Text>

        <Box
          color={'#111111'}
          _dark={{
            color: '#CBCBCB',
          }}
        >
          <Text mt={4}>
            {tariff?.deliveryCharge?.chargePeriod && t(`Energy Rates.labels.${tariff.deliveryCharge.chargePeriod}`)}{' '}
            {t('Energy Rates.delivery charge')}
          </Text>
          <Text>
            ${tariff?.deliveryCharge?.rateBands[0].rate} /{' '}
            {tariff?.deliveryCharge?.chargePeriod && t(`Energy Rates.units.${tariff.deliveryCharge.chargePeriod}`)}
          </Text>

          <Text mt={4}>{t('Energy Rates.import rate')}</Text>
          <Text>
            {t('Common.cost')}: ${tariff?.rates?.[selectedSeasonIndex]?.import?.[0]?.rateBands[0].rate} / kWh
          </Text>

          <Text mt={4}>{t('Energy Rates.export rate')}</Text>
          <Text>
            {t('Energy Rates.credit')}: ${tariff?.rates?.[selectedSeasonIndex]?.export?.[0]?.rateBands[0].rate} / kWh
          </Text>
        </Box>
      </SEHomeCard>

      <SEHomeCard borderTopLeftRadius={'5px'} borderBottomRightRadius={'39px'} my={4}>
        <SeasonMultiToggleSwitch
          value={selectedSeasonIndex}
          onChange={(newIndex) => setSelectedSeasonIndex(newIndex)}
        />
        <Text my={4}>
          {formattedStartDate} - {formattedEndDate}
        </Text>

        <Box>
          <Chart highcharts={Highcharts} options={chartOptions} />
        </Box>
      </SEHomeCard>

      <Center mt="auto" mb={4} flexDir={'column'}>
        <ArcButton
          arcColor="#3DCD58"
          minWidth="280px"
          py={6}
          isLoading={isSubmitting}
          data-testid="primary-button"
          onClick={handleSubmitForm}
        >
          {isDirtyForm ? t('Energy Rates.confirm rates') : t('Energy Rates.back to tariff list')}
        </ArcButton>
      </Center>
    </ConfirmTariffPageBase>
  );
};

function useChartOptions(selectedSeasonIndex: number) {
  const { control } = useTariffFormContext();
  const tariff = useWatch({ control, name: `tariff` });
  const textColor = useColorModeValue('black', 'white');
  const { t } = useTranslation();

  return useMemo(() => {
    const importRate = Number(tariff?.rates?.[selectedSeasonIndex]?.import?.[0]?.rateBands[0].rate) || 0;
    const exportRate = Number(tariff?.rates?.[selectedSeasonIndex]?.export?.[0]?.rateBands[0].rate) || 0;

    const chartOptions: Options = {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        height: 210,
        animation: false,
        marginBottom: 100,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: [''],
        labels: { useHTML: true },
        crosshair: true,
        lineColor: 'transparent',
        accessibility: { enabled: true, description: 'Rates' },
      },
      yAxis: {
        title: {
          text: `${t('Energy Rates.rate')} ( $ / kWh )`,
          style: { color: textColor },
        },
        tickAmount: 6,
        labels: {
          style: { color: textColor, fontSize: '12px' },
          formatter: function () {
            const value = Number(this.value);
            if (!value) return '0';
            return formatDollars(value);
          },
          align: 'right',
          x: -2,
        },
      },
      tooltip: {
        animation: false,
        style: {
          color: 'white',
          fontSize: '16px',
        },
        backgroundColor: 'rgba(0,0,0,0.7)',
        borderRadius: 50,
        borderWidth: 0,
        shadow: false,
        padding: 12,
        formatter: function () {
          return `<span style="color:${this.color}; font-size: 24px;">∎</span>${
            this.series.name
          }: <span>${formatDollars(Number(this.y) || 0)} ${t('Energy Rates.per')} kWh</span><br/>`;
        },
      },
      plotOptions: {
        column: {
          borderRadius: 0,
        },
      },
      legend: {
        align: 'left',
        itemStyle: { textOverflow: 'auto' },
        itemMarginTop: 1,
        labelFormatter: function () {
          // @ts-expect-error - not in types but accessible
          const yValue = this.yData[0];
          const style = `color: ${textColor}; font-size: 16px; fontWeight: 400;`;
          return `<span style="${style}">${this.name}: ${formatDollars(yValue)} ${t('Energy Rates.per')} kWh</span>`;
        },
        symbolRadius: 0,
        padding: 0,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: t('Energy Rates.import rate').toUpperCase(),
          type: 'column',
          color: '#3DCD58',
          data: [
            {
              borderColor: '#3DCD58',
              y: importRate,
              marker: { symbol: 'square' },
            },
          ],
        },
        {
          name: t('Energy Rates.export rate').toUpperCase(),
          type: 'column',
          color: '#FFCF44',
          data: [
            {
              borderColor: '#FFCF44',
              y: exportRate,
              marker: { symbol: 'square' },
            },
          ],
        },
      ],
    };

    return chartOptions;
  }, [selectedSeasonIndex]);
}
