import React from 'react';

import { EnergyUsageV2, PowerUsageV2 } from 'clipsal-cortex-types/src/api/api-usage-v2';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { StatefulChart } from '../../../common/components/StatefulChart';
import { useActivityChartData } from '../common/use-activity-chart-data';
import { usePowerEnergyApplianceSeries } from '../common/use-power-energy-appliance-series';
import { MATTER_DEVICE_ACTIVITY_UI_CONFIG } from '../constants';

type Props = {
  isLoaded: boolean;
  selectedDateRangeType: DateRangeType;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  powerUsageData: PowerUsageV2[];
  energyUsageData: EnergyUsageV2[];
  selectedDeviceIds: number[];
  chartOpts?: {
    height: number; // in pixels
  };
};

export function MatterOnlyActivityChart({
  isLoaded,
  selectedDateRangeType,
  rangeTypeToSelectedPeriod,
  powerUsageData,
  energyUsageData,
  selectedDeviceIds,
  chartOpts = {
    height: 320,
  },
}: Props) {
  const isDayView = selectedDateRangeType === DateRangeType.Day;
  const { height } = chartOpts;

  const applianceSeries = usePowerEnergyApplianceSeries({
    isReady: isLoaded,
    isIndependentDevices: true,
    isDayView,
    powerUsageData,
    energyUsageData,
    selectedDeviceIds,
    chartOpts: { applianceArea: MATTER_DEVICE_ACTIVITY_UI_CONFIG },
  });

  const options = useActivityChartData({
    series: applianceSeries,
    selectedDateRangeType,
    rangeTypeToSelectedPeriod,
    chartOpts,
  });

  return (
    <StatefulChart
      isLoaded={isLoaded}
      isData={!!selectedDeviceIds.length}
      height={height}
      options={options}
      testId={'activity-chart-container'}
    />
  );
}
