import React, { MouseEvent, TouchEvent } from 'react';
import { Button, Flex, Icon, Radio, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
  title: string;
  text: string;
  Icon: typeof Icon;
  onClick: (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => void;
  isChecked: boolean;
  isDisabled: boolean;
  route?: string;
  'data-testid'?: string;
};

export function EnergyModeListItem({ title, text, Icon, onClick, isChecked, isDisabled, route, ...props }: Props) {
  const navigate = useNavigate();
  const textColor = useColorModeValue('customGrey.600', 'customGrey.400');
  const { t } = useTranslation();

  return (
    <Flex flexDirection="row" ml={4} my={3} onClick={!isDisabled ? onClick : undefined}>
      <Flex flexDirection="column" ml={2}>
        <Flex flexDirection="row" alignItems="center" mb={2}>
          <Icon mr={3} w={8} h={8} />
          <Text fontWeight={500}>{title}</Text>
        </Flex>

        <Text fontSize={16} color={textColor} fontWeight={500}>
          {text}
        </Text>
        {route && (
          <Button
            colorScheme="schneiderSkyBlue"
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`../${route}?direction=forward`);
            }}
            data-testid={`edit-${route}-designated-circuits-link`}
            mt={2}
            fontWeight={500}
            justifyContent="left"
            pl={0}
          >
            {t('Energy Modes.edit circuits')}
          </Button>
        )}
      </Flex>
      <Flex flexDirection="row" alignItems="center" justifyContent="flex-end" width="45%">
        <Radio data-testid={props['data-testid']} isChecked={isChecked} size="lg" isDisabled={isDisabled} />
      </Flex>
    </Flex>
  );
}
