import React from 'react';
import { useSelector } from 'react-redux';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { GENERIC_BATTERIES_MANUFACTURER_IDS } from '../../battery/constants';
import { selectSite } from '../../site/siteSlice';
import { ActivityView } from '../common/ActivityView';
import { useDateRangeOptions } from '../common/use-date-range-options';

export function SenseOnlyActivity() {
  const { is_consumption_site: isConsumptionSite, devices } = useSelector(selectSite);
  const hasBattery = devices.some(
    // TODO remove manufacturer_id when API provides historical data
    ({ device_type: deviceType, manufacturer_id: manufacturerId }) =>
      deviceType === 'BATTERY_PACK' && !GENERIC_BATTERIES_MANUFACTURER_IDS.includes(manufacturerId)
  );
  const DATE_RANGE_OPTIONS = useDateRangeOptions();

  return (
    <ActivityView
      options={{
        dateRange: DATE_RANGE_OPTIONS.filter((option) => option.value !== DateRangeType.Year),
        powerSources: { solar: !isConsumptionSite, grid: true, battery: hasBattery },
      }}
    />
  );
}
