import React, { useState } from 'react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react';

type AlertBannerProps = React.PropsWithChildren<{
  title: string;
  type: AlertProps['status'];
  isCollapsible: boolean;
  isDismissable: boolean;
  isDefaultCollapsed?: boolean;
  /** unique key for the component to track dismissal */
  localStorageDismissedKey?: string;
  containerTestId?: string;
  collapseTestId?: string;
}>;

export function AlertBanner({
  title,
  isCollapsible,
  isDismissable,
  type = 'warning',
  isDefaultCollapsed = false,
  containerTestId,
  collapseTestId,
  localStorageDismissedKey,
  children,
}: AlertBannerProps) {
  const [isCollapsed, setIsCollapsed] = useState(isDefaultCollapsed);
  const [isDismissed, setIsDismissed] = useState(
    !!localStorage.getItem(localStorageDismissedKey ?? 'alertBannerDismissed')
  );
  if (isDismissed) return null;

  return (
    <Alert status={type} borderRadius="md" boxShadow="lg" data-testid={containerTestId}>
      <Box w={'100%'}>
        <Flex alignItems="center">
          <AlertIcon />
          <AlertTitle>{title}</AlertTitle>
          {isCollapsible && (
            <IconButton
              size={'xs'}
              ml={'auto'}
              pb={2}
              variant="unstyled"
              aria-label="Collapse alert"
              icon={isCollapsed ? <AddIcon /> : <MinusIcon />}
              onClick={() => setIsCollapsed(!isCollapsed)}
              data-testid={collapseTestId}
            />
          )}
        </Flex>
        <Collapse in={!isCollapsed}>
          <Box mx={8} my={2}>
            <AlertDescription display="block">{children}</AlertDescription>
          </Box>
          <Flex>
            {isDismissable && (
              <Button
                onClick={() => {
                  localStorage.setItem(localStorageDismissedKey ?? 'alertBannerDismissed', 'true');
                  setIsDismissed(true);
                }}
                my={3}
                ml={'auto'}
              >
                <Text ml={2}> Don't show me again</Text>
              </Button>
            )}
          </Flex>
        </Collapse>
      </Box>
    </Alert>
  );
}
