import React, { useEffect, useState } from 'react';
import { Box, Center, Heading } from '@chakra-ui/react';
import Highcharts from 'highcharts';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import '../activity.scss';

import highchartsAccessibility from 'highcharts/modules/accessibility';

import { DateRangeType, DateRangeTypePicker } from '../../../common/components/DateRangeTypePicker';
import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { selectCurrentDayForSite } from '../../site/siteSlice';
import { useGetUsageQuery } from '../activityApi';
import { ActivityDatePicker } from '../common/ActivityDatePicker';
import { useDateRangeOptions } from '../common/use-date-range-options';
import { MAX_ACTIVITY_MATTER_DEVICES } from '../constants';
import { getInitialSelectedDates } from '../utils';
import { FilterByMatterDevice } from './FilterByMatterDevice';
import { MatterOnlyActivityChart } from './MatterOnlyActivityChart';

highchartsAccessibility(Highcharts);

type MatterActivityState = {
  selectedDateRangeType: DateRangeType;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  selectedDeviceIds: number[];
};

export function MatterOnlyActivity() {
  const currentDay = useSelector(selectCurrentDayForSite);
  const { t } = useTranslation();
  const DATE_RANGE_OPTIONS = useDateRangeOptions();

  const [state, setState] = useState<MatterActivityState>({
    selectedDateRangeType: DateRangeType.Day,
    rangeTypeToSelectedPeriod: getInitialSelectedDates(currentDay),
    selectedDeviceIds: [],
  });
  const { selectedDateRangeType, rangeTypeToSelectedPeriod, selectedDeviceIds } = state;

  const {
    data: usageData,
    isLoading: isUsageLoading,
    isFetching: isUsageFetching,
    isError: isUsageError,
  } = useGetUsageQuery(rangeTypeToSelectedPeriod[selectedDateRangeType], selectedDateRangeType, false, false);
  const { energyUsageData, powerUsageData, displayedDevices } = usageData;
  const isLoaded = !isUsageFetching && !isUsageLoading;

  useEffect(() => {
    // We use a separate `isLoaded` value to RTK query to ensure we select the correct devices after the data has loaded.
    // Whenever the displayed devices change, we want to update the selected devices to include all devices.
    if (isLoaded) {
      setState((prevState) => ({
        ...prevState,
        selectedDeviceIds: orderBy(displayedDevices, (device) => device.energySummary, 'desc')
          .slice(0, MAX_ACTIVITY_MATTER_DEVICES)
          .map((device) => device.appliance_id),
      }));
    }
  }, [displayedDevices, isLoaded]);

  async function handleChangeSelectedDate(newDate: Date | null) {
    setState((prevState) => ({
      ...prevState,
      rangeTypeToSelectedPeriod: {
        ...rangeTypeToSelectedPeriod,
        [selectedDateRangeType]: newDate,
      },
    }));
  }

  async function handleToggleDevice(selectedDeviceId: number, isToggled: boolean) {
    setState((prevState) => ({
      ...prevState,
      selectedDeviceIds: isToggled
        ? [...selectedDeviceIds, selectedDeviceId] // Add device to the list
        : selectedDeviceIds.filter((deviceId) => deviceId !== selectedDeviceId), // Remove device from the list
    }));
  }

  async function handleChangeSelectedDateRangeType(newDateRangeType: DateRangeType) {
    setState((prevState) => ({
      ...prevState,
      selectedDateRangeType: newDateRangeType,
    }));
  }

  return (
    <SlidingAnimationPageBase
      includeBottomNav={true}
      includeTopNavHamButton={true}
      title={t('Activity.activity')}
      includeSchneiderHamButton={true}
    >
      <Box px={3}>
        <DateRangeTypePicker
          dateRangeOptions={DATE_RANGE_OPTIONS}
          selectedDateRangeType={selectedDateRangeType}
          setSelectedDateRangeType={handleChangeSelectedDateRangeType}
        />

        <ActivityDatePicker
          isLoaded={isLoaded}
          selectedDateRangeType={selectedDateRangeType}
          rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
          onChangeDate={handleChangeSelectedDate}
        />

        {isUsageError ? (
          <Center h="320px" data-testid="no-data-error">
            <Heading textAlign="center">{t('Activity.error fetching data')}</Heading>
          </Center>
        ) : (
          <MatterOnlyActivityChart
            isLoaded={isLoaded}
            selectedDateRangeType={selectedDateRangeType}
            selectedDeviceIds={selectedDeviceIds}
            energyUsageData={energyUsageData}
            powerUsageData={powerUsageData}
            rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
          />
        )}
      </Box>

      <Box id="filters-list">
        <FilterByMatterDevice
          isLoaded={isLoaded}
          selectedDateRangeType={selectedDateRangeType}
          displayedDevices={displayedDevices}
          selectedDeviceIds={selectedDeviceIds}
          onToggleDevice={handleToggleDevice}
        />
      </Box>
    </SlidingAnimationPageBase>
  );
}
