import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    iconColor: '#fff',
  },
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle, defaultProps: { colorScheme: 'primaryBranding' } });
