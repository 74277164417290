import {
  SEConsentPlugin,
  SEConsentPurpose,
  SEConsentStatus,
} from '@schmap/cordova-plugin-se-consent/www/SEConsentPlugin';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';

import { ENV_TYPE_SHORTHAND } from '../../../env-type';
import { CIAM_IDMS_BASE_URL, getFreshEimTokens } from '../../auth/auth-helpers';

export const PREFERENCE_CENTER_ID =
  ENV_TYPE_SHORTHAND === 'STAGING' ? 'fb0c62da-83e5-449c-b6d5-29aead868bf5' : 'f30e5c97-d4c1-4bfb-b91b-bb1438a12f06';
export const COLLECTION_POINT_ID =
  ENV_TYPE_SHORTHAND === 'STAGING' ? 'f845701c-376c-445a-b50e-0b48230c4a71' : 'c8ec0910-f68e-47aa-9b85-919062a0e348';

const SE_APIGEE_KEY = import.meta.env[`VITE_${ENV_TYPE_SHORTHAND}_SE_APIGEE_KEY`] as string; // APIGEE Client ID
const SE_APIGEE_SECRET = import.meta.env[`VITE_${ENV_TYPE_SHORTHAND}_SE_APIGEE_SECRET`];

const CONFIGURATIONS = {
  config: {
    apigeeConfig: {
      apiKey: SE_APIGEE_KEY,
      apiSecret: SE_APIGEE_SECRET,
      baseApiUrl: CIAM_IDMS_BASE_URL,
    },
    preferenceCenterId: PREFERENCE_CENTER_ID,
    collectionPointId: COLLECTION_POINT_ID,
  },
};

// SE Consent plugin setup, see docs at: https://didactic-fiesta-3d48cfd4.pages.github.io/ --
// -- You need to be part of the SCHMAP Github organisation to access these docs.
export async function instantiateConsentManagement() {
  try {
    // Get fresh cIAM access token
    const { ciamIdpAccessToken } = await getFreshEimTokens();
    await initializeWithConfig();
    await activate();

    const currentUser = await Auth.currentAuthenticatedUser();
    await setUserId(currentUser.attributes['email']);

    await setAccessToken(ciamIdpAccessToken);

    const consentStatus = await refreshConsent();
    if (consentStatus.isNeedToShowConsent) {
      await showConsentScreen();
    }
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
  }
}

export async function configureConsentManagementSettings() {
  try {
    // Get fresh cIAM access token
    const { ciamIdpAccessToken } = await getFreshEimTokens();
    await setAccessToken(ciamIdpAccessToken);
    await showConsentScreen();
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function initializeWithConfig() {
  return new Promise<void>((resolve, reject) => {
    SEConsentPlugin.initializeWithConfig(
      CONFIGURATIONS,
      'config', // Active config name. Must be a key from "configurations" object.
      () => {
        resolve();
      },
      (error) => reject('initializeWithConfig error: ' + error)
    );
  });
}

function activate() {
  return new Promise<void>((resolve, reject) => {
    SEConsentPlugin.activate(
      () => {
        resolve();
      },
      (error) => reject('activate error: ' + error)
    );
  });
}

function setUserId(email: string) {
  return new Promise<void>((resolve, reject) => {
    SEConsentPlugin.setUserId(
      email,
      () => {
        resolve();
      },
      (error) => reject('setUserId error: ' + error)
    );
  });
}

function setAccessToken(accessToken: string) {
  return new Promise<void>((resolve, reject) => {
    SEConsentPlugin.setAccessToken(
      accessToken,
      () => {
        resolve();
      },
      (error) => reject('setAccessToken error: ' + error)
    );
  });
}

function refreshConsent() {
  return new Promise<SEConsentStatus>((resolve, reject) => {
    SEConsentPlugin.refreshConsent(
      (consentStatus) => {
        resolve(consentStatus);
      },
      (error) => reject('refreshConsent error: ' + error)
    );
  });
}

function showConsentScreen() {
  return new Promise<SEConsentPurpose[]>((resolve, reject) => {
    SEConsentPlugin.showConsentScreen(
      (purposes) => {
        resolve(purposes);
      },
      (error) => reject('showConsentScreen error: ' + error)
    );
  });
}
