import React from 'react';
import { BarcodeScanner, ScanResult } from '@capacitor-community/barcode-scanner';
import { ButtonProps, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { didUserGrantBarcodePermission } from 'clipsal-cortex-utils/src/common';

import { ArcButton } from './ArcButton';

type Props = {
  buttonText?: string;
  onScan: (result: ScanResult) => void;
  onScanError: (error: ScanError) => void;
} & ButtonProps;

type ScanError = 'INVALID_QR_CODE' | 'PERMISSION_ERROR' | 'UNKNOWN_ERROR';

export function ScanBarcodeButton({ buttonText, onScan, onScanError, ...buttonProps }: Props) {
  const toast = useToast({ isClosable: true });
  const { t } = useTranslation();
  if (!buttonText) {
    buttonText = t('Common.scan qr code');
  }

  async function handleScanBarcode() {
    try {
      const hasPermission = await didUserGrantBarcodePermission();
      if (hasPermission) {
        await BarcodeScanner.hideBackground();
        document.body.classList.add('qrscanner');
        const result = await BarcodeScanner.startScan();
        await BarcodeScanner.stopScan();
        await BarcodeScanner.showBackground();
        document.body.classList.remove('qrscanner');

        if (result.hasContent) {
          onScan(result);
        } else {
          onScanError('INVALID_QR_CODE');
          toast({
            title: t('Common.invalid qr code'),
            description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
            status: 'error',
          });
        }
      } else {
        onScanError('PERMISSION_ERROR');
        toast({
          title: t('Common.camera permission required for qr code'),
          description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
          status: 'error',
        });
      }
    } catch (error) {
      onScanError('UNKNOWN_ERROR');
      toast({
        title: t('Common.something went wrong'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  }

  return (
    <ArcButton onClick={handleScanBarcode} {...buttonProps} arcColor="#3DCD57">
      {buttonText}
    </ArcButton>
  );
}
