import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { toZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Notification } from 'clipsal-cortex-types/src/api/api-notifications';
import { checkIfDatesAreOnSameDay } from 'clipsal-cortex-utils/src/calculations/date-utils';
import { formatTo12HourTime } from 'clipsal-cortex-utils/src/formatting/formatting';

import { MONTHS } from '../../common/constants';
import { selectSite } from '../site/siteSlice';

type Props = {
  notification: Notification;
};

export function NotificationTimestamp({ notification }: Props) {
  const site = useSelector(selectSite);
  const timestampColor = useColorModeValue('customGrey.500', 'customGrey.300');
  // Check if sent on the same day as the site's current day
  const siteDate = toZonedTime(new Date(), site.timezone);
  const notificationDate = toZonedTime(new Date(notification.sent_datetime + 'Z'), site.timezone);
  const { t } = useTranslation();

  if (checkIfDatesAreOnSameDay(siteDate, notificationDate)) {
    return (
      <Text color={timestampColor} fontSize={'sm'} textAlign="end">
        {formatTo12HourTime(notificationDate)}
      </Text>
    );
  }

  // Just show 'Yesterday' if it's the day before
  const comparisonDate = new Date(siteDate);
  comparisonDate.setDate(comparisonDate.getDate() - 1);

  if (checkIfDatesAreOnSameDay(comparisonDate, notificationDate)) {
    return (
      <Text color={timestampColor} fontSize={'sm'} textAlign="end">
        {t('Notifications.yesterday')} {formatTo12HourTime(notificationDate)}
      </Text>
    );
  }

  return (
    <Box data-testid="notification-timestamp">
      <Text color={timestampColor} fontSize="xs" textAlign="end">
        {t(`Common.${MONTHS[notificationDate.getMonth()].toLowerCase()}`).slice(0, 3)} {notificationDate.getDate()}
      </Text>
      <Text color={timestampColor} fontSize="xs" textAlign="end">
        {formatTo12HourTime(notificationDate)}
      </Text>
    </Box>
  );
}
