import React, { useMemo, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  ComponentWithAs,
  Flex,
  FormControl,
  FormErrorMessage,
  IconProps,
  Skeleton,
  SkeletonCircle,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { CustomSelect, SelectOption } from '../../../common/components/CustomSelect';
import { SlidingAnimationPageBase } from '../../../common/components/SlidingAnimationPageBase';
import { OutlineGradientIcon } from '../../../styles/custom-icons';
import {
  DEVICE_ICON_RESPONSIVE_SIZE,
  getIconForDevice,
  MANUFACTURER_ID_SE_SEM,
  MANUFACTURER_ID_SEM,
} from '../../devices/devices-helper';
import { useGetDevicesQuery } from '../../devices/devicesApi';
import { useGetSwitchesByManufacturerQuery } from '../../devices/switches/switchApi';
import { useAddDeviceToSwitchGroupMutation, useGetSwitchGroupForType } from '../../devices/switches/switchGroupApi';
import { useGetEnergyModeUIConfig } from './energy-modes-helpers';

const FORM_ID = 'add-designated-circuit-form'; // Passed to the form to be able to submit with an external button

export type AddDeviceFormData = {
  selectedCircuit: SelectOption;
};

export function AddDesignatedCircuit() {
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true });
  const [Icon, setIcon] = useState<ComponentWithAs<'svg', IconProps>>(getIconForDevice());
  const energyMode = useGetEnergyModeUIConfig();
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const { t } = useTranslation();

  const addDesignatedCircuitSchema = yup.object().shape({
    selectedCircuit: yup.object().required().label(t('Devices.smart circuit')),
  });
  const {
    data: switches,
    isLoading: isSwitchesLoading,
    isError: isSwitchesError,
  } = useGetSwitchesByManufacturerQuery([MANUFACTURER_ID_SEM, MANUFACTURER_ID_SE_SEM]);

  // We need to grab appliances because switches don't include the user_assignment field (for the icon)
  const { data: appliances, isLoading: isDevicesLoading, isError: isDevicesError } = useGetDevicesQuery();
  const {
    data: switchGroup,
    isLoading: isSwitchGroupsLoading,
    isError: isSwitchGroupsError,
  } = useGetSwitchGroupForType(energyMode.groupType!);

  const isLoaded = !isSwitchesLoading && !isDevicesLoading && !isSwitchGroupsLoading;
  const isError = isSwitchesError || isDevicesError || isSwitchGroupsError;

  const [addDeviceToSwitchGroup] = useAddDeviceToSwitchGroupMutation();
  const handleAddDevice = async ({ selectedCircuit }: AddDeviceFormData) => {
    try {
      await addDeviceToSwitchGroup({ switchId: Number(selectedCircuit.value), groupId: switchGroup!.id }).unwrap();
    } catch (error) {
      console.error(error);
      toast({
        status: 'error',
        title: t('Energy Modes.error adding circuit'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
      });
    }
    navigate(`../../${energyMode.route}?direction=back`);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddDeviceFormData>({
    resolver: yupResolver(addDesignatedCircuitSchema),
  });

  const switchGroupDeviceIds = useMemo(() => {
    return switchGroup?.control_devices.map((switchGroupDevice) => switchGroupDevice.control_device.id);
  }, [switchGroup]);

  const designatedCircuitOptions = useMemo(() => {
    return switches
      .filter((switchItem) => !switchGroupDeviceIds?.includes(switchItem.id))
      .map(
        (switchItem) => ({
          value: switchItem.id.toString(),
          label: switchItem.site_switch_label,
          icon: getIconForDevice(
            appliances.find((appliance) => appliance.switch_id === switchItem.id)?.user_assignment
          ),
        }),
        []
      );
  }, [switches, appliances, switchGroup]);

  return (
    <SlidingAnimationPageBase
      backURL={`../../${energyMode.route}?direction=back`}
      includeBottomNav
      title={t('Energy Modes.add circuit')}
      customTopNavButton={
        <Button
          data-testid="save-button"
          variant={'ghost'}
          size={'sm'}
          colorScheme={'schneiderSkyBlue'}
          hidden={isError}
          type="submit"
          form={FORM_ID}
          fontWeight={400}
          whiteSpace="pre-wrap"
          overflowWrap="anywhere"
        >
          {t('Common.save')}
        </Button>
      }
    >
      <Box
        px={3}
        as="form"
        data-testid="add-designated-circuit-form"
        id={FORM_ID}
        onSubmit={handleSubmit(handleAddDevice)}
      >
        {isError ? (
          <Alert status="error" variant="left-accent" data-testid="error-alert">
            <AlertIcon />
            <Box>
              <AlertTitle>{t('Common.notice')}</AlertTitle>
              <AlertDescription>
                {t('Energy Modes.error adding circuit')}.{' '}
                {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}.
              </AlertDescription>
            </Box>
          </Alert>
        ) : (
          <>
            <SkeletonCircle
              isLoaded={isLoaded}
              w={DEVICE_ICON_RESPONSIVE_SIZE}
              h={DEVICE_ICON_RESPONSIVE_SIZE}
              m="auto"
            >
              <Center
                flexDirection="column"
                mb={1}
                w={DEVICE_ICON_RESPONSIVE_SIZE}
                h={DEVICE_ICON_RESPONSIVE_SIZE}
                pos="relative"
                margin="auto"
              >
                <OutlineGradientIcon
                  w={DEVICE_ICON_RESPONSIVE_SIZE}
                  h={DEVICE_ICON_RESPONSIVE_SIZE}
                  color={iconColor}
                />
                <Center w="100%" pos="absolute">
                  {Icon && <Icon color={iconColor} w={[120, 180]} h={[120, 180]} data-testid="device-icon" />}
                </Center>
              </Center>
            </SkeletonCircle>
            <Skeleton isLoaded={isLoaded} mt={1}>
              <Flex flexDirection="column">
                <Controller
                  control={control}
                  name="selectedCircuit"
                  render={({ field }) => (
                    <FormControl
                      mb={4}
                      data-testid="select-designated-circuit-input"
                      isInvalid={!!errors?.selectedCircuit}
                    >
                      <CustomSelect
                        {...field}
                        onChange={(e) => {
                          e?.icon && setIcon(e.icon);
                          field.onChange(e);
                        }}
                        options={designatedCircuitOptions}
                        placeholder={`${t('Energy Modes.select circuit')}...`}
                        isSearchable={false}
                      />
                      <FormErrorMessage data-testid="selected-designated-circuit-error-message">
                        {errors?.selectedCircuit?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Flex>
            </Skeleton>
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
