import React, { useMemo, useState } from 'react';
import { Wifi } from '@capacitor-community/wifi';
import { Network } from '@capacitor/network';
import { Center, FormControl, FormErrorMessage, Heading, Image, Input, Text, useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useOnMount } from 'clipsal-cortex-utils/src/hooks/use-on-mount';

import semQrCodeImg from '../../../../assets/images/sem_qr_code_scan.svg';
import { get } from '../../../../common/api/api-helpers';
import { ArcButton } from '../../../../common/components/ArcButton';
import { SlidingAnimationPageBase } from '../../../../common/components/SlidingAnimationPageBase';
import { selectSite } from '../../../site/siteSlice';
import { fetchAccountId } from './utils';

type State = {
  isSerialNumInvalid: boolean;
  isSubmitting: boolean;
  serialNumber: string;
};

const INITIAL_STATE: State = {
  isSerialNumInvalid: false,
  isSubmitting: false,
  serialNumber: '',
};

/* istanbul ignore next -- @preserve */
export function MeterManualSerialEntry() {
  const { site_id: siteId } = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const toast = useToast({ isClosable: true, duration: null });
  const [state, setState] = useState<State>(INITIAL_STATE);
  const { serialNumber, isSerialNumInvalid, isSubmitting } = state;
  const { t } = useTranslation();
  const isRegisteredDevice = search.get('shouldRegisterDevice') === 'false';
  const shouldSkipWifiSetup = search.get('shouldSkipWifiSetup') === 'true';
  const serialNumberFromParams = search.get('serialNumber');
  useOnMount(() => {
    serialNumberFromParams && setState((prevState) => ({ ...prevState, serialNumber: serialNumberFromParams }));
  });
  async function handleSubmitSerialNumber() {
    search.set('serialNumber', serialNumber);
    search.set('direction', 'forward');
    if (shouldSkipWifiSetup) navigate(`../register?${search.toString()}`);
    else handleConnectToNetwork();
  }

  async function handleConnectToNetwork() {
    setState((prevState) => ({ ...prevState, isSubmitting: true }));

    // Before proceeding with SEM connections, we need to ensure the user is connected to the internet.
    // They could still be on the SEM wifi network if they retry at this point. Due to iOS restrictions,
    // we are unable to manually disconnect them from the SEM wifi network.
    const { connected: isConnectedToInternet } = await Network.getStatus();
    if (!isConnectedToInternet) {
      toast({
        title: t('Common.not connected to internet'),
        description: `${t('Set Up Hardware.make sure you are connected')} ${t(
          'Set Up Hardware.you may need to reconnect'
        )}`,
        status: 'error',
      });
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
      return;
    }

    try {
      const { password } = await get<{ password: string }>(`/v1/sense/${serialNumber}/password`);

      // Before connecting to the Wi-Fi, we need to fetch the accountId for the site.
      const { account_id: accountId, status } = await fetchAccountId(siteId, serialNumber);

      // SEM is already registered in Prosumer cloud, is online, and we are not performing Wi-Fi reconfig --
      // -- on an existing SEM, we can skip the connection tests
      if (shouldSkipWifiSetup || (!!accountId && status === 'ONLINE' && !isRegisteredDevice))
        return navigate(`../register?${search.toString()}`);

      try {
        const ssid = `Schneider Monitor ${serialNumber}`;
        await Wifi.connect({ ssid, password, isHiddenSsid: false });
        search.set('accountId', accountId?.toString() ?? '');
        navigate(`../connection_tests?${search.toString()}`);
      } catch (e) {
        setState((prevState) => ({ ...prevState, isSerialNumInvalid: true }));
        throw e;
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast({
        title: t('Set Up Hardware.error getting sem connect details'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  }

  // This ensures when coming from hardware details page, we can go back to the same page when going back
  const urlSearchParamsToGoBack = useMemo(() => {
    search.set('direction', 'back');
    return search.toString();
  }, []);

  return (
    <SlidingAnimationPageBase
      title={t('Common.device setup')}
      backURL={`../connect_instructions?${urlSearchParamsToGoBack}`}
    >
      <Center flexDirection="column" px={3} mt={5} textAlign="center">
        <Image w={'70%'} my={4} src={semQrCodeImg} alt="Schneider Energy Monitor sketch" />

        <Heading mt={2}>{t('Set Up Hardware.type serial number of device')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.search your wifi for sem')}</Text>
        <Text mt={1}>{t('Set Up Hardware.for example sem wifi')}</Text>

        <FormControl mt={3} isInvalid={isSerialNumInvalid}>
          <Input
            data-testid={'serial-number-input'}
            value={serialNumber}
            onChange={(e) => setState((p) => ({ ...p, serialNumber: e.target.value, isSerialNumInvalid: false }))}
            borderRadius={0}
            type="text"
            data-private
            background="white"
            _dark={{
              background: '#363E40',
            }}
            borderColor={'#9FA0A4'}
          />
          <FormErrorMessage>{t('Set Up Hardware.no wifi for serial')}</FormErrorMessage>
        </FormControl>

        <ArcButton
          data-testid={'submit-serial-number'}
          isLoading={isSubmitting}
          onClick={handleSubmitSerialNumber}
          isDisabled={!serialNumber}
          mt={8}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {t('Common.connect')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
